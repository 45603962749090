import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import PencilIcon from 'mdi-react/PencilIcon'
import SchoolIcon from 'mdi-react/SchoolIcon'
import TeacherIcon from 'mdi-react/TeacherIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import EyeIcon from 'mdi-react/EyeIcon'
import { taasStudentLink, taasTeacherLink } from '../links'

const StyledLink = styled(Link)`
  color: inherit;
`

const UserInfo = styled.div`
  padding: 24px;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  clear: both;

  .username {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: left;
    margin-right: 50px;
  }

  .email {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
  }
`

const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0;

  .details {
    flex-grow: 1;
    width: 100%;
  }

  .user {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .name {
    font-weight: bold;
    margin-right: 10px;
  }

  .chip {
    flex-shrink: 0;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const TaasUserTile = ({
  user,
  onEdit,
  onDelete,
  type,
  onTeacherEnrolments,
  onStudentEnrolments,
  onStudentObservers,
  onStudentFacilitators
}) => (
  <div>
    <Container>
      <div className="details">
        <div className="user">
          <div className="name">
            {type === 'students' ? (
              <StyledLink to={taasStudentLink(user, '')}>
                {user.firstname} {user.lastname}
              </StyledLink>
            ) : type === 'teachers' ? (
              <StyledLink to={taasTeacherLink(user, '')}>
                {user.firstname} {user.lastname}
              </StyledLink>
            ) : (
              <span>
                {user.firstname} {user.lastname}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="edit">
        {type !== 'teachers' && (
          <IconButton title={`Edit User`} onClick={() => onEdit(user)}>
            <PencilIcon />
          </IconButton>
        )}
        {type === 'teachers' && (
          <IconButton
            title="Manage Enrollments"
            onClick={() => onTeacherEnrolments(user)}>
            <SchoolIcon />
          </IconButton>
        )}
        {false && (
          <IconButton title={`De-activate User`} onClick={() => onDelete(user)}>
            <DeleteIcon />
          </IconButton>
        )}

        {false && type === 'students' && (
          <React.Fragment>
            <IconButton
              title="Manage Enrollments"
              onClick={() => onStudentEnrolments(user)}>
              <SchoolIcon />
            </IconButton>
            <IconButton
              title="Manage Observers"
              onClick={() => onStudentObservers(user)}>
              <EyeIcon />
            </IconButton>
            <IconButton
              title="Manage Facilitators"
              onClick={() => onStudentFacilitators(user)}>
              <TeacherIcon />
            </IconButton>
          </React.Fragment>
        )}
      </div>
    </Container>
    <UserInfo>
      <div className="username">Username: {user.username}</div>
      <div className="email">Email: {user.email}</div>
    </UserInfo>
  </div>
)

TaasUserTile.defaultProps = {
  type: 'students'
}

TaasUserTile.propTypes = {
  user: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onTeacherEnrolments: PropTypes.func,
  onStudentEnrolments: PropTypes.func.isRequired,
  onStudentFacilitators: PropTypes.func.isRequired,
  onStudentObservers: PropTypes.func.isRequired
}

export default TaasUserTile
