import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CourseCard from '../components/CourseCard'
import SimpleSearch from '../components/SimpleSearch'

const StyledCourses = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const RemainingSpace = styled.div`
  width: 215px;
  height: 0;
  margin-right: 10px;
`

const fillEmpty = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const CourseSearch = ({
  filter,
  documents,
  selection,
  onSelectionChange,
  ...rest
}) => (
  <SimpleSearch
    filter={filter}
    fields={['title', 'eid', 'edl']}
    documents={documents}>
    {results => {
      if (filter === '') results = documents
      return (
        <StyledCourses>
          {results.map(course => (
            <CourseCard
              key={course.id}
              course={course}
              isSelected={selection.includes(course.id)}
              onSelectionChange={val => onSelectionChange(course.id, val)}
              {...rest}
            />
          ))}
          {fillEmpty.map(key => (
            <RemainingSpace key={key} />
          ))}
        </StyledCourses>
      )
    }}
  </SimpleSearch>
)

CourseSearch.propTypes = {
  filter: PropTypes.string,
  options: PropTypes.object,
  documents: PropTypes.array,
  selection: PropTypes.array,
  onSelectionChange: PropTypes.func
}

CourseSearch.defaultProps = {
  filter: '',
  documents: [],
  selection: []
}

export default CourseSearch
