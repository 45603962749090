import React from 'react'
import { connect } from 'react-redux'
import TaasStudentList from './TaasStudentList'
import Screen from '../../components/Screen'
import {
  fetchStudents,
  deleteStudent,
  setBuzzInstance
} from '../../actions/taas'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'
import { channelLink, taasStudentLink } from '../../links'
import PlusIcon from 'mdi-react/PlusIcon'

class TaasStudentListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, students: [], error: null, searchTerm: '' }
    this.props.setAppBarState({
      visible: true,
      title: 'Students',
      rightIcon: <PlusIcon />,
      handleRight: () => {}
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.channel && !prevProps.channel) {
      this.props.setAppBarState({
        visible: true,
        title: `${this.props.channel.name} Students`,
        rightIcon: <PlusIcon />,
        canAdd: true,
        handleRight: () =>
          this.props.history.push(
            channelLink(this.props.channel, '/taas/students/create')
          )
      })
    }
  }

  handleSearch = searchTerm => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.setState({ searchTerm }), 300)
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, students: [] })
      const students = await this.props.fetchStudents()
      this.setState({ loading: false, students })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, students, searchTerm } = this.state

    return (
      <Screen
        name="students"
        isLoading={isLoading || loading}
        error={propError}>
        <GateExists exists={this.props.channel && this.props.organization}>
          <TaasStudentList
            {...this.props}
            students={students}
            searchTerm={searchTerm}
            onSearch={this.handleSearch}
            error={stateError}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const channel = getChannel(ownProps.match.params.channel)(state)
  return {
    user: state.auth.userData,
    channel,
    organization: getOrganization(ownProps.match.params.organization)(state),
    isLoading: state.organizations.isFetching || state.channels.isFetching,
    instance: channel ? state.taas[channel.id] : null
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchStudents: () => fetchStudents(ownProps.match.params.channel),
  onStudentEdit: student =>
    ownProps.history.push(taasStudentLink(student, '/edit')),
  onStudentDelete: student => dispatch(deleteStudent(student)),
  onStudentEnrolments: student =>
    ownProps.history.push(taasStudentLink(student, '/enrolments')),
  onStudentFacilitators: student =>
    ownProps.history.push(taasStudentLink(student, '/facilitators')),
  onStudentObservers: student =>
    ownProps.history.push(taasStudentLink(student, '/observers')),
  onFacilitators: channel =>
    ownProps.history.push(channelLink(channel, '/taas/facilitators')),
  onObservers: channel =>
    ownProps.history.push(channelLink(channel, '/taas/observers')),
  onTeachers: channel =>
    ownProps.history.push(channelLink(channel, '/taas/teachers')),
  onCourses: channel =>
    ownProps.history.push(channelLink(channel, '/taas/courses')),
  onImportUsers: channel =>
    ownProps.history.push(channelLink(channel, '/taas/import-users')),
  onImportEnrolments: channel =>
    ownProps.history.push(channelLink(channel, '/taas/import-enrolments')),
  onHelp: () => {
    window.open('/eDL Parent-Student Welcome Letter.pdf', '_blank')
  },
  onInstanceChange: (channel, instance) => {
    dispatch(setBuzzInstance(channel, instance))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasStudentListContainer)
