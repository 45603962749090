import React from 'react'
import { connect } from 'react-redux'
import TaasStudentEdit from './TaasStudentEdit'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import { channelsFetchAwait } from '../../actions/channels'
import { updateStudent, fetchStudent } from '../../actions/taas'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'

class TaasStudentEditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      student: null,
      loading: false,
      error: null
    }
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true })
      const student = await this.props.fetchStudent()
      this.setState({ loading: false, student })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    return (
      <Screen
        name="students"
        isLoading={this.props.isLoading || this.state.loading}
        error={this.props.error || this.state.error}>
        <GateExists
          exists={
            this.props.channel && this.props.organization && this.state.student
          }>
          <TaasStudentEdit {...this.props} student={this.state.student} />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchStudent: () =>
    fetchStudent(ownProps.match.params.channel, ownProps.match.params.student),
  onSubmit: data =>
    dispatch(
      updateStudent(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        ownProps.match.params.student,
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasStudentEditContainer)
