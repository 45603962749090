import React from 'react'
import { connect } from 'react-redux'
import RemoveFinalGrade from './RemoveFinalGrade'
import Screen from '../../components/Screen'
import {
  geniusCheckEnrollment,
  geniusRemoveEnrollmentSubmit
} from '../../actions/tools'

class RemoveFinalGradeContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      id: '',
      enrollment: null
    }
  }

  handleReview = async data => {
    this.setState({
      id: data.enrollmentId,
      enrollment: await this.props.checkEnrollment(data)
    })
  }

  handleConfirm = async () => {
    if (!this.state.enrollment || !this.state.id) {
      return
    }
    await this.props.removeEnrollment(this.state.id)
  }

  render() {
    return (
      <Screen name="remove genius final grade">
        <RemoveFinalGrade
          enrollment={this.state.enrollment}
          onReview={this.handleReview}
          onConfirm={this.handleConfirm}
          {...this.props}
        />
      </Screen>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  checkEnrollment: ({ enrollmentId }) => geniusCheckEnrollment(enrollmentId),
  removeEnrollment: id => dispatch(geniusRemoveEnrollmentSubmit(id))
})

export default connect(null, mapDispatchToProps)(RemoveFinalGradeContainer)
