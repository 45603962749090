import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Semver extends Component {
  render() {
    return (
      <div className="semver">
        <p className="version">v{this.props.version}</p>
        {this.props.isLatest && <div className="state">Latest</div>}
        <style>{`
          .semver {
            display: flex;
            align-items: center;
          }
          .version {
            margin: 0;
          }
          .state {
            height: 20px;
            line-height: 20px;
            color: white;
            font-size: 12px;
            margin: 0;
            margin-left: 50px;
            padding: 0px 10px 0px 10px;
            background: #75c621;
          }
        `}</style>
      </div>
    )
  }
}

Semver.propTypes = {
  version: PropTypes.string,
  isLatest: PropTypes.bool
}

export default Semver
