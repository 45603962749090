import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '../Spinner'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ServerError from '../../components/ServerError'

const FormLayout = ({
  onSubmit,
  submissionError,
  fieldComponents,
  buttonClassName,
  submitText,
  children,
  submitting,
  disabled,
  formOptions,
  formClassName
}) => (
  <Grid item>
    {submissionError && <ServerError error={submissionError} />}
    <form
      onSubmit={onSubmit}
      className={`form ${formClassName}`}
      {...formOptions}>
      <div className="white-box item-box">
        <div className="inner">
          <div className="row form-row">{fieldComponents}</div>

          <div style={{ marginBottom: '20px' }}>{children}</div>

          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            className={buttonClassName}
            type="submit">
            {submitText}
          </Button>
          <Spinner loading={submitting} textOnly />
        </div>
      </div>
    </form>
  </Grid>
)

FormLayout.defaultProps = {
  buttonClassName: 'btn',
  submitText: 'Submit',
  submitting: false,
  disabled: false,
  children: '',
  formOptions: {},
  formClassName: ''
}

FormLayout.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldComponents: PropTypes.arrayOf(PropTypes.node).isRequired,
  buttonClassName: PropTypes.string,
  submitText: PropTypes.string,
  submissionError: PropTypes.object,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  formOptions: PropTypes.object,
  formClassName: PropTypes.string
}

export default FormLayout
