import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { taasStudentLink } from '../../links'
import TaasLinkedUserSearch from '../../components/TaasLinkedUserSearch'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasEnrolmentListItem from '../../components/TaasEnrolmentListItem'

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

/* eslint-disable react/prop-types */

const TaasStudentView = ({
  organization,
  channel,
  student,
  enrolments,
  onStudentEdit,
  onStudentDelete,
  onEnrolmentAdd,
  onEnrolmentEdit,
  onEnrolmentDisable,
  observers,
  onStudentObservers,
  onObserverAdd,
  onObserverRemove,
  facilitators,
  onStudentFacilitators,
  onFacilitatorAdd,
  onFacilitatorRemove
}) => {
  const title = `${student.firstname} ${student.lastname}`
  return (
    <div>
      <Breadcrumbs
        links={[
          { title: 'Organizations', href: '/organizations' },
          {
            title: organization.name,
            href: `/organizations/${organization.id}`
          },
          {
            title: 'Channels',
            href: `/organizations/${organization.id}/channels`
          },
          {
            title: channel.name,
            href: `/organizations/${organization.id}/channels/${channel.id}`
          },
          {
            title: 'Students',
            href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
          }
        ]}
      />

      <Card>
        <CardHeader className="title" title={title} />
        <CardContent className="description">
          <div style={{ float: 'right', marginTop: '-70px' }}>
            <Button variant="contained" onClick={() => onStudentEdit(student)}>
              Edit
            </Button>
            {false && (
              <Button
                variant="contained"
                onClick={() => onStudentDelete(student)}>
                Disable
              </Button>
            )}
          </div>
          <div>
            <strong>ID</strong>: {student.id}
          </div>
          <div>
            <strong>First Name</strong>: {student.firstname}
          </div>
          <div>
            <strong>Last Name</strong>: {student.lastname}
          </div>
          <div>
            <strong>Username</strong>: {student.username}
          </div>
          <div>
            <strong>Email Address</strong>: {student.email}
          </div>
        </CardContent>
      </Card>

      <Card style={{ marginTop: '20px' }}>
        <CardHeader
          className="title"
          title={
            <StyledLink to={taasStudentLink(student, '/enrolments')}>
              Enrolments ({enrolments.length})
            </StyledLink>
          }
        />
        <CardContent className="description">
          <div style={{ float: 'right', marginTop: '-70px' }}>
            <Button variant="contained" onClick={() => onEnrolmentAdd(student)}>
              Add
            </Button>
          </div>
          <ul>
            {enrolments.slice(0, 5).map(enrolment => (
              <TaasEnrolmentListItem
                key={enrolment.id}
                enrolment={enrolment}
                onEdit={enrolment => onEnrolmentEdit(enrolment)}
                onDelete={enrolment =>
                  onEnrolmentDisable(channel, student, enrolment)
                }
              />
            ))}
          </ul>
        </CardContent>
      </Card>

      {false && (
        <>
          <Card style={{ marginTop: '20px' }}>
            <CardHeader
              className="title"
              title={
                <StyledLink to={taasStudentLink(student, '/facilitators')}>
                  Facilitators ({facilitators.length})
                </StyledLink>
              }
            />
            <CardContent className="description">
              <div style={{ float: 'right', marginTop: '-70px' }}>
                <Button
                  variant="contained"
                  onClick={() => onStudentFacilitators(student)}>
                  Add
                </Button>
              </div>
              <TaasLinkedUserSearch
                student={student}
                onAdd={onFacilitatorAdd}
                onRemove={onFacilitatorRemove}
                documents={facilitators.slice(0, 5)}
              />
            </CardContent>
          </Card>

          <Card style={{ marginTop: '20px' }}>
            <CardHeader
              className="title"
              title={
                <StyledLink to={taasStudentLink(student, '/observers')}>
                  Observers ({observers.length})
                </StyledLink>
              }
            />
            <CardContent className="description">
              <div style={{ float: 'right', marginTop: '-70px' }}>
                <Button
                  variant="contained"
                  onClick={() => onStudentObservers(student)}>
                  Add
                </Button>
              </div>
              <TaasLinkedUserSearch
                student={student}
                onAdd={onObserverAdd}
                onRemove={onObserverRemove}
                documents={observers.slice(0, 5)}
              />
            </CardContent>
          </Card>
        </>
      )}
    </div>
  )
}

TaasStudentView.propTypes = {
  organization: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired
}

export default TaasStudentView
