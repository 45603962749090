import joi from 'joi'
import createForm from '../../components/form/createForm'
import FileCsvInput from '../../components/form/elements/FileCsvInput'

const csvSchema = joi.array().items(
  joi.object({
    date: joi.string().required(),
    partner: joi.string().required(),
    lob: joi.string().required(),
    course: joi.string().required(),
    lms: joi
      .string()
      .allow(null)
      .allow(''),
    enrolments: joi.number().required()
  })
)

export default ({ onSubmit, ...props }) => {
  return createForm({
    fields: [
      {
        id: 'file',
        label: 'CSV File',
        inputComponent: FileCsvInput,
        validation: csvSchema,
        required: true
      }
    ],
    layoutOptions: {
      submitText: 'Submit Report'
    },
    onSubmit: async data => {
      return onSubmit(data.file)
    },
    ...props
  })
}
