import { connect } from 'react-redux'
import Courses from '../pages/Courses'
import { setAppBarState } from '../actions/ui'
import {
  fetchCoursesAwait,
  selectionAdd,
  selectionRemove
} from '../actions/courses'
import { getAllFolders } from '../selectors'

const mapStateToProps = (state, ownProps) => {
  let courses = state.courses.items
  let selection = state.courses.selection
  let available = []

  return {
    allFolders: getAllFolders(state),
    subscribed: courses,
    selection,
    available,
    isFetching: state.courses.isFetching,
    fetchError: state.courses.error.fetch,
    folderId: ownProps.match.params.id
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCourses: filter => {
    dispatch(fetchCoursesAwait(filter))
  },
  setAppBarState: appBarState => {
    dispatch(setAppBarState(appBarState))
  },
  onSelect: ids => {
    dispatch(selectionAdd(ids))
  },
  onDeselect: ids => {
    dispatch(selectionRemove(ids))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Courses)
