import React from 'react'
import { connect } from 'react-redux'
import TaasStudentEnrolmentList from './TaasStudentEnrolmentList'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import {
  fetchStudent,
  fetchStudentEnrolments,
  unenrolStudent
} from '../../actions/taas'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'
import PlusIcon from 'mdi-react/PlusIcon'
import { taasEnrolment } from '../../links'

class TaasStudentEnrolmentListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, students: [], error: null }
    this.props.setAppBarState({
      visible: true,
      title: 'Student Enrollments',
      rightIcon: <PlusIcon />,
      canAdd: true,
      handleRight: () => {
        const { organization, channel, student } = props.match.params
        this.props.history.push(
          `/organizations/${organization}/channels/${channel}/taas/students/${student}/enrolments/create`
        )
      }
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, student: null, enrolments: [] })
      const [student, enrolments] = await Promise.all([
        this.props.fetchStudent(),
        this.props.fetchStudentEnrolments()
      ])
      this.setState({ loading: false, student, enrolments })
    } catch (e) {
      console.error(e.message)
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, student, enrolments } = this.state

    return (
      <Screen
        name="student enrollments"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel &&
            this.props.organization &&
            this.state.student &&
            this.state.enrolments
          }>
          <TaasStudentEnrolmentList
            {...this.props}
            student={student}
            enrolments={enrolments}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchStudent: () =>
    fetchStudent(ownProps.match.params.channel, ownProps.match.params.student),
  fetchStudentEnrolments: () =>
    fetchStudentEnrolments(
      ownProps.match.params.channel,
      ownProps.match.params.student
    ),
  onEnrolmentEdit: enrolment => ownProps.history.push(taasEnrolment(enrolment)),
  onEnrolmentEnable: () => {},
  onEnrolmentDisable: (channel, student, enrolment) =>
    dispatch(unenrolStudent(channel, student, enrolment))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasStudentEnrolmentListContainer)
