import axios from 'axios'
import { each } from 'bluebird'
import { omit } from 'lodash'
import { push, replace } from 'connected-react-router'
import { getToken } from '../auth'

export const partnerReportingPartnersFetch = async () => {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/partner-usage/partners',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export const partnerReportingPartnerFetch = async id => {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/partner-usage/partners/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export const partnerCourseReportingSubmit = (partnerId, lobId, data) => {
  return async dispatch => {
    await partnerCourseReportingPost(partnerId, lobId, data)
    alert('Your report has been submitted')
    dispatch(push('/reloading'))
    dispatch(
      replace(`/tools/partner-course-usage-reporting/${partnerId}/${lobId}`)
    )
  }
}

const partnerCourseReportingPost = async (partnerId, lobId, data) => {
  await axios({
    method: 'POST',
    data,
    url:
      process.env.REACT_APP_REMOTE +
      `/partner-usage/partners/${partnerId}/${lobId}/course-usage`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

export const partnerCourseReportingBulkSubmit = reports => {
  return async dispatch => {
    await each(reports, async data => {
      await partnerCourseReportingPost(
        data.partnerId,
        data.lobId,
        omit(data, ['partnerId', 'lobId'])
      )
    })

    alert('Your reports have been submitted')
    dispatch(push('/reloading'))
    dispatch(replace('/tools/partner-course-usage-reporting/import'))
  }
}

const partnerCourseMappingsUpdate = async (partnerId, mappings) => {
  await axios({
    method: 'PUT',
    data: mappings,
    url:
      process.env.REACT_APP_REMOTE +
      `/partner-usage/partners/${partnerId}/mappings`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

export const partnerCourseMappingsSubmit = (partner, mappings) => {
  return async dispatch => {
    await partnerCourseMappingsUpdate(partner.id, mappings)
    alert('Your mappings have been updated!')
    dispatch(push('/reloading'))
    dispatch(replace(`/tools/partner-course-usage-reporting/${partner.id}`))
  }
}

export const geniusCheckEnrollment = async enrollmentId => {
  const result = await axios({
    method: 'POST',
    url:
      process.env.REACT_APP_REMOTE + `/support-tools/genius/remove-final-grade`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data: { enrollmentId }
  })
  return result.data
}

const geniusRemoveEnrollment = async enrollmentId => {
  const result = await axios({
    method: 'POST',
    url:
      process.env.REACT_APP_REMOTE + `/support-tools/genius/remove-final-grade`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data: { enrollmentId, confirm: true }
  })
  return result.data
}

export const geniusRemoveEnrollmentSubmit = enrollmentId => {
  return async dispatch => {
    await geniusRemoveEnrollment(enrollmentId)
    alert('Enrollment final score removed!')
    dispatch(push('/reloading'))
    dispatch(replace(`/tools/genius-remove-final-grade`))
  }
}
