import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasUserSearch from '../../components/TaasUserSearch'
import Search from '../../components/Search'

const TaasObserverList = ({
  channel,
  organization,
  observers,
  onObserverEdit,
  onObserverDelete,
  searchTerm,
  onSearch
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Dashboard',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
        }
      ]}
    />
    <div style={{ clear: 'both' }} />
    <Search
      className="users-search"
      value={searchTerm}
      handleChange={onSearch}
    />
    <Card className="observers">
      <CardHeader className="title" title="Observers" />
      <TaasUserSearch
        filter={searchTerm}
        documents={observers}
        type="observers"
        onEdit={onObserverEdit}
        onDelete={onObserverDelete}
      />
    </Card>
  </div>
)

TaasObserverList.propTypes = {
  observers: PropTypes.array.isRequired,
  channel: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  onObserverEdit: PropTypes.func.isRequired,
  onObserverDelete: PropTypes.func.isRequired
}

export default TaasObserverList
