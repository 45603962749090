const can = function(user, permission) {
  if (!user || !user.permissions) {
    return false
  }
  return user.permissions.indexOf(permission) !== -1
}

export const canAny = (user, permissions) => {
  return permissions.some(permission => can(user, permission))
}

export const canScoped = (
  user,
  globalPermission,
  scopedPermission,
  predicate
) => {
  if (!user) {
    throw new Error('No user populated')
  }

  if (can(user, globalPermission)) {
    return true
  }

  if (can(user, scopedPermission)) {
    return predicate()
  }

  return false
}

export default can
