/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from 'react'
import ReactGA from 'react-ga'

//  TODO: Add prop-types
/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default-member */

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    })
    ReactGA.pageview(page)
  }

  return class HOC extends Component {
    componentDidMount() {
      const page = this.props.location.pathname
      trackPage(page)
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname
      const nextPage = this.props.location.pathname

      if (currentPage !== nextPage) {
        trackPage(nextPage)
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

export default withTracker
