import React from 'react'
import { connect } from 'react-redux'
import OrganizationsCreate from './OrganizationsCreate'
import Screen from '../../components/Screen'
import {
  createOrganizationAwait,
  fetchOrganizationsAwait
} from '../../actions/organizations'
import { setAppBarState } from '../../actions/ui'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'

class OrganizationCreateContainer extends React.Component {
  constructor(props) {
    super(props)
    props.setAppBarState({
      visible: true,
      title: 'Organizations',
      rightIcon: <PlusCircleOutlineIcon />,
      handleRight: () => props.onClickAdd()
    })
    this.state = { error: null }
  }

  handleSubmit = async data => {
    const { onSubmit } = this.props
    try {
      await onSubmit(data)
    } catch (error) {
      this.setState({ error })
    }
  }

  render() {
    const { error: propError } = this.props
    const { error: stateError } = this.state

    return (
      <Screen name="organization create channel" error={propError}>
        <OrganizationsCreate
          {...this.props}
          onSubmit={this.handleSubmit}
          error={stateError}
        />
      </Screen>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => {
    dispatch(setAppBarState(appBarState))
  },
  onClickAdd: () => {
    ownProps.history.push('/organizations/create')
  },
  onSubmit: async data => {
    const org = await dispatch(createOrganizationAwait(data))
    await dispatch(fetchOrganizationsAwait())
    ownProps.history.push(`/organizations/${org.id}`)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationCreateContainer)
