const getInitial = () => ({
  isLoading: true,
  error: null,
  users: null,
  courses: null
})

function searchPreview(state = getInitial(), action) {
  switch (action.type) {
    case 'PREVIEW_USERS_START':
      return getInitial()

    case 'PREVIEW_USERS_SUCCESS':
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        users: action.users
      })

    case 'PREVIEW_USERS_ERROR':
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        users: null
      })

    case 'PREVIEW_COURSES_START':
      return getInitial()

    case 'PREVIEW_COURSES_SUCCESS':
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        courses: action.courses
      })

    case 'PREVIEW_COURSES_ERROR':
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        courses: null
      })

    default:
      return state
  }
}

export default searchPreview
