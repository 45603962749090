import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Screen from '../../components/Screen'
import { setAppBarState } from '../../actions/ui'
import { MultiFactorAuthentication } from '../../pages/MultiFactorAuthentication'
import { awaitMfaCode, awaitVerifyMfaCode } from '../../actions/mfaCodes'
import { withRouter } from 'react-router-dom'

const MfaSettingsContainer = props => {
  const {
    setAppBarState,
    awaitGenerateMfaCode,
    isFetching,
    user,
    error
  } = props

  useEffect(() => {
    setAppBarState({
      visible: true,
      title: 'Enable Multi-factor Authentication'
    })
  }, [])

  useEffect(() => {
    if (user && user.email) {
      awaitGenerateMfaCode(user.email)
    }
  }, [user])

  return (
    <Screen
      name="Enable multi-factor autentication"
      isLoading={isFetching || !user.id}
      error={error}
      renderContent={() => <MultiFactorAuthentication {...props} />}
    />
  )
}

const mapStateToProps = state => ({
  user: state.auth.userData,
  qrCodeSrcUrl: state.mfaCodes.data?.qrCode,
  code: state.mfaCodes.data?.code,
  isFetching: state.mfaCodes.isFetching,
  verified: state.mfaCodes.data?.verified === true,
  verificationErrorMessage: state.mfaCodes.error.verificationErrorMessage
})

const mapDispatchToProps = dispatch => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  awaitGenerateMfaCode: email => dispatch(awaitMfaCode(email)),
  onSubmit: (code, email) => dispatch(awaitVerifyMfaCode(code, email))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MfaSettingsContainer)
)
