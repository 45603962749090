import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Tile from './Tile'
import marked from 'marked'
marked.setOptions({
  sanitize: true
})

class NoteTile extends Component {
  render() {
    return (
      <div className="NoteTile">
        <Tile>
          <div className="notes">
            {this.props.notes ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(this.props.notes)
                }}
              />
            ) : (
              <p className="empty">No notes to display</p>
            )}
          </div>
        </Tile>
        <style>{`
          .NoteTile {
            margin-bottom: 10px;
            min-width: 298px;
          }
          .NoteTile .card__title {
            padding: 0;
            margin-bottom: 10px;
          }
          .NoteTile .notes {
            padding: 0;
          }
          .NoteTile .notes .empty {
            color: #757575;
          }
        `}</style>
      </div>
    )
  }
}

NoteTile.propTypes = {
  notes: PropTypes.string
}

export default NoteTile
