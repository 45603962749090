import joi from 'joi'
import createForm from '../../components/form/createForm'
import PasswordInput from '../../components/form/elements/PasswordInput'

export default ({ token, username, ...props }) =>
  createForm({
    fields: [
      {
        id: 'newPassword',
        label: 'Password',
        inputComponent: PasswordInput,
        required: true,
        validation: joi
          .string()
          .required()
          .min(8)
      },
      {
        id: 'confirmPassword',
        label: 'Confirm Password',
        inputComponent: PasswordInput,
        required: true,
        validation: joi
          .string()
          .valid(joi.ref('newPassword'))
          .required()
      }
    ],
    layoutOptions: {
      submitText: 'Change Password'
    },
    ...props,
    onSubmit: async data => {
      await props.onSubmit({
        ...data,
        token,
        username
      })
    }
  })
