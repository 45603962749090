import joi from 'joi'
import createForm from '../../components/form/createForm'
import ToggleInput from '../../components/form/elements/ToggleInput'
import FileCsvInput from '../../components/form/elements/FileCsvInput'

const csvSchema = joi.array().items(
  joi.object({
    username: joi.string().required(),
    courseid: joi.string().required(),
    startdate: joi.string().allow(''),
    enddate: joi.string().allow(''),
    reference: joi.string().allow('')
  })
)

export default ({ ...props }) =>
  createForm({
    fields: [
      {
        id: 'csv',
        label: 'CSV File',
        inputComponent: FileCsvInput,
        validation: csvSchema,
        required: true
      },
      {
        id: 'notify',
        label: 'Send Notification Emails',
        inputComponent: ToggleInput,
        validation: joi.bool(),
        defaultValue: true,
        required: true
      }
    ],
    layoutOptions: {
      submitText: 'Save'
    },
    ...props,
    onSubmit: async data => {
      return props.onSubmit(
        data.csv.map(row => ({
          ...row,
          notify: data.notify
        }))
      )
    }
  })
