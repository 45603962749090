import React, { Component } from 'react'
import Chip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'

class LmsChip extends Component {
  render() {
    const { lms } = this.props
    if (!lms) return null

    const color = lms.color || '#000000'
    return (
      <Chip
        className="lms__chip"
        key={lms.id}
        label={lms.name}
        style={{
          ...lmsStyle,
          background: color
        }}
      />
    )
  }
}

const lmsStyle = {
  height: '20px',
  color: 'white',
  fontSize: '12px',
  margin: '0',
  marginRight: '5px',
  lineHeight: '20px'
}

LmsChip.propTypes = {
  lms: PropTypes.object
}

export default LmsChip
