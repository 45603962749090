import { chunk } from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Button from '@material-ui/core/Button'
import ErrorMessage from '../components/ErrorMessage'
import Search from '../components/Search'
import CourseSearch from '../components/CourseSearch'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon'
import CourseSelectionActions from '../containers/ConnectCourseSelectionActions'
import InboxIcon from 'mdi-react/InboxIcon'
import FolderIcon from 'mdi-react/FolderIcon'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { CourseTypes } from '../selectors/index'

const Label = styled.div`
  > * {
    margin: 0;
  }

  margin: 20px 0;
`

const ActionsContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

const ActionsControlContainer = styled.section`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`
export const FILTER_ALL = 'all'

class Courses extends Component {
  static propTypes = {
    setAppBarState: PropTypes.func,
    history: PropTypes.object,
    isFetching: PropTypes.bool,
    fetchError: PropTypes.string,
    allFolders: PropTypes.array,
    courseFolders: PropTypes.array,
    subscribed: PropTypes.array,
    selection: PropTypes.array,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    onClear: PropTypes.func,
    onDownload: PropTypes.func,
    folderId: PropTypes.string,
    onCourseFoldersAdd: PropTypes.func.isRequired,
    onCourseFoldersRemove: PropTypes.func.isRequired,
    onCourseFoldersCreate: PropTypes.func.isRequired,
    onCourseFoldersSelectCancel: PropTypes.func.isRequired,
    onCourseFoldersSubmit: PropTypes.func.isRequired,
    onCourseFoldersDelete: PropTypes.func.isRequired,
    fetchCourses: PropTypes.func.isRequired
  }

  state = {
    searchTerm: '',
    filterBy: FILTER_ALL
  }

  constructor(props) {
    super(props)
    this.timeout = 0

    props.setAppBarState({
      visible: true,
      title: 'Courses',
      rightIcon: <PlusCircleOutlineIcon />,
      handleRight: () => {
        props.history.push('/course/new')
      }
    })
  }

  handleSearch = searchTerm => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.setState({ searchTerm }), 300)
  }

  handleSelectionChange = (id, value) => {
    const { onSelect, onDeselect } = this.props
    if (value) {
      onSelect([id])
    } else {
      onDeselect([id])
    }
  }

  handleFilterChange = event => {
    this.setState(prevState => ({
      ...prevState,
      filterBy: event.target.value
    }))
    this.props.fetchCourses(event.target.value)
  }

  render() {
    const {
      isFetching,
      allFolders,
      fetchError,
      subscribed,
      selection,
      folderId
    } = this.props
    const coursesAvaialbleForDisplay = subscribed.length > 0
    // const displayCourses = coursesAvaialbleForDisplay && !isFetching && !fetchError
    const noCourses = !coursesAvaialbleForDisplay && !isFetching && !fetchError

    return (
      <div className="main home">
        {isFetching && (
          <div className="loading">
            <CircularProgress size={50} />
          </div>
        )}

        {fetchError && (
          <ErrorMessage
            icon={<AlertCircleOutlineIcon />}
            message={fetchError}
          />
        )}

        {noCourses && (
          <ErrorMessage
            icon={<InboxIcon />}
            message={'No courses to display'}
          />
        )}

        {folderId && (
          <Button>
            <Link
              style={{ color: 'inherit', textDecoration: 'none' }}
              to="/courses">
              Back
            </Link>
          </Button>
        )}

        {folderId ? (
          allFolders
            .filter(c => c.id === folderId)
            .map(courseFolder => (
              <div key={courseFolder.id}>
                <Search
                  className="course-search"
                  value={this.state.searchTerm}
                  handleChange={this.handleSearch}
                />

                <div>
                  <CourseSelectionActions courses={courseFolder.courses} />
                  <Label>
                    <h4>
                      <Link
                        to={`/folders/${courseFolder.id}/`}
                        style={{ color: 'inherit', textDecoration: 'none' }}>
                        <FolderIcon />
                        {courseFolder.name}
                      </Link>
                    </h4>
                  </Label>
                  <div style={{ clear: 'both' }} />
                </div>

                <CourseSearch
                  filter={this.state.searchTerm}
                  documents={courseFolder.courses}
                  selection={selection}
                  onSelectionChange={this.handleSelectionChange}
                />
              </div>
            ))
        ) : (
          <div>
            <Label>
              <h4>Course Folders</h4>
            </Label>
            {chunk(allFolders, 2).map(folders => (
              <Grid container key={folders[0].id}>
                {folders.map(courseFolder => (
                  <Grid item xs={4} key={courseFolder.id}>
                    <List>
                      <ListItem key={courseFolder.id}>
                        <ListItemIcon>
                          <FolderIcon />
                        </ListItemIcon>
                        <Link
                          to={`/folders/${courseFolder.id}/`}
                          style={{ color: 'inherit', textDecoration: 'none' }}>
                          {courseFolder.name} ({courseFolder.courses.length})
                        </Link>
                      </ListItem>
                    </List>
                  </Grid>
                ))}
              </Grid>
            ))}
            <div>
              <Search
                className="course-search"
                value={this.state.searchTerm}
                handleChange={this.handleSearch}
              />

              <ActionsContainer>
                <Label>
                  <h4>All Courses</h4>
                </Label>
                <ActionsControlContainer>
                  <InputLabel htmlFor="filter-by">Filter By</InputLabel>
                  <Select
                    style={{ marginRight: '.5em', marginLeft: '.5em' }}
                    value={this.state.filterBy}
                    displayEmpty
                    inputProps={{ id: 'filter-by' }}
                    onChange={this.handleFilterChange}>
                    <MenuItem value={FILTER_ALL}>All</MenuItem>
                    <MenuItem value={CourseTypes.COURSE.toLowerCase()}>
                      Courses
                    </MenuItem>
                    <MenuItem value={CourseTypes.SIMULATION.toLowerCase()}>
                      Simulations
                    </MenuItem>
                  </Select>
                  <CourseSelectionActions courses={subscribed} />
                </ActionsControlContainer>
              </ActionsContainer>

              <CourseSearch
                filter={this.state.searchTerm}
                documents={subscribed}
                selection={selection}
                onSelectionChange={this.handleSelectionChange}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Courses
