import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'

export default ({ partner, onSubmit, ...props }) => {
  const courseOptions = [
    {
      value: 'other',
      label: 'Other'
    }
  ].concat(
    partner.courseMappings.map(row => ({
      value: row.code,
      label: `${row.code}: ${row.alias}`
    }))
  )

  return createForm({
    fields: [
      {
        id: 'code',
        label: 'Course Name',
        inputComponent: SelectInput,
        inputOptions: {
          options: courseOptions
        },
        required: true,
        validation: joi.string(),
        defaultValue: ''
      },
      partner.lms && partner.lms.length
        ? {
            id: 'lms',
            label: 'LMS',
            inputComponent: SelectInput,
            inputOptions: {
              options: partner.lms.map(row => ({
                value: row.id,
                label: row.name
              }))
            },
            required: true,
            validation: joi.string()
          }
        : null,
      {
        id: 'enrolments',
        label: 'Enrollment Count',
        inputComponent: TextInput,
        required: true,
        validation: joi.number(),
        defaultValue: 0
      }
    ].filter(row => row),
    layoutOptions: {
      submitText: 'Add Course'
    },
    onSubmit: async data => {
      return onSubmit(data)
    },
    ...props
  })
}
