import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTextarea = styled.textarea`
  width: 80%;
  height: 300px;
  margin: 15px auto;

  &.invalid {
    color: red;
  }
`

class SearchQueryEditor extends React.Component {
  state = {
    valid: true,
    query: '{}'
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    return {
      ...oldState,
      query: JSON.stringify(nextProps.query, null, 4)
    }
  }

  handleChange = ({ target }) => {
    const content = target.value
    this.setState({ query: content })
    try {
      const parsed = JSON.parse(content)
      this.props.onChange(parsed)
      this.setState({ valid: true })
    } catch (e) {
      this.setState({ valid: false })
    }
  }

  render() {
    const { valid, query } = this.state
    return (
      <StyledTextarea
        className={valid ? 'valid' : 'invalid'}
        onChange={this.handleChange}
        value={query}
        readOnly={true}
      />
    )
  }
}

SearchQueryEditor.propTypes = {
  query: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired
}

export default SearchQueryEditor
