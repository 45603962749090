import Drawer from '../components/Drawer'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { awaitSwitchBack } from '../actions/auth'

function mapStateToProps(state) {
  return {
    user: state.auth.userData,
    isLoggedInUserSuperAdmin: state.auth.userData.permission === 'superadmin',
    isLoggedInUserAdmin: state.auth.userData.permission === 'admin',
    organization: get(state, 'auth.userData.organization.id'),
    isAssuming: state.auth.userData.adminUser
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onSwitchBack: () => dispatch(awaitSwitchBack())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Drawer))
