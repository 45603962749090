import React from 'react'
import PropTypes from 'prop-types'
import Collapse from './Collapse'
import Semver from './Semver'
import FileLink from './FileLink'
import LmsChip from './LmsChip'
import LinkAndDate from './LinkAndDate'
import UploadDate from './UploadDate'
import filePackageColor from '../utilities/filePackageColor'
import styled from 'styled-components'
import ZipBoxIcon from 'mdi-react/ZipBoxIcon'
import FilePdfBoxIcon from 'mdi-react/FilePdfBoxIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment-timezone'
import { parseDescription, isVersionDeprecated } from '../helpers'

const Description = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-height: 42px;

  /* DeleteIcon */
  & > button {
    display: none;
  }

  &:hover > button {
    display: block;
    background-color: transparent;
  }

  & > button svg {
    height: 18px;
    width: 18px;
    fill: #e80000;
  }
`

const File = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  .lms__chip {
    margin: auto 1rem auto 0 !important;
    width: max-content;
  }
`

const LmsSemver = styled.div`
  display: grid;
  grid-template-columns: 150px ${props =>
      props.showLmsChips ? '140px' : 'auto'};
`

const DownloadedNote = styled.div`
  color: #757575;
  margin-left: 32px;
`

const iconMap = {
  package: <ZipBoxIcon />,
  guide: <FilePdfBoxIcon />
}

const FileListItem = props => {
  const { title, body } = parseDescription(props.description)
  const deprecated = isVersionDeprecated(
    props.version,
    props.type,
    props.isSuperAdmin
  )
  const lms =
    props.type === 'package'
      ? {
          id: props.name,
          name: props.name,
          color: filePackageColor(props.name)
        }
      : null

  return (
    <div>
      <Description>
        <Collapse title={title} body={body} />
        {props.edit && (
          <IconButton onClick={() => props.deleteFile(props.id)}>
            <DeleteIcon />
          </IconButton>
        )}
      </Description>
      <File>
        <LinkAndDate deprecated={deprecated}>
          <FileLink
            onClick={async () => {
              const link = await props.download(props.id)
              window.location.assign(link)
            }}
            label={props.filename}
            icon={iconMap[props.type]}
          />
          {props.date && (
            <UploadDate deprecated={deprecated}>
              {/* do not break before ']' */}
              {[
                moment(props.date).format('\\[ DD-MMM-YYYY'),
                <span key={props.date}>&nbsp;</span>,
                ']'
              ]}
            </UploadDate>
          )}
        </LinkAndDate>
        <LmsSemver showLmsChips={props.showLmsChips}>
          {props.showLmsChips && <LmsChip lms={lms} />}
          <Semver version={props.version} isLatest={props.isLatest} />
        </LmsSemver>
      </File>
      {props.downloaded && (
        <DownloadedNote>
          <p>downloaded {moment(props.downloaded).format('DD-MMM-YYYY')}</p>
        </DownloadedNote>
      )}
    </div>
  )
}

FileListItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  filename: PropTypes.string,
  downloaded: PropTypes.Date,
  type: PropTypes.oneOf(Object.keys(iconMap)),
  version: PropTypes.string,
  isLatest: PropTypes.bool,
  lms: PropTypes.object,
  date: PropTypes.string,
  edit: PropTypes.bool,
  deleteFile: PropTypes.func,
  download: PropTypes.func,
  showLmsChips: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default FileListItem
