import axios from 'axios'
import { delay } from 'bluebird'

async function login(username, password) {
  if (localStorage.getItem('token')) return

  try {
    const res = await axios.post(process.env.REACT_APP_REMOTE + '/auth', {
      username,
      password
    })

    return res
  } catch (e) {
    return Promise.reject(e.response)
  }
}

const acceptTerms = async (username, token) => {
  try {
    return await axios({
      method: 'POST',
      url: process.env.REACT_APP_REMOTE + '/auth/accept-terms',
      data: {
        username
      },
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + token }
    })
  } catch (err) {
    throw new Error(err.response)
  }
}

async function loginGoogle(code, redirectUri) {
  if (localStorage.getItem('token')) return

  try {
    const res = await axios.post(
      process.env.REACT_APP_REMOTE + '/auth/google',
      {
        code,
        redirectUri
      }
    )

    return res
  } catch (e) {
    return Promise.reject(e.response)
  }
}

async function organizationSelection(organization, selectionToken) {
  if (localStorage.getItem('token')) return
  try {
    const res = await axios.post(
      process.env.REACT_APP_REMOTE + '/auth/select',
      {
        selectionToken,
        organization
      }
    )

    return res
  } catch (e) {
    return Promise.reject(e.response)
  }
}

async function assume(userId) {
  try {
    const res = await axios({
      method: 'POST',
      url: process.env.REACT_APP_REMOTE + `/users/${userId}/assume`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    localStorage.adminToken = localStorage.token
    localStorage.token = res.data.token
    return res
  } catch (e) {
    return Promise.reject(e.response)
  }
}

export const forgotPassword = async username => {
  const response = await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/auth/reset/request',
    data: { username },
    withCredentials: true
  })
  return response
}

export const resetPassword = async data => {
  const response = await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/auth/reset',
    data,
    withCredentials: true
  })
  return response
}

export const fetchUserData = async () => {
  const user = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/users/current',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  if (
    user.headers['x-refresh-token'] &&
    user.headers['x-refresh-token'] !== localStorage.getItem('token') &&
    !isStuckInLoop()
  ) {
    localStorage.setItem('token', user.headers['x-refresh-token'])

    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href
    await delay(10000)
    return {}
  }

  clearLoop()

  const organization = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/organizations/current',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  user.data.organization = organization.data[0]

  // TODO: Dynamo does not persist empty strings or empty arrays. Ideally this
  // would be habdled serverside, but for now we make sure that organization.lms
  // exists (EDIT: We don't use dynamo anymore, this might be a redundant TODO)
  if (user.data.organization && !user.data.organization.lms)
    user.data.organization.lms = []

  return user.data
}

function isStuckInLoop() {
  let attempt = localStorage.getItem('refresh-attempts') || 0
  if (attempt) {
    attempt = parseInt(attempt, 10)
  }

  attempt += 1
  localStorage.setItem('refresh-attempts', attempt)

  if (attempt > 3) {
    reportError(new Error('User stuck in refresh loop'))
    return true
  }

  return false
}

function clearLoop() {
  localStorage.removeItem('refresh-attempts')
}

function switchBack() {
  localStorage.token = localStorage.adminToken
  localStorage.adminToken = null
}

function logout() {
  delete localStorage.token
}

function isUserLoggedIn() {
  return !!localStorage.token
}

function getToken() {
  return localStorage.getItem('token')
}

export {
  assume,
  switchBack,
  login,
  loginGoogle,
  logout,
  isUserLoggedIn,
  organizationSelection,
  getToken,
  acceptTerms
}
