import React from 'react'
import { connect } from 'react-redux'
import SurveyAdminCreate from './SurveyAdminCreate'
import Screen from '../../components/Screen'
import { surveysCreate } from '../../actions/surveys'
import { setAppBarState } from '../../actions/ui'

class SurveyAdminCreateContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props.setAppBarState({
      visible: true,
      title: 'Surveys',
      rightIcon: null,
      handleRight: null
    })
  }

  render() {
    return (
      <Screen name="survey create">
        <SurveyAdminCreate {...this.props} />
      </Screen>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  onSubmit: data => dispatch(surveysCreate(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyAdminCreateContainer)
