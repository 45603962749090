import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SearchQuery from './SearchQuery'
import { addArrayChild } from '../utilities/queryPath'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import EditIcon from 'mdi-react/EditIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'

const StyledOrList = styled.div`
  padding: 5px;
`

class SearchQueryCondition extends React.Component {
  renderKey(key) {
    const { readOnly, onEditNew, path } = this.props
    if (key === '$or') {
      return (
        <div className="scope">
          Any of the following
          {!readOnly && (
            <div className="controls">
              <Button onClick={() => onEditNew(path)}>
                add condition (or)
              </Button>
            </div>
          )}
        </div>
      )
    }
    if (key === '$and') {
      return <div className="scope">All of the following</div>
    }
    if (key === 'user') {
      return 'Filter by Users'
    }
    if (key === 'tag') {
      return 'Filter by Tags'
    }
    if (key === 'course') {
      return 'Filter by Courses'
    }
    if (key === 'organization') {
      return 'Filter by Organizations'
    }
    throw new Error(`Unknown key: ${key}`)
  }

  renderChild(key, value) {
    const { path, editingPath } = this.props
    if (editingPath === path) {
      // TODO scroll up
      return 'editing...'
    }

    if (key === '$or') {
      return this.render$Or(key, value)
    }
    if (key === '$and') {
      return this.render$And(key, value)
    }

    if (Array.isArray(value)) {
      return this.renderArray(key, value)
    }

    return this.renderValue(key, value)
  }

  render$Or(key, value) {
    const {
      refs,
      path,
      onEdit,
      onEditNew,
      addingPath,
      editingPath,
      onConditionChange,
      onConditionReplace,
      onConditionRemove,
      readOnly
    } = this.props
    return (
      <StyledOrList>
        {value.map((value, i) => {
          const newPath = addArrayChild(path, i)
          return (
            <SearchQuery
              key={i}
              query={value}
              keyName={key}
              path={newPath}
              onConditionRemove={onConditionRemove}
              onConditionChange={onConditionChange}
              onConditionReplace={onConditionReplace}
              addingPath={addingPath}
              editingPath={editingPath}
              onEdit={onEdit}
              onEditNew={onEditNew}
              refs={refs}
              readOnly={readOnly}
            />
          )
        })}
      </StyledOrList>
    )
  }

  renderArray(key, value) {
    const { path, onConditionRemove, readOnly } = this.props
    return (
      <div>
        {value.map((item, i) => {
          const childPath = addArrayChild(path, i)
          return (
            <Chip
              key={childPath}
              label={this.renderChild(key, item)}
              onDelete={readOnly ? null : () => onConditionRemove(childPath)}
              variant="outlined"
            />
          )
        })}
      </div>
    )
  }

  renderValue(key, value) {
    const { refs } = this.props
    try {
      if (typeof value === 'string' || typeof value === 'number') {
        value = refs[key][value]
      }
    } catch (e) {
      console.error(e)
      // intentionally empty / TODO
    }

    return <span className="query-value">{value}</span>
  }

  render$And(key, value) {
    const {
      refs,
      path,
      onConditionChange,
      onConditionReplace,
      onConditionRemove,
      onEdit,
      onEditNew,
      addingPath,
      editingPath,
      readOnly
    } = this.props
    return value.map((subQuery, i) => {
      const childPath = addArrayChild(path, i)
      return (
        <SearchQuery
          key={childPath}
          path={childPath}
          query={subQuery}
          onEdit={onEdit}
          onEditNew={onEditNew}
          onConditionChange={onConditionChange}
          onConditionReplace={onConditionReplace}
          onConditionRemove={onConditionRemove}
          refs={refs}
          addingPath={addingPath}
          editingPath={editingPath}
          readOnly={readOnly}
        />
      )
    })
  }

  render() {
    const {
      query,
      path,
      className,
      keyName,
      onConditionReplace,
      onConditionRemove,
      onEdit,
      readOnly
    } = this.props
    const editable =
      !readOnly &&
      (keyName === 'user' ||
        keyName === 'course' ||
        keyName === 'organization' ||
        keyName === 'tag')
    return (
      <div className={className}>
        <div>
          <div className="query-actions">
            <div className="controls">
              {!readOnly && (
                <Button
                  size="small"
                  onClick={() =>
                    onConditionReplace(path, '$or', [{ [keyName]: query }])
                  }>
                  OR
                </Button>
              )}
              {editable && (
                <Button size="small" onClick={() => onEdit(path)}>
                  <EditIcon />
                </Button>
              )}
              {!readOnly && (
                <Button size="small" onClick={() => onConditionRemove(path)}>
                  <DeleteIcon />
                </Button>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="key">{this.renderKey(keyName)}</div>
          <div>{this.renderChild(keyName, query)}</div>
        </div>
      </div>
    )
  }
}

SearchQueryCondition.propTypes = {
  path: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  query: PropTypes.any,
  onConditionChange: PropTypes.func.isRequired,
  onConditionReplace: PropTypes.func.isRequired,
  onConditionRemove: PropTypes.func.isRequired,
  refs: PropTypes.shape({}),
  onEdit: PropTypes.func.isRequired,
  onEditNew: PropTypes.func.isRequired,
  addingPath: PropTypes.string,
  editingPath: PropTypes.string,
  readOnly: PropTypes.bool
}

SearchQueryCondition.defaultProps = {
  addingPath: null,
  editingPath: null,
  readOnly: false
}

export default SearchQueryCondition
