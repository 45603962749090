import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import NotificationListItem from './NotificationListItem'
import Divider from '@material-ui/core/Divider'

const NotificationList = props => (
  <Card style={{ marginBottom: '2em' }}>
    {props.notifications.map((notification, i) => (
      <React.Fragment key={i}>
        <NotificationListItem
          cursor="pointer"
          onClick={(e, id) => props.onClick(e, id)}
          onSendClick={(e, id, emailSent) =>
            props.onSendClick(e, id, emailSent)
          }
          notification={notification}
          courseLimit={props.courseLimit}
          messageLimit={props.messageLimit}
          messageUpperLimit={props.messageUpperLimit}
          isSuperAdmin={props.isSuperAdmin}
        />
        {i < props.notifications.length - 1 && <Divider />}
      </React.Fragment>
    ))}
  </Card>
)

NotificationList.propTypes = {
  notifications: PropTypes.array,
  onClick: PropTypes.func,
  onSendClick: PropTypes.func,
  courseLimit: PropTypes.number,
  messageLimit: PropTypes.number,
  messageUpperLimit: PropTypes.number,
  isSuperAdmin: PropTypes.bool
}

export default NotificationList
