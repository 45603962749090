import axios from 'axios'
import { getToken } from '../auth'

export const fetchOrganizations = async (page, limit, search, filter) => {
  const searchParams = new URLSearchParams({
    ...(search && { search }),
    ...(page && { page }),
    ...(limit && { limit }),
    ...(filter && { filter })
  })
  const response = await axios({
    method: 'GET',
    url: `${
      process.env.REACT_APP_REMOTE
    }/v2/organizations?${searchParams.toString()}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return response.data
}

export const fetchOrganizationById = async id => {
  const response = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_REMOTE}/v2/organizations/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return response.data
}

export const fetchOrganizationsByIds = async ids => {
  const response = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_REMOTE}/v2/organizations/by-ids`,
    data: ids,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return response.data
}

export const postOrganization = async newOrganization => {
  const result = await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/organizations',
    data: newOrganization,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result
}

export const putOrganization = async updatedOrganization => {
  const result = await axios({
    method: 'PUT',
    url:
      process.env.REACT_APP_REMOTE + `/organizations/${updatedOrganization.id}`,
    data: updatedOrganization,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result
}

export const deleteOrganization = async id => {
  const result = await axios({
    method: 'DELETE',
    url: process.env.REACT_APP_REMOTE + `/organizations/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result
}
export const suggestOrganizations = async query => {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/organizations/suggest',
    params: { query },
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}
