import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasEnrolmentListItem from '../../components/TaasEnrolmentListItem'
import { taasTeacherLink } from '../../links'

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

/* eslint-disable react/prop-types */

const TaasTeacherView = ({
  organization,
  channel,
  teacher,
  enrolments,
  onEnrolmentAdd,
  onEnrolmentEdit,
  onEnrolmentDisable
}) => {
  const title = `${teacher.firstname} ${teacher.lastname}`
  return (
    <div>
      <Breadcrumbs
        links={[
          { title: 'Organizations', href: '/organizations' },
          {
            title: organization.name,
            href: `/organizations/${organization.id}`
          },
          {
            title: 'Channels',
            href: `/organizations/${organization.id}/channels`
          },
          {
            title: channel.name,
            href: `/organizations/${organization.id}/channels/${channel.id}`
          },
          {
            title: 'Dashboard',
            href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
          },
          {
            title: 'Teachers',
            href: `/organizations/${organization.id}/channels/${channel.id}/taas/teachers`
          }
        ]}
      />

      <Card>
        <CardHeader className="title" title={title} />
        <CardContent className="description">
          <div>
            <strong>ID</strong>: {teacher.id}
          </div>
          <div>
            <strong>First Name</strong>: {teacher.firstname}
          </div>
          <div>
            <strong>Last Name</strong>: {teacher.lastname}
          </div>
          <div>
            <strong>Username</strong>: {teacher.username}
          </div>
          <div>
            <strong>Email Address</strong>: {teacher.email}
          </div>
        </CardContent>
      </Card>

      <Card style={{ marginTop: '20px' }}>
        <CardHeader
          className="title"
          title={
            <StyledLink to={taasTeacherLink(teacher, '/enrolments')}>
              Enrolments ({enrolments.length})
            </StyledLink>
          }
        />
        <CardContent className="description">
          <div style={{ float: 'right', marginTop: '-70px' }}>
            <Button variant="contained" onClick={() => onEnrolmentAdd(teacher)}>
              Add
            </Button>
          </div>
          <ul>
            {enrolments.slice(0, 5).map(enrolment => (
              <TaasEnrolmentListItem
                key={enrolment.id}
                enrolment={enrolment}
                onEdit={enrolment => onEnrolmentEdit(enrolment)}
                onDelete={enrolment =>
                  onEnrolmentDisable(channel, teacher, enrolment)
                }
              />
            ))}
          </ul>
        </CardContent>
      </Card>
    </div>
  )
}

TaasTeacherView.propTypes = {
  organization: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  teacher: PropTypes.object.isRequired
}

export default TaasTeacherView
