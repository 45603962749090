import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TypeformSurvey from '../../components/TypeformSurvey'

const SurveyUserView = ({ user, survey, onComplete }) => (
  <div>
    <Breadcrumbs links={[{ title: 'Surveys', href: '/surveys' }]} />
    <Card>
      <CardHeader className="title" title={`Edit Survey: ${survey.name}`} />
      <CardContent className="description">
        <TypeformSurvey
          onComplete={() => onComplete(survey)}
          params={{ userid: user.id }}
          url={survey.typeformUrl}
        />
      </CardContent>
    </Card>
  </div>
)

SurveyUserView.propTypes = {
  survey: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired
}

export default SurveyUserView
