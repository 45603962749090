import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'
import ToggleInput from '../../components/form/elements/ToggleInput'

export default props => {
  return createForm({
    fields: [
      {
        id: 'instanceId',
        label: 'Instance',
        inputComponent: SelectInput,
        inputOptions: {
          options: [
            { value: 'buzz', label: 'Buzz' },
            { value: 'buzz2', label: 'Buzz 2' }
          ]
        },
        required: true,
        defaultValue: 'buzz',
        validation: joi.string()
      },
      {
        id: 'reference',
        label: 'Reference',
        inputComponent: TextInput,
        validation: joi.string().allow(''),
        defaultValue: ''
      },
      {
        id: 'appendChannelName',
        label: 'Append Channel Name',
        inputComponent: ToggleInput,
        required: false,
        defaultValue: false,
        validation: joi.bool()
      },
      {
        id: 'onlyMissing',
        label: 'Only Copy Missing Courses',
        inputComponent: ToggleInput,
        required: false,
        defaultValue: false,
        validation: joi.bool()
      }
    ],
    layoutOptions: {
      submitText: 'Copy Courses'
    },
    ...props
  })
}
