import { omit, uniqBy, orderBy } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import PartnerCourseReportingPartner from './PartnerCourseReportingPartner'
import Screen from '../../components/Screen'
import {
  partnerReportingPartnerFetch,
  partnerCourseReportingSubmit
} from '../../actions/tools'
import { setAppBarState } from '../../actions/ui'

class PartnerReportingContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      partner: null,
      addedLines: [],
      error: null,
      selected: [],
      errors: []
    }
    this.props.setAppBarState({
      visible: true,
      title: 'Partner Usage Reporting',
      rightIcon: null,
      handleRight: () => {}
    })
  }

  async componentDidMount() {
    try {
      const partner = await this.props.fetchPartner()
      const lob = partner.linesOfBusiness.find(
        lob => lob.id === this.props.match.params.lob
      )

      this.setState({
        partner,
        lob,
        isLoading: false,
        addedLines: this.loadFromLocalStorage(partner.id, lob.id)
      })
    } catch (e) {
      this.setState({ isLoading: false, error: e })
    }
  }

  loadFromLocalStorage(partnerId, lobId) {
    const stored = localStorage.getItem(`partner-usage-${partnerId}-${lobId}`)
    if (!stored) {
      return []
    }

    try {
      return JSON.parse(stored).map(row => ({
        ...row,
        date: new Date(row.date)
      }))
    } catch (e) {
      console.error(e)
      return []
    }
  }

  handleAddLine = async data => {
    this.setState(
      {
        addedLines: uniqBy(
          orderBy(
            this.state.addedLines.concat([
              {
                ...data,
                date: new Date()
              }
            ]),
            ['code', 'date'],
            ['asc', 'desc']
          ),
          row => `${row.code}${row.lms}`
        )
      },
      () => {
        this.updateLocalState(this.state.addedLines)
      }
    )
  }

  handleRemoveLine = courseCode => {
    this.setState(
      {
        addedLines: this.state.addedLines.filter(row => row.code !== courseCode)
      },
      () => {
        this.updateLocalState(this.state.addedLines)
      }
    )
  }

  updateLocalState(lines) {
    localStorage.setItem(
      `partner-usage-${this.state.partner.id}-${this.state.lob.id}`,
      JSON.stringify(lines)
    )
  }

  handleSubmit = async data => {
    const key = `partner-usage-${this.state.partner.id}-${this.state.lob.id}`
    await this.props.onSubmit({
      ...data,
      courses: this.state.addedLines.map(row => omit(row, 'date'))
    })
    localStorage.removeItem(key)
  }

  render() {
    return (
      <Screen
        name="partner course usage reporting"
        isLoading={this.state.isLoading}
        exists={this.state.lob}
        error={this.props.error || this.state.error}
        renderContent={() => (
          <PartnerCourseReportingPartner
            {...this.props}
            partner={this.state.partner}
            lob={this.state.lob}
            addedLines={this.state.addedLines}
            errors={this.state.errors}
            onAddLine={this.handleAddLine}
            onRemoveLine={this.handleRemoveLine}
            onSubmit={this.handleSubmit}
          />
        )}
      />
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPartner: () =>
    partnerReportingPartnerFetch(ownProps.match.params.partner),
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  onSubmit: data =>
    dispatch(
      partnerCourseReportingSubmit(
        ownProps.match.params.partner,
        ownProps.match.params.lob,
        data
      )
    )
})

export default connect(null, mapDispatchToProps)(PartnerReportingContainer)
