import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PlusIcon from 'mdi-react/PlusIcon'
import OrganizationUsers from './OrganizationUsers'
import Screen from '../../../components/Screen'
import GateExists from '../../../components/GateExists'
import { isSuperAdmin, canAssume } from '../../../selectors/auth'
import { awaitAssumeUser } from '../../../actions/auth'
import { setAppBarState } from '../../../actions/ui'
import { canAny } from '../../../utilities/can'
import { useOrgUsers } from '../../../hooks/useOrgUsers'
import { useOrganization } from '../../../hooks/useOrganization'

const OrganizationUsersContainer = props => {
  const {
    organizationId,
    isLoading,
    error,
    history,
    setAppBarState,
    me
  } = props
  const { users, isFetchingUsers, usersError } = useOrgUsers(organizationId)
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )

  useEffect(() => {
    if (me) {
      setAppBarState({
        visible: true,
        title: 'Users',
        rightIcon: <PlusIcon />,
        handleRight: () =>
          history.push(`/organizations/${organizationId}/users/create`),
        canAdd: canAny(me, ['USERS_ADD_ORG', 'USERS_ADD_ALL'])
      })
    }
  }, [me])

  return (
    <Screen
      name="organization users"
      isLoading={isLoading || isFetchingUsers || isFetchingOrg}
      error={error || usersError || orgError}>
      <GateExists exists={users && organization}>
        <OrganizationUsers
          {...props}
          users={users}
          organization={organization}
        />
      </GateExists>
    </Screen>
  )
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  me: state.auth.userData,
  isLoading: state.channels.isFetching,
  organizationId: ownProps.match.params.organization,
  canAssume: canAssume(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  onAssume: user => dispatch(awaitAssumeUser(user)),
  onEdit: (user, me) =>
    user.id !== me.id && canAny(me, ['USERS_EDIT_ORG', 'USERS_EDIT_ALL'])
      ? () => {
          ownProps.history.push(
            `/organizations/${ownProps.match.params.organization}/users/${user.id}/edit`
          )
        }
      : null
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationUsersContainer)
