import can from '../utilities/can'

export const isSuperAdmin = state =>
  state.auth.userData.permission === 'superadmin'

export const isObserver = state => state.auth.userData.permission === 'observer'

export const canAssume = state =>
  state.auth.userData.id &&
  !state.auth.userData.adminUser &&
  can(state.auth.userData, 'USERS_ASSUME')
