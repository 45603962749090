import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import styles from '../styles'
import { DialogActions } from '@material-ui/core'

class ConfirmDeleteDialog extends Component {
  render() {
    return (
      <div>
        <Dialog
          className="confirmDeleteDialog"
          open={this.props.active}
          onClose={this.props.onCancel}>
          <DialogTitle>
            {this.props.title ||
              'Are you sure you want to complete this deletion?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className="description">
              This action cannot be undone!
            </DialogContentText>
            {this.props.children}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onCancel}>Cancel</Button>
            <Button
              onClick={this.props.onConfirm}
              style={
                !this.props.disabled
                  ? styles.buttons_red
                  : styles.buttons_disabled
              }>
              Confirm Delete
            </Button>
          </DialogActions>
        </Dialog>
        <style>{`
          .confirmDeleteDialog .description {
            color: ${styles.buttons_red.backgroundColor};
            font-weight: 700;
          }
        `}</style>
      </div>
    )
  }
}

ConfirmDeleteDialog.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

ConfirmDeleteDialog.defaultProps = {
  active: false
}
export default ConfirmDeleteDialog
