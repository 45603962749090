import React from 'react'
import { connect } from 'react-redux'
import MergeCourse from './MergeCourse'
import Screen from '../../components/Screen'
import { courseMerge } from '../../actions/courses'

class MergeCourseContainer extends React.Component {
  render() {
    return (
      <Screen name="merge course">
        <MergeCourse {...this.props} />
      </Screen>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(courseMerge(data))
})

export default connect(null, mapDispatchToProps)(MergeCourseContainer)
