/**
 * Updates a path component with a dotted child
 * Ensures there are no bad paths (ex .name)
 *
 * @param {String} path - ex "user"
 * @param {String} childPath - ex "name"
 * @return {String} - ex "user.name"
 */
export const addDotChild = (path, childPath) => {
  return (path + '.' + childPath)
    .split('.')
    .filter(n => n)
    .join('.')
}

/**
 * Adds an array child to a path component
 *
 * @param {String} path - ex "user"
 * @param {Number} i
 * @return {String} - ex "users[0]"
 */
export const addArrayChild = (path, i) => {
  return `${path}[${i}]`
}

/**
 * Gets the latest child component of a path
 * Sometimes we need to replace it entirely rather than setting its value
 *
 * @param {String} path
 * @return {String} path component
 */
export const getChild = path =>
  path
    .split('.')
    .filter(n => n)
    .slice(-1)
    .join('')
