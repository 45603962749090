import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { DialogActions } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const ErrorMessage = styled.div`
  color: red;
  margin: 20px 0;
`

class CourseReleaseDateSelectionDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dateReleasing: props.dateReleasing || '',
      dateReleasingError: ''
    }
  }

  handleDateChange = ({ target: { value } }) => {
    const m = moment(value)
    if (m.isValid()) {
      this.setState({ dateReleasing: m.toDate(), dateReleasingError: '' })
    } else {
      this.setState({ dateReleasing: null, dateReleasingError: 'Invalid date' })
    }
  }

  handleSave = async () => {
    await this.props.onSubmit({ dateReleasing: this.state.dateReleasing })
    this.setState({ dateReleasing: '', dateReleasingError: '' })
    return
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.active} onClose={this.props.onCancel}>
          <DialogTitle>Set Package Release Date</DialogTitle>
          <DialogContent>
            <p>
              When a date is set, users will see a red square icon, indicating
              that the course has a release coming soon. They will be warned
              when attempting to download these courses.
            </p>

            <TextField
              label="Release Date"
              type="date"
              defaultValue={this.state.dateReleasing}
              onChange={this.handleDateChange}
              InputLabelProps={{ shrink: true }}
            />

            {this.props.error && (
              <ErrorMessage>{this.props.error}</ErrorMessage>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onCancel}>Cancel</Button>
            <Button disabled={this.props.isSaving} onClick={this.handleSave}>
              {this.props.isSaving ? <CircularProgress size={32} /> : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

CourseReleaseDateSelectionDialog.propTypes = {
  title: PropTypes.string,
  active: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  dateReleasing: PropTypes.string
}

CourseReleaseDateSelectionDialog.defaultProps = {
  active: false,
  dateReleasing: ''
}

export default CourseReleaseDateSelectionDialog
