import { useState, useEffect } from 'react'
import { fetchOrgUsers } from '../actions/users'

export const useOrgUsers = (
  orgId,
  withChannelInfo = false,
  channel,
  changing
) => {
  const [result, setResult] = useState(null)
  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        if (withChannelInfo) {
          if (channel && changing) {
            const users = await fetchOrgUsers(orgId)
            const usersWithChannelInfo = users?.map(user => ({
              ...user,
              inChannel: channel.users.includes(user.id),
              changing: changing.includes(user.id)
            }))
            setResult(usersWithChannelInfo)
            setIsFetching(false)
          }
        } else {
          const users = await fetchOrgUsers(orgId)
          setResult(users)
          setIsFetching(false)
        }
      } catch (error) {
        console.error(error)
        setError(error)
      }
    })()
  }, [channel, changing])

  return {
    users: result,
    isFetchingUsers: isFetching,
    usersError: error
  }
}
