import axios from 'axios'
import { push, replace } from 'connected-react-router'
import { getToken } from '../auth'

export async function fetchAdmins() {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/admins',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export async function fetchAdmin(id) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/admins/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export function createAdmin(data) {
  return async function(dispatch) {
    await axios({
      method: 'POST',
      url: process.env.REACT_APP_REMOTE + '/admins',
      data,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    dispatch(push('/admins/'))
  }
}

export function updateAdmin(id, data) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      url: process.env.REACT_APP_REMOTE + `/admins/${id}`,
      data,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    dispatch(push('/admins/'))
  }
}

export function deleteAdmin(user) {
  return async function(dispatch) {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(`Are you sure you want to delete user "${user.email}"?`)) {
      return
    }

    await axios({
      method: 'DELETE',
      url: process.env.REACT_APP_REMOTE + `/admins/${user.id}`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })

    dispatch(push(`/reloading`))
    dispatch(replace('/admins/'))
  }
}

export async function fetchRoles() {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/admins/roles',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}
