import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import ChannelReports from './ChannelReports'
import Screen from '../../components/Screen'
import { getChannel } from '../../selectors'
import { channelsFetchAwait } from '../../actions/channels'
import GateExists from '../../components/GateExists'
import { useOrganization } from '../../hooks/useOrganization'
import { useReports } from '../../hooks/useReports'

const ChannelReportsContainer = props => {
  const {
    fetchChannels,
    isLoading,
    error,
    organizationId,
    channelId,
    channel
  } = props
  const { reports, isFetchingReports, reportsError } = useReports(channelId)
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )

  useEffect(() => {
    fetchChannels()
  }, [])

  return (
    <Screen
      name="channel reports"
      isLoading={isLoading || isFetchingReports || isFetchingOrg}
      error={error || reportsError || orgError}>
      <GateExists exists={channel && organization}>
        <ChannelReports
          channel={channel}
          reports={reports}
          organization={organization}
          error={reportsError}
          {...props}
        />
      </GateExists>
    </Screen>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.users.isFetching || state.channels.isFetching,
    organizationId: ownProps.match.params.organization,
    channelId: ownProps.match.params.channel,
    channel: getChannel(ownProps.match.params.channel)(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  },
  onReportView: report => {
    const { organization, channel } = ownProps.match.params
    dispatch(
      push(
        `/organizations/${organization}/channels/${channel}/reports/${report.id}`
      )
    )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelReportsContainer)
