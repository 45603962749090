import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import styled from 'styled-components'
import ErrorMessage from '../components/ErrorMessage'
import BlurredBackgroundBanner from '../components/BlurredBackgroundBanner'
import DetailedCourseCard from '../components/DetailedCourseCard'
import CardWithHeader from '../components/CardWithHeader'
import CourseFeaturedFileList from '../containers/ConnectCourseFeaturedFileList'
import CourseFileTabs from '../containers/ConnectCourseFileTabs'
import CourseMetaForm from '../components/CourseMetaForm'
import FileDialog from '../components/FileDialog'
import ErrorDialog from '../components/ErrorDialog'
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog'
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon'
import PencilIcon from 'mdi-react/PencilIcon'
import PencilOffIcon from 'mdi-react/PencilOffIcon'
import _ from 'lodash'

const CourseContainer = styled.div`
  display: grid;
  grid-template-columns: 270px minmax(300px, auto);
  grid-template-rows: auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 17px;

  .disable-margin {
    margin: 0;
    margin-left: -12px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: -ms-grid;
    -ms-grid-columns: 270px 1fr;
    -ms-grid-rows: auto auto 1fr;

    .tile {
      -ms-grid-row: 1;
      -ms-grid-column-span: 2;
      margin-bottom: 20px;
    }
    .course {
      -ms-grid-row: 2;
      -ms-grid-column: 1;
      margin-right: 20px;
    }
    .tabRow {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-column-span: 2;
      margin-top: 20px;
    }
    .card-with-header {
      -ms-grid-row: 2;
      -ms-grid-column: 2;
    }
  }
`

class Course extends Component {
  static propTypes = {
    course: PropTypes.object.isRequired,
    tags: PropTypes.array,
    error: PropTypes.object.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isAdding: PropTypes.bool.isRequired,
    isDeletingFile: PropTypes.bool.isRequired,
    fileDeleteError: PropTypes.string,
    notifications: PropTypes.array,
    setAppBarState: PropTypes.func.isRequired,
    fetchCourseNotifications: PropTypes.func.isRequired,
    editCourse: PropTypes.func.isRequired,
    createCourse: PropTypes.func.isRequired,
    fetchCourses: PropTypes.func.isRequired,
    addCourseFile: PropTypes.func.isRequired,
    addFile: PropTypes.func.isRequired,
    fetchFiles: PropTypes.func.isRequired,
    fetchFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    dismissErrors: PropTypes.func.isRequired,
    dismissFileDeleteError: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    organizationSubscribesToCourse: PropTypes.bool
  }

  static defaultProps = {
    error: {},
    tags: []
  }

  constructor(props) {
    super(props)

    this.state = {
      course: props.course,
      isEditing: props.isEditing,
      edited: false,
      fileDeletionRequested: false,
      imgFile: null,
      bannerImgFile: null,
      syllabusFile: null,
      isFileDialogActive: false,
      fileDialogState: {
        file: null,
        type: ''
      },
      fileIdToDelete: ''
    }

    props.setAppBarState({
      visible: true,
      title: 'Course',
      rightIcon: <PencilIcon />,
      handleRight: this.toggleEditState
    })

    props.fetchCourseNotifications(props.match.params.id)
    props.fetchFiles(props.match.params.id)

    this.initialState = { ...this.state }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.course, prevProps.course)) {
      this.setState({
        course: this.props.course
      })
    }
  }

  toggleEditState = () => {
    this.props.setAppBarState({
      visible: true,
      title: 'Course',
      rightIcon: this.state.isEditing ? <PencilIcon /> : <PencilOffIcon />,
      handleRight: this.toggleEditState
    })
    this.setState({ isEditing: !this.state.isEditing })
  }

  onFileDrop = params => {
    this.setState({
      fileDialogState: { ...params },
      isFileDialogActive: true
    })
  }

  toggleFileDialog = values => {
    this.setState({
      isFileDialogActive: !this.state.isFileDialogActive,
      fileDialogState: Object.assign({}, this.state.fileDialogState, values)
    })
  }

  onChange = (value, field, e) => {
    if (e) e.stopPropagation()

    let course = _.merge({}, this.state.course)
    _.set(course, field, value)
    this.setState({ course, edited: true })
  }

  onImgDrop = (accepted, rejected, field) => {
    if (accepted.length === 0) return

    this.setState({
      course: { ...this.state.course, [field]: accepted[0].preview },
      [`${field}File`]: accepted[0],
      edited: true
    })
  }

  onSyllabusDrop = accepted => {
    if (accepted.length === 0) return

    this.setState({
      course: { ...this.state.course, syllabus: { name: accepted[0].name } },
      syllabusFile: accepted[0],
      edited: true
    })
  }

  onSubmit = async () => {
    const { course, imgFile, bannerImgFile, syllabusFile } = this.state
    await this.setState({ edited: false })
    if (course.img.substring(0, 4) === 'blob') delete course.img
    if (course.bannerImg.substring(0, 4) === 'blob') delete course.bannerImg

    const newCourse = {
      ...course,
      eid: parseInt(course.eid, 10)
    }

    let errors = false
    let courseId
    if (course.id) {
      const updateRes = await this.props.editCourse(newCourse)
      if (updateRes.type === 'EDIT_COURSE_FAILURE') errors = true
      courseId = course.id
    } else {
      const newRes = await this.props.createCourse(newCourse)
      if (newRes.type === 'ADD_COURSE_FAILURE') {
        errors = true
      } else if (newRes.data.id) {
        courseId = newRes.data.id
      }
    }

    const courseWithS3Urls = { id: courseId, ...newCourse }
    let flag = false
    if (imgFile) {
      const imgRes = await this.props.addCourseFile('img', imgFile, courseId)
      if (imgRes.type === 'ADD_FILE_FAILURE') errors = true
      if (imgRes.url) courseWithS3Urls.img = imgRes.url
      flag = true
    }
    if (bannerImgFile) {
      const bannerRes = await this.props.addCourseFile(
        'bannerImg',
        bannerImgFile,
        courseId
      )
      if (bannerRes.type === 'ADD_FILE_FAILURE') errors = true
      if (bannerRes.url) courseWithS3Urls.bannerImg = bannerRes.url
      flag = true
    }
    if (syllabusFile) {
      const syllabusRes = await this.props.addCourseFile(
        'syllabus',
        syllabusFile,
        courseId
      )
      if (syllabusRes.type === 'ADD_FILE_FAILURE') errors = true
      if (syllabusRes.url) courseWithS3Urls.syllabus.url = syllabusRes.url
      flag = true
    }
    if (flag) await this.props.editCourse(courseWithS3Urls)

    await this.props.fetchCourses()
    this.props.history.replace(`/course/${courseId}`, {
      course: courseWithS3Urls
    })

    if (!errors) this.initialState = { ...this.state }
  }

  onCancel = () => {
    this.setState({ ...this.initialState, isEditing: true })
  }

  dismissErrors = type => {
    this.setState({ ...this.initialState, isEditing: true })
    this.props.dismissErrors(type)
  }

  deleteFile = async () => {
    await this.props.deleteFile(this.state.fileIdToDelete)
    this.setState({ fileDeletionRequested: false, fileIdToDelete: '' })
    this.props.fetchFiles()
  }

  cancelFileDelete = () => {
    this.setState({ fileDeletionRequested: false })
  }

  requestFileDeletion = id => {
    this.setState({ fileDeletionRequested: true, fileIdToDelete: id })
  }

  handleNotificationClick = (e, id) => {
    e.preventDefault()
    this.props.history.push(`/notification/${id}`)
  }

  download = async id => {
    return await this.props.fetchFile(id)
  }

  render() {
    const errors = Object.keys(this.props.error)

    return (
      <div>
        {this.props.isFetching && (
          <div className="main loading">
            <CircularProgress size={50} />
          </div>
        )}
        {this.props.error.fetch && (
          <div className="main">
            <ErrorMessage
              icon={<AlertCircleOutlineIcon />}
              message={this.props.error.fetch}
            />
          </div>
        )}
        {errors.map(
          type =>
            type !== 'fetch' && (
              <ErrorDialog
                key={type}
                active={this.props.error[type] ? true : false}
                actions={[
                  {
                    label: 'Ok',
                    onClick: () => this.dismissErrors(type)
                  }
                ]}
                error={this.props.error[type]}
              />
            )
        )}
        {this.props.fileDeleteError && (
          <ErrorDialog
            active={this.props.fileDeleteError ? true : false}
            actions={[
              { label: 'Ok', onClick: this.props.dismissFileDeleteError }
            ]}
            error={this.props.fileDeleteError}
          />
        )}
        <FileDialog
          active={this.state.isFileDialogActive}
          toggleActive={this.toggleFileDialog}
          input={this.state.fileDialogState}
          courseId={this.props.course.id}
          addFile={this.props.addFile}
          fetchFiles={this.props.fetchFiles}
          isAdding={this.props.isAdding}
          tags={this.props.tags}
          dateReleasing={this.props.course.dateReleasing}
        />
        <ConfirmDeleteDialog
          title="Are you sure you want to delete this file?"
          active={this.state.fileDeletionRequested ? true : false}
          onConfirm={this.deleteFile}
          onCancel={this.cancelFileDelete}
        />
        {!this.props.isFetching &&
          !this.props.error.fetch &&
          !this.props.error.add && (
            <BlurredBackgroundBanner
              color="#9c9c9c"
              image={this.state.course.bannerImg}
              edit={this.state.isEditing}
              onDrop={this.onImgDrop}
              onChange={this.onChange}>
              <CourseContainer className="main home">
                {this.state.isEditing && (
                  <Card style={{ gridColumn: 'span 2', zIndex: 2 }}>
                    <CourseMetaForm
                      course={this.state.course}
                      onChange={this.onChange}
                      onCancel={this.onCancel}
                      onSubmit={this.onSubmit}
                      disableSubmit={
                        !this.state.edited ||
                        this.state.course.title.length === 0 ||
                        this.state.course.eid < 1
                      }
                      disableCancel={!this.state.edited}
                      isEditing={this.props.isEditing}
                    />
                  </Card>
                )}
                <DetailedCourseCard
                  course={this.state.course}
                  edit={this.state.isEditing}
                  edited={this.state.edited}
                  onChange={this.onChange}
                  onImgDrop={(accepted, rejected) =>
                    this.onImgDrop(accepted, rejected, 'img')
                  }
                  onSyllabusDrop={(accepted, rejected) =>
                    this.onSyllabusDrop(accepted, rejected)
                  }
                />
                <CardWithHeader
                  title="Current Files"
                  color={this.state.course.color}>
                  <CourseFeaturedFileList
                    showLmsChips
                    courseUrl={this.state.course.url}
                    download={id => this.download(id)}
                    courseId={this.state.course.id}
                    organizationSubscribesToCourse={!!this.state.course.eid}
                  />
                </CardWithHeader>
                {this.props.course.title && (
                  <div className="tabRow" style={{ gridColumn: 'span 2' }}>
                    <CourseFileTabs
                      showLmsChips
                      courseId={this.state.course.id}
                      notifications={this.props.notifications}
                      color={this.state.course.color}
                      colorSecondary={this.state.course.colorSecondary}
                      edit={this.state.isEditing}
                      deleteFile={id => this.requestFileDeletion(id)}
                      onFileDrop={params => this.onFileDrop(params)}
                      toggleFileDialog={this.toggleFileDialog}
                      download={id => this.download(id)}
                      handleNotificationClick={(e, id) =>
                        this.handleNotificationClick(e, id)
                      }
                    />
                  </div>
                )}
              </CourseContainer>
            </BlurredBackgroundBanner>
          )}
      </div>
    )
  }
}

export default Course
