import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CircularProgress from '@material-ui/core/CircularProgress'

class ScreenLoading extends React.Component {
  constructor(props) {
    super(props)
    this.state = { active: false }
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ active: true })
    }, this.props.minDuration)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    if (!this.state.active) {
      return null
    }

    return (
      <Card>
        <CardHeader title={this.props.message} />
        <CardContent style={{ align: 'center' }}>
          <CircularProgress size={50} />
        </CardContent>
      </Card>
    )
  }
}

ScreenLoading.defaultProps = {
  minDuration: 500
}

ScreenLoading.propTypes = {
  minDuration: PropTypes.number,
  message: PropTypes.string.isRequired
}

export default ScreenLoading
