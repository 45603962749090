import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import UserTile from '../../components/UserTile'

const AdminList = ({ me, admins, onEdit, onDelete }) => (
  <Card className="admins">
    {admins.map((admin, i) => (
      <React.Fragment key={admin.id}>
        <UserTile
          user={admin}
          isSuperAdmin
          isCurrentUser={me.id === admin.id}
          handleEditUserButton={onEdit}
          handleDeleteUserButton={onDelete}
        />
        {i < admins.length - 1 && <Divider />}
      </React.Fragment>
    ))}
  </Card>
)

AdminList.propTypes = {
  admins: PropTypes.array.isRequired,
  me: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func
}

export default AdminList
