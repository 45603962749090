import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import SimpleSearch from '../../components/SimpleSearch'
import Search from '../../components/Search'
import BulkCopyForm from './BulkCopyForm'

const ScrollableList = styled(List)`
  height: 500px;
  overflow: auto;
`

const ToolsBulkCopyCourses = ({
  channels,
  channelMap,
  selected,
  searchTerm,
  errors,
  onSearchChange,
  onSelect,
  onSubmit,
  onFormChange
}) => (
  <div>
    <Breadcrumbs links={[{ title: 'Tools', href: '/tools' }]} />
    <Card>
      <CardHeader className="title" title="Bulk Copy Courses" />
      <CardContent className="description">
        <h2>Options</h2>
        <BulkCopyForm onChange={onFormChange} onSubmit={onSubmit}>
          <h2>
            Channels
            {selected.length > 0 && ` (${selected.length})`}
          </h2>
          <Search
            autoFocus
            className="course-search"
            value={searchTerm}
            handleChange={onSearchChange}
          />
          <ScrollableList>
            <SimpleSearch
              filter={searchTerm}
              fields={['name', 'orgName']}
              documents={channels.map(row => ({
                ...row,
                orgName: row.organization.name
              }))}>
              {results => {
                if (searchTerm === '') results = channels
                return results.map(channel => (
                  <ListItem key={channel.id} onClick={() => onSelect(channel)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selected.includes(channel.id)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': getLabelId(channel) }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={getLabelId(channel)}
                      primary={
                        <>
                          <strong>{channel.name}</strong>
                          <span>({channel.organization.name})</span>
                        </>
                      }
                    />
                  </ListItem>
                ))
              }}
            </SimpleSearch>
          </ScrollableList>

          {errors.length > 0 && (
            <div style={{ color: 'red' }}>
              <h2>Errors</h2>
              <ul>
                {errors.map(result => (
                  <li key={result.id}>
                    {getChannelName(result.id, channelMap)}: {result.error}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </BulkCopyForm>
      </CardContent>
    </Card>
  </div>
)

const getLabelId = channel => {
  return `channel-label-${channel.id}`
}

const getChannelName = (id, channelMap) => {
  return channelMap[id] || `${id} (could not find name)`
}

ToolsBulkCopyCourses.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  errors: PropTypes.array,
  selected: PropTypes.array.isRequired,
  channels: PropTypes.array.isRequired,
  channelMap: PropTypes.object.isRequired,
  searchTerm: PropTypes.string
}

export default ToolsBulkCopyCourses
