import { channelGetConfigurableReports } from '../requests/channels'
import { useRequest } from './useRequest'

export const useConfigurableReports = () => {
  const { result, isFetching, error } = useRequest(
    channelGetConfigurableReports
  )

  return {
    configReports: result,
    isFetchingConfigReports: isFetching,
    configReportsError: error
  }
}
