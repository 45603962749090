import axios from 'axios'
import { getToken } from '../auth'

export function fetchNotificationsByCourseIdAwait(id) {
  return function(dispatch) {
    if (!getToken()) return dispatch(fetchNotificationsByCourseIdFailure())

    dispatch(fetchNotificationsByCourseIdRequest())
    return fetchNotificationsByCourseId(id).then(
      result => dispatch(fetchNotificationsByCourseIdSuccess(id, result)),
      err => dispatch(fetchNotificationsByCourseIdFailure(id, err))
    )
  }
}

async function fetchNotificationsByCourseId(id) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/notifications?course=${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

function fetchNotificationsByCourseIdRequest() {
  const payload = 'Loading course notifications'
  return { type: 'FETCH_COURSE_NOTIFICATIONS', payload }
}

function fetchNotificationsByCourseIdSuccess(id, items) {
  const payload = { id, items }
  return { type: 'FETCH_COURSE_NOTIFICATIONS_SUCCESS', payload }
}

function fetchNotificationsByCourseIdFailure(id) {
  const payload = `There was an error loading the notifications for course ${id}`
  return { type: 'FETCH_COURSE_NOTIFICATIONS_FAILURE', payload }
}
