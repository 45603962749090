import React from 'react'
import { connect } from 'react-redux'
import TaasTeacherView from './TaasTeacherView'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import { channelsFetchAwait } from '../../actions/channels'
import {
  fetchTeacher,
  fetchTeacherEnrolments,
  unenrolTeacher
} from '../../actions/taas'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'
import { taasTeacherLink, taasTeacherEnrolment } from '../../links'

class TaasTeacherViewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teacher: null,
      loading: false,
      submitting: false,
      error: null
    }
    this.props.setAppBarState({
      visible: true,
      title: 'View Teacher',
      rightIcon: null
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true })
      const [teacher, enrolments] = await Promise.all([
        this.props.fetchTeacher(),
        this.props.fetchTeacherEnrolments()
      ])

      this.setState({
        loading: false,
        teacher,
        enrolments
      })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError } = this.state

    return (
      <Screen
        name="teacher"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel && this.props.organization && this.state.teacher
          }>
          <TaasTeacherView
            {...this.props}
            teacher={this.state.teacher}
            enrolments={this.state.enrolments}
            observers={this.state.observers}
            facilitators={this.state.facilitators}
            error={stateError}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchTeacher: () =>
    fetchTeacher(ownProps.match.params.channel, ownProps.match.params.teacher),
  fetchTeacherEnrolments: () =>
    fetchTeacherEnrolments(
      ownProps.match.params.channel,
      ownProps.match.params.teacher
    ),
  onTeacherEnrolments: teacher =>
    ownProps.history.push(taasTeacherLink(teacher, '/enrolments')),
  onEnrolmentAdd: teacher =>
    ownProps.history.push(taasTeacherLink(teacher, '/enrolments/create')),
  onEnrolmentEdit: enrolment =>
    ownProps.history.push(taasTeacherEnrolment(enrolment)),
  onEnrolmentEnable: () => {},
  onEnrolmentDisable: (channel, teacher, enrolment) =>
    dispatch(unenrolTeacher(channel, teacher, enrolment))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasTeacherViewContainer)
