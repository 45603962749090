import moment from 'moment-timezone'
import React from 'react'
import { map } from 'bluebird'
import { groupBy } from 'lodash'
import { connect } from 'react-redux'
import PartnerCourseReportingImport from './PartnerCourseReportingImport'
import Screen from '../../components/Screen'
import {
  partnerReportingPartnersFetch,
  partnerCourseReportingBulkSubmit
} from '../../actions/tools'
import { setAppBarState } from '../../actions/ui'
import {
  generateDateOptions,
  generateEndDate
} from '../../utilities/partnerReporting'

class PartnerCourseReportingImportContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      selected: [],
      errors: []
    }
    this.props.setAppBarState({
      visible: true,
      title: 'Partner Usage Reporting Importer',
      rightIcon: null,
      handleRight: () => {}
    })
  }

  async componentDidMount() {
    try {
      const partners = await this.props.fetchPartners()

      this.setState({
        partners,
        isLoading: false
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        error: e
      })
    }
  }

  handleSubmit = async data => {
    this.setState({ errors: [] })

    data = data.map((row, i) => ({
      ...row,
      line: i + 2
    }))

    const groups = groupBy(
      data,
      row => row.partner + '/' + row.lob + '/' + row.date
    )
    let errors = []

    const savableGroups = await map(
      Object.keys(groups),
      async groupName => {
        const partnerId = groups[groupName][0].partner
        const lobId = groups[groupName][0].lob
        const date = moment(groups[groupName][0].date).toDate()

        try {
          return await this.prepareChunk(
            partnerId,
            lobId,
            date,
            groups[groupName]
          )
        } catch (e) {
          if (e.validation) {
            errors.push(e.message)
            return null
          }
          throw e
        }
      },
      { concurrency: 5 }
    )

    if (errors.length) {
      this.setState({ errors })
      return
    }

    await this.props.onSubmit(savableGroups)
  }

  prepareChunk = async (partnerId, lobId, date, rows) => {
    const firstLine = rows[0].line

    const partner = this.state.partners.find(
      partner => partner.id === partnerId
    )
    if (!partner) {
      const validOptions = this.state.partners.map(row => row.id).join(', ')
      throw createValidationError(
        `Line ${rows[0].line}: Partner ${partnerId} not found. Valid options: ${validOptions}`
      )
    }

    const lob = partner.linesOfBusiness.find(row => row.id === lobId)
    if (!lob) {
      const validOptions = partner.linesOfBusiness.map(row => row.id).join(', ')
      throw createValidationError(
        `Line ${rows[0].line}: LOB ${lobId} not found. Valid options: ${validOptions}`
      )
    }

    const dateOptions = generateDateOptions(partner, lob)
    const matchingDate = dateOptions.find(dateOption => {
      return dateOption.start.toString() === date.toString()
    })

    if (!matchingDate) {
      throw createValidationError(
        `Line ${firstLine}: Invalid date selected. Refer to the manual form for valid options.`
      )
    }

    const dateEnd = generateEndDate(date, dateOptions)
    if (!dateEnd) {
      throw createValidationError(
        `Line ${firstLine}: Invalid date selected (could not get end date). Refer to the manual form for valid options.`
      )
    }

    const courses = rows.map(row => {
      let mapped = partner.courseMappings.find(
        mapping => mapping.alias.trim() === row.course
      )
      if (!mapped && row.course.match(/^EDL(\d+)$/)) {
        mapped = { code: row.course, alias: row.course }
      }

      if (!mapped) {
        throw createValidationError(
          `Line ${row.line}: Course ${row.course} not found`
        )
      }

      if (partner.lms && partner.lms.length) {
        if (!row.lms) {
          throw createValidationError(`Line ${row.line}: No LMS specified`)
        }

        const matchingLms = partner.lms.find(lms => lms.id === row.lms)
        if (!matchingLms) {
          const validLmsOptions = partner.lms.map(lms => lms.id).join(', ')
          throw createValidationError(
            `Line ${row.line}: Invalid LMS specified. Should be one of: ${validLmsOptions}`
          )
        }
      }

      return { code: mapped.code, enrolments: row.enrolments, lms: row.lms }
    })

    return {
      partnerId,
      lobId,
      datePeriodStart: date,
      datePeriodEnd: dateEnd,
      courses
    }
  }

  render() {
    return (
      <Screen
        name="partner usage import"
        error={this.props.error || this.state.error}
        renderContent={() => (
          <PartnerCourseReportingImport
            {...this.props}
            errors={this.state.errors}
            onSubmit={this.handleSubmit}
          />
        )}
      />
    )
  }
}

const createValidationError = message => {
  const err = new Error(message)
  err.validation = true
  return err
}

const mapDispatchToProps = dispatch => ({
  fetchPartners: () => partnerReportingPartnersFetch(),
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  onSubmit: data => dispatch(partnerCourseReportingBulkSubmit(data))
})

export default connect(
  null,
  mapDispatchToProps
)(PartnerCourseReportingImportContainer)
