import joi from 'joi'
import createForm from './form/createForm'
import TextInput from './form/elements/TextInput'

export const MultifactorVerificationForm = ({ onSubmit, ...props }) => {
  return createForm({
    fields: [
      {
        id: 'code',
        label: 'Please provide your verification code',
        inputComponent: TextInput,
        required: true,
        validation: joi.string()
      }
    ],
    layoutOptions: {
      submitText: 'Verify'
    },
    onSubmit: async data => {
      return onSubmit(data)
    },
    ...props
  })
}
