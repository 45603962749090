import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'

class ToggleInput extends Component {
  static defaultProps = {
    value: false,
    fieldSuffix: '',
    valid: true
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target: { checked } }) {
    const { id, onChange, onBlur } = this.props

    onChange(id, checked)
    if (onBlur) onBlur()
  }

  render() {
    const { id, value, valid, fieldSuffix, classNames } = this.props
    const validClass = valid ? '' : 'invalid'
    return (
      <div
        className={classNames ? `toggle-input ${classNames}` : 'toggle-input'}>
        <FormLabel htmlFor={id}>{this.props.label}</FormLabel>
        <div className="switch">
          <input
            type="checkbox"
            checked={value === true}
            id={id}
            className={validClass}
            onChange={this.handleChange}
          />
          <span className="lever" />
        </div>
        {fieldSuffix !== '' ? (
          <span className="field-suffix">{fieldSuffix}</span>
        ) : null}
      </div>
    )
  }
}

ToggleInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  fieldSuffix: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  valid: PropTypes.bool,
  classNames: PropTypes.string
}

ToggleInput.displayName = 'ToggleInput'

export default ToggleInput
