import { getToken } from '../auth'
import { push, replace } from 'connected-react-router'
import { getChannel } from '../selectors'
import { setBuzzInstance } from './taas'
import {
  fetchChannels,
  channelsCreate,
  channelsUpdate,
  channelUsersAdd,
  channelUsersRemove,
  channelCreatePlatformConfiguration,
  channelExchange,
  channelCopy,
  channelDomainCreate,
  channelBuzzAdminCreate,
  channelSync,
  updateBuzzLtiRegistration,
  checkBuzzLtiRegistration
} from '../requests/channels'

export const channelsFetchAwait = (
  id,
  channelId,
  magicallySetBuzzInstance
) => async dispatch => {
  if (!getToken()) return dispatch(channelsFetchFailure())
  dispatch(channelsFetchStart(id))
  try {
    const channels = await fetchChannels(id)
    if (channelId && magicallySetBuzzInstance) {
      dispatch(
        setBuzzInstance(channels.find(channel => channel.id === channelId))
      )
    }
    return dispatch(channelsFetchSuccess(channels))
  } catch (error) {
    dispatch(channelsFetchFailure())
  }
}

function channelsFetchStart(id) {
  return { type: 'CHANNELS_FETCH_START', payload: id }
}

function channelsFetchSuccess(payload) {
  return { type: 'CHANNELS_FETCH_SUCCESS', payload }
}

function channelsFetchFailure(error) {
  return { type: 'CHANNELS_FETCH_FAILURE', payload: error }
}

export function channelsCreateAwait(id, data) {
  return function(dispatch) {
    if (!getToken()) return dispatch(channelsCreateFailure())
    dispatch(channelsCreateStart())
    channelsCreate(id, data)
      .then(newChannel => {
        dispatch(channelsCreateSuccess(newChannel))
        dispatch(push(`/organizations/${id}/channels`))
      })
      .catch(() => dispatch(channelsCreateFailure()))
  }
}

function channelsCreateStart() {
  return { type: 'CHANNELS_CREATE_START' }
}

function channelsCreateSuccess(payload) {
  return { type: 'CHANNELS_CREATE_SUCCESS', payload }
}

function channelsCreateFailure(error) {
  return { type: 'CHANNELS_CREATE_FAILURE', payload: error }
}

export function channelsUpdateAwait(orgId, channelId, data) {
  return function(dispatch) {
    if (!getToken()) return dispatch(channelsUpdateFailure())
    dispatch(channelsUpdateStart())
    channelsUpdate(orgId, channelId, data)
      .then(updated => {
        dispatch(channelsUpdateSuccess(updated))
        dispatch(push(`/organizations/${orgId}/channels`))
      })
      .catch(() => dispatch(channelsUpdateFailure()))
  }
}

function channelsUpdateStart() {
  return { type: 'CHANNELS_UPDATE_START' }
}

function channelsUpdateSuccess(payload) {
  return { type: 'CHANNELS_UPDATE_SUCCESS', payload }
}

function channelsUpdateFailure(error) {
  return { type: 'CHANNELS_UPDATE_FAILURE', payload: error }
}

export function channelUsersAddAwait(channelId, user) {
  return function(dispatch, getState) {
    const channel = getChannel(channelId)(getState())
    if (!getToken())
      return dispatch(channelUsersAddFailure(channel, user, 'Not logged in'))
    dispatch(channelUsersAddStart(channel, user))
    channelUsersAdd(channel, user)
      .then(() => {
        dispatch(channelUsersAddSuccess(channel, user))
      })
      .catch(e => dispatch(channelUsersAddFailure(channel, user, e.message)))
  }
}

function channelUsersAddStart(channel, user) {
  const payload = { user: user.id, channel: channel.id }
  return { type: 'CHANNEL_USERS_ADD_START', payload }
}

function channelUsersAddSuccess(channel, user) {
  const payload = { user: user.id, channel: channel.id }
  return { type: 'CHANNEL_USERS_ADD_SUCCESS', payload }
}

function channelUsersAddFailure(channel, user, error) {
  const payload = { user: user.id, channel: channel.id, error }
  return { type: 'CHANNEL_USERS_ADD_FALIURE', payload }
}

export function channelUsersRemoveAwait(channelId, user) {
  return function(dispatch, getState) {
    const channel = getChannel(channelId)(getState())
    if (!getToken())
      return dispatch(channelUsersRemoveFailure(channel, user, 'Not logged in'))
    dispatch(channelUsersRemoveStart(channel, user))
    channelUsersRemove(channel, user)
      .then(() => {
        dispatch(channelUsersRemoveSuccess(channel, user))
      })
      .catch(e => dispatch(channelUsersRemoveFailure(channel, user, e.message)))
  }
}

function channelUsersRemoveStart(channel, user) {
  const payload = { user: user.id, channel: channel.id }
  return { type: 'CHANNEL_USERS_REMOVE_START', payload }
}

function channelUsersRemoveSuccess(channel, user) {
  const payload = { user: user.id, channel: channel.id }
  return { type: 'CHANNEL_USERS_REMOVE_SUCCESS', payload }
}

function channelUsersRemoveFailure(channel, user, error) {
  const payload = { user: user.id, channel: channel.id, error }
  return { type: 'CHANNEL_USERS_REMOVE_FALIURE', payload }
}

export function channelPlatformConfigure(organizationId, channelId, data) {
  return async function(dispatch) {
    await channelCreatePlatformConfiguration(organizationId, channelId, data)
    dispatch(push(`/organizations/${organizationId}/channels/${channelId}`))
    alert('Done!')
  }
}

export function copyToClipboard(text) {
  const textField = document.createElement('textarea')
  textField.innerText = text
  document.body.appendChild(textField)
  if (window.navigator.platform === 'iPhone') {
    textField.setSelectionRange(0, 99999)
  } else {
    textField.select()
  }
  document.execCommand('copy')
  textField.remove()
}

export const channelSyncAwait = (channel, remote) => async () => {
  return channelSync(channel, remote)
}

export const channelExchangeAwait = (
  organizationId,
  channelId
) => async dispatch => {
  const out = await channelExchange(organizationId, channelId)
  dispatch(push(`/reloading`))
  alert('Done!')
  dispatch(replace(`/organizations/${organizationId}/channels/${channelId}`))
  return out
}

export const channelCopyAwait = (
  organizationId,
  channelId,
  data
) => async dispatch => {
  await channelCopy(organizationId, channelId, data)
  dispatch(push(`/organizations/${organizationId}/channels/${channelId}`))
  alert('Done!')
}

export const channelDomainCreateAwait = (
  organizationId,
  channelId,
  data
) => async dispatch => {
  await channelDomainCreate(organizationId, channelId, data)
  dispatch(push(`/organizations/${organizationId}/channels/${channelId}`))
  alert('Done!')
}

export const channelBuzzAdminCreateAwait = (
  organizationId,
  channelId,
  data
) => async dispatch => {
  await channelBuzzAdminCreate(organizationId, channelId, data)
  dispatch(push(`/organizations/${organizationId}/channels/${channelId}`))
  alert('Done!')
}

const buzzLtiActions = {
  check: {
    requested: () => ({ type: 'CHANNELS_CHECK_BUZZ_LTI_REQUEST' }),
    success: (isRegistered, buzzInstance) => ({
      type: 'CHANNELS_CHECK_BUZZ_LTI_SUCCESS',
      isRegistered,
      buzzInstance
    }),
    failure: (isRegistered, buzzInstance) => ({
      type: 'CHANNELS_CHECK_BUZZ_LTI_FAILURE',
      isRegistered,
      buzzInstance
    })
  },
  update: {
    requested: () => ({ type: 'CHANNELS_UPDATE_BUZZ_LTI_REQUEST' }),
    success: (isRegistered, buzzInstance) => ({
      type: 'CHANNELS_UPDATE_BUZZ_LTI_SUCCESS',
      isRegistered,
      buzzInstance
    }),
    failure: buzzInstance => ({
      type: 'CHANNELS_UPDATE_BUZZ_LTI_FAILURE',
      buzzInstance
    })
  }
}

export const checkBuzzLtiRegistrationAction = (
  organizationId,
  channelId,
  buzzInstance
) => async dispatch => {
  try {
    dispatch(buzzLtiActions.check.requested())
    const { isRegistered } = await checkBuzzLtiRegistration(
      organizationId,
      channelId,
      buzzInstance
    )
    dispatch(buzzLtiActions.check.success(isRegistered, buzzInstance))
  } catch (error) {
    console.error(error)
    dispatch(buzzLtiActions.check.failure(buzzInstance))
  }
}

export const updateBuzzLtiRegistrationAction = (
  organizationId,
  channelId,
  buzzInstance
) => async dispatch => {
  try {
    dispatch(buzzLtiActions.update.requested())
    const { isRegistered } = await updateBuzzLtiRegistration(
      organizationId,
      channelId,
      buzzInstance
    )
    dispatch(buzzLtiActions.update.success(isRegistered, buzzInstance))
    dispatch(channelsFetchAwait(organizationId))
    alert(`Buzz LTI Registration Successfully Updated!`)
  } catch (error) {
    console.error(error)
    alert('Buzz LTI Registration Update Failed! Try again later.')
    dispatch(buzzLtiActions.update.failure(buzzInstance))
  }
}
