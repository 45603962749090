import React from 'react'
import PropTypes from 'prop-types'
import { MaterialPicker } from 'react-color'

class ColorPicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isColorPickerVisible: false
    }

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen() {
    this.setState({ ...this.state, isColorPickerVisible: true })
  }

  handleClose() {
    this.setState({ ...this.state, isColorPickerVisible: false })
  }

  render() {
    const colorStyle = {
      height: '20px',
      width: '20px',
      backgroundColor: this.props.color,
      display: 'inline-block',
      cursor: 'pointer'
    }

    const popupStyle = {
      position: 'absolute',
      zIndex: 2
    }

    const coverStyle = {
      position: 'fixed',
      top: '0',
      right: '0',
      bottom: '0',
      left: '0'
    }

    const { className, ...restProps } = this.props

    return (
      <div className={className}>
        <div style={colorStyle} onClick={this.handleOpen} />
        {this.state.isColorPickerVisible && (
          <div style={popupStyle}>
            <div style={coverStyle} onClick={this.handleClose} />
            <MaterialPicker {...restProps} />
          </div>
        )}
        <style>{`
          /* The picker breaks with box-sizing set to border-box */
          .material-picker {
            box-sizing: content-box;
          }
        `}</style>
      </div>
    )
  }
}

ColorPicker.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
}

export default ColorPicker
