/**
 * @link https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 */
export function getParameterByName(name, url = window.location.href) {
  const cleanName = name.replace(/[[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + cleanName + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
