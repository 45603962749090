import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import SearchQueryContainer from '../containers/ConnectSearchQueryContainer'
import CourseTile from '../components/CourseTile'

class SearchPreview extends React.Component {
  constructor(props) {
    super(props)
    try {
      this.state = {
        query: JSON.parse(localStorage.getItem('preview'))
      }
    } catch (e) {
      this.state = { query: null }
    }
  }

  componentDidMount() {
    const { query } = this.state
    if (this.props.type === 'users') {
      this.props.previewUsers(query)
    }
    if (this.props.type === 'courses') {
      this.props.previewCourses(query)
    }
  }

  renderUser(user) {
    const orgs = this.props.organizations
    const orgName = (() => {
      const org = orgs.find(org => org.id === user.organization)
      if (org) {
        return org.name
      }
      return ''
    })()

    console.log({
      organizations: orgs,
      orgName,
      orgId: user.organization
    })

    const name = `${user.name.first} ${user.name.last}`

    return (
      <Card key={user.id}>
        <CardContent>
          <Typography component="strong" variant="text">
            {name}
          </Typography>
          {orgName && (
            <Typography variant="subtitle1" color="textSecondary">
              {orgName}
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  }

  render() {
    const { isLoading, error, users, courses, type } = this.props
    const { query } = this.state

    if (!query) {
      return (
        <div>
          <h2>Error Loading Preview</h2>
          <p>Please try again.</p>
        </div>
      )
    }

    return (
      <div>
        <div>
          <h2>
            Preview Matching {type[0].toUpperCase()}
            {type.substr(1)}
          </h2>
          <SearchQueryContainer readOnly query={query} saveable={false} />
        </div>
        {isLoading && <h2>Loading...</h2>}
        {!isLoading && error && <div>{error.message}</div>}
        {!isLoading && users && (
          <div>{users.map(user => this.renderUser(user))}</div>
        )}
        {!isLoading && courses && (
          <div className="courses">
            {courses.map(course => (
              <CourseTile key={course.id} course={course} />
            ))}
          </div>
        )}
      </div>
    )
  }
}

SearchPreview.defaultProps = {
  error: null,
  users: null,
  courses: null
}

SearchPreview.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  courses: PropTypes.arrayOf(PropTypes.shape({})),
  organizations: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string.isRequired,
  previewUsers: PropTypes.func.isRequired,
  previewCourses: PropTypes.func.isRequired
}

export default SearchPreview
