import React from 'react'
import PropTypes from 'prop-types'
import OrganizationChannelForm from './OrganizationChannelForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const OrganizationChannelsCreate = ({
  organization,
  reports,
  onSubmit,
  channelVerifyQuery
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        }
      ]}
    />
    <Card>
      <CardHeader className="title" title="Create New Channel" />
      <CardContent className="description">
        <OrganizationChannelForm
          reports={reports}
          onSubmit={onSubmit}
          channelVerifyQuery={channelVerifyQuery}
        />
      </CardContent>
    </Card>
  </div>
)

OrganizationChannelsCreate.propTypes = {
  reports: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  channelVerifyQuery: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired
}

export default OrganizationChannelsCreate
