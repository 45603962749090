const initial = {
  isFetching: true,
  isAdding: false,
  isEditing: false,
  isDeleting: false,
  error: {
    fetch: null,
    add: null,
    edit: null,
    delete: null
  },
  items: {
    byId: {},
    currentPageItemIds: []
  },
  totalPages: 0
}

function organizations(state = initial, action) {
  switch (action.type) {
    case 'FETCH_ORGANIZATIONS': {
      const isFetching = true
      const stateChange = { isFetching }
      return Object.assign({}, state, stateChange)
    }
    case 'FETCH_ORGANIZATIONS_SUCCESS': {
      const items = action.payload.items
      const byId = {}
      const currentPageItemIds = []
      items.forEach(item => {
        byId[item.id] = item
        currentPageItemIds.push(item.id)
      })

      return {
        ...state,
        items: {
          ...state.items,
          byId,
          currentPageItemIds
        },
        totalPages: action.payload.totalPages,
        isFetching: false,
        error: {
          ...state.error,
          fetch: null
        }
      }
    }
    case 'FETCH_ORGANIZATIONS_FAILURE': {
      const isFetching = false
      const error = Object.assign({}, state.error, { fetch: action.payload })
      const stateChange = {
        isFetching,
        error,
        items: {
          currentPageItemIds: [],
          byId: {
            ...state.items.byId
          }
        }
      }
      return Object.assign({}, state, stateChange)
    }
    case 'FETCH_ORGANIZATIONS_SUCCESS_BY_IDS': {
      const items = action.organizations
      const byId = {
        ...state.items.byId
      }
      items.forEach(item => {
        byId[item.id] = item
      })
      return {
        ...state,
        isFetching: false,
        error: {
          ...state.error,
          fetch: null
        },
        items: {
          ...state.items,
          byId
        }
      }
    }
    case 'ADD_ORGANIZATION': {
      const isFetching = true
      const stateChange = { isFetching }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_ORGANIZATION_SUCCESS': {
      const isFetching = false
      const error = Object.assign({}, state.error, { add: null })
      const stateChange = { isFetching, error }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_ORGANIZATION_FAILURE': {
      const isFetching = false
      const error = Object.assign({}, state.error, { add: action.payload })
      const stateChange = { isFetching, error }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_ORGANIZATION_DISMISS': {
      const isFetching = false
      const error = Object.assign({}, state.error, { add: action.payload })
      const stateChange = { isFetching, error }
      return Object.assign({}, state, stateChange)
    }
    case 'EDIT_ORGANIZATION': {
      const isEditing = true
      const stateChange = { isEditing }
      return Object.assign({}, state, stateChange)
    }
    case 'EDIT_ORGANIZATION_SUCCESS': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: null })
      const stateChange = { isEditing, error }
      return Object.assign({}, state, stateChange)
    }
    case 'EDIT_ORGANIZATION_FAILURE': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: action.payload })
      const stateChange = { isEditing, error }
      return Object.assign({}, state, stateChange)
    }
    case 'EDIT_ORGANIZATION_DISMISS': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: action.payload })
      const stateChange = { isEditing, error }
      return Object.assign({}, state, stateChange)
    }
    case 'DEL_ORGANIZATION': {
      const isDeleting = true
      const stateChange = { isDeleting }
      return Object.assign({}, state, stateChange)
    }
    case 'DEL_ORGANIZATION_SUCCESS': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: null })
      const stateChange = { isDeleting, error }
      return Object.assign({}, state, stateChange)
    }
    case 'DEL_ORGANIZATION_FAILURE': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: action.payload })
      const stateChange = { isDeleting, error }
      return Object.assign({}, state, stateChange)
    }
    case 'DEL_ORGANIZATION_DISMISS': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: null })
      const stateChange = { isDeleting, error }
      return Object.assign({}, state, stateChange)
    }
    default: {
      return state
    }
  }
}

export default organizations
