const initial = {
  isFetching: false,
  error: null
}

function notificationsByCourseId(state = initial, action) {
  switch (action.type) {
    case 'FETCH_COURSE_NOTIFICATIONS': {
      return { ...state, isFetching: true }
    }
    case 'FETCH_COURSE_NOTIFICATIONS_SUCCESS': {
      return {
        ...state,
        isFetching: false,
        error: null,
        [action.payload.id]: action.payload.items
      }
    }
    case 'FETCH_COURSE_NOTIFICATIONS_FAILURE': {
      return { ...state, isFetching: false, error: action.payload }
    }
    default: {
      return state
    }
  }
}

export default notificationsByCourseId
