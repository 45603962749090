// TODO (https://edl-cats.atlassian.net/browse/CLIENT-457): Re-visit and pull this list from from the API (once sim course CRUD is implemented)
export default [
  {
    title: 'Fashion',
    licensePrefix: 'OVBF',
    productCode: 'VBFA1',
    productFamily: 'VB'
  },
  {
    title: 'Accounting',
    licensePrefix: 'OVBA',
    productCode: 'VBA1',
    productFamily: 'VB'
  },
  {
    title: 'Personal Finance',
    licensePrefix: 'OVBP',
    productCode: 'PF2',
    productFamily: 'VB'
  },
  {
    title: 'Restaurant',
    licensePrefix: 'OVBH',
    productCode: 'H2',
    productFamily: 'VB'
  },
  {
    title: 'Restaurant Collaboration',
    licensePrefix: 'OVBHC',
    productCode: 'VBHC2',
    productFamily: 'VB'
  },
  {
    title: 'Entrepreneurship',
    licensePrefix: 'OVBE',
    productCode: 'VBE1',
    productFamily: 'VB'
  },
  {
    title: 'Entrepreneurship Collaboration',
    licensePrefix: 'OVBEC',
    productCode: 'VBEC1',
    productFamily: 'VB'
  },
  {
    title: 'Hotel',
    licensePrefix: 'OVBO',
    productCode: 'VBHOT1',
    productFamily: 'VB'
  },
  {
    title: 'Hotel Collaboration',
    licensePrefix: 'OVBOC',
    productCode: 'VBHOTC1',
    productFamily: 'VB'
  },
  {
    title: 'Management',
    licensePrefix: 'OVBM',
    productCode: 'VBM3',
    productFamily: 'VB'
  },
  {
    title: 'Management Collaboration',
    licensePrefix: 'OVBMC',
    productCode: 'VBMC3',
    productFamily: 'VB'
  },
  {
    title: 'Retailing',
    licensePrefix: 'OVBR',
    productCode: 'VBR4',
    productFamily: 'VB'
  },
  {
    title: 'Retailing Collaboration',
    licensePrefix: 'OVBRC',
    productCode: 'VBRC4',
    productFamily: 'VB'
  },
  {
    title: 'Sports & Entertainment',
    licensePrefix: 'OVBS',
    productCode: 'VBS2',
    productFamily: 'VB'
  },
  {
    title: 'Sports & Entertainment Collaboration',
    licensePrefix: 'OVBSC',
    productCode: 'VBSC2',
    productFamily: 'VB'
  },
  {
    title: 'Dental Assisting: Skills & Procedures',
    licensePrefix: 'OVHD',
    productCode: 'VHD1',
    productFamily: 'VH'
  },
  {
    title: 'Dental Assisting: Skills',
    licensePrefix: 'OVHDA',
    productCode: 'VHDA1',
    productFamily: 'VH'
  },
  {
    title: 'Dental Assisting: Procedures',
    licensePrefix: 'OVHDB',
    productCode: 'VHDB1',
    productFamily: 'VH'
  },
  {
    title: 'Custom Course',
    licensePrefix: 'OVCC',
    productCode: 'VBCC',
    productFamily: 'VB'
  },
  {
    title: 'VB All Access',
    licensePrefix: 'OVAA',
    productCode: 'VBAA',
    productFamily: 'VB'
  }
]
