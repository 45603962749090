import {
  getToken,
  login,
  loginGoogle,
  assume,
  organizationSelection,
  switchBack,
  acceptTerms,
  forgotPassword,
  resetPassword,
  fetchUserData
} from '../auth'
import { resetState } from './resetState'
import { decodeToken } from '../utilities/decodeToken'

/**
 * Finalize login once a successful terminus to the flow has been reached (and
 * there a no more UI state a user need to resolve like org selection or terms acceptance.)
 * @param {*} dispatch Pass through the redux dispatch function from caller.
 * @param {*} token Token string to store in localStorage in order to 'Save' the login.
 * @returns Dispatch results of loginSuccess
 */
export const completeLogin = async (dispatch, token) => {
  localStorage.setItem('token', token)
  dispatch(awaitUserData())
  return dispatch(loginSuccess(token))
}

export const awaitLogin = (username, password) => dispatch => {
  dispatch(loginRequest())
  return login(username, password).then(
    ({ data }) => {
      if (decodeToken(data.token).requires2ndFactor) {
        return dispatch(requiresMfa(data))
      }

      if (!data.hasAcceptedTerms) {
        return dispatch(promptTerms(data))
      }

      if (data.selectionToken) {
        return dispatch(promptOrg(data))
      }

      return completeLogin(dispatch, data.token)
    },
    error => {
      let message = 'Failed to login. Please try again.'
      if (error.data && error.data && error.data.message) {
        message = error.data.message
      }

      return dispatch(loginFailure(message))
    }
  )
}

export const awaitGoogleLogin = ({ code }) => dispatch => {
  dispatch(loginRequest())
  return loginGoogle(code).then(
    ({ data }) => {
      if (!data.hasAcceptedTerms) {
        return dispatch(promptTerms(data))
      }

      if (data.selectionToken) {
        return dispatch(promptOrg(data))
      }

      return completeLogin(dispatch, data.token)
    },
    error => {
      let message = 'Failed to login with Google'
      if (error.data && error.data.message) {
        message = error.data.message
      }

      dispatch(loginFailure(message))
    }
  )
}

const loginRequest = () => ({ type: 'LOGIN_REQUEST' })

export const loginSuccess = token => ({
  type: 'LOGIN_SUCCESS',
  payload: token
})

const loginFailure = loginErrorMessage => ({
  type: 'LOGIN_FAILURE',
  payload: loginErrorMessage
})

export const awaitOrganizationSelection = id => (dispatch, getState) => {
  const {
    auth: { selectionToken }
  } = getState()

  return organizationSelection(id, selectionToken).then(
    ({ data }) => {
      return completeLogin(dispatch, data.token)
    },
    error => {
      let message = 'Failed to login. Please try again.'
      if (error.data && error.data && error.data.message) {
        message = error.data.message
      }

      return dispatch(loginFailure(message))
    }
  )
}

export const promptOrg = ({ selectionToken, token, organizations }) => ({
  type: 'LOGIN_PROMPT_ORG',
  payload: {
    selectionToken,
    token,
    organizations
  }
})

const requiresMfa = ({ token, selectionToken, organizations, username }) => ({
  type: 'LOGIN_REQUIRES_MFA',
  payload: {
    token,
    selectionToken,
    organizations,
    usernameForTerms: username
  }
})

export const promptTerms = ({
  token,
  selectionToken,
  organizations,
  username
}) => {
  return {
    type: 'LOGIN_PROMPT_TERMS',
    payload: {
      token,
      selectionToken,
      organizations,
      usernameForTerms: username
    }
  }
}

export const awaitAcceptTerms = () => async (dispatch, getState) => {
  try {
    const {
      auth: { selectionToken, token, organizations, usernameForTerms }
    } = getState()

    await acceptTerms(usernameForTerms, token)
    dispatch(termsAccepted())

    if (selectionToken) {
      return dispatch(promptOrg({ selectionToken, token, organizations }))
    }
    return completeLogin(dispatch, token)
  } catch (error) {
    let message =
      'Failed to login. Error accepting terms of use, please try again.'
    if (error?.data?.message) {
      message = error.data.message
    }
    return dispatch(loginFailure(message))
  }
}

const termsAccepted = () => ({ type: 'LOGIN_TERMS_ACCEPTED' })

// unified method for setting fail states.
export const rejectLogin = (message, error) => dispatch => {
  if (error) console.error(error)
  dispatch(loginFailure(message))
}

export const awaitForgotPassword = email => dispatch => {
  dispatch(forgotPasswordRequest())
  return forgotPassword(email).then(
    () => dispatch(forgotPasswordSuccess()),
    err => dispatch(forgotPasswordFailure(err))
  )
}

const forgotPasswordRequest = () => ({ type: 'FORGOT_PASSWORD_REQUEST' })

const forgotPasswordSuccess = () => ({
  type: 'FORGOT_PASSWORD_SUCCESS'
})

const forgotPasswordFailure = forgotPasswordErrorMessage => ({
  type: 'FORGOT_PASSWORD_FAILURE',
  payload: forgotPasswordErrorMessage
})

export const awaitResetPassword = data => dispatch => {
  dispatch(resetPasswordRequest())
  return resetPassword(data).then(
    result => {
      dispatch(resetPasswordSuccess())
      return result
    },
    err => dispatch(resetPasswordFailure(err))
  )
}

const resetPasswordRequest = () => ({
  type: 'RESET_PASSWORD_REQUEST'
})

const resetPasswordSuccess = () => ({
  type: 'RESET_PASSWORD_SUCCESS'
})

const resetPasswordFailure = () => ({
  type: 'RESET_PASSWORD_FAILURE',
  payload:
    'Password reset failed. Please try again!\nIf the issue persists try requesting a new password reset link.'
})

export const logout = currentDate => dispatch => {
  dispatch(resetState())
  dispatch({
    type: 'LOGOUT',
    payload: currentDate
  })
}

export const awaitUserData = () => dispatch => {
  if (!getToken()) return dispatch(fetchUserDataFailure)

  dispatch(fetchUserDataRequest())
  return fetchUserData().then(
    result => dispatch(fetchUserDataSuccess(result)),
    err => dispatch(fetchUserDataFailure(err))
  )
}

const fetchUserDataRequest = () => ({ type: 'FETCH_USER_DATA_REQUEST' })

const fetchUserDataSuccess = userData => ({
  type: 'FETCH_USER_DATA_SUCCESS',
  payload: userData
})

const fetchUserDataFailure = error => ({
  type: 'FETCH_USER_DATA_FAILURE',
  payload: error,
  error
})

export const awaitAssumeUser = user => async () => {
  if (
    // eslint-disable-next-line no-restricted-globals
    !confirm(
      `Are you sure you want to login as ${user.email}? \n\nTo switch back, you'll need to log in again.`
    )
  ) {
    return
  }

  await assume(user.id)
  window.location = '/'
}

export const awaitSwitchBack = () => async () => {
  // eslint-disable-next-line no-restricted-globals
  if (!confirm('Are you sure you want to switch back to your admin account?')) {
    return
  }

  await switchBack()
  window.location = '/'
}
