import React from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import DownloadHistory from './DownloadHistory'
import Screen from '../../components/Screen'
import { setAppBarState } from '../../actions/ui'
import { getDownloadHistory } from '../../selectors'
import { downloadHistoryFetchAwait } from '../../actions/downloads'

class DownloadHistoryContainer extends React.Component {
  componentDidMount() {
    this.props.fetchDownloads()
    this.props.setAppBarState({
      visible: true,
      title: 'Download History',
      rightIcon: null,
      handleRight: () => {}
    })
  }

  render() {
    const { isLoading, error: propError } = this.props
    return (
      <Screen name="download history" isLoading={isLoading} error={propError}>
        <DownloadHistory
          onClick={this.props.onCourseView}
          downloads={this.props.downloads}
        />
      </Screen>
    )
  }
}

const mapStateToProps = state => ({
  downloads: getDownloadHistory(state),
  isLoading: state.downloads.isLoading
})

const mapDispatchToProps = dispatch => {
  return {
    setAppBarState: appBarState => {
      dispatch(setAppBarState(appBarState))
    },
    fetchDownloads: () => {
      dispatch(downloadHistoryFetchAwait())
    },
    onCourseView: download => {
      dispatch(push(`/course/${download.course}`))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadHistoryContainer)
