const initial = {}

function taas(state = initial, action) {
  switch (action.type) {
    case 'TAAS_SET_INSTANCE':
      return {
        ...state,
        [action.channel]: action.instance
      }

    default:
      return state
  }
}

export default taas
