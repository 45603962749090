import { fetchChannels } from '../requests/channels'
import { useRequest } from './useRequest'

export const useChannels = orgId => {
  const { result, isFetching, error } = useRequest(fetchChannels, orgId)

  return {
    channels: result,
    isFetchingChannel: isFetching,
    channelError: error
  }
}
