import React from 'react'
import PropTypes from 'prop-types'
import RemoveFinalGradeForm from './RemoveFinalGradeForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'

const RemoveFinalGrade = ({ enrollment, onReview, onConfirm }) => (
  <div>
    <Breadcrumbs links={[{ title: 'Tools', href: '/tools' }]} />
    <Card>
      <CardHeader className="title" title="Genius: Remove Final Grade" />
      <CardContent className="description">
        <RemoveFinalGradeForm onSubmit={onReview} />
      </CardContent>
    </Card>

    {enrollment && (
      <Card style={{ marginTop: '25px' }}>
        <CardContent className="description">
          <p>
            You are about to delete an enrollment final grade for the domain{' '}
            {enrollment.domain}, and the course {enrollment.course}. This
            operation is unrecoverable. Are you sure you wish to continue?
          </p>
          <Button color="secondary" variant="contained" onClick={onConfirm}>
            Confirm Deletion
          </Button>
        </CardContent>
      </Card>
    )}
  </div>
)

RemoveFinalGrade.propTypes = {
  onReview: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  enrollment: PropTypes.shape({
    domain: PropTypes.string.isRequired,
    course: PropTypes.string.isRequired
  })
}

export default RemoveFinalGrade
