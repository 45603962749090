import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogContentText from '@material-ui/core/DialogContentText'

const DownloadProgress = ({ state }) => {
  const { stage, ...rest } = state
  return (
    <div>
      <Dialog open={isOpen(stage)}>
        <DialogTitle>Download</DialogTitle>
        <DialogContent>
          <p>
            {getStage(stage)}
            {isProcessing(stage) && (
              <span>&nbsp;({progressOfStage(stage, rest)})</span>
            )}
          </p>
          <DialogContentText style={{ textAlign: 'center' }}>
            {isProcessing(stage) && <CircularProgress size={100} />}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

const isProcessing = stage => ['gathering', 'downloading'].includes(stage)

const isOpen = stage => ['gathering', 'downloading', 'complete'].includes(stage)

const progressOfStage = (stage, state) => {
  if (stage === 'gathering') {
    return (
      <span>
        {state.coursesCompleted.length} of{' '}
        {state.coursesQueued.length +
          state.coursesProcessing.length +
          state.coursesCompleted.length}
      </span>
    )
  }

  if (stage === 'downloading') {
    return (
      <span>
        {state.downloadsCompleted.length} of{' '}
        {state.downloadsQueued.length +
          state.downloadsProcessing.length +
          state.downloadsCompleted.length}
      </span>
    )
  }

  return ''
}

const getStage = stage => {
  if (stage === 'gathering') {
    return 'Gathering Files from Courses'
  }

  if (stage === 'downloading') {
    return 'Downloading Files'
  }

  if (stage === 'complete') {
    return 'Complete'
  }

  return null
}

DownloadProgress.defaultProps = {}

DownloadProgress.propTypes = {
  state: PropTypes.object.isRequired
}

export default DownloadProgress
