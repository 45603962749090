import React from 'react'
import SearchQueryInput from './SearchQueryInput'
import { suggestUsers } from '../actions/users'

const SearchQueryInputUser = props => (
  <SearchQueryInput
    type="user"
    getSuggestions={suggestUsers}
    renderSuggestion={suggestion => <div>{suggestion.name}</div>}
    getSuggestionValue={suggestion => suggestion.id}
    {...props}
  />
)

export default SearchQueryInputUser
