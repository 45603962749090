import React from 'react'
import PropTypes from 'prop-types'
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon'
import ErrorMessage from './ErrorMessage'

const GateExists = ({ exists, message, renderContent, children }) =>
  exists ? (
    renderContent ? (
      renderContent()
    ) : (
      children
    )
  ) : (
    <ErrorMessage icon={<AlertCircleOutlineIcon />} message={message} />
  )

GateExists.defaultProps = {
  message: 'Item not found',
  exists: false
}

GateExists.propTypes = {
  exists: PropTypes.any,
  message: PropTypes.string,
  children: PropTypes.any,
  renderContent: PropTypes.func
}

export default GateExists
