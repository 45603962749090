import React from 'react'
import PropTypes from 'prop-types'
import OrganizationChannelsCopyForm from './OrganizationChannelsCopyForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const OrganizationChannelsCopy = ({ organization, channel, onSubmit }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        }
      ]}
    />
    <Card>
      <CardHeader className="title" title="Copy Courses on Buzz" />
      <CardContent className="description">
        <strong>{channel.name}</strong>
        <p>
          Warning: this action will create additional courses each time it runs.
          Use with caution.
        </p>
        <OrganizationChannelsCopyForm channel={channel} onSubmit={onSubmit} />
      </CardContent>
    </Card>
  </div>
)

OrganizationChannelsCopy.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  channelVerifyQuery: PropTypes.func.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired
}

export default OrganizationChannelsCopy
