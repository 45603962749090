import { getToken } from './auth'
import axios from 'axios'

export const reportError = async (error, vars, level = 'error') => {
  const payload = {
    message: error.stack || error,
    level,
    vars
  }

  console.log(JSON.stringify(payload, null, 4))

  await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/internal/clientLog',
    data: payload,
    headers: { Authorization: 'Bearer ' + getToken() },
    withCredentials: true
  })
}

export const uncaughtErrorHandler = evt => {
  reportError(evt.error, {
    url: window.location.href,
    agent: navigator.userAgent
  }).then(() => {})
}

export const handleRenderError = (error, errorInfo, vars = {}) => {
  console.log({
    error,
    errorInfo
  })
  reportError(error, {
    ...vars,
    url: window.location.href,
    agent: navigator.userAgent
  }).then(() => {})
}

export const handleScreenError = (error, vars = {}) => {
  console.log({
    error,
    vars
  })

  reportError(
    error,
    {
      ...vars,
      uncaught: true,
      url: window.location.href,
      agent: navigator.userAgent
    },
    'warn'
  ).then(() => {})
}
