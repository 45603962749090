import React from 'react'
import PropTypes from 'prop-types'

const Tile = ({ children, ...rest }) => (
  <div className="tile" {...rest}>
    {children}
    <style>{`
      .tile {
        background: #ffffff;
        box-shadow:
          0 2px 2px 0 rgba(0,0,0,.14),
          0 3px 1px -2px rgba(0,0,0,.2),
          0 1px 5px 0 rgba(0,0,0,.12);
        padding: 24px;
      }
    `}</style>
  </div>
)

Tile.propTypes = {
  children: PropTypes.node
}

export default Tile
