import React from 'react'
import { connect } from 'react-redux'
import PartnerCourseReporting from './PartnerCourseReporting'
import Screen from '../../components/Screen'
import { partnerReportingPartnersFetch } from '../../actions/tools'
import { setAppBarState } from '../../actions/ui'

class PartnerReportingContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      partners: [],
      error: null
    }
    this.props.setAppBarState({
      visible: true,
      title: 'Partner Course Usage Reporting',
      rightIcon: null,
      handleRight: () => {}
    })
  }

  async componentDidMount() {
    try {
      const partners = await this.props.fetchPartners()

      this.setState({
        partners,
        isLoading: false
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        error: e
      })
    }
  }

  render() {
    return (
      <Screen
        name="Partner course usage reporting"
        isLoading={this.state.isLoading}
        error={this.props.error || this.state.error}
        renderContent={() => (
          <PartnerCourseReporting
            {...this.props}
            partners={this.state.partners}
          />
        )}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPartners: () => partnerReportingPartnersFetch(),
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState))
})

export default connect(null, mapDispatchToProps)(PartnerReportingContainer)
