import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import PlusIcon from 'mdi-react/PlusIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import SimpleSearch from '../components/SimpleSearch'

const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 24px;

  .details {
    flex-grow: 1;
    width: 100%;
  }

  .user {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .name {
    font-weight: bold;
    margin-right: 10px;
  }

  .chip {
    flex-shrink: 0;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const TaasLinkedUserSearch = ({
  filter,
  student,
  documents,
  onAdd,
  onRemove
}) => (
  <SimpleSearch
    filter={filter}
    fields={['firstname', 'lastname', 'email', 'username']}
    documents={documents}>
    {results => {
      if (filter === '') results = documents
      return (
        <div>
          {results.map((user, i) => (
            <div key={user.id}>
              <Container>
                <div className="user">
                  <div className="name">
                    {user.firstname} {user.lastname}
                  </div>
                  <div className="email">{user.email}</div>
                </div>

                <div className="edit">
                  {user.authorized ? (
                    <IconButton onClick={() => onRemove(student, user)}>
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => onAdd(student, user)}>
                      <PlusIcon />
                    </IconButton>
                  )}
                </div>
              </Container>
              {i < results.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      )
    }}
  </SimpleSearch>
)

TaasLinkedUserSearch.propTypes = {
  student: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  filter: PropTypes.string,
  options: PropTypes.object,
  documents: PropTypes.array
}

TaasLinkedUserSearch.defaultProps = {
  filter: '',
  documents: []
}

export default TaasLinkedUserSearch
