import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

const PasswordResetSentDialog = ({ open, handleClose }) => (
  <Dialog open={open}>
    <DialogTitle>Password Reset Sent</DialogTitle>
    <DialogContent>
      <DialogContentText>
        We have sent you an email with instructions on how to reset your
        password.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant="contained" color="primary">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
)

PasswordResetSentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default PasswordResetSentDialog
