import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import CardHeader from '@material-ui/core/CardHeader'
import UserTile from '../../../components/UserTile'
import Breadcrumbs from '../../../components/Breadcrumbs'

const OrganizationUsers = ({
  isSuperAdmin,
  organization,
  users,
  me,
  onEdit,
  canAssume,
  onAssume
}) => {
  return (
    <div className="main">
      <Breadcrumbs
        links={[
          { title: 'Organizations', href: '/organizations' },
          {
            title: organization.name,
            href: `/organizations/${organization.id}`
          }
        ]}
      />
      <Card className="organizations">
        <CardHeader className="title" title="Users" />
        {users.map((user, i) => (
          <React.Fragment key={user.id}>
            <UserTile
              isSuperAdmin={isSuperAdmin}
              organization={organization}
              user={user}
              isCurrentUser={user.id === me.id}
              onAssume={canAssume ? onAssume : null}
              handleEditUserButton={onEdit(user, me)}
            />
            {i < users.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </Card>
    </div>
  )
}

OrganizationUsers.propTypes = {
  isSuperAdmin: PropTypes.bool.isRequired,
  organization: PropTypes.object,
  users: PropTypes.array,
  me: PropTypes.object.isRequired,
  onAssume: PropTypes.func,
  onEdit: PropTypes.func,
  canAssume: PropTypes.bool
}

export default OrganizationUsers
