import React from 'react'
import { connect } from 'react-redux'
import PlusIcon from 'mdi-react/PlusIcon'
import TaasCourseStudentEnrolmentEdit from './TaasCourseStudentEnrolmentEdit'
import Screen from '../../../components/Screen'
import { isSuperAdmin } from '../../../selectors/auth'
import {
  fetchCourse,
  updateCourseEnrolment,
  fetchCourseEnrolment
} from '../../../actions/taas'
import { channelsFetchAwait } from '../../../actions/channels'
import { setAppBarState } from '../../../actions/ui'
import { getOrganization, getChannel } from '../../../selectors'
import GateExists from '../../../components/GateExists'

class TaasCourseEnrolmentEditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, course: null, enrolment: null, error: null }
    this.props.setAppBarState({
      visible: true,
      title: 'Course Enrollments',
      rightIcon: <PlusIcon />,
      handleRight: () => {
        const { organization, channel, course } = props.match.params
        this.props.history.push(
          `/organizations/${organization}/channels/${channel}/taas/courses/${course}/enrolments/create`
        )
      }
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, course: null })
      const [course, enrolment] = await Promise.all([
        this.props.fetchCourse(),
        this.props.fetchEnrolment()
      ])
      this.setState({ loading: false, course, enrolment })
    } catch (e) {
      console.error(e.message)
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, course, enrolment } = this.state

    return (
      <Screen
        name="course enrollment edit"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel &&
            this.props.organization &&
            this.state.course &&
            this.state.enrolment
          }>
          <TaasCourseStudentEnrolmentEdit
            {...this.props}
            enrolment={enrolment}
            course={course}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchCourse: () =>
    fetchCourse(ownProps.match.params.channel, ownProps.match.params.course),
  fetchEnrolment: () =>
    fetchCourseEnrolment(
      ownProps.match.params.channel,
      ownProps.match.params.course,
      ownProps.match.params.id,
      'student'
    ),
  onSubmit: data =>
    dispatch(
      updateCourseEnrolment(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        ownProps.match.params.course,
        ownProps.match.params.id,
        data,
        'student'
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasCourseEnrolmentEditContainer)
