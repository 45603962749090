import { isUserLoggedIn } from '../auth'

const initial = {
  isLoggedIn: isUserLoggedIn(),

  isLoggingIn: false,
  isAcceptingTerms: false,
  isSelectingOrg: false,
  isFetchingUserData: false,
  isRequestingPassword: false,
  isResetingPassword: false,

  fetchUserDataErrorMessage: null,
  loginErrorMessage: null,
  resetPasswordErrorMessage: null,
  usernameForTerms: '',
  organizations: [],
  token: '',
  selectionToken: '',

  verificationErrorMessage: null,

  userData: {}
}

function auth(state = initial, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST': {
      return { ...state, isLoggingIn: true }
    }
    case 'LOGIN_SUCCESS': {
      return {
        ...state,
        token: action.payload,
        usernameForTerms: '',
        selectionToken: '',
        organizations: [],
        isLoggingIn: false,
        isSelectingOrg: false,
        isAcceptingTerms: false,
        isLoggedIn: true
      }
    }
    case 'LOGIN_REQUIRES_MFA': {
      return {
        ...state,
        usernameForTerms: action.payload.usernameForTerms,
        selectionToken: action.payload.selectionToken,
        organizations: action.payload.organizations,
        token: action.payload.token,
        isLoggingIn: false,
        isLoggedIn: false
      }
    }
    case 'POST_MFA_CODE_AUTH_SUCCESS': {
      return {
        ...state,
        token: action.payload,
        isLoggedIn: true,
        verificationErrorMessage: null
      }
    }
    case 'POST_MFA_CODE_AUTH_FAILURE': {
      return { ...state, verificationErrorMessage: action.payload }
    }
    case 'LOGIN_PROMPT_ORG': {
      return {
        ...state,
        organizations: action.payload.organizations,
        selectionToken: action.payload.selectionToken,
        token: action.payload.token,
        isSelectingOrg: true
      }
    }
    case 'LOGIN_ORG_SELECTED': {
      return {
        ...state,
        isSelectingOrg: false
      }
    }
    case 'LOGIN_PROMPT_TERMS': {
      return {
        ...state,
        usernameForTerms: action.payload.usernameForTerms,
        selectionToken: action.payload.selectionToken,
        organizations: action.payload.organizations,
        token: action.payload.token,
        isAcceptingTerms: true
      }
    }
    case 'LOGIN_TERMS_ACCEPTED': {
      return {
        ...state,
        isAcceptingTerms: false
      }
    }
    case 'LOGIN_FAILURE': {
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: false,
        isSelectingOrg: false,
        isAcceptingTerms: false,
        loginErrorMessage: action.payload
      }
    }

    case 'FORGOT_PASSWORD_REQUEST': {
      return {
        ...state,
        isRequestingPassword: true
      }
    }
    case 'FORGOT_PASSWORD_RESPONSE': {
      return {
        ...state,
        isRequestingPassword: false
      }
    }
    case 'RESET_PASSWORD_REQUEST': {
      return {
        ...state,
        isResetingPassword: true
      }
    }
    case 'RESET_PASSWORD_SUCCESS': {
      delete localStorage.token
      return {
        ...state,
        isResetingPassword: false
      }
    }
    case 'RESET_PASSWORD_FAILURE': {
      delete localStorage.token
      return { ...state, resetPasswordErrorMessage: action.payload }
    }
    case 'LOGOUT': {
      delete localStorage.token
      return { ...state, isLoggedIn: false }
    }
    case 'FETCH_USER_DATA_REQUEST': {
      return state
    }
    case 'FETCH_USER_DATA_SUCCESS': {
      const userData = action.payload
      return { ...state, userData }
    }
    case 'FETCH_USER_DATA_FAILURE': {
      return { ...state, fetchUserDataErrorMessage: action.payload }
    }
    case 'CLEAR_LOGIN_ERROR_MESSAGE': {
      return { ...state, loginErrorMessage: null }
    }

    default: {
      return state
    }
  }
}

export default auth
