import React, { Component } from 'react'
import ErrorMessage from '../components/ErrorMessage'
import AlertIcon from 'mdi-react/AlertIcon'

class NotFound extends Component {
  render() {
    return <ErrorMessage icon={<AlertIcon />} message="404 Page Not Found" />
  }
}

export default NotFound
