import { mapKeys } from 'lodash'
import { connect } from 'react-redux'
import Notification from '../pages/Notification'
import {
  dismissEditError,
  dismissPostError,
  dismissDeleteError,
  dismissSendError,
  sendNotificationEmailAwait
} from '../actions/notifications'
import { findListObjectById } from '../helpers'
import { setAppBarState } from '../actions/ui'
import {
  fetchNotificationFilesAwait,
  getS3GetUrlAwait,
  deleteFileAwait,
  dismissUploadError
} from '../actions/notificationFiles'

const mapStateToProps = (state, props) => {
  return {
    notification: state.notifications.items.find(
      findListObjectById.bind(this, props.match.params.id)
    ),
    isFetching: state.notifications.isFetching,
    error: {
      ...state.notifications.error,
      ...mapKeys(state.notificationFiles.error, (v, key) => `files-${key}`)
    },
    isSuperAdmin: state.auth.userData.permission === 'superadmin',
    isFetchingFiles: state.notificationFiles.isFetching,
    files: state.notificationFiles.items
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    dismissErrors: type => {
      if (type === 'add') dispatch(dismissPostError())
      if (type === 'edit') dispatch(dismissEditError())
      if (type === 'delete') dispatch(dismissDeleteError())
      if (type === 'send') dispatch(dismissSendError())
      if (type === 'files-add') dispatch(dismissUploadError())
    },
    setAppBarState: appBarState => {
      dispatch(setAppBarState(appBarState))
    },
    sendNotificationEmail: () => {
      dispatch(sendNotificationEmailAwait(props.match.params.id))
    },
    fetchFiles: () => {
      dispatch(fetchNotificationFilesAwait(props.match.params.id))
    },
    onFileDownload: id => {
      dispatch(getS3GetUrlAwait(id))
    },
    onFileDelete: id => {
      dispatch(deleteFileAwait(id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
