import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import filePackageColor from '../utilities/filePackageColor'
import Semver from './Semver'
import FileLink from './FileLink'
import Collapse from './Collapse'
import LmsChip from './LmsChip'
import LinkAndDate from './LinkAndDate'
import UploadDate from './UploadDate'
import ZipBoxIcon from 'mdi-react/ZipBoxIcon'
import FilePdfBoxIcon from 'mdi-react/FilePdfBoxIcon'
import moment from 'moment-timezone'
import { parseDescription } from '../helpers'

const StyledFeaturedFileListItem = styled.li`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;

  .lms__chip {
    margin: auto 50px 5px 0 !important;
  }
`

const IconURL = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
`
const Title = styled.h3`
  flex: 1 1 auto;
  margin-bottom: 5px;
`

const iconMap = {
  package: <ZipBoxIcon />,
  guide: <FilePdfBoxIcon />
}

const FeaturedFileListItem = props => {
  const { title, body } = parseDescription(props.description)
  const lms =
    props.type === 'package'
      ? {
          id: props.name,
          name: props.name,
          color: filePackageColor(props.name)
        }
      : null

  return (
    <StyledFeaturedFileListItem>
      {props.iconUrl && <IconURL src={props.iconUrl} alt="" />}
      <Title>{props.title}</Title>
      {props.showLmsChips && lms && <LmsChip lms={lms} />}
      {props.version && <Semver version={props.version} isLatest={true} />}
      <Collapse title={title} body={body} />
      {props.filename && (
        <LinkAndDate wrap="wrap" width="100%">
          <FileLink
            width="calc(100% - 130px)"
            onClick={async () => {
              const link = await props.download(props.id)
              window.location.assign(link)
            }}
            label={props.filename}
            icon={iconMap[props.type]}
          />
          {props.date && (
            <UploadDate>
              {/* do not break before ']' */}
              {[
                moment(props.date).format('\\[ DD-MMM-YYYY'),
                <span key={props.date}>&nbsp;</span>,
                ']'
              ]}
            </UploadDate>
          )}
        </LinkAndDate>
      )}
    </StyledFeaturedFileListItem>
  )
}

FeaturedFileListItem.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  iconUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  filename: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(iconMap)),
  version: PropTypes.string,
  lms: PropTypes.object,
  date: PropTypes.string,
  download: PropTypes.func,
  showLmsChips: PropTypes.bool
}

export default FeaturedFileListItem
