import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import PencilIcon from 'mdi-react/PencilIcon'
import AccountsIcon from 'mdi-react/AccountsIcon'
import TournamentIcon from 'mdi-react/TournamentIcon'
import styled from 'styled-components'
import OrganizationStatus from './OrganizationStatus'
import OrganizationTags from './OrganizationTags'

const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 24px;

  .details {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 1;
    gap: 16px;
    width: 100%;
  }

  .name {
    font-weight: bold;
  }

  .chip {
    flex-shrink: 0;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

const OrganizationTile = ({
  organization,
  handleChannelsButton,
  handleUsersButton,
  handleEditOrgButton,
  isSuperAdmin
}) => {
  const { isActive, orgTags, name, salesforceId } = organization
  return (
    <Container>
      <div className="details">
        <OrganizationStatus isActive={isActive} />
        <div className="name">{`${name} - ${salesforceId}`}</div>
        <OrganizationTags tags={orgTags} />
      </div>
      <div className="edit">
        {handleChannelsButton && (
          <IconButton onClick={() => handleChannelsButton(organization)}>
            <TournamentIcon />
          </IconButton>
        )}
        <IconButton onClick={() => handleUsersButton(organization)}>
          <AccountsIcon />
        </IconButton>
        {isSuperAdmin && (
          <IconButton onClick={() => handleEditOrgButton(organization)}>
            <PencilIcon />
          </IconButton>
        )}
      </div>
    </Container>
  )
}

OrganizationTile.propTypes = {
  isSuperAdmin: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
    .isRequired,
  isObserver: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
  organization: PropTypes.object,
  handleUsersButton: PropTypes.func,
  handleChannelsButton: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
    .isRequired,
  handleEditOrgButton: PropTypes.func
}

export default OrganizationTile
