import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { DialogActions } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

class CourseFolderSelectionDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      nameError: ''
    }
  }

  handleNameChange = ({ target: { value } }) => {
    this.setState({ name: value })
  }

  handleCreate = async () => {
    if (!this.state.name) {
      this.setState({ nameError: 'Please specify a name' })
      return
    }

    await this.props.onCreate({ name: this.state.name })
    this.setState({ name: '', nameError: '' })
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.active} onClose={this.props.onCancel}>
          <DialogTitle>Select a Course Folder</DialogTitle>
          <DialogContent>
            <List>
              {this.props.intent === 'assign' && (
                <ListItem>
                  <TextField
                    type="text"
                    label="Folder Name"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                    error={this.state.nameError}
                    helperText="Create a new course folder"
                    margin="normal"
                  />
                  <Button onClick={this.handleCreate}>Save</Button>
                </ListItem>
              )}
              {this.props.courseFolders.map(folder => (
                <ListItem key={folder.id}>
                  <ListItemText>
                    <Button onClick={() => this.props.onSubmit(folder.id)}>
                      {folder.name} ({folder.courses.length})
                    </Button>
                  </ListItemText>

                  {!folder.readOnly && (
                    <ListItemSecondaryAction>
                      {folder.deleting ? (
                        <CircularProgress size={16} />
                      ) : (
                        <Button onClick={() => this.props.onDelete(folder.id)}>
                          x
                        </Button>
                      )}
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onCancel}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

CourseFolderSelectionDialog.propTypes = {
  intent: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  courseFolders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  onCancel: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

CourseFolderSelectionDialog.defaultProps = {
  active: false
}

export default CourseFolderSelectionDialog
