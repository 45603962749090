import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { sortBy, groupBy } from 'lodash'

const StyledTable = styled.table`
  width: 600px;
  border-collapse: collapse;
  border: 1px solid #ccc;

  td,
  th {
    text-align: left;
    width: 20;
    padding: 5px;
  }
`

const lookupAlias = (courseCode, partner) => {
  const matching = partner.courseMappings.filter(row => row.code === courseCode)
  if (!matching.length) {
    return 'Other'
  }

  return matching.map(row => row.alias).join(' or ')
}

const LinesTable = ({ partner, addedLines, onRemove }) => {
  if (partner.lms && partner.lms.length) {
    return (
      <LinesTableMultipleLms
        partner={partner}
        addedLines={addedLines}
        onRemove={onRemove}
      />
    )
  }

  const preppedLines = sortBy(
    addedLines.map(line => ({
      ...line,
      alias: lookupAlias(line.code, partner)
    })),
    'alias'
  )

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Course</th>
          <th>Count</th>
          <th>LMS</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {preppedLines.map(line => (
          <tr key={line.code}>
            <td>{lookupAlias(line.code, partner)}</td>
            <td>{line.enrolments}</td>
            <td>{line.lms}</td>
            <td>
              <button type="button" onClick={() => onRemove(line.code)}>
                x
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

const LinesTableMultipleLms = ({ partner, addedLines, onRemove }) => {
  const data = linesByCode(addedLines, partner)

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Course</th>
          {partner.lms.map(lms => (
            <th key={lms.id}>{lms.name}</th>
          ))}
          <th>LMS</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data.map(line => (
          <tr key={line.code}>
            <td>{line.alias}</td>
            {Object.keys(line.lms).map(lms => (
              <td key={lms}>{line.lms[lms]}</td>
            ))}
            <td>
              <button type="button" onClick={() => onRemove(line.code)}>
                x
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

const linesByCode = (addedLines, partner) => {
  const groupedLines = groupBy(addedLines, 'code')
  return sortBy(
    Object.keys(groupedLines).map(key => ({
      alias: lookupAlias(key, partner),
      code: key,
      lms: groupedLines[key].reduce(
        (out, row) => ({
          ...out,
          [row.lms]: row.enrolments
        }),
        partner.lms.reduce(
          (out, item) => ({
            ...out,
            [item.id]: 0
          }),
          {}
        )
      )
    })),
    'alias'
  )
}

LinesTable.propTypes = {
  partner: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  addedLines: PropTypes.arrayOf(
    PropTypes.shape({
      lineOfBusiness: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired
    })
  ).isRequired
}

LinesTableMultipleLms.propTypes = {
  partner: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  addedLines: PropTypes.arrayOf(
    PropTypes.shape({
      lineOfBusiness: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired
    })
  ).isRequired
}

export default LinesTable
