import { connect } from 'react-redux'
import { setAppBarState } from '../actions/ui'
import { VerifyMultiFactorAuthentication } from '../pages/VerifyMultiFactorAuthentication'

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    setAppBarState: appBarState => {
      dispatch(setAppBarState(appBarState))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyMultiFactorAuthentication)
