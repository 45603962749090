import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import OrganizationTile from '../../components/OrganizationTile'
import { Pagination } from '../../components/pagination/Pagination'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
import { canScoped } from '../../utilities/can'
import { debounce } from 'lodash'
import ErrorMessage from '../../components/ErrorMessage'
import NoOrgsIcon from 'mdi-react/DomainOffIcon'
import Spinner from '../../components/Spinner'
import SearchWithSimpleFilter from '../../components/SearchWithSimpleFilter'
import styled from 'styled-components'

export const OrganizationTypes = Object.freeze({
  Primary: 'PRIMARY',
  Secondary: 'SECONDARY',
  College: 'COLLEGE'
})

const OrgsCard = styled(Card)`
  margin-bottom: 30px;
`

const Organizations = ({
  isFetching,
  totalPages,
  isSuperAdmin,
  isObserver,
  organizations,
  onClickEdit,
  onClickUsers,
  onClickChannels,
  fetchOrganizations,
  setAppBarState,
  onClickAdd,
  me
}) => {
  const predicate = org => () => org.id === me.organization.id
  const limit = 25
  const [typeFilter, setTypeFilter] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(0)

  useEffect(() => {
    setAppBarState({
      visible: true,
      title: 'Organizations',
      rightIcon: <PlusCircleOutlineIcon />,
      handleRight: () => onClickAdd()
    })
  }, [])

  useEffect(() => {
    fetchOrganizations(0, limit, searchTerm, typeFilter)
    setPage(0)
  }, [searchTerm, typeFilter])

  const handleSearch = debounce(
    searchTerm => {
      setSearchTerm(searchTerm)
    },
    500,
    { maxWait: 1000 }
  )

  const handleFilter = event => {
    setTypeFilter(event.target.value)
  }

  return (
    <>
      <SearchWithSimpleFilter
        filterLabel="Type"
        filterValue={typeFilter}
        searchValue={searchTerm}
        filterOptions={OrganizationTypes}
        handleSearchChange={handleSearch}
        handleFilterChange={handleFilter}
      />
      {isFetching ? (
        <Spinner loading />
      ) : organizations.length > 0 ? (
        <>
          <OrgsCard className="organizations">
            {organizations.map((organization, i) => (
              <React.Fragment key={organization.id}>
                <OrganizationTile
                  organization={organization}
                  user={null}
                  isSuperAdmin={isSuperAdmin}
                  isObserver={isObserver}
                  handleEditOrgButton={organization =>
                    onClickEdit(organization)
                  }
                  handleUsersButton={() => onClickUsers(organization)}
                  handleChannelsButton={
                    canScoped(
                      me,
                      'CHANNELS_READ_ALL',
                      'CHANNELS_READ_ORG',
                      predicate(organization)
                    )
                      ? () => {
                          onClickChannels(organization)
                        }
                      : () => {}
                  }
                />
                {i < organizations.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </OrgsCard>
          <Pagination
            totalPages={totalPages}
            limit={limit}
            fetch={fetchOrganizations}
            search={searchTerm}
            filter={typeFilter}
            page={page}
            setPage={setPage}
          />
        </>
      ) : (
        <ErrorMessage
          icon={<NoOrgsIcon size={48} />}
          message="No Organizations Found"
        />
      )}
    </>
  )
}

Organizations.propTypes = {
  me: PropTypes.object,
  isSuperAdmin: PropTypes.bool.isRequired,
  isObserver: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickEdit: PropTypes.func,
  onClickUsers: PropTypes.func,
  onSearchChange: PropTypes.func,
  onClickChannels: PropTypes.func,
  fetchOrganizations: PropTypes.func.isRequired,
  setAppBarState: PropTypes.func.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  }),
  totalPages: PropTypes.number
}

export default Organizations
