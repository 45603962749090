const defaultReadMore = '<p class="readMore">read more...</p>'

/**
 * Truncates an HTML string up to a number of words
 * attempting to keep paragraphs or lines intact when possible, up to an upper limit.
 *
 * @param {String} htmlStr
 * @param {Integer} words
 * @param {Integer} safetyLength
 * @param {String} more - text to append
 * @return {String}
 */
export default (
  htmlStr,
  words,
  safetyLength = words,
  more = defaultReadMore
) => {
  const node = document.createElement('div')
  node.innerHTML = htmlStr

  let wordCount = 0
  let out = ''

  for (var i = 0; i < node.children.length; i++) {
    var child = node.children.item(i)
    var addingWords = child.textContent.split(' ').length
    var newWordCount = addingWords + wordCount
    var isLast = i === node.children.length - 1

    // fits only when within safety. prevent next
    if (newWordCount > words && newWordCount <= safetyLength) {
      out += child.outerHTML
      if (!isLast) {
        out += more
      }
      break
      // doesnt fit at all
    } else if (newWordCount > words) {
      if (!isLast) {
        out += more
      }
      break
    } else {
      out += child.outerHTML
    }

    wordCount = newWordCount
  }

  return out
}
