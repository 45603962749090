import React from 'react'
import { connect } from 'react-redux'
import PlusIcon from 'mdi-react/PlusIcon'
import TaasCourseStudentEnrolmentCreate from './TaasCourseStudentEnrolmentCreate'
import Screen from '../../../components/Screen'
import { isSuperAdmin } from '../../../selectors/auth'
import {
  fetchCourse,
  fetchStudents,
  createCourseEnrolment
} from '../../../actions/taas'
import { channelsFetchAwait } from '../../../actions/channels'
import { setAppBarState } from '../../../actions/ui'
import { getOrganization, getChannel } from '../../../selectors'
import GateExists from '../../../components/GateExists'

class TaasCourseStudentEnrolmentCreateContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      course: null,
      users: null,
      error: null
    }
    this.props.setAppBarState({
      visible: true,
      title: 'Course Enrollments',
      rightIcon: <PlusIcon />,
      handleRight: () => {
        const { organization, channel, course } = props.match.params
        this.props.history.push(
          `/organizations/${organization}/channels/${channel}/taas/courses/${course}/enrolments/create`
        )
      }
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, course: null, courses: null })
      const [course, users] = await Promise.all([
        this.props.fetchCourse(),
        this.props.fetchStudents()
      ])
      this.setState({ loading: false, course, users })
    } catch (e) {
      console.error(e.message)
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, course, users } = this.state

    return (
      <Screen
        name="enroll users"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel && this.props.organization && this.state.course
          }>
          <TaasCourseStudentEnrolmentCreate
            {...this.props}
            course={course}
            users={users}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchCourse: () =>
    fetchCourse(ownProps.match.params.channel, ownProps.match.params.course),
  fetchStudents: () => fetchStudents(ownProps.match.params.channel),
  onSubmit: data =>
    dispatch(
      createCourseEnrolment(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        ownProps.match.params.course,
        'student',
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasCourseStudentEnrolmentCreateContainer)
