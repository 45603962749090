const initial = {
  isFetching: false,
  isAdding: false,
  isDeleting: false,
  isGettingS3GetUrl: false,
  error: {
    fetch: null,
    add: null,
    delete: null,
    s3GetUrl: null
  },
  items: [],
  selected: []
}

function files(state = initial, action) {
  switch (action.type) {
    case 'FETCH_FILES': {
      const isFetching = true
      const stateChange = { isFetching }
      return Object.assign({}, state, stateChange)
    }
    case 'FETCH_FILES_SUCCESS': {
      const isFetching = false
      const error = Object.assign({}, state.error, { fetch: null })
      const items = action.payload
      const stateChange = { isFetching, error, items }
      return Object.assign({}, state, stateChange)
    }
    case 'FETCH_FILES_FAILURE': {
      const isFetching = false
      const error = Object.assign({}, state.error, { fetch: action.payload })
      const items = []
      const stateChange = { isFetching, error, items }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_FILE': {
      const isAdding = true
      const stateChange = { isAdding }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_FILE_SUCCESS': {
      const isAdding = false
      const error = Object.assign({}, state.error, { add: null })
      const stateChange = { isAdding, error }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_FILE_FAILURE': {
      const isAdding = false
      const error = Object.assign({}, state.error, { add: action.payload })
      const stateChange = { isAdding, error }
      return Object.assign({}, state, stateChange)
    }
    case 'S3GETURL_REQUEST': {
      const isGettingS3GetUrl = true
      const stateChange = { isGettingS3GetUrl }
      return Object.assign({}, state, stateChange)
    }
    case 'S3GETURL_SUCCESS': {
      const isGettingS3GetUrl = false
      const error = Object.assign({}, state.error, { s3GetUrl: null })
      const stateChange = { isGettingS3GetUrl, error }
      return Object.assign({}, state, stateChange)
    }
    case 'S3GETURL_FAILURE': {
      const isGettingS3GetUrl = false
      const error = Object.assign({}, state.error, { s3GetUrl: action.payload })
      const stateChange = { isGettingS3GetUrl, error }
      return Object.assign({}, state, stateChange)
    }
    case 'DELETE_FILE': {
      return { ...state, isDeleting: true }
    }
    case 'DELETE_FILE_SUCCESS': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: null })
      return { ...state, isDeleting, error }
    }
    case 'DELETE_FILE_FAILURE': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: action.payload })
      return { ...state, isDeleting, error }
    }
    case 'DELETE_FILE_DISMISS': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: null })
      return { ...state, isDeleting, error }
    }
    default: {
      return state
    }
  }
}

export default files
