import React, { Component } from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import logo from '../assets/edynamic-logo-medium.png'
import BellIcon from 'mdi-react/BellIcon'
import UserSwitchIcon from 'mdi-react/UserSwitchIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import SchoolIcon from 'mdi-react/SchoolIcon'
import HistoryIcon from 'mdi-react/HistoryIcon'
import DomainIcon from 'mdi-react/DomainIcon'
import LogoutIcon from 'mdi-react/LogoutIcon'
import UserIcon from 'mdi-react/UserIcon'
import LabelIcon from 'mdi-react/LabelIcon'
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon'
import AccountQuestionIcon from 'mdi-react/AccountQuestionIcon'
import HelpCircleIcon from 'mdi-react/HelpCircleIcon'
import can from '../utilities/can'

class Drawer extends Component {
  constructor(props) {
    super(props)
    this.changeRoute = this.changeRoute.bind(this)
  }

  changeRoute(url) {
    this.props.history.push(url)
  }

  render() {
    return (
      <div style={styles.drawerInnerContainer}>
        <div style={styles.logoContainer}>
          <img style={styles.logoImage} src={logo} alt="eDynamic Logo" />
        </div>
        <List>
          <ListSubheader>eDynamic</ListSubheader>
          <Divider />
          <ListItem button onClick={() => this.changeRoute('/notifications')}>
            <ListItemText>Notifications</ListItemText>
            <ListItemIcon>
              <BellIcon />
            </ListItemIcon>
          </ListItem>
          {can(this.props.user, 'COURSES_READ_ASSIGNED') && (
            <ListItem button onClick={() => this.changeRoute('/courses')}>
              <ListItemText>Courses</ListItemText>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
            </ListItem>
          )}
          {can(this.props.user, 'USERS_READ_ALL_ORGS') ? (
            <ListItem button onClick={() => this.changeRoute('/organizations')}>
              <ListItemText>Organizations</ListItemText>
              <ListItemIcon>
                <DomainIcon />
              </ListItemIcon>
            </ListItem>
          ) : (
            <ListItem button onClick={() => this.changeRoute(`/organizations`)}>
              <ListItemText>My Organization</ListItemText>
              <ListItemIcon>
                <DomainIcon />
              </ListItemIcon>
            </ListItem>
          )}
          {this.props.isLoggedInUserSuperAdmin && (
            <ListItem button onClick={() => this.changeRoute('/tags')}>
              <ListItemText>Tags</ListItemText>
              <ListItemIcon>
                <LabelIcon />
              </ListItemIcon>
            </ListItem>
          )}
          <ListItem
            button
            onClick={() => this.changeRoute('/download-history')}>
            <ListItemText>Download History</ListItemText>
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
          </ListItem>
          {can(this.props.user, 'SURVEYS_ENTER') && (
            <ListItem button onClick={() => this.changeRoute('/surveys')}>
              <ListItemText>Surveys</ListItemText>
              <ListItemIcon>
                <AccountQuestionIcon />
              </ListItemIcon>
            </ListItem>
          )}
          {this.props.isLoggedInUserSuperAdmin && (
            <ListItem button onClick={() => this.changeRoute('/admins')}>
              <ListItemText>Admins</ListItemText>
              <ListItemIcon>
                <AccountMultipleIcon />
              </ListItemIcon>
            </ListItem>
          )}
          {this.props.user.isAdmin && (
            <ListItem button onClick={() => this.changeRoute('/tools')}>
              <ListItemText>Tools</ListItemText>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
            </ListItem>
          )}
          <ListSubheader>Account</ListSubheader>
          <Divider />
          <ListItem
            button
            onClick={() => window.open('http://support.edynamiclearning.com/')}>
            <ListItemIcon>
              <HelpCircleIcon />
            </ListItemIcon>
            <ListItemText>Support</ListItemText>
          </ListItem>
          <ListItem button onClick={() => this.changeRoute('/account')}>
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText>Account</ListItemText>
          </ListItem>
          {this.props.isAssuming && (
            <ListItem button onClick={this.props.onSwitchBack}>
              <ListItemIcon>
                <UserSwitchIcon />
              </ListItemIcon>
              <ListItemText>Switch to Admin</ListItemText>
            </ListItem>
          )}
          <ListItem button onClick={() => this.changeRoute('/logout')}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
        <style>{`
          .link {
            justify-content: flex-start;
            text-decoration: none;
            color: #212121;
            opacity: 1 !important;
          }
          .pin {
            float: right;
          }
        `}</style>
      </div>
    )
  }
}

const styles = {
  mainContainer: {},
  drawerInnerContainer: { paddingTop: 10 },
  logoContainer: { textAlign: 'center' },
  logoImage: { width: '80%' }
}

Drawer.propTypes = {
  isLoggedInUserSuperAdmin: PropTypes.bool,
  isLoggedInUserAdmin: PropTypes.bool,
  isAssuming: PropTypes.bool,
  onSwitchBack: PropTypes.func,
  organization: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default Drawer
