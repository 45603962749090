import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import OrganizationChannelUsers from './OrganizationChannelUsers'
import Screen from '../../components/Screen'
import { getChannel } from '../../selectors'
import {
  channelsFetchAwait,
  channelsUpdateAwait,
  channelUsersAddAwait,
  channelUsersRemoveAwait
} from '../../actions/channels'
import GateExists from '../../components/GateExists'
import can from '../../utilities/can'
import { useOrganization } from '../../hooks/useOrganization'
import { useOrgUsers } from '../../hooks/useOrgUsers'

const OrganizationChannelUsersContainer = props => {
  const {
    fetchChannels,
    organizationId,
    channel,
    isLoading,
    error,
    changing
  } = props
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )
  const { users, isFetchingUsers, usersError } = useOrgUsers(
    organizationId,
    true,
    channel,
    changing
  )

  useEffect(() => {
    fetchChannels()
  }, [])

  return (
    <Screen
      name="channel users"
      isLoading={isLoading || isFetchingUsers || isFetchingOrg}
      error={error || usersError || orgError}>
      <GateExists exists={channel && users && organization}>
        <OrganizationChannelUsers
          error={orgError}
          channel={channel}
          organization={organization}
          users={users}
          {...props}
        />
      </GateExists>
    </Screen>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.users.isFetching || state.channels.isFetching,
    organizationId: ownProps.match.params.organization,
    channel: getChannel(ownProps.match.params.id)(state),
    isAdmin: can(state.auth.userData, 'CHANNEL_USERS_MANAGE_ALL'),
    changing: state.channels.changing
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  },
  onSubmit: data =>
    dispatch(
      channelsUpdateAwait(
        ownProps.match.params.organization,
        ownProps.match.params.id,
        data
      )
    ),
  onUsersAdd: user =>
    dispatch(channelUsersAddAwait(ownProps.match.params.id, user)),
  onUsersRemove: user =>
    dispatch(channelUsersRemoveAwait(ownProps.match.params.id, user))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationChannelUsersContainer)
