import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import StyledLink from '../../components/Link'

const link = survey => `/tools/surveys/${survey.id}`

const SurveyAdminList = ({ surveys }) => (
  <div>
    <Breadcrumbs links={[{ title: 'Tools', href: '/tools' }]} />
    <Card className="tools">
      <CardHeader className="title" title="Surveys" />
      <CardContent>
        <ul>
          {surveys.map(survey => (
            <li key={survey.id}>
              <StyledLink to={link(survey)}>
                {survey.name}
                {!survey.published ? ' (draft)' : ''}
              </StyledLink>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  </div>
)

SurveyAdminList.propTypes = {
  surveys: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      published: PropTypes.bool.isRequired
    })
  ).isRequired
}

export default SurveyAdminList
