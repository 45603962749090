import React from 'react'
import PropTypes from 'prop-types'
import Tile from './Tile'
import FileListItem from './FileListItem'
import classnames from 'classnames'
import { isVersionDeprecated } from '../helpers'

const FileList = props => {
  return (
    <div>
      {props.files.map((file, i) => (
        <Tile
          key={i}
          className={classnames('tile', {
            deprecated: isVersionDeprecated(
              file.version,
              file.type,
              props.isSuperAdmin
            )
          })}>
          <FileListItem
            key={i}
            {...file}
            edit={props.edit}
            deleteFile={id => props.deleteFile(id)}
            download={id => props.download(id)}
            showLmsChips={props.showLmsChips}
            isSuperAdmin={props.isSuperAdmin}
          />
        </Tile>
      ))}
      <style>{`
      .Tile .fileList {
        list-style-type: none;
        padding: 0.5rem 0;
      }
      .Tile .fileList > div {
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 1rem;
      }
      .deprecated {
        background-color: #DEDEDE;
        opacity: 0.5;
      }
      `}</style>
    </div>
  )
}

FileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape(FileListItem.propTypes)),
  edit: PropTypes.bool,
  deleteFile: PropTypes.func,
  download: PropTypes.func,
  showLmsChips: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default FileList
