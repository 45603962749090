import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'

export default ({ ...props }) =>
  createForm({
    fields: [
      {
        id: 'enrollmentId',
        label: 'Enrollment ID',
        inputComponent: TextInput,
        required: true,
        validation: joi.string()
      }
    ],
    layoutOptions: {
      submitText: 'Continue'
    },
    ...props
  })
