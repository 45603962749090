import React from 'react'
import { connect } from 'react-redux'
import AdminEdit from './AdminEdit'
import Screen from '../../components/Screen'
import { fetchAdmin, updateAdmin, fetchRoles } from '../../actions/admins'
import { setAppBarState } from '../../actions/ui'
import GateExists from '../../components/GateExists'

class AdminEditContainer extends React.Component {
  constructor(props) {
    super(props)
    props.setAppBarState({
      visible: true,
      title: 'Admins'
    })
    this.state = {
      isLoading: false,
      admin: null,
      roles: [],
      loadingError: null
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true, admin: null, loadingError: null })
      const [admin, roles] = await Promise.all([
        this.props.fetchAdmin(),
        this.props.fetchRoles()
      ])
      this.setState({ loading: false, admin, roles })
    } catch (e) {
      this.setState({ loading: false, loadingError: e.message })
    }
  }

  render() {
    const { admin, loading, loadingError } = this.state

    return (
      <Screen name="admin create" error={loadingError} isLoading={loading}>
        <GateExists exists={admin}>
          <AdminEdit {...this.props} admin={admin} roles={this.state.roles} />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchAdmin: () => fetchAdmin(ownProps.match.params.admin),
  onSubmit: data => dispatch(updateAdmin(ownProps.match.params.admin, data)),
  fetchRoles: () => fetchRoles()
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditContainer)
