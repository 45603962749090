import React, { useState } from 'react'
import { connect } from 'react-redux'
import OrganizationChannelsCreate from './OrganizationChannelsCreate'
import Screen from '../../components/Screen'
import { channelsCreateAwait } from '../../actions/channels'
import { channelVerifyQuery } from '../../requests/channels'
import GateExists from '../../components/GateExists'
import { useConfigurableReports } from '../../hooks/useConfigurableReports'
import { useOrganization } from '../../hooks/useOrganization'

const OrganizationChannelsCreateContainer = props => {
  const { isLoading, error, onSubmit, organizationId } = props
  const [submitError, setSubmitError] = useState(null)
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )
  const {
    configReports,
    isFetchingConfigReports,
    configReportsError
  } = useConfigurableReports()

  const handleSubmit = async data => {
    try {
      await onSubmit(data)
    } catch (err) {
      setSubmitError(err)
    }
  }

  return (
    <Screen
      name="organization create channel"
      isLoading={isLoading || isFetchingConfigReports || isFetchingOrg}
      error={error || configReportsError || orgError}
      renderContent={() => (
        <GateExists exists={organization}>
          <OrganizationChannelsCreate
            reports={configReports}
            onSubmit={handleSubmit}
            error={submitError}
            organization={organization}
            {...props}
          />
        </GateExists>
      )}
    />
  )
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.organizations.isFetching,
  organizationId: ownProps.match.params.organization
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data =>
    dispatch(channelsCreateAwait(ownProps.match.params.organization, data)),
  channelVerifyQuery: channelVerifyQuery
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationChannelsCreateContainer)
