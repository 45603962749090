import moment from 'moment-timezone'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import logo from '../assets/edynamic-logo-medium.png'
import CloseIcon from 'mdi-react/CloseIcon'
import ImageIcon from 'mdi-react/ImageIcon'
import LaunchIcon from 'mdi-react/LaunchIcon'
import DownloadIcon from 'mdi-react/DownloadIcon'
import FileDocumentBoxIcon from 'mdi-react/FileDocumentBoxIcon'
import FileLink from './FileLink'
import ComingSoonBanner from './ComingSoonBanner'
import courseCode from '../utilities/courseCode'

class DetailedCourseCard extends Component {
  render() {
    const course = Object.assign(
      { eid: '', img: '', title: '', subtitle: '', url: '' },
      this.props.course,
      {
        img: this.props.course
          ? this.props.edit
            ? this.props.course.img || ''
            : this.props.course.img || logo
          : ''
      }
    )

    const syllabus = Object.assign({}, { url: '', name: '' }, course.syllabus)
    const cursor = this.props.edit ? 'pointer' : 'default'
    const eid = courseCode(course)
    const courseUrl =
      course.url.substring(0, 4) === 'http'
        ? course.url
        : 'http://' + course.url

    return (
      <div className="course">
        <Card className="card">
          {course.comingSoon && <ComingSoonBanner />}
          <Dropzone
            accept="image/jpeg, image/png"
            disabled={!this.props.edit ? true : false}
            multiple={false}
            className="image"
            acceptClassName="acceptDrop"
            onDrop={(accepted, rejected) =>
              this.props.onImgDrop(accepted, rejected)
            }>
            {this.props.edit && course.img && (
              <IconButton
                className="delete"
                onClick={e => this.props.onChange('', 'img', e)}>
                <CloseIcon />
              </IconButton>
            )}
            {!course.img && (
              <div className="drop">
                <ImageIcon size={50} />
                <div className="message">
                  Drag over image or click to upload
                </div>
              </div>
            )}
            {!this.props.edit && (
              <IconButton
                className="download"
                onClick={() => window.open(course.img)}>
                <DownloadIcon />
              </IconButton>
            )}
          </Dropzone>
          {!this.props.edit && (
            <div className="content">
              <h3 className="edl">
                EDL
                {eid}
              </h3>
              <h2 className="title">{course.title}</h2>
              <p className="subtitle">{course.subtitle}</p>
              {course.dateReleasing && (
                <p style={{ color: '#a00' }}>
                  Attention! This course is currently being updated. A new
                  package will be available on{' '}
                  {moment(this.props.course.dateReleasing).format(
                    'DD-MMM-YYYY'
                  )}
                  . Please consider waiting to implement this course to ensure
                  you have the latest version.
                </p>
              )}
              {course.url && (
                <Button
                  className="link"
                  onClick={() => window.open(courseUrl)}
                  fullWidth>
                  <LaunchIcon className="launch" />
                  Course Details
                </Button>
              )}
              {syllabus.name && (
                <Button
                  className="link"
                  onClick={() => window.open(syllabus.url)}
                  fullWidth>
                  <FileDocumentBoxIcon className="launch" />
                  Syllabus
                </Button>
              )}
            </div>
          )}
          {this.props.edit && (
            <div className="content">
              <TextField
                type="number"
                label="EDL Code"
                value={course.eid}
                error={course.eid < 1 && this.props.edited}
                onChange={event =>
                  this.props.onChange(event.target.value, 'eid')
                }
                fullWidth
                margin="normal"
              />
              <TextField
                type="text"
                label="EDL Code Suffix"
                value={course.eidSuffix}
                error={
                  course.eidSuffix &&
                  !course.eidSuffix.match(/^[A-Z]{1,3}$/) &&
                  this.props.edited
                }
                onChange={event =>
                  this.props.onChange(event.target.value, 'eidSuffix')
                }
                fullWidth
                margin="normal"
              />
              <TextField
                type="text"
                label="Title"
                value={course.title}
                error={course.title.length === 0 && this.props.edited}
                onChange={event =>
                  this.props.onChange(event.target.value, 'title')
                }
                fullWidth
                margin="normal"
              />
              <TextField
                type="text"
                label="Subtitle"
                value={course.subtitle}
                onChange={event =>
                  this.props.onChange(event.target.value, 'subtitle')
                }
                fullWidth
                margin="normal"
              />
              <TextField
                type="text"
                label="Frost ID"
                value={course.frostId}
                onChange={event =>
                  this.props.onChange(event.target.value, 'frostId')
                }
                fullWidth
                margin="normal"
              />
              <TextField
                type="text"
                label="Buzz ID"
                value={course.buzzId}
                onChange={event =>
                  this.props.onChange(event.target.value, 'buzzId')
                }
                fullWidth
                margin="normal"
              />
              <TextField
                type="text"
                label="Buzz 2 ID"
                value={course.buzz2Id}
                onChange={event =>
                  this.props.onChange(event.target.value, 'buzz2Id')
                }
                fullWidth
                margin="normal"
              />
              <TextField
                type="date"
                label="Created Date"
                defaultValue={
                  this.props.course.dateCreated
                    ? moment(this.props.course.dateCreated).format('YYYY-MM-DD')
                    : null
                }
                onChange={event =>
                  this.props.onChange(event.target.value || null, 'dateCreated')
                }
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <div>
                <InputLabel htmlFor="level">Level</InputLabel>
                <NativeSelect
                  inputProps={{ id: 'level' }}
                  defaultValue={this.props.course.level}
                  onChange={event =>
                    this.props.onChange(event.target.value || null, 'level')
                  }
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}>
                  <option value=""></option>
                  <option value="MS">Middle School</option>
                  <option value="HS">High School</option>
                </NativeSelect>
              </div>

              <TextField
                type="text"
                label="Cluster"
                value={course.cluster}
                onChange={event =>
                  this.props.onChange(event.target.value, 'cluster')
                }
                fullWidth
                margin="normal"
              />
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    label="CRP Course"
                    checked={this.props.course.crp_course}
                    onChange={event =>
                      this.props.onChange(event.target.checked, 'crp_course')
                    }
                    fullWidth
                    margin="normal"
                  />
                }
                classes={{ root: 'disable-margin' }}
                label="CRP Course"
                labelPlacement="end"
              />
              <div>
                <InputLabel htmlFor="explorer_concentrator">
                  Explorer Concentrator
                </InputLabel>
                <NativeSelect
                  inputProps={{ id: 'explorer_concentrator' }}
                  defaultValue={this.props.course.explorer_concentrator}
                  onChange={event =>
                    this.props.onChange(
                      event.target.value || null,
                      'explorer_concentrator'
                    )
                  }
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}>
                  <option value=""></option>
                  <option value="Explorer">Explorer</option>
                  <option value="Concentrator">Concentrator</option>
                </NativeSelect>
              </div>
              <TextField
                type="text"
                label="Pathways"
                value={course.pathways}
                onChange={event =>
                  this.props.onChange(event.target.value, 'pathways')
                }
                fullWidth
                margin="normal"
              />
              <TextField
                type="text"
                label="CTE Elective"
                value={course.cte_elective}
                onChange={event =>
                  this.props.onChange(event.target.value, 'cte_elective')
                }
                fullWidth
                margin="normal"
              />

              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    label="Coming Soon"
                    checked={this.props.course.comingSoon}
                    onChange={event =>
                      this.props.onChange(event.target.checked, 'comingSoon')
                    }
                    fullWidth
                    margin="normal"
                  />
                }
                classes={{ root: 'disable-margin' }}
                label="Coming Soon"
                labelPlacement="end"
              />
              <p className="details">Course Details:</p>
              <TextField
                type="text"
                label="URL"
                value={course.url}
                onChange={event =>
                  this.props.onChange(event.target.value, 'url')
                }
                fullWidth
                margin="normal"
              />
              <p className="details">Syllabus:</p>
              <Dropzone
                disabled={!this.props.edit ? true : false}
                multiple={false}
                className="syllabus"
                onDrop={(accepted, rejected) =>
                  this.props.onSyllabusDrop(accepted, rejected)
                }>
                {this.props.edit &&
                  (this.props.edited || syllabus.url) &&
                  syllabus.name && (
                    <FileLink
                      icon={<FileDocumentBoxIcon />}
                      label={syllabus.name}
                    />
                  )}
                {this.props.edit &&
                  (this.props.edited || syllabus.url) &&
                  syllabus.name && (
                    <IconButton
                      className="delete"
                      onClick={e =>
                        this.props.onChange(null, 'syllabus.name', e)
                      }>
                      <CloseIcon />
                    </IconButton>
                  )}
                {((!this.props.edited && !syllabus.url) || !syllabus.name) && (
                  <div className="syllabusUpload">
                    <FileLink label={'Click to upload syllabus'} />
                  </div>
                )}
              </Dropzone>
            </div>
          )}
        </Card>
        <style>{`
          .course {
            margin: 0;
            height: 100%;
          }
          .card {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
          .image {
            flex-grow: 0;
            flex-basis: 200px;
            background: url(${course.img});
            background-size: cover;
            background-position: center;
          }
          .image:hover {
            cursor: ${cursor};
          }
          .syllabus {
            padding-top: 5px;
            padding-left: 5px;
            border: dashed 1px lightgrey;
          }
          .syllabus .delete {
            position: relative;
            bottom: 36px;
          }
          .syllabus .link-label {
            width: 161px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .syllabus:hover {
            cursor: ${cursor};
          }
          .syllabusUpload {
            padding-bottom: 5px;
            padding-left: 5px;
          }
          .syllabusUpload .fileLink .link-icon {
            margin: 0;
          }
          .acceptDrop {
            border: 1px solid #56ff47;
          }
          .drop {
            height: 100%;
            background-color: #c6c6c6;
            color: #757575;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .drop span {
            font-size: 100px;
          }
          .drop span svg {
            width: 50px;
            height: 50px;
            fill: #757575
          }
          .delete {
            float: right;
          }
          .delete:hover {
            cursor: default;
          }
          .delete svg {
            fill: #e80000;
          }
          .download:hover {
            cursor: cursor;
          }
          .download svg {
            fill: #ffffff;
          }
          .content {
            flex: 0.6;
            padding: 16px;
          }
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .content {
              flex: auto;
            }
          }
          .title, .edl, .subtitle {
            margin: 0;
            margin-bottom: 5px
          }
          .edl, .subtitle {
            color: #757575;
            margin-bottom: 16px;
          }
          .details {
            font-weight: bold;
            margin: 0;
            margin-bottom: 5px;
          }
          .content .link {
            justify-content: flex-start;
          }
          .launch {
            width: 16px;
            height: 16px;
            vertical-align: -2px;
            margin-right: 1em;
          }
          .input {
            padding-top: 16px;
            padding-bottom: 16px;
          }
          .message:hover {
            cursor: ${cursor};
          }
        `}</style>
      </div>
    )
  }
}

DetailedCourseCard.propTypes = {
  course: PropTypes.object,
  edit: PropTypes.bool,
  edited: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onImgDrop: PropTypes.func.isRequired,
  onSyllabusDrop: PropTypes.func.isRequired
}

export default DetailedCourseCard
