import React from 'react'
import PropTypes from 'prop-types'
import SimpleSearch from '../components/SimpleSearch'
import Divider from '@material-ui/core/Divider'
import TaasUserTile from '../components/TaasUserTile'

const TaasUserSearch = ({ filter, documents, ...rest }) => (
  <SimpleSearch
    filter={filter}
    fields={['firstname', 'lastname', 'email', 'username']}
    documents={documents}>
    {results => {
      if (filter === '') results = documents
      return (
        <div>
          {results.map((user, i) => (
            <div key={user.id}>
              <TaasUserTile user={user} {...rest} />
              {i < results.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      )
    }}
  </SimpleSearch>
)

TaasUserSearch.propTypes = {
  filter: PropTypes.string,
  options: PropTypes.object,
  documents: PropTypes.array,
  selection: PropTypes.array,
  onSelectionChange: PropTypes.func
}

TaasUserSearch.defaultProps = {
  filter: '',
  documents: [],
  selection: []
}

export default TaasUserSearch
