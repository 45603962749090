import { useState, useEffect } from 'react'

export const useRequest = (requestFunc, ...requestParams) => {
  const [result, setResult] = useState(null)
  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await requestFunc(...requestParams)
        setResult(result)
      } catch (error) {
        console.error(error)
        setError(error)
      } finally {
        setIsFetching(false)
      }
    })()
  }, [])

  return { result, isFetching, error }
}
