import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import PartnerCourseReportingForm from './PartnerCourseReportingForm'
import PartnerCourseReportingLineForm from './PartnerCourseReportingLineForm'
import LinesTable from './LinesTable'

const PartnerCourseReportingPartner = ({
  partner,
  lob,
  addedLines,
  onAddLine,
  onRemoveLine,
  onSubmit
}) => {
  const title = `Report Usage for ${partner.name} - ${lob.name}`
  return (
    <div>
      <Breadcrumbs
        links={[
          { title: 'Tools', href: '/tools' },
          {
            title: 'Partner Course Usage Reporting',
            href: '/tools/partner-course-usage-reporting'
          },
          {
            title: partner.name,
            href: '/tools/partner-course-usage-reporting/' + partner.id
          }
        ]}
      />
      <Card>
        <CardHeader className="title" title={title} />
        <CardContent className="description">
          <p>Reporting schedule: {lob.reportingSchedule.frequency}</p>
          <h2>Step 3: Report</h2>
          <PartnerCourseReportingForm
            disabled={!addedLines.length}
            onSubmit={onSubmit}
            partner={partner}
            lob={lob}>
            {addedLines.length > 0 && (
              <div>
                <h2>Courses</h2>
                <LinesTable
                  addedLines={addedLines}
                  partner={partner}
                  onRemove={onRemoveLine}
                />
              </div>
            )}
            <div>
              <h2>Add Usage</h2>
              <PartnerCourseReportingLineForm
                key={addedLines.length}
                partner={partner}
                onSubmit={onAddLine}
                line={{}}
              />
            </div>
          </PartnerCourseReportingForm>
        </CardContent>
      </Card>
    </div>
  )
}

PartnerCourseReportingPartner.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onAddLine: PropTypes.func.isRequired,
  onRemoveLine: PropTypes.func.isRequired,
  errors: PropTypes.array,
  addedLines: PropTypes.array.isRequired,
  partner: PropTypes.object.isRequired,
  lob: PropTypes.object.isRequired
}

export default PartnerCourseReportingPartner
