import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SearchQueryInput from '../../components/form/elements/SearchQueryInput'
import ToggleInput from '../../components/form/elements/ToggleInput'

export default ({ survey = {}, onSubmit, ...props }) => {
  return createForm({
    fields: [
      {
        id: 'name',
        label: 'Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: survey.name
      },
      {
        id: 'typeformUrl',
        label: 'Typeform Embed URL',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: survey.typeformUrl
      },
      {
        id: 'userQuery',
        label: 'Available to Users',
        inputComponent: SearchQueryInput,
        required: true,
        validation: joi.object(),
        defaultValue: survey.userQuery || {}
      },
      {
        id: 'published',
        label: 'Published',
        inputComponent: ToggleInput,
        required: true,
        validation: joi.bool(),
        defaultValue: !!survey.published
      }
    ],
    layoutOptions: {
      submitText: survey.id ? 'Update Survey' : 'Create Survey'
    },
    onSubmit: async data => {
      return onSubmit(data)
    },
    ...props
  })
}
