import { fetchOrganizationById } from '../requests/organizations'
import { useRequest } from './useRequest'

export const useOrganization = id => {
  const { result, isFetching, error } = useRequest(fetchOrganizationById, id)

  return {
    organization: result,
    isFetchingOrg: isFetching,
    orgError: error
  }
}
