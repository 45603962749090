import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import createBrowserHistory from 'history/createBrowserHistory'
import {
  connectRouter,
  routerMiddleware,
  ConnectedRouter
} from 'connected-react-router'
import { Provider } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import thunk from 'redux-thunk'
import ReactGA from 'react-ga'

import withTracker from './withTracker'

import rootReducer from './reducers/root'

import axios from 'axios'
import AppLayout from './containers/ConnectAppLayout'
import Courses from './containers/ConnectCourses'
import Course from './containers/ConnectCourse'
import Notifications from './containers/ConnectNotifications'
import Notification from './containers/ConnectNotification'
import Organizations from './containers/organizations/OrganizationsContainer'
import OrganizationUsers from './containers/organization-users/list/OrganizationUsersContainer'
import OrganizationUsersCreate from './containers/organization-users/create/OrganizationUsersCreateContainer'
import OrganizationUsersEdit from './containers/organization-users/edit/OrganizationUsersEditContainer'
import OrganizationChannels from './containers/organization-channels/OrganizationChannelsContainer'
import OrganizationChannelsCreate from './containers/organization-channels-create/OrganizationChannelsCreateContainer'
import OrganizationChannelsEdit from './containers/organization-channels-edit/OrganizationChannelsEditContainer'
import ChannelView from './containers/channel-view/ChannelViewContainer'
import OrganizationChannelsCopy from './containers/organization-channels-copy/OrganizationChannelsCopyContainer'
import OrganizationChannelUsers from './containers/organization-channel-users/OrganizationChannelUsersContainer'
import OrganizationsCreate from './containers/organizations-create/OrganizationsCreateContainer'
import OrganizationsEdit from './containers/organizations-edit/OrganizationsEditContainer'
import OrganizationsView from './containers/organization-view/OrganizationView'
import Login from './containers/ConnectLogin'
import Logout from './pages/Logout'
import Test from './pages/Test'
import SearchPreview from './containers/ConnectSearchPreview'
import ProtectedRoute from './containers/ConnectProtectedRoute'
import NotFound from './pages/NotFound'
import PasswordReset from './containers/ConnectPasswordReset'
import DownloadHistory from './containers/download-history/DownloadHistoryContainer'
import Tags from './containers/ConnectTags'
import TaasStudentList from './containers/taas-student-list/TaasStudentListContainer'
import TaasStudentCreate from './containers/taas-student-create/TaasStudentCreateContainer'
import TaasStudentEdit from './containers/taas-student-edit/TaasStudentEditContainer'
import TaasStudentView from './containers/taas-student-view/TaasStudentViewContainer'
import TaasStudentEnrolmentList from './containers/taas-student-enrolment-list/TaasStudentEnrolmentListContainer'
import TaasStudentEnrolmentCreate from './containers/taas-student-enrolment-create/TaasStudentEnrolmentCreateContainer'
import TaasStudentEnrolmentEdit from './containers/taas-student-enrolment-edit/TaasStudentEnrolmentEditContainer'
import TaasObserverList from './containers/taas-observer-list/TaasObserverListContainer'
import TaasObserverCreate from './containers/taas-observer-create/TaasObserverCreateContainer'
import TaasObserverEdit from './containers/taas-observer-edit/TaasObserverEditContainer'
import TaasFacilitatorList from './containers/taas-facilitator-list/TaasFacilitatorListContainer'
import TaasFacilitatorCreate from './containers/taas-facilitator-create/TaasFacilitatorCreateContainer'
import TaasFacilitatorEdit from './containers/taas-facilitator-edit/TaasFacilitatorEditContainer'
import TaasStudentFacilitatorList from './containers/taas-student-facilitator-list/TaasStudentFacilitatorListContainer'
import TaasStudentObserverList from './containers/taas-student-observer-list/TaasStudentObserverListContainer'
import TaasTeacherList from './containers/taas-teacher-list/TaasTeacherListContainer'
import TaasTeacherView from './containers/taas-teacher-view/TaasTeacherViewContainer'
import TaasTeacherEnrolmentList from './containers/taas-teacher-enrolment-list/TaasTeacherEnrolmentListContainer'
import TaasTeacherEnrolmentEdit from './containers/taas-teacher-enrolment-edit/TaasTeacherEnrolmentEditContainer'
import TaasTeacherEnrolmentCreate from './containers/taas-teacher-enrolment-create/TaasTeacherEnrolmentCreateContainer'
import TaasCourseList from './containers/taas/course-list/TaasCourseListContainer'
import TaasCourseView from './containers/taas/course-view/TaasCourseViewContainer'
import TaasCourseStudentEnrolmentList from './containers/taas/course-student-enrolment-list/TaasCourseStudentEnrolmentListContainer'
import TaasCourseStudentEnrolmentCreate from './containers/taas/course-student-enrolment-create/TaasCourseStudentEnrolmentCreateContainer'
import TaasCourseStudentEnrolmentEdit from './containers/taas/course-student-enrolment-edit/TaasCourseStudentEnrolmentEditContainer'
import TaasCourseTeacherEnrolmentList from './containers/taas/course-teacher-enrolment-list/TaasCourseTeacherEnrolmentListContainer'
import TaasCourseTeacherEnrolmentCreate from './containers/taas/course-teacher-enrolment-create/TaasCourseTeacherEnrolmentCreateContainer'
import TaasCourseTeacherEnrolmentEdit from './containers/taas/course-teacher-enrolment-edit/TaasCourseTeacherEnrolmentEditContainer'
import AdminList from './containers/admin-list/AdminListContainer'
import AdminCreate from './containers/admin-create/AdminCreateContainer'
import AdminEdit from './containers/admin-edit/AdminEditContainer'
import Tools from './containers/tools/ToolsContainer'
import BulkCopyCourses from './containers/tools-bulk-copy-courses/ToolsBulkCopyCoursesContainer'
import RemoveFinalGrade from './containers/tools-genius-remove-final-grade/RemoveFinalGradeContainer'
import PartnerCourseReporting from './containers/partner-course-reporting/PartnerCourseReportingContainer'
import PartnerCourseReportingLob from './containers/partner-course-reporting-lob/PartnerCourseReportingLobContainer'
import PartnerCourseReportingPartner from './containers/partner-course-reporting-partner/PartnerCourseReportingPartnerContainer'
import PartnerCourseReportingImport from './containers/partner-course-reporting-import/PartnerCourseReportingImportContainer'
import SurveyUserList from './containers/survey-user-list/SurveyUserListContainer'
import SurveyUserView from './containers/survey-user-view/SurveyUserViewContainer'
import SurveyAdminList from './containers/survey-admin-list/SurveyAdminListContainer'
import SurveyAdminCreate from './containers/survey-admin-create/SurveyAdminCreateContainer'
import SurveyAdminEdit from './containers/survey-admin-edit/SurveyAdminEditContainer'
import MergeCourse from './containers/tools-merge-course/MergeCourseContainer'
import TaasImportUsers from './containers/taas-import-users/TaasImportUsersContainer'
import TaasImportEnrolments from './containers/taas-import-enrolments/TaasImportEnrolmentsContainer'
import TaasReset from './containers/taas-reset/TaasResetContainer'
import ChannelDomainCreate from './containers/channel-domain-create/ChannelDomainCreateContainer'
import ChannelBuzzAdminCreate from './containers/channel-buzz-admin-create/ChannelBuzzAdminCreateContainer'
import ChannelPlatformConfigure from './containers/channel-platform-configure/ChannelPlatformConfigureContainer'
import ChannelReports from './containers/channel-reports/ChannelReportsContainer'
import ChannelReportView from './containers/channel-report-view/ChannelReportViewContainer'
import AccountSettings from './containers/account-settings/AccountSettingsContainer'
import { uncaughtErrorHandler } from './errorHandler'

import './css/index.css'
import { MuiThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import MfaSettings from './containers/mfa-settings/MfaSettingsContainer'
import VerifyMultiFactorAuthentication from './containers/ConnectVerifyMultiFactorAuthentication'

axios.interceptors.request.use(config => {
  if (config.url.includes(process.env.REACT_APP_REMOTE)) {
    config.headers['X-Client-Url'] = window.location.href
  }
  return config
})

window.addEventListener('error', uncaughtErrorHandler)

const browserHistory = createBrowserHistory()

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

//  If user data fetching fails with 401, user is logged out
const handle401 = () => next => action => {
  if (
    action.type === 'FETCH_USER_DATA_FAILURE' &&
    action.error &&
    action.error.response &&
    action.error.response.status === 401
  ) {
    browserHistory.push('/logout')
    return
  }

  return next(action)
}

const store = createStore(
  connectRouter(browserHistory)(rootReducer),
  composeEnhancers(
    applyMiddleware(routerMiddleware(browserHistory), thunk, handle401)
  )
)

// eslint-disable-next-line import/no-named-as-default-member
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <ConnectedRouter history={browserHistory}>
        <AppLayout>
          <Switch>
            <Redirect exact path="/" to="/notifications" />
            <Redirect exact path="/folders" to="/courses" />
            <ProtectedRoute
              exact
              path="/courses"
              component={withTracker(Courses)}
            />
            <ProtectedRoute
              path="/folders/:id"
              component={withTracker(Courses)}
            />
            <ProtectedRoute
              path="/course/:id"
              component={withTracker(Course)}
            />
            <ProtectedRoute
              exact
              path="/notifications"
              component={withTracker(Notifications)}
            />
            <ProtectedRoute exact path="/tags" component={withTracker(Tags)} />
            <ProtectedRoute
              path="/notification/:id"
              component={withTracker(Notification)}
            />
            <ProtectedRoute
              exact
              path="/organizations"
              component={withTracker(Organizations)}
            />
            <ProtectedRoute
              exact
              path="/organizations/create"
              component={withTracker(OrganizationsCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:id"
              component={withTracker(OrganizationsView)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:id/edit"
              component={withTracker(OrganizationsEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/users"
              component={withTracker(OrganizationUsers)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/users/create"
              component={withTracker(OrganizationUsersCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/users/:id/edit"
              component={withTracker(OrganizationUsersEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels"
              component={withTracker(OrganizationChannels)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/create"
              component={withTracker(OrganizationChannelsCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:id/users"
              component={withTracker(OrganizationChannelUsers)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/students"
              component={withTracker(TaasStudentList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/students/create"
              component={withTracker(TaasStudentCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/students/:student"
              component={withTracker(TaasStudentView)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/students/:student/edit"
              component={withTracker(TaasStudentEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/students/:student/enrolments"
              component={withTracker(TaasStudentEnrolmentList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/students/:student/enrolments/create"
              component={withTracker(TaasStudentEnrolmentCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/students/:student/enrolments/:id"
              component={withTracker(TaasStudentEnrolmentEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/observers"
              component={withTracker(TaasObserverList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/observers/create"
              component={withTracker(TaasObserverCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/observers/:observer/edit"
              component={withTracker(TaasObserverEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/facilitators"
              component={withTracker(TaasFacilitatorList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/facilitators/create"
              component={withTracker(TaasFacilitatorCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/facilitators/:facilitator/edit"
              component={withTracker(TaasFacilitatorEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/students/:student/facilitators"
              component={withTracker(TaasStudentFacilitatorList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/students/:student/observers"
              component={withTracker(TaasStudentObserverList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/create-domain"
              component={withTracker(ChannelDomainCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/create-buzz-admin"
              component={withTracker(ChannelBuzzAdminCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/teachers"
              component={withTracker(TaasTeacherList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/teachers/:teacher"
              component={withTracker(TaasTeacherView)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/teachers/:teacher/enrolments"
              component={withTracker(TaasTeacherEnrolmentList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/teachers/:teacher/enrolments/create"
              component={withTracker(TaasTeacherEnrolmentCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/teachers/:teacher/enrolments/:id"
              component={withTracker(TaasTeacherEnrolmentEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/courses"
              component={withTracker(TaasCourseList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/courses/:course"
              component={withTracker(TaasCourseView)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/courses/:course/student-enrolments"
              component={withTracker(TaasCourseStudentEnrolmentList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/courses/:course/student-enrolments/create"
              component={withTracker(TaasCourseStudentEnrolmentCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/courses/:course/student-enrolments/:id"
              component={withTracker(TaasCourseStudentEnrolmentEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/courses/:course/teacher-enrolments"
              component={withTracker(TaasCourseTeacherEnrolmentList)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/courses/:course/teacher-enrolments/create"
              component={withTracker(TaasCourseTeacherEnrolmentCreate)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/courses/:course/teacher-enrolments/:id"
              component={withTracker(TaasCourseTeacherEnrolmentEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/platform-configuration"
              component={withTracker(ChannelPlatformConfigure)}
            />
            <Route
              exact
              path="/taas/reset-password"
              component={withTracker(TaasReset)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/import-users"
              component={withTracker(TaasImportUsers)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/taas/import-enrolments"
              component={withTracker(TaasImportEnrolments)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:id/buzz-copy"
              component={withTracker(OrganizationChannelsCopy)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:id"
              component={withTracker(ChannelView)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:id/edit"
              component={withTracker(OrganizationChannelsEdit)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/reports"
              component={withTracker(ChannelReports)}
            />
            <ProtectedRoute
              exact
              path="/organizations/:organization/channels/:channel/reports/:id"
              component={withTracker(ChannelReportView)}
            />
            <ProtectedRoute
              exact
              path="/download-history"
              component={withTracker(DownloadHistory)}
            />
            <ProtectedRoute exact path="/test" component={Test} />
            <ProtectedRoute
              exact
              path="/admins"
              component={withTracker(AdminList)}
            />
            <ProtectedRoute
              exact
              path="/admins/create"
              component={withTracker(AdminCreate)}
            />
            <ProtectedRoute
              exact
              path="/admins/:admin/edit"
              component={withTracker(AdminEdit)}
            />
            <ProtectedRoute
              exact
              path="/tools"
              component={withTracker(Tools)}
            />
            <ProtectedRoute
              exact
              path="/tools/merge-course"
              component={withTracker(MergeCourse)}
            />
            <ProtectedRoute
              exact
              path="/tools/bulk-copy-courses"
              component={withTracker(BulkCopyCourses)}
            />
            <ProtectedRoute
              exact
              path="/tools/genius-remove-final-grade"
              component={withTracker(RemoveFinalGrade)}
            />
            <ProtectedRoute
              exact
              path="/tools/partner-course-usage-reporting"
              component={withTracker(PartnerCourseReporting)}
            />
            <ProtectedRoute
              exact
              path="/tools/partner-course-usage-reporting/import"
              component={withTracker(PartnerCourseReportingImport)}
            />
            <ProtectedRoute
              exact
              path="/tools/partner-course-usage-reporting/:partner"
              component={withTracker(PartnerCourseReportingLob)}
            />
            <ProtectedRoute
              exact
              path="/tools/partner-course-usage-reporting/:partner/:lob"
              component={withTracker(PartnerCourseReportingPartner)}
            />
            <ProtectedRoute
              exact
              path="/surveys"
              component={withTracker(SurveyUserList)}
            />
            <ProtectedRoute
              exact
              path="/surveys/:id"
              component={withTracker(SurveyUserView)}
            />
            <ProtectedRoute
              exact
              path="/tools/surveys"
              component={withTracker(SurveyAdminList)}
            />
            <ProtectedRoute
              exact
              path="/tools/surveys/create"
              component={withTracker(SurveyAdminCreate)}
            />
            <ProtectedRoute
              exact
              path="/tools/surveys/:id"
              component={withTracker(SurveyAdminEdit)}
            />
            <ProtectedRoute
              exact
              path="/account"
              component={withTracker(AccountSettings)}
            />
            <ProtectedRoute
              exact
              path="/multi-factor-authentication"
              component={withTracker(MfaSettings)}
            />
            <Route exact path="/login" component={withTracker(Login)} />
            <Route
              exact
              path="/verify-mfa"
              component={withTracker(VerifyMultiFactorAuthentication)}
            />
            <Route exact path="/logout" component={withTracker(Logout)} />
            <Route
              path="/password-reset"
              component={withTracker(PasswordReset)}
            />
            <Route exact path="/test" component={Test} />
            <Route
              exact
              path="/search-preview/:type"
              component={SearchPreview}
            />
            <ProtectedRoute path="*" component={withTracker(NotFound)} />
          </Switch>
        </AppLayout>
      </ConnectedRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
)
