import axios from 'axios'
import { push, replace } from 'connected-react-router'
import { getToken } from '../auth'

export function setBuzzInstance(channel, setInstance) {
  return function(dispatch) {
    const instance = setInstance || getInstance(channel)
    localStorage.setItem(`buzz_instance_${channel.id}`, instance)

    dispatch({
      type: 'TAAS_SET_INSTANCE',
      channel: channel.id,
      instance
    })

    if (setInstance) {
      window.location.reload()
    }
  }
}

const getInstance = channel => {
  const stored = localStorage.getItem(`buzz_instance_${channel.id}`)
  if (stored) {
    return stored
  }

  if (channel && channel.buzzIds) {
    return 'buzz'
  }

  if (channel && channel.buzz2Ids) {
    return 'buzz2'
  }
}

const getHeaders = channelId => ({
  Authorization: `Bearer ${getToken()}`,
  'X-BuzzInstance': localStorage.getItem(`buzz_instance_${channelId}`)
})

export async function fetchCourses(channelId) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/courses`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchCourse(channelId, courseId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE + `/taas/${channelId}/courses/${courseId}`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchCourseTeacherEnrolments(channelId, courseId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/courses/${courseId}/teacher-enrolments`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchCourseEnrolments(channelId, courseId, type) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/courses/${courseId}/${type}-enrolments`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchCourseEnrolment(
  channelId,
  courseId,
  enrolmentId,
  type
) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/courses/${courseId}/${type}-enrolments/${enrolmentId}`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export function unenrolCourse() {}

export function updateCourseEnrolment(
  organizationId,
  channelId,
  courseId,
  enrolmentId,
  data,
  type
) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      data,
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channelId}/courses/${courseId}/${type}-enrolments/${enrolmentId}`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })

    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/courses/${courseId}/${type}-enrolments`
      )
    )
  }
}

export async function fetchStudents(channelId) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/students`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchStudent(channelId, studentId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE + `/taas/${channelId}/students/${studentId}`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchStudentEnrolments(channelId, studentId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/students/${studentId}/enrolments`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchStudentEnrolment(channelId, studentId, courseId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/students/${studentId}/enrolments/${courseId}`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export function createStudentEnrolment(
  organizationId,
  channelId,
  studentId,
  data
) {
  return async function(dispatch) {
    await axios({
      method: 'POST',
      data,
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channelId}/students/${studentId}/enrolments`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })

    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/students/${studentId}/enrolments`
      )
    )
  }
}
export function updateStudentEnrolment(
  organizationId,
  channelId,
  studentId,
  enrolmentId,
  data
) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      data,
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channelId}/students/${studentId}/enrolments/${enrolmentId}`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })

    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/students/${studentId}/enrolments`
      )
    )
  }
}

export function unenrolStudent(channel, student, enrolment) {
  return async function(dispatch) {
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to withdraw this enrollment?')) {
      return
    }

    await axios({
      method: 'DELETE',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channel.id}/students/${student.id}/enrolments/${enrolment.id}`,
      withCredentials: true,
      headers: getHeaders(channel.id)
    })

    dispatch(push(`/reloading`))
    dispatch(
      replace(
        `/organizations/${channel.organization}/channels/${channel.id}/taas/students/${student.id}/enrolments`
      )
    )
  }
}

export function createCourseEnrolment(
  organizationId,
  channelId,
  courseId,
  type,
  data
) {
  return async function(dispatch) {
    await axios({
      method: 'POST',
      data,
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channelId}/courses/${courseId}/${type}-enrolments`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })

    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/courses/${courseId}/${type}-enrolments`
      )
    )
  }
}

export function createStudent(organizationId, channelId, data) {
  return async function(dispatch) {
    await axios({
      method: 'POST',
      data,
      url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/students`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })
    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/students`
      )
    )
  }
}

export function updateStudent(organizationId, channelId, studentId, data) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      data,
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channelId}/students/${studentId}`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })
    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/students`
      )
    )
  }
}

export function deleteStudent(student) {
  return async function(dispatch) {
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to de-activate this student?')) {
      return
    }

    await axios({
      method: 'DELETE',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${student.channel}/students/${student.id}`,
      withCredentials: true,
      headers: getHeaders(student.channel)
    })
    dispatch(push(`/reloading`))
    dispatch(
      replace(
        `/organizations/${student.organization}/channels/${student.channel}/taas/students`
      )
    )
  }
}

export async function fetchObservers(channelId) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/observers`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchObserver(channelId, observerId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/observers/${observerId}`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export function createObserver(organizationId, channelId, data) {
  return async function(dispatch) {
    await axios({
      method: 'POST',
      data,
      url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/observers`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })
    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/observers`
      )
    )
  }
}

export function updateObserver(organizationId, channelId, observerId, data) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      data,
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channelId}/observers/${observerId}`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })
    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/observers`
      )
    )
  }
}

export function deleteObserver(observer) {
  return async function(dispatch) {
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to de-activate this observer?')) {
      return
    }

    await axios({
      method: 'DELETE',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${observer.channel}/observers/${observer.id}`,
      withCredentials: true,
      headers: getHeaders(observer.channel)
    })
    dispatch(push('/reloading'))
    dispatch(
      replace(
        `/organizations/${observer.organization}/channels/${observer.channel}/taas/observers`
      )
    )
  }
}

export async function fetchTeachers(channelId) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/teachers`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchTeacher(channelId, teacherId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE + `/taas/${channelId}/teachers/${teacherId}`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchTeacherEnrolments(channelId, teacherId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/teachers/${teacherId}/enrolments`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchTeacherEnrolment(channelId, teacherId, courseId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/teachers/${teacherId}/enrolments/${courseId}`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export function createTeacherEnrolment(
  organizationId,
  channelId,
  teacherId,
  data
) {
  return async function(dispatch) {
    await axios({
      method: 'POST',
      data,
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channelId}/teachers/${teacherId}/enrolments`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })

    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/teachers/${teacherId}/enrolments`
      )
    )
  }
}

export function updateTeacherEnrolment(
  organizationId,
  channelId,
  teacherId,
  enrolmentId,
  data
) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      data,
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channelId}/teachers/${teacherId}/enrolments/${enrolmentId}`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })

    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/teachers/${teacherId}/enrolments`
      )
    )
  }
}

export function unenrolTeacher(channel, teacher, enrolment) {
  return async function(dispatch) {
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to withdraw this enrollment?')) {
      return
    }

    await axios({
      method: 'DELETE',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channel.id}/teachers/${teacher.id}/enrolments/${enrolment.id}`,
      withCredentials: true,
      headers: getHeaders(channel.id)
    })

    dispatch(push(`/reloading`))
    dispatch(
      replace(
        `/organizations/${channel.organization}/channels/${channel.id}/taas/teachers/${teacher.id}/enrolments`
      )
    )
  }
}

export function unenrolCourseUser(channel, course, enrolment, type) {
  return async function(dispatch) {
    if (
      // eslint-disable-next-line
      !confirm(`Are you sure you want to withdraw this ${type} enrollment?`)
    ) {
      return
    }

    await axios({
      method: 'DELETE',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channel.id}/courses/${course.id}/${type}-enrolments/${enrolment.id}`,
      withCredentials: true,
      headers: getHeaders(channel.id)
    })

    dispatch(push(`/reloading`))
    dispatch(
      replace(
        `/organizations/${channel.organization}/channels/${channel.id}/taas/courses/${course.id}/${type}-enrolments`
      )
    )
  }
}

export async function fetchFacilitators(channelId) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/facilitators`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export async function fetchFacilitator(channelId, facilitatorId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/facilitators/${facilitatorId}`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export function createFacilitator(organizationId, channelId, data) {
  return async function(dispatch) {
    await axios({
      method: 'POST',
      data,
      url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/facilitators`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })
    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/facilitators`
      )
    )
  }
}

export function updateFacilitator(
  organizationId,
  channelId,
  facilitatorId,
  data
) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      data,
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${channelId}/facilitators/${facilitatorId}`,
      withCredentials: true,
      headers: getHeaders(channelId)
    })
    dispatch(
      push(
        `/organizations/${organizationId}/channels/${channelId}/taas/facilitators`
      )
    )
  }
}

export function deleteFacilitator(facilitator) {
  return async function(dispatch) {
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to de-activate this facilitator?')) {
      return
    }

    await axios({
      method: 'DELETE',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${facilitator.channel}/facilitators/${facilitator.id}`,
      withCredentials: true,
      headers: getHeaders(facilitator.channel)
    })
    dispatch(push(`/reloading`))
    dispatch(
      replace(
        `/organizations/${facilitator.organization}/channels/${facilitator.channel}/taas/facilitators`
      )
    )
  }
}

export async function fetchStudentFacilitators(channelId, studentId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/students/${studentId}/facilitators`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export function addStudentFacilitator(student, facilitator) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${student.channel}/students/${student.id}/facilitators/${facilitator.id}`,
      withCredentials: true,
      headers: getHeaders(student.channel)
    })
    dispatch(push(`/reloading`))
    dispatch(
      replace(
        `/organizations/${student.organization}/channels/${student.channel}/taas/students/${student.id}/facilitators`
      )
    )
  }
}
export function removeStudentFacilitator(student, facilitator) {
  return async function(dispatch) {
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to de-authorize this facilitator?')) {
      return
    }

    await axios({
      method: 'DELETE',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${facilitator.channel}/students/${student.id}/facilitators/${facilitator.id}`,
      withCredentials: true,
      headers: getHeaders(student.channel)
    })
    dispatch(push(`/reloading`))
    dispatch(
      replace(
        `/organizations/${facilitator.organization}/channels/${facilitator.channel}/taas/students/${student.id}/facilitators`
      )
    )
  }
}

export async function fetchStudentObservers(channelId, studentId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/taas/${channelId}/students/${studentId}/observers`,
    withCredentials: true,
    headers: getHeaders(channelId)
  })
  return result.data
}

export function addStudentObserver(student, observer) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${student.channel}/students/${student.id}/observers/${observer.id}`,
      withCredentials: true,
      headers: getHeaders(student.channel)
    })
    dispatch(push(`/reloading`))
    dispatch(
      replace(
        `/organizations/${student.organization}/channels/${student.channel}/taas/students/${student.id}/observers`
      )
    )
  }
}
export function removeStudentObserver(student, observer) {
  return async function(dispatch) {
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to de-authorize this observer?')) {
      return
    }

    await axios({
      method: 'DELETE',
      url:
        process.env.REACT_APP_REMOTE +
        `/taas/${observer.channel}/students/${student.id}/observers/${observer.id}`,
      withCredentials: true,
      headers: getHeaders(student.channel)
    })
    dispatch(push(`/reloading`))
    dispatch(
      replace(
        `/organizations/${observer.organization}/channels/${observer.channel}/taas/students/${student.id}/observers`
      )
    )
  }
}

export async function importUsers(users, channelId) {
  const {
    data: { skipped }
  } = await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/import-users`,
    withCredentials: true,
    data: { users },
    headers: getHeaders(channelId)
  })
  if (skipped.length) {
    // eslint-disable-next-line
    alert(
      `Done! ${skipped.length} out of ${users.length} users already existed.`
    )
  } else {
    alert('Done!')
  }
}

export async function importEnrolments(enrolments, channelId) {
  await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + `/taas/${channelId}/import-enrolments`,
    withCredentials: true,
    data: { enrolments },
    headers: getHeaders(channelId)
  })
  alert('Done!')
}

export async function resetPassword(data) {
  await axios({
    method: 'POST',
    data,
    url: process.env.REACT_APP_REMOTE + '/auth/taas/reset-password',
    withCredentials: true
  })
}
