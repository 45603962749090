import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import ViewDashboardIcon from 'mdi-react/ViewDashboardIcon'
import CardRow from '../../components/CardRow'
import Breadcrumbs from '../../components/Breadcrumbs'

const ChannelReports = ({ organization, channel, reports, onReportView }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        {
          title: organization.name,
          href: `/organizations/${organization.id}`
        },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        }
      ]}
    />
    {reports.length ? (
      <Card className="reports">
        <CardHeader className="title" title="Reports" />
        {reports.map(report => (
          <CardRow key={report.id}>
            <div>
              <strong>{report.title}</strong>
              <p>{report.description}</p>
            </div>
            <div>
              <IconButton onClick={() => onReportView(report, channel)}>
                <ViewDashboardIcon />
              </IconButton>
            </div>
          </CardRow>
        ))}
      </Card>
    ) : (
      <Card>
        <CardRow>
          <p>No reports configured for this channel.</p>
        </CardRow>
      </Card>
    )}
  </div>
)

ChannelReports.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  channel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  reports: PropTypes.arrayOf(PropTypes.object).isRequired,
  onReportView: PropTypes.func.isRequired
}

export default ChannelReports
