import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'

export default ({
  channels,
  canEditChannels,
  user = { name: {}, channels: [] },
  ...props
}) => {
  return createForm({
    fields: [
      {
        id: 'firstname',
        label: 'First Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: user.name.first
      },
      {
        id: 'lastname',
        label: 'Last Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: user.name.last
      },
      {
        id: 'email',
        label: 'Email Address',
        inputComponent: TextInput,
        required: true,
        validation: joi.string().email({ tlds: { allow: false } }),
        defaultValue: user.email
      },
      canEditChannels
        ? {
            id: 'channels',
            label: 'Authorized Channels',
            inputComponent: SelectInput,
            inputOptions: {
              isMulti: true,
              options: channels.map(channel => ({
                value: channel.id,
                label: channel.name
              }))
            },
            required: false,
            validation: joi.array().items(joi.string()),
            defaultValue: user.channels
          }
        : null
    ].filter(row => row),
    layoutOptions: {
      submitText: 'Save User'
    },
    ...props
  })
}
