import React from 'react'
import { connect } from 'react-redux'
import SurveyAdminEdit from './SurveyAdminEdit'
import Screen from '../../components/Screen'
import { surveysEdit, surveysDelete, surveysFetch } from '../../actions/surveys'
import { setAppBarState } from '../../actions/ui'

class SurveyAdminEditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props.setAppBarState({
      visible: true,
      title: 'Surveys',
      rightIcon: null,
      handleRight: null
    })
    this.state = { error: null, survey: null, isLoading: true }
  }

  async componentDidMount() {
    try {
      this.setState({
        survey: await this.props.fetchSurvey(),
        isLoading: false
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        error: e
      })
    }
  }

  handleSubmit = async data => {
    await this.props.onSubmit(this.state.survey, data)
  }

  render() {
    return (
      <Screen
        name="survey edit"
        error={this.state.error}
        exists={!!this.state.survey}
        isLoading={this.state.isLoading}
        renderContent={() => (
          <SurveyAdminEdit
            {...this.props}
            survey={this.state.survey}
            onSubmit={this.handleSubmit}
          />
        )}
      />
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchSurvey: () => surveysFetch(ownProps.match.params.id),
  onSubmit: (survey, data) => dispatch(surveysEdit(survey, data)),
  onDelete: survey => dispatch(surveysDelete(survey))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyAdminEditContainer)
