import styled from 'styled-components'

export default styled.div`
  display: flex;
  width: ${props => props.width || null};
  flex-wrap: ${props => props.wrap || 'nowrap'}
  margin-right: 1rem !important;

  & button {
    margin-right: 1rem;
  }

  & button:hover {
    cursor: ${props => (props.deprecated ? 'default' : 'pointer')};
    opacity: ${props => (props.deprecated ? '0.5' : '1')};
  }

  & span {
    color: ${props => (props.deprecated ? '#000000' : '#757575')};
  }
`
