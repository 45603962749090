import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Checkbox from '@material-ui/core/Checkbox'
import logo from '../assets/edynamic-logo-medium.png'
import ComingSoonBanner from './ComingSoonBanner'
import courseCode from '../utilities/courseCode'
import { CourseTypes } from '../selectors/index'

const StyledCourse = styled.div`
  width: 215px;
  margin: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;

  .image {
    height: 160px;
  }

  h3,
  > p {
    margin: 0;
  }
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const StyledCardContent = styled(CardContent)`
  padding: 10px 10px 50px 10px !important;
  height: 80px;
`

const Title = styled.h3`
  font-weight: normal;
`

const Subtitle = styled.div`
  font-weight: bold;
  color: #757575;
`

const UnpublishedIndicator = styled.div`
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.4);
  right: 0;
  z-index: 1;
  background: #f7e927 !important;
  height: 18px !important;
  width: 18px !important;
  border-radius: 100%;
  margin: 5px;
`

const ComingSoonIndicator = styled.div`
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.4);
  left: 0;
  z-index: 1;
  background: #aa0000 !important;
  height: 18px !important;
  width: 18px !important;
  margin: 5px;
`

const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  top: -40px;
  left: -5px;
  input[type='checkbox']:checked {
    color: #c66c17 !important;
  }
`
const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: '#c66c17'
    }
  },
  checked: {}
})

const ColoredCheckbox = withStyles(checkBoxStyles)(StyledCheckbox)

const GenericCourseCard = ({ course }) => {
  const eid = courseCode(course)
  return (
    <Card className="card">
      {course.comingSoon && <ComingSoonBanner />}
      {!course.published && (
        <UnpublishedIndicator title="This course is not yet published." />
      )}
      {course.dateReleasing && (
        <ComingSoonIndicator title="This course is currently being updated. A new package will be available soon." />
      )}
      <CardMedia image={course.img || logo} className="image" />
      <StyledCardContent>
        <div>
          <Subtitle>
            EDL
            {eid}
          </Subtitle>
          <Title>{course.title}</Title>
        </div>
      </StyledCardContent>
    </Card>
  )
}

GenericCourseCard.propTypes = {
  course: PropTypes.object
}

const SimulationCard = ({ course }) => {
  return (
    <Card className="card">
      {!course.published && (
        <UnpublishedIndicator title="This simulation is not yet published." />
      )}
      <CardMedia image={course.img || logo} className="image" />
      <StyledCardContent>
        <div>
          <Title>{course.title}</Title>
        </div>
      </StyledCardContent>
    </Card>
  )
}

SimulationCard.propTypes = {
  course: PropTypes.object
}

const componentRegistry = {
  [CourseTypes.COURSE]: GenericCourseCard,
  [CourseTypes.SIMULATION]: SimulationCard
}

class CourseCard extends Component {
  handleSelection = ({ target: { checked } }) => {
    const { onSelectionChange } = this.props
    onSelectionChange(checked)
  }

  render() {
    const { course, isSelected } = this.props
    if (!course) return null

    if (course.courseType === 'MISSION') return null

    const CourseCard = componentRegistry[course.courseType]

    return (
      <StyledCourse>
        <StyledLink to={`/course/${course.id}/`}>
          <CourseCard course={course} />
        </StyledLink>
        <ColoredCheckbox onChange={this.handleSelection} checked={isSelected} />
      </StyledCourse>
    )
  }
}

CourseCard.propTypes = {
  course: PropTypes.object,
  isSelected: PropTypes.bool,
  onSelectionChange: PropTypes.func
}

CourseCard.defaultProps = {
  isSelected: false,
  onSelectionChange: () => {}
}

export default CourseCard
