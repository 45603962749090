import React from 'react'
import PropTypes from 'prop-types'
import ScreenLoading from './ScreenLoading'
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon'
import ErrorMessage from './ErrorMessage'
import ErrorBoundary from './ErrorBoundary'
import { handleScreenError } from '../errorHandler'

class Screen extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    })
  }

  render() {
    const {
      name,
      helpText,
      isLoading,
      children,
      error: propError,
      renderContent,
      exists,
      notFoundMessage,
      debug
    } = this.props
    const { error: stateError, errorInfo } = this.state

    const error = stateError || propError

    if (error) {
      handleScreenError(error)
      const errorMessage = `Could not load ${name}...`
      return (
        <div className="error-screen">
          <h2>{errorMessage}</h2>
          <p>{helpText}</p>
          {errorInfo && debug && <pre>{errorInfo.componentStack}</pre>}
          {error && debug && <pre>{error.stack}</pre>}
        </div>
      )
    }

    if (isLoading) {
      return <ScreenLoading message={`Loading ${name}`} />
    }

    if (Object.keys(this.props).includes('exists') && !exists) {
      return (
        <ErrorMessage
          icon={<AlertCircleOutlineIcon />}
          message={notFoundMessage}
        />
      )
    }

    let out = renderContent ? renderContent() : children

    return (
      <ErrorBoundary
        renderContent={error => (
          <div className="error-screen">
            <h2>Could not load {name}</h2>
            <p>{helpText}</p>
            {errorInfo && debug && <pre>{errorInfo.componentStack}</pre>}
            {error && debug && <pre>{error.stack}</pre>}
          </div>
        )}>
        {out}
      </ErrorBoundary>
    )
  }
}

Screen.defaultProps = {
  helpText: 'This error has been logged. Refresh to try again.',
  notFoundMessage: 'Item not found.',
  error: null,
  debug: process.NODE_ENV !== 'production',
  isLoading: false
}

Screen.propTypes = {
  name: PropTypes.string.isRequired,
  exists: PropTypes.bool,
  notFoundMessage: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  }),
  helpText: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  renderContent: PropTypes.func,
  debug: PropTypes.bool
}

export default Screen
