import ColorHash from 'color-hash'

const hasher = new ColorHash()

const existing = {
  Blackboard: '#00600c',
  'Moodle2.6': '#db7f00',
  Buzz: '#0066cc',
  Canvas: '#db0012',
  BrainHoney: '#00cccc',
  'Moodle1.9': '#ffaa00'
}

/**
 * Deterministically generate a color for a package name
 * Use the existing mappings above first, falling bash to color hash
 *
 * @param {String} name
 * @return {String} hex color code
 */
export default name => {
  if (typeof existing[name] !== 'undefined') {
    return existing[name]
  }

  return hasher.hex(name)
}
