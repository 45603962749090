import React from 'react'
import PropTypes from 'prop-types'
import OrganizationForm from './OrganizationForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const OrganizationsCreate = ({ onSubmit }) => (
  <div>
    <Breadcrumbs links={[{ title: 'Organizations', href: '/organizations' }]} />
    <Card>
      <CardHeader className="title" title="Create New Organization" />
      <CardContent className="description">
        <OrganizationForm onSubmit={onSubmit} />
      </CardContent>
    </Card>
  </div>
)

OrganizationsCreate.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default OrganizationsCreate
