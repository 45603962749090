import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import {
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Card,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import BuzzInstance from './BuzzInstance'
import PencilIcon from 'mdi-react/PencilIcon'
import KeyIcon from 'mdi-react/KeyIcon'
import UserIcon from 'mdi-react/UserIcon'
import UsersIcon from 'mdi-react/UsersIcon'
import FormattedDate from '../../components/FormattedDate'
import SearchQuerySummary from '../../components/SearchQuerySummary'
import can, { canScoped } from '../../utilities/can'
import Integration from './Integration'
import GateExists from '../../components/GateExists'
import Screen from '../../components/Screen'
import { useOrganization } from '../../hooks/useOrganization'
import SimulationList from '../../components/simulations/SimulationList'

const ChannelView = ({
  setAppBarState,
  fetchChannels,
  onChannelSync,
  isLoading,
  error,
  user,
  channel,
  organizationId,
  isSuperAdmin,
  onChannelEdit,
  onChannelUsers,
  onChannelTaas,
  onCreateDomain,
  onConfigurePlatform,
  onCopy,
  onCreateBuzzAdmin,
  onExchange,
  isUpdatingBuzzLti,
  updateBuzzLtiRegistration,
  checkBuzzLtiRegistration,
  buzzLtiRegistrations
}) => {
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )

  const [syncing, setSyncing] = useState({
    buzz: false,
    buzz2: false,
    frost: false,
    delivery: false
  })

  useEffect(() => {
    setAppBarState({
      visible: true,
      title: 'Channels',
      rightIcon: null,
      handleRight: null
    })
    fetchChannels()
  }, [])

  const handleSync = async provider => {
    const lowCaseProvider = provider.toLowerCase()
    try {
      setSyncing({ [lowCaseProvider]: true })
      await onChannelSync(channel, lowCaseProvider)
      alert(`Sync complete`)
    } catch (e) {
      console.error(e)
      alert(`Failed to sync ${provider}`)
    } finally {
      setSyncing({ [lowCaseProvider]: false })
    }
  }
  const canManage = can(user, 'CHANNELS_MANAGE_ALL')
  const canManageBuzzUsers = canScoped(
    user,
    'CHANNELS_MANAGE_BUZZ_USERS_ORG',
    'CHANNELS_MANAGE_BUZZ_USERS_ALL',
    () => organization.id === user.organization
  )

  return (
    <Screen
      name="view channel"
      isLoading={isLoading || isFetchingOrg}
      error={error || orgError}>
      <GateExists
        exists={channel && organization}
        renderContent={() => (
          <div>
            <Breadcrumbs
              links={[
                { title: 'Organizations', href: '/organizations' },
                {
                  title: organization.name,
                  href: `/organizations/${organization.id}`
                },
                {
                  title: 'Channels',
                  href: `/organizations/${organization.id}/channels`
                }
              ]}
            />
            <div style={{ clear: 'both' }} />
            <h4>Channel Information</h4>
            <Card>
              <CardHeader
                className="title"
                title={channel.name}
                subheader={
                  <span>
                    Date Created: <FormattedDate date={channel.dateCreated} />,
                    Last Updated:
                    <FormattedDate date={channel.dateUpdated} />
                  </span>
                }
              />
              <CardContent className="description">
                {channel.nameClient && (
                  <div>Client Alias: {channel.nameClient}</div>
                )}
                {can(user, 'CHANNELS_MANAGE_ALL') && (
                  <div>
                    <div className="query">
                      Query: <SearchQuerySummary query={channel.coursesQuery} />
                    </div>
                    {channel.disabled && (
                      <strong>Course Access Disabled</strong>
                    )}
                  </div>
                )}
                {isSuperAdmin && channel.notes && (
                  <div>Private Notes: {channel.notes}</div>
                )}
              </CardContent>
              <CardActions>
                <div>
                  {canManage && (
                    <Button onClick={() => onChannelEdit(channel)}>
                      <PencilIcon /> Edit
                    </Button>
                  )}
                  <Button onClick={() => onChannelUsers(channel)}>
                    <UserIcon />
                    Users
                  </Button>
                  {channel.taas &&
                    canScoped(
                      user,
                      'CHANNELS_MANAGE_TAAS_ALL',
                      'CHANNELS_MANAGE_TAAS_ORG',
                      () => channel.organization === user.organization.id
                    ) && (
                      <Button onClick={() => onChannelTaas(channel)}>
                        <UsersIcon /> Student Dashboard
                      </Button>
                    )}
                  {(!channel.buzzIds || !channel.buzz2Ids) &&
                    // buzz2 temporarily disabled
                    !(channel.buzzIds && !channel.buzz2Ids) &&
                    can(user, 'CHANNELS_MANAGE_ALL') && (
                      <Button onClick={() => onCreateDomain(channel)}>
                        <UsersIcon /> Create Buzz Domain...
                      </Button>
                    )}
                </div>
              </CardActions>
            </Card>

            <div>
              {(channel.ltiKey ||
                channel.buzzIds ||
                channel.buzz2Ids ||
                canManage) && <h4>Integrations</h4>}

              <Integration
                title="LTI 1.1: Frost"
                configured={!!channel.ltiKey}
                autoSync={channel.frostSync}
                onSync={() => handleSync('frost')}
                syncing={syncing.frost}
                canManage={can(user, 'CHANNELS_MANAGE_ALL')}
                actions={
                  canManage && (
                    <Button onClick={() => onExchange()}>
                      <KeyIcon /> Sync Config
                    </Button>
                  )
                }
                configureBody={
                  <Button onClick={() => onExchange()}>
                    <KeyIcon /> Exchange LTI Key
                  </Button>
                }
                body={
                  channel.ltiKey && (
                    <div>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography variant="caption">URL</Typography>
                          <code>lti.edynamiclearning.com</code>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography variant="caption">Key</Typography>
                          <code>{channel.ltiKey.key}</code>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="caption">Secret</Typography>
                          <code>{channel.ltiKey.secret}</code>
                        </Grid>
                      </Grid>
                    </div>
                  )
                }
              />

              <Integration
                title="LTI 1.3: Delivery"
                configured={!!channel.platform}
                autoSync={channel.deliverySync}
                onSync={() => handleSync('delivery')}
                syncing={syncing.delivery}
                canManage={can(user, 'CHANNELS_MANAGE_ALL')}
                canView={can(user, 'FEATURE_LTI_13')}
                actions={
                  canManage && (
                    <Button onClick={() => onConfigurePlatform()}>
                      <KeyIcon /> Configure Platform...
                    </Button>
                  )
                }
                configureBody={
                  <Button onClick={() => onConfigurePlatform()}>
                    <KeyIcon /> Configure Platform...
                  </Button>
                }
                body={
                  channel.platform && (
                    <div>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography variant="caption">Name</Typography>
                          <code>{channel.platform.name}</code>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4}>
                          <Typography variant="caption">Issuer</Typography>
                          <code>{channel.platform.issuer}</code>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="caption">Client ID</Typography>
                          <code>{channel.platform.client_id}</code>
                        </Grid>
                      </Grid>
                    </div>
                  )
                }
              />
              <BuzzInstance
                buzzInstance="buzz"
                title="LMS: Buzz"
                isUpdatingBuzzLti={isUpdatingBuzzLti}
                channel={channel}
                onSync={handleSync}
                syncing={syncing.buzz}
                canManage={canManage}
                canManageBuzzUsers={canManageBuzzUsers}
                onCopy={onCopy}
                onCreateBuzzAdmin={onCreateBuzzAdmin}
                isRegistered={buzzLtiRegistrations.buzz}
                updateBuzzLtiRegistration={updateBuzzLtiRegistration}
                checkBuzzLtiRegistration={checkBuzzLtiRegistration}
              />
              <BuzzInstance
                buzzInstance="buzz2"
                title="LMS: Buzz 2"
                isUpdatingBuzzLti={isUpdatingBuzzLti}
                channel={channel}
                onSync={handleSync}
                syncing={syncing.buzz2}
                canManage={canManage}
                canManageBuzzUsers={canManageBuzzUsers}
                onCopy={onCopy}
                onCreateBuzzAdmin={onCreateBuzzAdmin}
                isRegistered={buzzLtiRegistrations.buzz2}
                updateBuzzLtiRegistration={updateBuzzLtiRegistration}
                checkBuzzLtiRegistration={checkBuzzLtiRegistration}
              />
            </div>
            {channel.licenses && channel.licenses.length > 0 && (
              <div>
                <h4>Licenses</h4>
                <Card>
                  <CardContent className="description">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Simulation</TableCell>
                          <TableCell>Product Code</TableCell>
                          <TableCell>Max Concurrent Users</TableCell>
                          <TableCell>Salesforce Account ID</TableCell>
                          <TableCell>Online License Key</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {channel.licenses.map(license => {
                          const ACTIVE = 'active'
                          const FUTURE = 'future'
                          const {
                            id,
                            onlineLicenseKey,
                            maxConcurrentUsers,
                            salesforceAccountId,
                            terms
                          } = license
                          const simulation = SimulationList.find(
                            prefix =>
                              prefix.licensePrefix ===
                              onlineLicenseKey.split('-')[0]
                          )
                          const { title, productCode } = simulation

                          const statuses = terms.map(term => {
                            if (moment.utc().isBetween(term.start, term.end)) {
                              return ACTIVE
                            }
                            if (moment.utc(term.start).isAfter(moment.utc())) {
                              return FUTURE
                            }
                          })

                          let status = 'expired'
                          if (statuses.includes(ACTIVE)) {
                            status = ACTIVE
                          } else if (statuses.includes(FUTURE)) {
                            status = FUTURE
                          }

                          return (
                            <TableRow key={id}>
                              <TableCell component="th" scope="row">
                                {title}
                              </TableCell>
                              <TableCell>{productCode}</TableCell>
                              <TableCell>{maxConcurrentUsers}</TableCell>
                              <TableCell>{salesforceAccountId}</TableCell>
                              <TableCell>{onlineLicenseKey}</TableCell>
                              <TableCell>{status}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </div>
            )}
          </div>
        )}
      />
    </Screen>
  )
}

ChannelView.propTypes = {
  setAppBarState: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  channel: PropTypes.object,
  organizationId: PropTypes.string,
  isSuperAdmin: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  onChannelEdit: PropTypes.func.isRequired,
  fetchChannels: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onExchange: PropTypes.func.isRequired,
  onChannelSync: PropTypes.func.isRequired,
  onChannelUsers: PropTypes.func.isRequired,
  onChannelTaas: PropTypes.func.isRequired,
  onCreateDomain: PropTypes.func.isRequired,
  onCreateBuzzAdmin: PropTypes.func.isRequired,
  onConfigurePlatform: PropTypes.func.isRequired,
  isUpdatingBuzzLti: PropTypes.bool.isRequired,
  updateBuzzLtiRegistration: PropTypes.func.isRequired,
  checkBuzzLtiRegistration: PropTypes.func.isRequired,
  buzzLtiRegistrations: PropTypes.object.isRequired
}

export default ChannelView
