import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Input, Tooltip } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import './styles.css'
import Snackbar from '@material-ui/core/Snackbar'

const CopiedNotification = ({ open }) => (
  <Snackbar open={open} message={'Copied!'} />
)

CopiedNotification.propTypes = {
  open: PropTypes.bool.isRequired
}

export const ReadonlyTextFieldWithCopy = ({ value }) => {
  const ref = useRef(null)
  const [showNotification, setShowNotification] = useState(false)
  const [formattedWithSpaces, setFormattedWithSpaces] = useState(null)

  useEffect(() => {
    let timeoutId

    if (showNotification) {
      timeoutId = setTimeout(() => {
        setShowNotification(false)
      }, 2000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [showNotification])

  useEffect(() => {
    if (value) {
      setFormattedWithSpaces((value.match(/.{1,4}/g) ?? []).join('  ').trim())
    }
  }, [value])

  return (
    <Fragment>
      <Input
        inputRef={ref}
        inputProps={{
          style: {
            width: `${formattedWithSpaces ? formattedWithSpaces.length : 0}ch`
          },
          className: 'textField'
        }}
        type="text"
        readOnly={true}
        onClick={async () => {
          if (!navigator.clipboard) {
            console.error('Clipboard API is not supported')
            return
          }

          if (ref.current) {
            await navigator.clipboard.writeText(
              ref.current.value.split('  ').join('')
            )
            setShowNotification(true)
          }
        }}
        endAdornment={
          <InputAdornment position="end" variant={'filled'}>
            <Tooltip title="Copy secret to clipboard" arrow>
              <IconButton disableRipple={true}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        value={formattedWithSpaces}
      />
      <CopiedNotification open={showNotification} />
    </Fragment>
  )
}

ReadonlyTextFieldWithCopy.propTypes = {
  value: PropTypes.string.isRequired
}
