import { connect } from 'react-redux'
import OrganizationChannels from './OrganizationChannels'
import { getChannels } from '../../selectors'
import { isSuperAdmin } from '../../selectors/auth'
import { channelsFetchAwait, channelSyncAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  user: state.auth.userData,
  isLoading: state.organizations.isFetching || state.channels.isFetching,
  organizationId: ownProps.match.params.organization,
  channels: getChannels(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  },
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  onChannelSyncFrost: channel => dispatch(channelSyncAwait(channel, 'frost')),
  onChannelSyncBuzz: channel => dispatch(channelSyncAwait(channel, 'buzz')),
  onChannelSyncBuzz2: channel => dispatch(channelSyncAwait(channel, 'buzz2')),
  onChannelEdit: channel =>
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${channel.id}/edit`
    ),
  onChannelUsers: channel =>
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${channel.id}/users`
    ),
  onChannelTaas: channel => {
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${channel.id}/taas/students`
    )
  },
  onChannelReports: channel => {
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${channel.id}/reports`
    )
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationChannels)
