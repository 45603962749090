import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasStudentEnrolmentForm from './TaasStudentEnrolmentForm'

const CourseIdIndicator = styled.div`
  float: right;
  padding: 15px;
  font-size: 24px;
  color: #ccc;
`

const TaasStudentEnrolmentCreate = ({
  channel,
  organization,
  student,
  courses,
  selectedCourseId,
  onChange,
  onSubmit
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Students',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
        },
        {
          title: `${student.firstname} ${student.lastname}`,
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students/${student.id}`
        },
        {
          title: 'Enrollments',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students/${student.id}/enrolments`
        }
      ]}
    />
    <Card className="students">
      {selectedCourseId && (
        <CourseIdIndicator>ID {selectedCourseId}</CourseIdIndicator>
      )}
      <CardHeader className="title" title="Enroll Student" />
      <CardContent className="description">
        <TaasStudentEnrolmentForm
          courses={courses}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
  </div>
)

TaasStudentEnrolmentCreate.propTypes = {
  student: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  courses: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedCourseId: PropTypes.string
}

export default TaasStudentEnrolmentCreate
