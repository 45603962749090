import React from 'react'
import { connect } from 'react-redux'
import TaasImportUsers from './TaasImportUsers'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { setBuzzInstance, importUsers } from '../../actions/taas'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'

class TaasImportUsersContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props.setAppBarState({
      visible: true,
      title: 'Import Users',
      rightIcon: null,
      handleRight: () => {}
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
  }

  render() {
    return (
      <Screen
        name="user importer"
        isLoading={this.props.isLoading}
        error={this.props.error}>
        <GateExists exists={this.props.channel && this.props.organization}>
          <TaasImportUsers {...this.props} />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const channel = getChannel(ownProps.match.params.channel)(state)
  return {
    isSuperAdmin: isSuperAdmin(state),
    channel,
    organization: getOrganization(ownProps.match.params.organization)(state),
    isLoading: state.organizations.isFetching || state.channels.isFetching,
    instance: channel ? state.taas[channel.id] : null
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  onSubmit: async users => {
    await importUsers(users, ownProps.match.params.channel)
  },
  onInstanceChange: (channel, instance) => {
    dispatch(setBuzzInstance(channel, instance))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasImportUsersContainer)
