import React from 'react'
import SearchQueryInput from './SearchQueryInput'
import { suggestCourses } from '../actions/courses'

const SearchQueryInputCourse = props => (
  <SearchQueryInput
    type="course"
    getSuggestions={suggestCourses}
    renderSuggestion={suggestion => {
      return <div>{suggestion.name}</div>
    }}
    getSuggestionValue={suggestion => suggestion.id}
    {...props}
  />
)

export default SearchQueryInputCourse
