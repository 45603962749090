import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Breadcrumbs from '../../../components/Breadcrumbs'
import { generateBreadcrumbs } from '../links'
import TaasEnrolmentListItem from '../../../components/TaasEnrolmentListItem'

const TaasStudentEnrolmentList = ({
  channel,
  organization,
  course,
  enrolments,
  onEnrolmentEdit,
  onEnrolmentDisable
}) => (
  <div>
    <Breadcrumbs
      links={generateBreadcrumbs(organization, channel, [
        {
          title: 'Courses',
          href: '/courses'
        },
        {
          title: course.title,
          href: `/courses/${course.id}`
        }
      ])}
    />
    <Card className="students">
      <CardHeader className="title" title="Student Enrollments" />
      <ul>
        {enrolments.map(enrolment => (
          <TaasEnrolmentListItem
            key={enrolment.id}
            enrolment={enrolment}
            onEdit={enrolment => onEnrolmentEdit(enrolment)}
            onDelete={enrolment =>
              onEnrolmentDisable(channel, course, enrolment)
            }
          />
        ))}
      </ul>
    </Card>
  </div>
)

TaasStudentEnrolmentList.propTypes = {
  course: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  enrolments: PropTypes.array.isRequired,
  onEnrolmentEdit: PropTypes.func.isRequired,
  onEnrolmentEnable: PropTypes.func.isRequired,
  onEnrolmentDisable: PropTypes.func.isRequired
}

export default TaasStudentEnrolmentList
