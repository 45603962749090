import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import './pagination.css'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'

export const Pagination = ({
  fetch,
  limit,
  totalPages,
  search,
  filter,
  page,
  setPage
}) => {
  return (
    <ReactPaginate
      breakLabel={<>&hellip;</>}
      breakLinkClassName="break-link"
      nextLabel={<ChevronRightIcon />}
      activeLinkClassName="selected"
      containerClassName="pagination"
      pageLinkClassName="pagination-item-link"
      previousLinkClassName="pagination-previous-next-link"
      nextLinkClassName="pagination-previous-next-link"
      onPageChange={({ selected }) => {
        window.scrollTo(0, 0)
        fetch(selected + 1, limit, search, filter)
        if (setPage) setPage(selected)
      }}
      pageRangeDisplayed={2}
      pageCount={totalPages}
      previousLabel={<ChevronLeftIcon />}
      renderOnZeroPageCount={null}
      forcePage={page}
    />
  )
}

Pagination.propTypes = {
  fetch: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  search: PropTypes.string,
  filter: PropTypes.string,
  page: PropTypes.number,
  setPage: PropTypes.func
}
