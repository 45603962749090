import axios from 'axios'
import { getToken } from '../auth'

function previewUsersStart() {
  return { type: 'PREVIEW_USERS_START' }
}

function previewUsersSuccess(users) {
  return { type: 'PREVIEW_USERS_SUCCESS', users }
}

function previewUsersError(error) {
  return { type: 'PREVIEW_USERS_ERROR', error }
}

async function previewUsers(query) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/users/search',
    params: { query },
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export function previewUsersAwait(query) {
  return function(dispatch) {
    dispatch(previewUsersStart)
    return previewUsers(query)
      .then(users => dispatch(previewUsersSuccess(users)))
      .catch(error => dispatch(previewUsersError(error)))
  }
}

function previewCoursesStart() {
  return { type: 'PREVIEW_COURSES_START' }
}

function previewCoursesSuccess(courses) {
  return { type: 'PREVIEW_COURSES_SUCCESS', courses }
}

function previewCoursesError(error) {
  return { type: 'PREVIEW_COURSES_ERROR', error }
}

async function previewCourses(query) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/courses/search',
    params: { query },
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export function previewCoursesAwait(query) {
  console.log({ query })
  return function(dispatch) {
    dispatch(previewCoursesStart)
    return previewCourses(query)
      .then(courses => dispatch(previewCoursesSuccess(courses)))
      .catch(error => dispatch(previewCoursesError(error)))
  }
}
