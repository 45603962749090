import React from 'react'
import Search from './Search'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InputLabel, Select } from '@material-ui/core'

const FilterSelect = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 7em;
  label {
    font-size: 0.75em;
  }
`

const AlignSearchAndFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 1.5em;
  gap: 1em;
  .search {
    margin: 0;
    width: 100%;
  }
`

const SearchWithSimpleFilter = ({
  searchValue,
  handleSearchChange,
  filterValue,
  filterLabel,
  filterOptions,
  handleFilterChange
}) => (
  <AlignSearchAndFilter>
    <Search
      autoFocus
      className="search"
      value={searchValue}
      handleChange={handleSearchChange}
    />
    <FilterSelect>
      <InputLabel htmlFor="organization-type">{filterLabel}</InputLabel>
      <Select
        native
        value={filterValue}
        onChange={handleFilterChange}
        inputProps={{
          name: 'type',
          id: 'organization-type'
        }}>
        <option value="">All</option>
        {Object.entries(filterOptions).map(([property, value], key) => (
          <option key={key} value={value}>
            {property}
          </option>
        ))}
      </Select>
    </FilterSelect>
  </AlignSearchAndFilter>
)

SearchWithSimpleFilter.propTypes = {
  searchValue: PropTypes.string,
  filterValue: PropTypes.string,
  filterLabel: PropTypes.string.isRequired,
  filterOptions: PropTypes.object.isRequired,
  handleSearchChange: PropTypes.func,
  handleFilterChange: PropTypes.func
}

export default SearchWithSimpleFilter
