/**
 * Helper to reduce boiletplate for the various TAAS breadcrumbs
 */
export function generateBreadcrumbs(organization, channel, rest = []) {
  const baseUrl = `/organizations/${organization.id}/channels/${channel.id}/taas`

  return [
    { title: 'Organizations', href: '/organizations' },
    { title: organization.name, href: `/organizations/${organization.id}` },
    {
      title: 'Channels',
      href: `/organizations/${organization.id}/channels`
    },
    {
      title: channel.name,
      href: `/organizations/${organization.id}/channels/${channel.id}`
    },
    {
      title: 'Dashboard',
      href: `${baseUrl}/students`
    },
    ...rest.map(({ title, href }) => ({
      title,
      href: `${baseUrl}${href}`
    }))
  ]
}
