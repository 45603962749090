import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'

const FixedFooterDialog = props => (
  <div>
    <Dialog
      open={props.active}
      fullWidth
      onEscapeKeyDown={props.onEscKeyDown}
      onBackdropClick={props.onOverlayClick}
      className={'fixedFooterDialog ' + props.className}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        {props.actions.map((action, i) => (
          <Button key={i} {...action}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  </div>
)

FixedFooterDialog.propTypes = {
  actions: PropTypes.array,
  active: PropTypes.bool,
  onEscKeyDown: PropTypes.func,
  onOverlayClick: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

export default FixedFooterDialog
