import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { logout } from '../actions/auth'
import { connect } from 'react-redux'
import { clearLoginErrorMessage } from '../actions/ui'

const Logout = ({ logout, clearLoginErrorMessage }) => {
  clearLoginErrorMessage()
  logout()
  return <Redirect to="/login" />
}

Logout.propTypes = {
  logout: PropTypes.func,
  clearLoginErrorMessage: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  clearLoginErrorMessage: () => dispatch(clearLoginErrorMessage())
})

export default connect(null, mapDispatchToProps)(Logout)
