import { connect } from 'react-redux'
import ChannelView from './ChannelView'
import { isSuperAdmin } from '../../selectors/auth'
import { getChannel } from '../../selectors'
import { setAppBarState } from '../../actions/ui'
import {
  channelsFetchAwait,
  channelSyncAwait,
  channelExchangeAwait,
  updateBuzzLtiRegistrationAction,
  checkBuzzLtiRegistrationAction
} from '../../actions/channels'

const mapStateToProps = (state, ownProps) => ({
  user: state.auth.userData,
  isLoading: state.organizations.isFetching || state.channels.isFetching,
  isSuperAdmin: isSuperAdmin(state),
  organizationId: ownProps.match.params.organization,
  channel: getChannel(ownProps.match.params.id)(state),
  isUpdatingBuzzLti: state.channels.isUpdatingBuzzLti,
  buzzLtiRegistrations: state.channels.buzzLtiRegistrations
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  },
  onCopy: instance =>
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${
        ownProps.match.params.id
      }/buzz-copy${instance ? `?instance=${instance}` : ''}`
    ),
  onExchange: () =>
    dispatch(
      channelExchangeAwait(
        ownProps.match.params.organization,
        ownProps.match.params.id
      )
    ),
  onChannelSync: (channel, provider) =>
    dispatch(channelSyncAwait(channel, provider)),
  onChannelEdit: channel =>
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${channel.id}/edit`
    ),
  onChannelUsers: channel =>
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${channel.id}/users`
    ),
  onChannelTaas: channel => {
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${channel.id}/taas/students`
    )
  },
  onCreateDomain: channel => {
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${channel.id}/create-domain`
    )
  },
  onCreateBuzzAdmin: instance =>
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${
        ownProps.match.params.id
      }/create-buzz-admin${instance ? `?instance=${instance}` : ''}`
    ),
  onConfigurePlatform: () =>
    ownProps.history.push(
      `/organizations/${ownProps.match.params.organization}/channels/${ownProps.match.params.id}/platform-configuration`
    ),
  checkBuzzLtiRegistration: buzzInstance =>
    dispatch(
      checkBuzzLtiRegistrationAction(
        ownProps.match.params.organization,
        ownProps.match.params.id,
        buzzInstance
      )
    ),
  updateBuzzLtiRegistration: buzzInstance =>
    dispatch(
      updateBuzzLtiRegistrationAction(
        ownProps.match.params.organization,
        ownProps.match.params.id,
        buzzInstance
      )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(ChannelView)
