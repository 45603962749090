import axios from 'axios'
import { getToken } from '../auth'
import _ from 'lodash'
import { map } from 'bluebird'
import { selectionClear } from './courses'
import { isDeprecated, getGroupingKey } from '../helpers'

const concurrency = 10

export function downloadsFetchAwait() {
  return function(dispatch) {
    dispatch(downloadsFetchStart())
    return fetchDownloads()
      .then(files => {
        dispatch(downloadsFetchFinish(files))
      })
      .catch(error => {
        dispatch(downloadsFetchFailure(error.message))
      })
  }
}

function downloadsFetchStart() {
  return { type: 'DOWNLOADS_FETCH_START' }
}

function downloadsFetchFinish(payload) {
  return { type: 'DOWNLOADS_FETCH_FINISH', payload }
}

function downloadsFetchFailure(error) {
  const payload = error
  return { type: 'DOWNLOADS_FETCH_FALURE', payload }
}

async function fetchDownloads() {
  const results = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/files/downloads/mine',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return results.data
}

export function downloadHistoryFetchAwait() {
  return function(dispatch) {
    dispatch(downloadHistoryFetchStart())
    return fetchDownloadHistory()
      .then(files => {
        dispatch(downloadHistoryFetchFinish(files))
      })
      .catch(error => {
        dispatch(downloadHistoryFetchFailure(error.message))
      })
  }
}

function downloadHistoryFetchStart() {
  return { type: 'DOWNLOAD_HISTORY_FETCH_START' }
}

function downloadHistoryFetchFinish(payload) {
  return { type: 'DOWNLOAD_HISTORY_FETCH_FINISH', payload }
}

function downloadHistoryFetchFailure(error) {
  const payload = error
  return { type: 'DOWNLOAD_HISTORY_FETCH_FAILURE', payload }
}

async function fetchDownloadHistory() {
  const results = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/files/downloads/org',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return results.data
}

function downloadScanStart(ids) {
  return { type: 'BULK_DOWNLOAD_SCAN_START', payload: ids }
}

function downloadScanCourseStart(id) {
  return { type: 'BULK_DOWNLOAD_SCAN_COURSE_START', payload: id }
}

function downloadScanCourseFinish(id) {
  return { type: 'BULK_DOWNLOAD_SCAN_COURSE_FINISH', payload: id }
}

function downloadScanFinish(files) {
  return { type: 'BULK_DOWNLOAD_SCAN_FINISH', payload: files }
}

function downloadFilesStart() {
  return { type: 'BULK_DOWNLOAD_DOWNLOAD_START' }
}

function downloadFilesFinish() {
  return { type: 'BULK_DOWNLOAD_DOWNLOAD_FINISH' }
}

function downloadFileStart(id) {
  return { type: 'BULK_DOWNLOAD_DOWNLOAD_FILE_START', payload: id }
}

function downloadFileFinish(id) {
  return { type: 'BULK_DOWNLOAD_DOWNLOAD_FILE_FINISH', payload: id }
}

function downloadsReset() {
  return { type: 'BULK_DOWNLOAD_RESET' }
}

function getCoursesComingSoon(ids, courses) {
  return ids
    .map(id => {
      const course = courses.find(row => row.id === id)
      if (!course) return false
      if (!course.dateReleasing) return false
      return course.title
    })
    .filter(title => title)
}

export function downloadSelected(selected) {
  return async (dispatch, getState) => {
    /* eslint-disable no-restricted-globals */
    const comingSoon = getCoursesComingSoon(selected, getState().courses.items)
    if (comingSoon.length) {
      if (
        !confirm(
          `The following courses are currently being updated.  To receive updated content a new package installation will be required,  please consider waiting to implement this course until  the new package is available. \n\n${comingSoon.join(
            ', '
          )}`
        )
      ) {
        return
      }
    }
    /* eslint-enable no-restricted-globals */

    dispatch(downloadScanStart(selected))

    const files = []
    await map(
      selected,
      async courseId => {
        dispatch(downloadScanCourseStart(courseId))
        const newFiles = await fetchFilesFromCourse(courseId)
        newFiles.forEach(file => files.push(file))
        dispatch(downloadScanCourseFinish(courseId))
      },
      { concurrency }
    )

    dispatch(downloadScanFinish(files.map(row => row.id)))
    dispatch(downloadFilesStart())

    await map(
      files,
      async file => {
        dispatch(downloadFileStart(file.id))
        const url = await getDownloadUrl(file.id)
        downloadFile(url)
        dispatch(downloadFileFinish(file.id))
      },
      { concurrency: 1 }
    )

    dispatch(downloadFilesFinish())

    setTimeout(() => {
      dispatch(downloadsReset())
      dispatch(selectionClear())
    }, 1500)
  }
}

async function fetchFilesFromCourse(courseId) {
  const files = await fetchFiles(courseId)

  const filtered = _.chain(files)
    .filter(['type', 'package'])
    .filter(f => !isDeprecated(f))
    .orderBy('version', 'desc')
    .groupBy(getGroupingKey)
    .mapValues(item => item[0])
    .values()
    .value()

  return filtered
}

async function fetchFiles(courseId) {
  const results = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/files/byCourse/${courseId}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return results.data
}

async function getDownloadUrl(id) {
  const sig = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/files/${id}/signedS3GetUrl`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return sig.data.url
}

function downloadFile(url) {
  window.open(url, '_blank')
}
