import { v4 as uuid } from 'uuid'
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import FormLabel from '@material-ui/core/FormLabel'

class LtiKeyInput extends React.PureComponent {
  handleAdd = () => {
    this.props.onChange(
      this.props.value.concat([{ id: uuid(), key: '', secret: '' }])
    )
  }

  handleRemove = id => {
    this.props.onChange(this.props.value.filter(row => row.id !== id))
  }

  handleChange = (id, k, v) => {
    this.props.onChange(
      this.props.value.map(row => {
        if (row.id === id) {
          return {
            ...row,
            [k]: v
          }
        }
        return row
      })
    )
  }

  render() {
    return (
      <div>
        <FormLabel>LTI Keys</FormLabel>
        <Button
          disabled={this.props.max <= this.props.value.length}
          onClick={this.handleAdd}>
          Add Key
        </Button>
        <List>
          {this.props.value.map(({ id, key, secret }) => (
            <ListItem key={id}>
              <TextField
                label="Key"
                value={key}
                onChange={e => this.handleChange(id, 'key', e.target.value)}
                required={false}
                fullWidth
                multiline
                margin="normal"
              />
              <TextField
                label="Secret"
                value={secret}
                onChange={e => this.handleChange(id, 'secret', e.target.value)}
                required={false}
                fullWidth
                multiline
                margin="normal"
              />
              <Button onClick={() => this.handleRemove(id)}>Remove</Button>
            </ListItem>
          ))}
        </List>
      </div>
    )
  }
}

LtiKeyInput.defaultProps = {
  value: [],
  max: 100
}

LtiKeyInput.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      secret: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number
}

export default LtiKeyInput
