import React from 'react'
import PropTypes from 'prop-types'
import OrganizationForm from '../organizations-create/OrganizationForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'

const OrganizationsEdit = ({ organization, onSubmit, onDelete }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` }
      ]}
    />
    <Card>
      <CardHeader
        className="title"
        title={`Edit Organization: ${organization.name}`}
      />
      <CardContent className="description">
        <OrganizationForm organization={organization} onSubmit={onSubmit} />
      </CardContent>
    </Card>
    <Divider />
    <div style={{ margin: '20px 0' }} />
    <Card>
      <CardActions className="actions" style={{ margin: '10px' }}>
        <Button onClick={onDelete} variant="contained" color="secondary">
          Delete Organization
        </Button>
        <Button onClick={() => {}} variant="contained">
          Exchange Keys
        </Button>
      </CardActions>
    </Card>
  </div>
)

OrganizationsEdit.propTypes = {
  organization: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default OrganizationsEdit
