import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import logo from '../assets/edynamic-logo-medium.png'
import { Link } from 'react-router-dom'

class CourseTile extends Component {
  constructor(props) {
    super(props)
    this.formatTitle = this.formatTitle.bind(this)
  }

  formatTitle(title) {
    return (
      title
        .toLowerCase()
        .split(' ')
        .join('-') + '-course'
    )
  }

  render() {
    const { course } = this.props
    if (!course) return null

    return (
      <div>
        <Link
          to={`/course/${course.id}/`}
          style={{ color: 'inherit', textDecoration: 'none' }}
          onClick={e => {
            e.stopPropagation()
          }}>
          <div className="course">
            <Card className="course__card">
              <CardMedia className="course__image" image={course.img || logo} />
              <div className="course__title">
                <h5>{course.title}</h5>
                <h6>{course.subtitle}</h6>
              </div>
            </Card>
          </div>
          <style>{`
            .course {
              margin-right: 10px;
              padding-bottom: 10px;
            }
            .course__card {
              display: flex;
              flex-direction: row;
              margin: 0;
              width: 250px;
              height: 50px;
            }
            .course .course__card .course__image {
              width: 65px;
              height: 50px;
              background-size: 65px 50px;
            }
            .course .course__card .course__title {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              margin: 5px;
              width: 175px;
              height: 40px;
            }
            .course .course__card .course__title h5 {
              margin: 0;
            }
            .course .course__card .course__title h6 {
              margin: 0;
              color: #757575;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          `}</style>
        </Link>
      </div>
    )
  }
}

CourseTile.propTypes = {
  course: PropTypes.object
}

export default CourseTile
