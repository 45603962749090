import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import DownloadIcon from 'mdi-react/DownloadIcon'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../components/Breadcrumbs'
import Link from '../../components/Link'
import Button from '@material-ui/core/Button'
import PartnerCourseMappingsForm from './PartnerCourseMappingsForm'

const buildLink = (partner, lineOfBusiness) =>
  `/tools/partner-course-usage-reporting/${partner.id}/${lineOfBusiness.id}`

const PartnerCourseReportingLob = ({
  partner,
  onDownloadMappings,
  onUploadMappings
}) => {
  return (
    <div>
      <Breadcrumbs
        links={[
          { title: 'Tools', href: '/tools' },
          {
            title: 'Partner Course Usage Reporting',
            href: '/tools/partner-course-usage-reporting'
          }
        ]}
      />
      <Card>
        <CardHeader
          className="title"
          title={`${partner.name} Partner Course Usage Reporting`}
        />
        <CardContent className="description">
          <h3>Step 2: Select a LOB</h3>
          <ul>
            {partner.linesOfBusiness.map(lineOfBusiness => (
              <li key={lineOfBusiness.id}>
                <Link to={buildLink(partner, lineOfBusiness)}>
                  {lineOfBusiness.name}
                </Link>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>
      <Card style={{ marginTop: '20px' }}>
        <CardHeader className="title" title="Course Mappings" />
        <CardContent className="description">
          <p>
            To change course mappings, upload a new mappings file. When you
            upload mappings, it will replace all existing ones. You should
            download the current one first, modify it in Excel, and then
            re-upload it.
          </p>
          <Button onClick={() => onDownloadMappings()}>
            <DownloadIcon />
            Download Existing Mappings
          </Button>

          <PartnerCourseMappingsForm onSubmit={onUploadMappings} />
        </CardContent>
      </Card>
    </div>
  )
}

PartnerCourseReportingLob.propTypes = {
  partner: PropTypes.object.isRequired,
  onDownloadMappings: PropTypes.func.isRequired,
  onUploadMappings: PropTypes.func.isRequired
}

export default PartnerCourseReportingLob
