import React from 'react'
import { connect } from 'react-redux'
import TaasTeacherEnrolmentList from './TaasTeacherEnrolmentList'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import {
  fetchTeacher,
  fetchTeacherEnrolments,
  unenrolTeacher
} from '../../actions/taas'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'
import PlusIcon from 'mdi-react/PlusIcon'
import { taasTeacherEnrolment } from '../../links'

class TaasTeacherEnrolmentListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, enrolments: [], error: null }
    this.props.setAppBarState({
      visible: true,
      title: 'Teacher Enrollments',
      rightIcon: <PlusIcon />,
      canAdd: true,
      handleRight: () => {
        const { organization, channel, teacher } = props.match.params
        this.props.history.push(
          `/organizations/${organization}/channels/${channel}/taas/teachers/${teacher}/enrolments/create`
        )
      }
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, teacher: null, enrolments: [] })
      const [teacher, enrolments] = await Promise.all([
        this.props.fetchTeacher(),
        this.props.fetchTeacherEnrolments()
      ])
      this.setState({ loading: false, teacher, enrolments })
    } catch (e) {
      console.error(e.message)
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, teacher, enrolments } = this.state

    return (
      <Screen
        name="teacher enrollments"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel &&
            this.props.organization &&
            this.state.teacher &&
            this.state.enrolments
          }>
          <TaasTeacherEnrolmentList
            {...this.props}
            teacher={teacher}
            enrolments={enrolments}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchTeacher: () =>
    fetchTeacher(ownProps.match.params.channel, ownProps.match.params.teacher),
  fetchTeacherEnrolments: () =>
    fetchTeacherEnrolments(
      ownProps.match.params.channel,
      ownProps.match.params.teacher
    ),
  onEnrolmentEdit: enrolment =>
    ownProps.history.push(taasTeacherEnrolment(enrolment)),
  onEnrolmentEnable: () => {},
  onEnrolmentDisable: (channel, teacher, enrolment) =>
    dispatch(unenrolTeacher(channel, teacher, enrolment))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasTeacherEnrolmentListContainer)
