import React from 'react'
import { connect } from 'react-redux'
import SurveyUserView from './SurveyUserView'
import Screen from '../../components/Screen'
import { surveyFetchUser, surveysComplete } from '../../actions/surveys'
import { setAppBarState } from '../../actions/ui'

class SurveyUserViewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props.setAppBarState({
      visible: true,
      title: 'Surveys',
      rightIcon: null,
      handleRight: null
    })
    this.state = { error: null }
  }

  async componentDidMount() {
    try {
      this.setState({
        survey: await this.props.fetchSurvey(),
        isLoading: false
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        error: e
      })
    }
  }

  handleSubmit = async data => {
    const { onSubmit } = this.props
    try {
      await onSubmit(this.state.survey, data)
    } catch (error) {
      this.setState({ error })
    }
  }

  render() {
    const { error: propError } = this.props
    const { error: stateError, survey } = this.state

    return (
      <Screen
        name="survey edit"
        error={propError}
        exists={!!survey}
        renderContent={() => (
          <SurveyUserView
            {...this.props}
            survey={survey}
            onSubmit={this.handleSubmit}
            error={stateError}
          />
        )}
      />
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.userData
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchSurvey: () => surveyFetchUser(ownProps.match.params.id),
  onComplete: survey => dispatch(surveysComplete(survey))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyUserViewContainer)
