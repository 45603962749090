import { connect } from 'react-redux'
import SearchPreview from '../pages/SearchPreview'
import {
  previewUsersAwait,
  previewCoursesAwait
} from '../actions/searchPreview'

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.searchPreview.users,
    courses: state.searchPreview.courses,
    organizations: state.organizations.items,
    isLoading: state.searchPreview.isLoading,
    error: state.searchPreview.error,
    type: ownProps.match.params.type
  }
}

const mapDispatchToProps = dispatch => {
  return {
    previewUsers: query => dispatch(previewUsersAwait(query)),
    previewCourses: query => dispatch(previewCoursesAwait(query))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPreview)
