import { connect } from 'react-redux'
import PasswordReset from '../pages/PasswordReset'
import { awaitResetPassword } from '../actions/auth'
import { setAppBarState } from '../actions/ui'

const mapStateToProps = state => {
  return {
    resetErrorMessage: state.auth.resetPasswordErrorMessage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendPasswordReset: data => {
      return dispatch(awaitResetPassword(data))
    },
    setAppBarState: appBarState => {
      dispatch(setAppBarState(appBarState))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
