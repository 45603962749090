import axios from 'axios'
import { getToken } from '../auth'

export function fetchNotificationsAwait(page, limit) {
  return function(dispatch) {
    if (!getToken()) return dispatch(fetchNotificationsFailure())

    dispatch(fetchNotificationsRequest())
    return fetchNotifications(page || 1, limit || 25).then(
      result => {
        dispatch(fetchNotificationsSuccess(result))
      },
      err => dispatch(fetchNotificationsFailure(err))
    )
  }
}

async function fetchNotifications(page, limit) {
  const result = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_REMOTE}/v2/notifications?page=${page}&limit=${limit}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return result.data
}

function fetchNotificationsRequest() {
  const payload = 'Loading notifications'
  return { type: 'FETCH_NOTIFICATIONS', payload }
}

function fetchNotificationsSuccess(items) {
  const payload = {
    items: items.data,
    totalPages: items.metadata.pagination.totalPages
  }
  return {
    type: 'FETCH_NOTIFICATIONS_SUCCESS',
    payload
  }
}

function fetchNotificationsFailure() {
  const payload = 'There was an error loading notifications'
  return { type: 'FETCH_NOTIFICATIONS_FAILURE', payload }
}

export function postNotificationAwait(notification) {
  return function(dispatch) {
    dispatch(postNotificationRequest())
    return postNotification(notification).then(
      () => dispatch(postNotificationSuccess()),
      err => dispatch(postNotificationFailure(err))
    )
  }
}

async function postNotification(notification) {
  const response = await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/notifications',
    data: notification,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return response
}

function postNotificationRequest() {
  const payload = 'Posting notification'
  return { type: 'POST_NOTIFICATION', payload }
}

function postNotificationSuccess() {
  const payload = 'Posted new notification'
  return { type: 'POST_NOTIFICATION_SUCCESS', payload }
}

function postNotificationFailure() {
  const payload = 'There was an error creating the notification'
  return { type: 'POST_NOTIFICATION_FAILURE', payload }
}

export function dismissPostError() {
  const payload = null
  return { type: 'POST_NOTIFICATION_DISMISS', payload }
}

export function editNotificationAwait(notification) {
  return function(dispatch) {
    dispatch(editNotificationRequest())
    return putNotification(notification).then(
      result => {
        dispatch(editNotificationSuccess())
        return result
      },
      err => dispatch(editNotificationFailure(err))
    )
  }
}

async function putNotification(notification) {
  const response = await axios({
    method: 'PUT',
    url: process.env.REACT_APP_REMOTE + '/notifications/' + notification.id,
    data: notification,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return response
}

function editNotificationRequest() {
  const payload = 'Editing notification'
  return { type: 'EDIT_NOTIFICATION', payload }
}

function editNotificationSuccess() {
  const payload = 'Edited notification successfully'
  return { type: 'EDIT_NOTIFICATION_SUCCESS', payload }
}

function editNotificationFailure() {
  const payload = 'There was an error editing the notification'
  return { type: 'EDIT_NOTIFICATION_FAILURE', payload }
}

export function dismissEditError() {
  const payload = null
  return { type: 'EDIT_NOTIFICATION_DISMISS', payload }
}

export function deleteNotificationAwait(notificationId) {
  return function(dispatch) {
    dispatch(deleteNotificationRequest())
    return deleteNotification(notificationId).then(
      result => {
        dispatch(deleteNotificationSuccess())
        return result
      },
      err => dispatch(deleteNotificationFailure(err))
    )
  }
}

async function deleteNotification(notificationId) {
  const response = await axios({
    method: 'DELETE',
    url: process.env.REACT_APP_REMOTE + '/notifications/' + notificationId,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return response
}

function deleteNotificationRequest() {
  const payload = 'Deleting notification'
  return { type: 'DELETE_NOTIFICATION', payload }
}

function deleteNotificationSuccess() {
  const payload = 'Deleted notification successfully'
  return { type: 'DELETE_NOTIFICATION_SUCCESS', payload }
}

function deleteNotificationFailure() {
  const payload = 'There was an error deleting the notification'
  return { type: 'DELETE_NOTIFICATION_FAILURE', payload }
}

export function dismissDeleteError() {
  const payload = null
  return { type: 'DELETE_NOTIFICATION_DISMISS', payload }
}

export const sendNotificationEmailAwait = notificationId => dispatch => {
  dispatch(sendNotificationEmailRequest())
  return sendNotificationEmail(notificationId).then(
    result => {
      dispatch(sendNotificationEmailSuccess(result.data))
      return result
    },
    err => dispatch(sendNotificationEmailFailure(err))
  )
}

function sendNotificationEmail(notificationId) {
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_REMOTE}/notifications/${notificationId}/send`,
    withCredentials: true,
    headers: { Authorization: `Bearer ${getToken()}` }
  })
}

function sendNotificationEmailRequest() {
  const payload = 'Sending notification'
  return { type: 'SEND_NOTIFICATION', payload }
}

function sendNotificationEmailSuccess(notification) {
  const payload = {
    message: 'Sent notification email successfully',
    notification
  }
  return { type: 'SEND_NOTIFICATION_SUCCESS', payload }
}

function sendNotificationEmailFailure() {
  const payload = 'There was an error sending the notification email'
  return { type: 'SEND_NOTIFICATION_FAILURE', payload }
}

export function dismissSendError() {
  const payload = null
  return { type: 'SEND_NOTIFICATION_DISMISS', payload }
}
