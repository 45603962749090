const initial = {
  isFetching: false,
  isAddingCourse: false,
  isAddingFile: false,
  isEditing: false,
  error: {
    fetch: null,
    addCourse: null,
    addFile: null,
    edit: null
  },
  items: [],
  selection: [],
  releaseDates: {
    active: false,
    saving: false,
    courses: null,
    error: null
  }
}

function courses(state = initial, action) {
  switch (action.type) {
    case 'FETCH_COURSES': {
      const isFetching = true
      const stateChange = { isFetching }
      return Object.assign({}, state, stateChange)
    }
    case 'FETCH_COURSES_SUCCESS': {
      const isFetching = false
      const error = Object.assign({}, state.error, { fetch: null })
      const items = action.payload
      const stateChange = { isFetching, error, items }
      return Object.assign({}, state, stateChange)
    }
    case 'FETCH_COURSES_FAILURE': {
      const isFetching = false
      const error = Object.assign({}, state.error, { fetch: action.payload })
      const items = []
      const stateChange = { isFetching, error, items }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_COURSE': {
      return { ...state, isAddingCourse: true }
    }
    case 'ADD_COURSE_SUCCESS': {
      return {
        ...state,
        isAddingCourse: false,
        error: { ...state.error, addCourse: null }
      }
    }
    case 'ADD_COURSE_FAILURE': {
      return {
        ...state,
        isAddingCourse: false,
        error: { ...state.error, addCourse: action.payload }
      }
    }
    case 'ADD_COURSE_ERROR_DISMISS': {
      const isAdding = false
      const error = Object.assign({}, state.error, { addCourse: null })
      return { ...state, isAdding, error }
    }
    case 'EDIT_COURSE': {
      return { ...state, isEditing: true }
    }
    case 'EDIT_COURSE_SUCCESS': {
      return {
        ...state,
        isEditing: false,
        error: { ...state.error, edit: null }
      }
    }
    case 'EDIT_COURSE_FAILURE': {
      return {
        ...state,
        isEditing: false,
        error: { ...state.error, edit: action.payload }
      }
    }
    case 'EDIT_COURSE_ERROR_DISMISS': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: null })
      return { ...state, isEditing, error }
    }
    case 'ADD_FILE': {
      const isAddingFile = true
      const stateChange = { isAddingFile }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_FILE_SUCCESS': {
      const isAddingFile = false
      const error = Object.assign({}, state.error, { addFile: null })
      const stateChange = { isAddingFile, error }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_FILE_FAILURE': {
      const isAddingFile = false
      const error = Object.assign({}, state.error, { addFile: action.payload })
      const stateChange = { isAddingFile, error }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_FILE_ERROR_DISMISS': {
      const isAddingFile = false
      const error = Object.assign({}, state.error, { addFile: null })
      return { ...state, isAddingFile, error }
    }
    case 'COURSE_SELECTIONS_ADD': {
      return {
        ...state,
        selection: state.selection.concat(action.payload)
      }
    }
    case 'COURSE_SELECTIONS_REMOVE': {
      return {
        ...state,
        selection: state.selection.filter(id => !action.payload.includes(id))
      }
    }
    case 'COURSE_SELECTIONS_CLEAR': {
      return {
        ...state,
        selection: []
      }
    }

    case 'COURSES_RELEASE_DATE_PROMPT': {
      return {
        ...state,
        releaseDates: {
          ...state.releaseDates,
          error: null,
          active: true,
          saving: false,
          courses: action.payload
        }
      }
    }

    case 'COURSES_RELEASE_DATE_START': {
      return {
        ...state,
        releaseDates: {
          ...state.releaseDates,
          saving: true
        }
      }
    }

    case 'COURSES_RELEASE_DATE_CANCEL': {
      return {
        ...state,
        releaseDates: {
          ...state.releaseDates,
          active: false,
          saving: false,
          courses: null
        }
      }
    }

    case 'COURSES_RELEASE_DATE_FINISH': {
      return {
        ...state,
        selection: [],
        releaseDates: {
          ...state.releaseDates,
          active: false,
          saving: false,
          courses: null
        },
        items: state.items.map(row => {
          if (state.releaseDates.courses.includes(row.id)) {
            return {
              ...row,
              dateReleasing: action.payload
            }
          }
          return row
        })
      }
    }

    case 'COURSES_RELEASE_DATE_FAILURE': {
      return {
        ...state,
        releaseDates: {
          ...state.releaseDates,
          error: action.payload,
          saving: false
        }
      }
    }

    default: {
      return state
    }
  }
}

export default courses
