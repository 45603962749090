import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import AttachmentIcon from 'mdi-react/AttachmentIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'

const NotificationFileItem = ({ file, onDownload, onDelete, isSuperAdmin }) => (
  <ListItem>
    <ListItemIcon>
      <AttachmentIcon />
    </ListItemIcon>
    <ListItemText>
      <Button onClick={onDownload}>{file.name}</Button>
    </ListItemText>
    {isSuperAdmin && (
      <ListItemSecondaryAction>
        <IconButton onClick={onDelete} edge="end" aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    )}
  </ListItem>
)

NotificationFileItem.defaultProps = {
  isSuperAdmin: false
}

NotificationFileItem.propTypes = {
  isSuperAdmin: PropTypes.bool,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  file: PropTypes.shape({
    filename: PropTypes.string,
    name: PropTypes.string
  })
}

export default NotificationFileItem
