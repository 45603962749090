import React from 'react'
import PlusIcon from 'mdi-react/PlusIcon'

const initial = {
  appBarState: {
    visible: true,
    title: '',
    rightIcon: <PlusIcon />,
    handleRight: function() {
      return
    }
  },
  isNavDrawerPinned: true,
  isCreateNewNotificationDialogOpen: false,
  isForgotPasswordCardShown: false
}

function ui(state = initial, action) {
  switch (action.type) {
    case 'SET_NAVDRAWER_PINNED_STATE': {
      return { ...state, isNavDrawerPinned: action.payload }
    }
    case 'SET_FORGOT_PASSWORD_CARD_SHOWN': {
      return { ...state, isForgotPasswordCardShown: action.payload }
    }
    case 'SET_APPBAR_STATE': {
      return { ...state, appBarState: action.payload }
    }
    default: {
      return state
    }
  }
}

export default ui
