import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import PasswordInput from '../../components/form/elements/PasswordInput'
import ToggleInput from '../../components/form/elements/ToggleInput'

export default ({ facilitator = {}, ...props }) =>
  createForm({
    fields: [
      {
        id: 'firstname',
        label: 'First Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: facilitator.firstname
      },
      {
        id: 'lastname',
        label: 'Last Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: facilitator.lastname
      },
      facilitator.id
        ? null
        : {
            id: 'username',
            label: 'Username',
            inputComponent: TextInput,
            required: true,
            validation: joi.string(),
            defaultValue: facilitator.username
          },
      {
        id: 'email',
        label: 'Email Address',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: facilitator.email
      },
      {
        id: 'password',
        label: facilitator.id ? 'Change Password' : 'Set Initial Password',
        inputComponent: PasswordInput,
        required: false,
        validation: joi.string().when('resetPassword', {
          is: true,
          then: joi.string().allow(''),
          otherwise: joi
            .string()
            .min(8)
            .allow('')
        }),
        defaultValue: '',
        isVisible: data => !data.resetPassword
      },
      {
        id: 'resetPassword',
        label: `OR ${facilitator.id ? 'Reset' : 'Set'} Password via Email`,
        inputComponent: ToggleInput,
        defaultValue: false,
        validation: joi.bool().default(false)
      }
    ].filter(row => row),
    layoutOptions: {
      submitText: 'Save',
      formOptions: {
        autocomplete: 'off'
      }
    },
    ...props
  })
