import { useEffect, useState } from 'react'
import { fetchOrgUser } from '../actions/users'

export const useOrgUser = (orgId, userId, mapChannels = false, channels) => {
  const [result, setResult] = useState(null)
  const [isFetching, setIsFetching] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        if (mapChannels) {
          if (channels) {
            const user = await fetchOrgUser(orgId, userId)
            const userWithChannels = {
              ...user,
              channels: channels.reduce((channelsIds, channel) => {
                if (channel.users.includes(user.id)) {
                  channelsIds.push(channel.id)
                }
                return channelsIds
              }, [])
            }
            setResult(userWithChannels)
            setIsFetching(false)
          }
        } else {
          const user = await fetchOrgUser(orgId, userId)
          setResult(user)
          setIsFetching(false)
        }
      } catch (error) {
        console.error(error)
        setError(error)
      }
    })()
  }, [channels])

  return {
    user: result,
    isFetchingUser: isFetching,
    userError: error
  }
}
