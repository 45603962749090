import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Error = styled.div`
  color: red;
  margin: 0 0 20px 0;
`

const ServerError = ({ error }) =>
  !!error && <Error>Error: {getFriendlyMessage(error)}</Error>

const getFriendlyMessage = error => {
  if (error.response && error.response.data.message) {
    return error.response.data.message
  }
  return error.message
}

ServerError.defaultProps = {
  error: null
}

ServerError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    response: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.object
    })
  })
}

export default ServerError
