import { connect } from 'react-redux'
import Course from '../pages/Course'
import { findListObjectById } from '../helpers'
import { setAppBarState } from '../actions/ui'

import {
  editCourseAwait,
  createCourseAwait,
  fetchCoursesAwait,
  addCourseFileAwait,
  dismissAddCourseError,
  dismissAddFileError,
  dismissEditError
} from '../actions/courses'
import {
  createFileAwait,
  fetchFilesAwait,
  getS3GetUrlAwait,
  deleteFileAwait,
  dismissDeleteError
} from '../actions/files'
import { fetchNotificationsByCourseIdAwait } from '../actions/notificationsByCourseId'

const defaultCourse = {
  eid: 0,
  title: '',
  subtitle: '',
  img: '',
  bannerImg: '',
  url: '',
  color: '#c6c6c6',
  colorSecondary: '#9c9c9c',
  published: false
}

const mapStateToProps = (state, ownProps) => {
  const course =
    Object.assign(
      {},
      defaultCourse,
      state.courses.items.find(
        findListObjectById.bind(this, ownProps.match.params.id)
      )
    ) || defaultCourse

  let organizationSubscribesToCourse = true

  return {
    course,
    tags: state.tags.items,
    error: state.courses.error,
    isFetching: state.courses.isFetching,
    isEditing: state.courses.isEditing,
    isAdding: state.files.isAdding,
    isDeletingFile: state.files.isDeleting,
    fileDeleteError: state.files.error.delete,
    notifications: state.notificationsByCourseId[ownProps.match.params.id],
    organizationSubscribesToCourse
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchCourseNotifications: id =>
    dispatch(fetchNotificationsByCourseIdAwait(id)),
  editCourse: course => dispatch(editCourseAwait(course)),
  createCourse: course => dispatch(createCourseAwait(course)),
  fetchCourses: () => dispatch(fetchCoursesAwait()),
  addCourseFile: (...params) => dispatch(addCourseFileAwait(...params)),
  addFile: (params, file) => dispatch(createFileAwait(params, file)),
  fetchFiles: () => dispatch(fetchFilesAwait(ownProps.match.params.id)),
  fetchFile: id => dispatch(getS3GetUrlAwait(id)),
  deleteFile: id => dispatch(deleteFileAwait(id)),
  dismissErrors: type => {
    if (type === 'addCourse') dispatch(dismissAddCourseError())
    if (type === 'addFile') dispatch(dismissAddFileError())
    if (type === 'edit') dispatch(dismissEditError())
  },
  dismissFileDeleteError: () => dispatch(dismissDeleteError())
})

export default connect(mapStateToProps, mapDispatchToProps)(Course)
