import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from 'mdi-react/EditIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import UserSwitchIcon from 'mdi-react/UserSwitchIcon'
import styled from 'styled-components'

const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 24px;

  .details {
    flex-grow: 1;
    width: 100%;
  }

  .user {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .name {
    font-weight: bold;
    margin-right: 10px;
    line-height: 28px;
  }

  .user {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    font-weight: 300;

    span {
      margin-right: 10%;
      margin-bottom: 5px;
    }
  }

  .edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

class UserTile extends Component {
  render() {
    return (
      <Container>
        <div className="details">
          <div className="user">
            <span className="name">{`${this.props.user.name.first} ${this.props.user.name.last}`}</span>
            <span>{this.props.user.email}</span>
            {this.props.isSuperAdmin && (
              <span>
                {this.props.user.roleLabel || this.props.user.permission}
              </span>
            )}
          </div>
        </div>
        <div className="edit">
          {this.props.handleEditUserButton && (
            <IconButton
              onClick={() => this.props.handleEditUserButton(this.props.user)}>
              <EditIcon />
            </IconButton>
          )}

          {this.props.onAssume && (
            <IconButton onClick={() => this.props.onAssume(this.props.user)}>
              <UserSwitchIcon />
            </IconButton>
          )}

          {!this.props.isCurrentUser && this.props.handleDeleteUserButton && (
            <IconButton
              onClick={() =>
                this.props.handleDeleteUserButton(this.props.user)
              }>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </Container>
    )
  }
}

UserTile.defaultProps = {
  isSuperAdmin: false,
  isCurrentUser: false
}

UserTile.propTypes = {
  isSuperAdmin: PropTypes.bool,
  user: PropTypes.object,
  organization: PropTypes.object,
  handleEditUserButton: PropTypes.func,
  handleDeleteUserButton: PropTypes.func,
  onAssume: PropTypes.func,
  isCurrentUser: PropTypes.bool
}

export default UserTile
