import Login from '../pages/Login'
import { connect } from 'react-redux'
import {
  awaitForgotPassword,
  awaitLogin,
  awaitGoogleLogin,
  awaitOrganizationSelection,
  awaitAcceptTerms,
  rejectLogin
} from '../actions/auth'
import { setForgotPasswordCardShown, setAppBarState } from '../actions/ui'
import { awaitSubmitMfaCode } from '../actions/mfaCodes'

function mapStateToProps(state) {
  return {
    isAcceptingTerms: state.auth.isAcceptingTerms,
    isSelectingOrg: state.auth.isSelectingOrg,
    isLoggingIn: state.auth.isLoggingIn,
    loginErrorMessage: state.auth.loginErrorMessage,
    isForgotPasswordCardShown: state.ui.isForgotPasswordCardShown,
    organizations: state.auth.organizations,
    verificationErrorMessage: state.auth.verificationErrorMessage
  }
}

const mapDispatchToProps = {
  verifyCode: awaitSubmitMfaCode,
  login: awaitLogin,
  acceptTerms: awaitAcceptTerms,
  googleLogin: awaitGoogleLogin,
  organizationSelection: awaitOrganizationSelection,
  forgotPassword: awaitForgotPassword,
  rejectLogin,
  setForgotPasswordCardShown,
  setAppBarState
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
