import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ErrorMessage = styled.div`
  color: red;
  margin: 10px 0;
`

const WarningMessage = styled.div`
  color: orange;
  margin: 10px 0;
`

const FieldLayout = ({
  id,
  className,
  type,
  visible,
  description,
  errorMessages,
  warningMessages,
  children,
  maxErrors
}) => {
  let wrappingClass = `input-field ${type} ${className}`
  if (errorMessages.length || warningMessages.length) {
    wrappingClass += ' errors '
  }
  const divId = `field-${id}`
  const errors = errorMessages
    .map(message => ({
      message,
      errorClass: 'error-message',
      component: ErrorMessage
    }))
    .concat(
      warningMessages.map(message => ({
        message,
        errorClass: 'warning-message',
        component: WarningMessage
      }))
    )
    .slice(0, maxErrors)

  const style = { marginTop: '10px' }
  if (!visible) {
    style.display = 'none'
  }

  return (
    <div style={style} id={divId} className={wrappingClass}>
      {children}
      {errors.map(({ component: Component, message, errorClass }) => (
        <Component key={message} className={errorClass}>
          {message}
        </Component>
      ))}
      {description !== '' ? (
        <div className="field-description">{description}</div>
      ) : null}
    </div>
  )
}

FieldLayout.displayName = 'FieldLayout'

FieldLayout.defaultProps = {
  className: '',
  label: '',
  description: '',
  errorMessages: [],
  warningMessages: [],
  required: false,
  maxErrors: 1
}

FieldLayout.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  visible: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inputId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  errorMessages: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ),
  warningMessages: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ),
  maxErrors: PropTypes.number
}

export default FieldLayout
