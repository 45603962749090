import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const ProtectedRoute = ({ isLoggedIn, ...rest }) =>
  isLoggedIn ? <Route {...rest} /> : <Redirect to="/login" />

ProtectedRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
}

ProtectedRoute.defaultProps = {
  isLoggedIn: false
}

const mapStateToProps = state => {
  return { isLoggedIn: state.auth.isLoggedIn }
}

export default connect(mapStateToProps)(ProtectedRoute)
