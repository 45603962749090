import React from 'react'
import PropTypes from 'prop-types'
import AdminForm from '../admin-create/AdminForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const AdminEdit = ({ admin, roles, onSubmit }) => (
  <div>
    <Breadcrumbs links={[{ title: 'Admins', href: '/admins' }]} />
    <Card>
      <CardHeader className="title" title="Edit Admin" />
      <CardContent className="description">
        <AdminForm user={admin} roles={roles} onSubmit={onSubmit} />
      </CardContent>
    </Card>
  </div>
)

AdminEdit.propTypes = {
  admin: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default AdminEdit
