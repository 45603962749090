import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../../components/Breadcrumbs'
import TaasCourseTeacherEnrolmentForm from '../course-student-enrolment-create/TaasCourseStudentEnrolmentForm'
import { generateBreadcrumbs } from '../links'

const TaasCourseEnrolmentEdit = ({
  channel,
  organization,
  course,
  users,
  enrolment,
  onSubmit
}) => (
  <div>
    <Breadcrumbs
      links={generateBreadcrumbs(organization, channel, [
        { title: 'Courses', href: '/courses' },
        {
          title: course.title,
          href: `/courses/${course.id}`
        },
        {
          title: 'Teacher Enrollments',
          href: `/courses/${course.id}/teacher-enrolments`
        }
      ])}
    />
    <Card className="courses">
      <CardHeader className="title" title="Edit Enrollment" />
      <CardContent className="description">
        <TaasCourseTeacherEnrolmentForm
          users={users}
          type="teacher"
          enrolment={enrolment}
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
  </div>
)

TaasCourseEnrolmentEdit.propTypes = {
  course: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  enrolment: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default TaasCourseEnrolmentEdit
