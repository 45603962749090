export const setNavDrawerPinnedState = isNavDrawerPinned => ({
  type: 'SET_NAVDRAWER_PINNED_STATE',
  payload: isNavDrawerPinned
})
export const setForgotPasswordCardShown = bool => ({
  type: 'SET_FORGOT_PASSWORD_CARD_SHOWN',
  payload: bool
})
export const clearLoginErrorMessage = () => ({
  type: 'CLEAR_LOGIN_ERROR_MESSAGE'
})

export const setAppBarState = appBarState => ({
  type: 'SET_APPBAR_STATE',
  payload: appBarState
})
