import React from 'react'
import { connect } from 'react-redux'
import AccountSettings from './AccountSettings'
import Screen from '../../components/Screen'
import { updateSettings } from '../../actions/users'
import { setAppBarState } from '../../actions/ui'
import { awaitUserData } from '../../actions/auth'

class AccountSettingsContainer extends React.Component {
  constructor(props) {
    super(props)
    props.setAppBarState({
      visible: true,
      title: 'User Profile'
    })
  }

  render() {
    return (
      <Screen
        name="account settings"
        isLoading={this.props.isFetching || !this.props.user.id}
        error={this.props.error}
        renderContent={() => <AccountSettings {...this.props} />}
      />
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.userData,
  account: state.auth.account,
  courses: state.courses.items,
  isFetching: state.courses.isFetching
})

const mapDispatchToProps = dispatch => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  onSubmit: data => dispatch(updateSettings(data)),
  awaitUserData: () => dispatch(awaitUserData())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsContainer)
