import React from 'react'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Tile from './Tile'
import NotificationFileItem from './NotificationFileItem'
import UploadIcon from 'mdi-react/UploadIcon'

const FilesTile = styled.div`
  margin-top: 10px;
`

const NotificationFileList = ({
  files,
  isFetching,
  isSuperAdmin,
  onShowAttachDialog,
  onDownload,
  onDelete
}) => {
  return (
    <FilesTile>
      <Tile>
        <CardHeader
          className="title"
          title="Attachments"
          action={
            isSuperAdmin && (
              <IconButton onClick={onShowAttachDialog}>
                <UploadIcon />
              </IconButton>
            )
          }
        />
        <CardContent>
          {isFetching ? (
            <div className="loading">
              <CircularProgress size={100} />
            </div>
          ) : files.length ? (
            <List dense>
              {files.map(file => (
                <NotificationFileItem
                  key={file.id}
                  file={file}
                  onDownload={() => onDownload(file.id)}
                  onDelete={() => onDelete(file.id)}
                  isSuperAdmin={isSuperAdmin}
                />
              ))}
            </List>
          ) : (
            <p>No files have been attached to this notification.</p>
          )}
        </CardContent>
      </Tile>
    </FilesTile>
  )
}

NotificationFileList.defaultProps = {
  isFetching: false,
  isSuperAdmin: false,
  files: []
}

NotificationFileList.propTypes = {
  error: PropTypes.object,
  isFetching: PropTypes.bool,
  files: PropTypes.array,
  isSuperAdmin: PropTypes.bool,
  onShowAttachDialog: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func
}

export default NotificationFileList
