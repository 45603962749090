import { channelGetReports } from '../requests/channels'
import { useRequest } from './useRequest'

export const useReports = channelId => {
  const { result, isFetching, error } = useRequest(channelGetReports, channelId)

  return {
    reports: result,
    isFetchingReports: isFetching,
    reportsError: error
  }
}
