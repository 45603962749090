import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import AccountSettingsForm from './AccountSettingsForm'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import { MultiFactorAuthSection } from './MultiFactorAuthSection'
import { getToken } from '../../auth'
import { decodeToken } from '../../utilities/decodeToken'

const AccountSettings = ({ user, courses, onSubmit, awaitUserData }) => {
  const token = decodeToken(getToken())

  useEffect(() => {
    awaitUserData()
  }, [])

  return (
    <div>
      <Card>
        <CardHeader className="title" title="Account" />
        <CardContent className="description">
          <h3>Your Profile</h3>
          <p>Email Address: {user.email}</p>
          {user.organization && <p>Organization: {user.organization.name}</p>}

          <MultiFactorAuthSection enabled={token?.mfaEnabled ?? false} />

          <h3>Account Settings</h3>
          <div>
            <AccountSettingsForm
              user={user}
              courses={courses}
              onSubmit={onSubmit}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

AccountSettings.propTypes = {
  user: PropTypes.object.isRequired,
  courses: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  awaitUserData: PropTypes.func.isRequired
}

export default AccountSettings
