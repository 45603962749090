import joi from 'joi'
import createForm from '../../components/form/createForm'
import FileCsvInput from '../../components/form/elements/FileCsvInput'

const csvSchema = joi.array().items(
  joi.object({
    type: joi
      .string()
      .valid('student', 'observer', 'facilitator')
      .required(),
    username: joi.string().required(),
    email: joi
      .string()
      .email({ tlds: { allow: false } })
      .required(),
    password: joi.string().when('resetPassword', {
      is: true,
      then: joi.string().allow(''),
      otherwise: joi
        .string()
        .required()
        .min(8)
    }),
    resetPassword: joi
      .bool()
      .falsy('', 'n', 'no', 'false', 'N', 'NO', 'FALSE')
      .truthy('yes', 'y', 'true', 'YES', 'Y')
      .default(false),
    firstname: joi.string().required(),
    lastname: joi.string().required()
  })
)

export default ({ ...props }) =>
  createForm({
    fields: [
      {
        id: 'csv',
        label: 'CSV File',
        inputComponent: FileCsvInput,
        validation: csvSchema,
        required: true
      }
    ],
    layoutOptions: {
      submitText: 'Save'
    },
    ...props,
    onSubmit: async data => {
      return props.onSubmit(data.csv)
    }
  })
