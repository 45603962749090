import React from 'react'
import PropTypes from 'prop-types'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import ChevronUpIcon from 'mdi-react/ChevronUpIcon'
import IconButton from '@material-ui/core/IconButton'
import classNames from 'classnames'
import IconsWithDate from './IconsWithDate'

const Trigger = props => {
  const classes = {
    Trigger: props.hasBody,
    Trigger__noBody: !props.hasBody
  }

  return (
    <div className={classNames(classes)}>
      <div className={'trigger__title'}>
        <span
          dangerouslySetInnerHTML={{
            __html: props.title
          }}
        />
        {props.hasBody && (
          <IconButton>
            {props.isOpened ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </IconButton>
        )}
      </div>
      <div className={'trigger__date'}>
        {props.date && <IconsWithDate date={props.date} />}
      </div>
      <style>{`
        .Trigger {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          white-space: nowrap;
        }
        .Trigger__noBody {
          cursor: default;
          margin: 8px 0 10px 0;
        }
      `}</style>
    </div>
  )
}

Trigger.propTypes = {
  title: PropTypes.string,
  isOpened: PropTypes.bool,
  hasBody: PropTypes.bool,
  date: PropTypes.string,
  displayNote: PropTypes.bool
}

export default Trigger
