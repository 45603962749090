import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import OrganizationChannelsCopy from './OrganizationChannelsCopy'
import Screen from '../../components/Screen'
import { getChannel } from '../../selectors'
import { channelsFetchAwait, channelCopyAwait } from '../../actions/channels'
import GateExists from '../../components/GateExists'
import { useOrganization } from '../../hooks/useOrganization'

const OrganizationChannelsCopyContainer = props => {
  const {
    fetchChannels,
    isLoading,
    onSubmit,
    channel,
    error,
    organizationId
  } = props
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )
  const [submitError, setSubmitError] = useState(null)

  useEffect(() => {
    fetchChannels()
  }, [])

  const handleSubmit = async data => {
    try {
      await onSubmit(data)
    } catch (err) {
      setSubmitError(err)
    }
  }

  return (
    <Screen
      name="channel copy"
      isLoading={isLoading || isFetchingOrg}
      error={error || orgError}>
      <GateExists exists={channel && organization}>
        <OrganizationChannelsCopy
          organization={organization}
          channel={channel}
          onSubmit={handleSubmit}
          error={submitError}
          {...props}
        />
      </GateExists>
    </Screen>
  )
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.organizations.isFetching || state.channels.isFetching,
  organizationId: ownProps.match.params.organization,
  channel: getChannel(ownProps.match.params.id)(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  },
  onSubmit: data =>
    dispatch(
      channelCopyAwait(
        ownProps.match.params.organization,
        ownProps.match.params.id,
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationChannelsCopyContainer)
