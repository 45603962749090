import axios from 'axios'
import { getToken } from '../auth'

export function fetchNotificationFilesAwait(notificationId) {
  return function(dispatch) {
    if (!getToken()) return dispatch(fetchNotificationFilesFailure())

    dispatch(fetchNotificationFilesRequest(notificationId))
    return fetchNotificationFiles(notificationId).then(
      result => dispatch(fetchNotificationFilesSuccess(result)),
      err => dispatch(fetchNotificationFilesFailure(err))
    )
  }
}

function fetchNotificationFilesRequest(notificationId) {
  const payload = { notificationId: notificationId }
  return { type: 'NOTIFICATION_FILES_FETCH', payload }
}

function fetchNotificationFilesFailure() {
  const payload = 'There was an error fetching notification files.'
  return { type: 'NOTIFICATION_FILES_FETCH_FAILURE', payload }
}

async function fetchNotificationFiles(notificationId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      '/notification-files/byNotification/' +
      notificationId,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return new Promise(resolve => {
    setTimeout(() => {
      resolve(result.data)
    }, 2500)
  })
}

function fetchNotificationFilesSuccess(files) {
  const payload = files
  return { type: 'NOTIFICATION_FILES_FETCH_SUCCESS', payload }
}

export function getS3GetUrlAwait(id) {
  return function(dispatch) {
    if (!getToken()) return dispatch(getS3GetUrlFailure())

    dispatch(getS3GetUrlRequest())
    return getSignedS3GetUrl(id).then(
      result => {
        dispatch(getS3GetUrlSuccess(result))
        window.location = result
        return result
      },
      err => dispatch(getS3GetUrlFailure(err))
    )
  }
}

async function getSignedS3GetUrl(id) {
  const sig = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE + `/notification-files/${id}/signedS3GetUrl`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return sig.data.url
}

function getS3GetUrlRequest() {
  const payload = 'Getting signed S3 GET url'
  return { type: 'NOTIFICATION_FILES_S3GETURL_REQUEST', payload }
}

function getS3GetUrlSuccess(fileUrl) {
  const payload = fileUrl
  return { type: 'NOTIFICATION_FILES_S3GETURL_SUCCESS', payload }
}

function getS3GetUrlFailure() {
  const payload = 'There was an error getting the signed S3 GET url'
  return { type: 'NOTIFICATION_FILES_S3GETURL_FAILURE', payload }
}

export function createFileAwait(params, file) {
  return function(dispatch) {
    if (!getToken()) return dispatch(addFileFailure())

    dispatch(addingFile())
    return addFile(params, file).then(
      result => {
        dispatch(addFileSuccess(result))
        dispatch(fetchNotificationFilesAwait(params.notification))
        return result
      },
      err => dispatch(addFileFailure(err))
    )
  }
}

async function getSignedS3PutUrl(params) {
  const sig = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/notification-files/signedS3PutUrl',
    params,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return sig
}

async function addFile(params, file) {
  const uploadParams = { ...params, type: file.type }
  const sig = await getSignedS3PutUrl(uploadParams)
  const { url, filename, contentType, contentDisposition } = sig.data

  const headers = {
    'Content-Type': contentType,
    'Content-Disposition': contentDisposition
  }

  if (window.location.hostname.indexOf('.test') !== -1) {
    // TODO get uploads working locally wtihout this
    delete headers['Content-Disposition']
  }

  await axios.put(url, file, { headers })

  delete params.ext
  const newFile = {
    ...params,
    filename,
    date: new Date()
  }

  const res = await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/notification-files',
    data: newFile,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return res
}

function addingFile() {
  const payload = 'Adding files'
  return { type: 'NOTIFICATION_FILES_ADD', payload }
}

function addFileSuccess() {
  const payload = 'Added files successfully'
  return { type: 'NOTIFICATION_FILES_ADD_SUCCESS', payload }
}

function addFileFailure() {
  const payload = 'Failed to add files'
  return { type: 'NOTIFICATION_FILES_ADD_FAILURE', payload }
}

export function deleteFileAwait(fileId) {
  return function(dispatch) {
    dispatch(deletingFile())

    return axios({
      method: 'DELETE',
      url: process.env.REACT_APP_REMOTE + `/notification-files/${fileId}`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
      .then(() => {
        dispatch(deleteFileSuccess(fileId))
      })
      .catch(e => {
        dispatch(deleteFileFailure(e, fileId))
      })
  }
}

function deletingFile() {
  const payload = 'Deleting File'
  return { type: 'NOTIFICATION_FILES_DELETE', payload }
}

function deleteFileSuccess(id) {
  return { type: 'NOTIFICATION_FILES_DELETE_SUCCESS', payload: id }
}

function deleteFileFailure() {
  const payload = 'Could not delete file'
  return { type: 'NOTIFICATION_FILES_DELETE_FAILURE', payload }
}

export function dismissUploadError() {
  return { type: 'NOTIFICATION_FILES_DISMISS_FAILURE', payload: null }
}
