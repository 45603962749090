import { getToken } from '../auth'
import {
  fetchOrganizations,
  fetchOrganizationsByIds,
  postOrganization,
  putOrganization,
  deleteOrganization
} from '../requests/organizations'

const fetchOrgByIdsActions = {
  request: () => ({
    type: 'FETCH_ORGANIZATIONS_BY_IDS',
    payload: 'Loading organizations'
  }),
  success: organizations => ({
    type: 'FETCH_ORGANIZATIONS_SUCCESS_BY_IDS',
    organizations: organizations.data
  }),
  failed: () => ({
    type: 'FETCH_ORGANIZATIONS_FAILURE_BY_IDS',
    payload: 'Failed to load organizations'
  })
}

export const fetchOrganizationsByIdsAwait = ids => async dispatch => {
  if (!getToken()) return dispatch(fetchOrgByIdsActions.failed())

  try {
    dispatch(fetchOrgByIdsActions.request())
    const result = await fetchOrganizationsByIds(ids)
    dispatch(fetchOrgByIdsActions.success(result))
  } catch (error) {
    dispatch(fetchOrgByIdsActions.failed(error))
  }
}

const fetchOrgActions = {
  request: () => ({
    type: 'FETCH_ORGANIZATIONS',
    payload: 'Loading organizations'
  }),
  success: organizations => ({
    type: 'FETCH_ORGANIZATIONS_SUCCESS',
    payload: {
      items: organizations.data,
      totalPages: organizations.metadata.pagination.totalPages
    }
  }),
  failed: () => ({
    type: 'FETCH_ORGANIZATIONS_FAILURE',
    payload: 'Failed to load organizations'
  })
}

export const fetchOrganizationsAwait = (
  page,
  limit,
  search,
  filter
) => async dispatch => {
  if (!getToken()) return dispatch(fetchOrgActions.failed())
  try {
    dispatch(fetchOrgActions.request())
    const result = await fetchOrganizations(
      page || 1,
      limit || 25,
      search,
      filter
    )
    return dispatch(fetchOrgActions.success(result))
  } catch (error) {
    return dispatch(fetchOrgActions.failed(error))
  }
}

const createOrgActions = {
  request: () => ({
    type: 'ADD_ORGANIZATION',
    payload: 'Adding organization'
  }),
  success: () => ({
    type: 'ADD_ORGANIZATION_SUCCESS',
    payload: 'Added organization successfully'
  }),
  failed: () => ({
    type: 'ADD_ORGANIZATION_FAILURE',
    payload: 'Failed to add organization'
  })
}

export const dismissAddError = () => ({
  type: 'ADD_ORGANIZATION_DISMISS',
  payload: null
})

export const createOrganizationAwait = newOrganization => async dispatch => {
  dispatch(createOrgActions.request())
  try {
    const result = await postOrganization(newOrganization)
    dispatch(createOrgActions.success())
    return result.data
  } catch (error) {
    dispatch(createOrgActions.failed(error))
  }
}

const editOrgActions = {
  request: () => ({
    type: 'EDIT_ORGANIZATION',
    payload: 'Editing organization'
  }),
  success: () => ({
    type: 'EDIT_ORGANIZATION_SUCCESS',
    payload: 'Edited organization successfully'
  }),
  failed: () => ({
    type: 'EDIT_ORGANIZATION_FAILURE',
    payload: 'Failed to edit organization'
  })
}

export const dismissEditError = () => ({
  type: 'EDIT_ORGANIZATION_DISMISS',
  payload: null
})

export const editOrganizationAwait = newOrganization => async dispatch => {
  dispatch(editOrgActions.request())
  try {
    const result = await putOrganization(newOrganization)
    dispatch(editOrgActions.success())
    return result
  } catch (error) {
    dispatch(editOrgActions.failed(error))
  }
}

const deleteOrgActions = {
  request: () => ({
    type: 'DEL_ORGANIZATION',
    payload: 'Deleting organization'
  }),
  success: () => ({
    type: 'DEL_ORGANIZATION_SUCCESS',
    payload: 'Deleted organization successfully'
  }),
  failed: () => ({
    type: 'DEL_ORGANIZATION_FAILURE',
    payload: 'Failed to delete organization'
  })
}

export const dismissDeleteError = () => ({
  type: 'DEL_ORGANIZATION_DISMISS',
  payload: null
})

export const deleteOrganizationAwait = id => async dispatch => {
  dispatch(deleteOrgActions.request())
  try {
    const result = await deleteOrganization(id)
    dispatch(deleteOrgActions.success())
    return result
  } catch (error) {
    dispatch(deleteOrgActions.failed(error))
  }
}
