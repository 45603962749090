import React from 'react'
import PropTypes from 'prop-types'
import Selection from '../components/Selection'
import CourseFolderSelectionDialog from '../components/CourseFolderSelectionDialog'
import CourseReleaseDateSelectionDialog from '../components/CourseReleaseDateSelectionDialog'

class CourseSelectionActions extends React.Component {
  handleSelectAll = () => {
    const { onSelect, courses } = this.props
    onSelect(courses.map(row => row.id))
  }

  render() {
    return (
      <React.Fragment>
        <Selection
          selected={this.props.selection}
          onSelectAll={this.handleSelectAll}
          onClear={this.props.onClear}
          actions={[
            {
              id: 'download',
              label: 'Download',
              onSelect: this.props.onDownload
            },
            {
              id: 'folder.add',
              label: 'Add to Folder...',
              onSelect: this.props.onCourseFoldersAdd
            },
            {
              id: 'folder.remove',
              label: 'Remove from Folder...',
              onSelect: this.props.onCourseFoldersRemove
            }
          ].concat(
            this.props.isSuperAdmin
              ? [
                  {
                    id: 'release.date',
                    label: 'Set Package Release',
                    onSelect: this.props.onReleaseDatePrompt
                  }
                ]
              : []
          )}
        />

        <CourseFolderSelectionDialog
          intent={this.props.intent}
          active={this.props.isSelectingCourseFolder}
          courseFolders={this.props.courseFolders}
          onCancel={this.props.onCourseFoldersSelectCancel}
          onSubmit={this.props.onCourseFoldersSubmit}
          onCreate={this.props.onCourseFoldersCreate}
          onDelete={this.props.onCourseFoldersDelete}
        />

        <CourseReleaseDateSelectionDialog
          active={this.props.isSelectingReleaseDate}
          onCancel={this.props.onReleaseDateCancel}
          onSubmit={this.props.onReleaseDateSubmit}
          isSaving={this.props.isSavingReleaseDate}
          error={this.props.releaseDateSaveError}
        />
      </React.Fragment>
    )
  }
}

CourseSelectionActions.propTypes = {
  isSuperAdmin: PropTypes.bool,
  intent: PropTypes.string,
  courses: PropTypes.array,
  courseFolders: PropTypes.array,
  isSelectingCourseFolder: PropTypes.array,
  selection: PropTypes.array,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  onClear: PropTypes.func,
  onDownload: PropTypes.func,
  onCourseFoldersAdd: PropTypes.func.isRequired,
  onCourseFoldersRemove: PropTypes.func.isRequired,
  onCourseFoldersCreate: PropTypes.func.isRequired,
  onCourseFoldersSelectCancel: PropTypes.func.isRequired,
  onCourseFoldersSubmit: PropTypes.func.isRequired,
  onCourseFoldersDelete: PropTypes.func.isRequired,
  isSelectingReleaseDate: PropTypes.bool.isRequired,
  onReleaseDatePrompt: PropTypes.func.isRequired,
  onReleaseDateCancel: PropTypes.func.isRequired,
  onReleaseDateSubmit: PropTypes.func.isRequired,
  isSavingReleaseDate: PropTypes.bool.isRequired,
  releaseDateSaveError: PropTypes.string
}

export default CourseSelectionActions
