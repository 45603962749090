import React from 'react'
import PropTypes from 'prop-types'
import AdminForm from './AdminForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const AdminCreate = ({ roles, onSubmit }) => (
  <div>
    <Breadcrumbs links={[{ title: 'Admins', href: '/admins' }]} />
    <Card>
      <CardHeader className="title" title="Create New Admin" />
      <CardContent className="description">
        <AdminForm roles={roles} onSubmit={onSubmit} />
      </CardContent>
    </Card>
  </div>
)

AdminCreate.propTypes = {
  roles: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default AdminCreate
