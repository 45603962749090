import axios from 'axios'
import { getToken } from '../auth'
import { push } from 'connected-react-router'

export const surveysFetchAll = async () => {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/surveys/admin',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export const surveysFetchUser = async () => {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/surveys',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export const surveyFetchUser = async id => {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/surveys/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export const surveysFetch = async id => {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/surveys/admin/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export const surveysCreate = data => {
  return async function(dispatch) {
    await axios({
      method: 'POST',
      url: process.env.REACT_APP_REMOTE + '/surveys/admin',
      data,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    dispatch(push('/tools/surveys'))
  }
}

export const surveysEdit = (survey, data) => {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      url: process.env.REACT_APP_REMOTE + `/surveys/admin/${survey.id}`,
      data,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    dispatch(push('/tools/surveys'))
  }
}

export const surveysDelete = survey => {
  return async function(dispatch) {
    await axios({
      method: 'DELETE',
      url: process.env.REACT_APP_REMOTE + `/surveys/admin/${survey.id}`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    dispatch(push('/tools/surveys'))
  }
}

export const surveysComplete = survey => {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      url: process.env.REACT_APP_REMOTE + `/surveys/${survey.id}/complete`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    alert('Thank you for your submission!')
    dispatch(push('/surveys'))
  }
}
