import { uniq, without } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Select from 'react-select'
import SearchQueryInputUser from './SearchQueryInputUser'
import SearchQueryInputOrganization from './SearchQueryInputOrganization'
import SearchQueryInputCourse from './SearchQueryInputCourse'
import SearchQueryInputTag from './SearchQueryInputTag'
import Button from '@material-ui/core/Button'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import CancelIcon from 'mdi-react/CancelIcon'
import Chip from '@material-ui/core/Chip'
import { fetchOrganizationsByIdsAwait } from '../actions/organizations'
import { connect } from 'react-redux'
import { fetchTagsByIdsAwait } from '../actions/tags'

const options = [
  { value: 'course', label: 'Course' },
  { value: 'user', label: 'User' },
  { value: 'organization', label: 'Organization' },
  { value: 'tag', label: 'Tag' }
]

const StyledSelect = styled(Select)`
  width: 200px;
  font-size: 12pt;
`

const StyledForm = styled.form`
  padding: 5px;
  margin-bottom: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  .values {
    margin: 10px 0;
  }
`

const selectStyles = {
  option: provided => ({
    ...provided,
    fontSize: '12px'
  }),
  control: provided => ({
    ...provided,
    width: 200,
    fontSize: '12px',
    float: 'left'
  })
}

class SearchQueryAddCondition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: options.find(option => option.value === props.type) || {},
      values: props.value,
      value: ''
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.type) {
      this.props.onSubmit(this.state.type.value, this.state.values)
    }
    this.setState({ type: '', values: [], value: '' })
  }

  handleTypeChange = selected => {
    this.setState({ type: selected, values: [], value: '' })
  }

  handleValueChange = value => {
    this.setState({ value })
  }

  handleValueSelect = value => {
    if (this.state.type.value === 'organization') {
      this.props.fetchOrganizationsByIds([value])
    }

    if (this.state.type.value === 'tag') {
      this.props.fetchTagsByIds([value])
    }

    this.setState(state => ({
      ...state,
      values: uniq([...state.values, value]),
      value: ''
    }))
  }

  handleRemove = value => {
    this.setState(state => ({
      ...state,
      values: without(state.values, value)
    }))
  }

  getInputComponent = type => {
    if (type === 'user') {
      return SearchQueryInputUser
    }
    if (type === 'organization') {
      return SearchQueryInputOrganization
    }
    if (type === 'course') {
      return SearchQueryInputCourse
    }
    if (type === 'tag') {
      return SearchQueryInputTag
    }
    return () => <></>
  }

  render() {
    const { type, value, values } = this.state
    const { refs, onCancel, allowedTypes, editMode } = this.props
    const InputComponent = this.getInputComponent(type.value)
    return (
      <StyledForm onSubmit={this.handleSubmit}>
        <h4>{editMode ? 'Edit Condition' : 'Add Condition'}</h4>
        <div className="inputs">
          {editMode ? (
            type.label
          ) : (
            <StyledSelect
              styles={selectStyles}
              options={options.filter(
                ({ value }) => allowedTypes.indexOf(value) !== -1
              )}
              value={type}
              onChange={this.handleTypeChange}
              placeholder="Select a Filter Type"
            />
          )}

          <div
            style={{
              display: 'inline-block',
              marginLeft: '15px',
              width: 'calc(100% - 250px)'
            }}>
            <InputComponent
              value={value}
              onChange={this.handleValueChange}
              onSelect={this.handleValueSelect}
            />
          </div>
          <div style={{ clear: 'both' }} />
        </div>

        <div className="values">
          {values.map(value => (
            <Chip
              key={value}
              label={refs[type.value][value]}
              onDelete={() => this.handleRemove(value)}
              variant="outlined"
            />
          ))}
        </div>

        <Button type="submit" className="query-save">
          <ContentSaveIcon />
        </Button>
        {onCancel && (
          <Button onClick={onCancel}>
            <CancelIcon />
          </Button>
        )}
        <div style={{ clear: 'both' }} />
      </StyledForm>
    )
  }
}

SearchQueryAddCondition.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  refs: PropTypes.shape({}),
  type: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  allowedTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchOrganizationsByIds: PropTypes.func.isRequired,
  fetchTagsByIds: PropTypes.func.isRequired,
  editMode: PropTypes.bool
}

SearchQueryAddCondition.defaultProps = {
  refs: {},
  type: '',
  value: [],
  editMode: false,
  onCancel: null
}

const mapDispatchToProps = {
  fetchOrganizationsByIds: fetchOrganizationsByIdsAwait,
  fetchTagsByIds: fetchTagsByIdsAwait
}

export default connect(null, mapDispatchToProps)(SearchQueryAddCondition)
