import React from 'react'
import { connect } from 'react-redux'
import TaasReset from './TaasReset'
import Screen from '../../components/Screen'
import { resetPassword } from '../../actions/taas'
import { getParameterByName } from '../../utilities/qs'

class TaasResetContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { complete: false }
  }

  handleSubmit = async data => {
    await this.props.onSubmit(data)
    this.setState({ complete: true })

    if (this.props.url) {
      setTimeout(() => {
        window.location = this.props.url
      }, 1500)
    }
  }

  render() {
    return (
      <Screen name="reset password" error={this.props.error}>
        <TaasReset
          {...this.props}
          onSubmit={this.handleSubmit}
          complete={this.state.complete}
        />
      </Screen>
    )
  }
}

const mapStateToProps = () => ({
  token: getParameterByName('token'),
  username: getParameterByName('username'),
  url: getParameterByName('url')
})

const mapDispatchToProps = () => ({
  onSubmit: data => resetPassword(data)
})

export default connect(mapStateToProps, mapDispatchToProps)(TaasResetContainer)
