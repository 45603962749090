import React from 'react'
import { connect } from 'react-redux'
import TaasStudentObserverList from './TaasStudentObserverList'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import {
  fetchStudent,
  fetchObservers,
  fetchStudentObservers,
  addStudentObserver,
  removeStudentObserver
} from '../../actions/taas'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'

class TaasStudentObserverListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, students: [], error: null, searchTerm: '' }
    this.props.setAppBarState({
      visible: true,
      title: 'Student Observers',
      rightIcon: null
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, student: null, observers: [] })
      const [student, studentObservers, allObservers] = await Promise.all([
        this.props.fetchStudent(),
        this.props.fetchStudentObservers(),
        this.props.fetchObservers()
      ])

      const observers = allObservers.map(row => ({
        ...row,
        authorized: studentObservers.some(
          studentObserver => studentObserver.id === row.id
        )
      }))

      this.setState({ loading: false, student, observers })
    } catch (e) {
      console.error(e.message)
      this.setState({ loading: false, error: e.message })
    }
  }

  handleSearch = searchTerm => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.setState({ searchTerm }), 300)
  }

  render() {
    const { isLoading, error: propError } = this.props
    const {
      loading,
      error: stateError,
      student,
      observers,
      searchTerm
    } = this.state

    return (
      <Screen
        name="student observers"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel &&
            this.props.organization &&
            this.state.student &&
            this.state.observers
          }>
          <TaasStudentObserverList
            {...this.props}
            student={student}
            observers={observers}
            searchTerm={searchTerm}
            onSearch={this.handleSearch}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchStudent: () =>
    fetchStudent(ownProps.match.params.channel, ownProps.match.params.student),
  fetchStudentObservers: () =>
    fetchStudentObservers(
      ownProps.match.params.channel,
      ownProps.match.params.student
    ),
  fetchObservers: () => fetchObservers(ownProps.match.params.channel),
  onAdd: (student, observer) => dispatch(addStudentObserver(student, observer)),
  onRemove: (student, observer) =>
    dispatch(removeStudentObserver(student, observer))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasStudentObserverListContainer)
