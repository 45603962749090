import React, { Component } from 'react'
import PropTypes from 'prop-types'
import marked from 'marked'
marked.setOptions({
  sanitize: true
})

class ErrorMessage extends Component {
  render() {
    return (
      <div className="errorMessage">
        <div className="blank-icon">{this.props.icon}</div>
        <div
          className="error-text"
          dangerouslySetInnerHTML={{
            __html: marked(this.props.message)
          }}
        />
        <style>{`
          .errorMessage {
            display: flex;
            flex-direction: column;
            height: 500px;
            color: #9b9b9b;
            text-align: center;
            justify-content: center;
          }

          @media only screen and (max-height: 660px) {
            .errorMessage {
              height: 300px;
            }
          }

          @media only screen and (max-height: 465px) {
            .errorMessage {
              height: 100%;
            }
          }

          .errorMessage .error-text {
            white-space: pre-wrap;
            margin: 0 20px;
          }

          .errorMessage .blank-icon svg {
            width: 100px;
            height: 100px;
            fill: #9b9b9b;
            padding-bottom: 50px;
          }
        `}</style>
      </div>
    )
  }
}

ErrorMessage.propTypes = {
  icon: PropTypes.element,
  message: PropTypes.string
}

export default ErrorMessage
