import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import TeacherIcon from 'mdi-react/TeacherIcon'
import EyeIcon from 'mdi-react/EyeIcon'
import UserIcon from 'mdi-react/UserIcon'
import UserMultipleAddIcon from 'mdi-react/UserMultipleAddIcon'
import SchoolIcon from 'mdi-react/SchoolIcon'
import BookIcon from 'mdi-react/BookIcon'
import DownloadIcon from 'mdi-react/DownloadIcon'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasUserSearch from '../../components/TaasUserSearch'
import Search from '../../components/Search'
import can from '../../utilities/can'

const instanceOptions = [
  { value: 'buzz', label: 'Buzz' },
  { value: 'buzz2', label: 'Buzz 2' }
]

const TaasStudentList = ({
  channel,
  user,
  organization,
  students,
  onStudentEdit,
  onStudentDelete,
  onStudentEnrolments,
  onStudentFacilitators,
  onStudentObservers,
  onFacilitators,
  onObservers,
  onTeachers,
  onCourses,
  searchTerm,
  onSearch,
  onHelp,
  instance,
  onImportUsers,
  onImportEnrolments,
  onInstanceChange
}) => (
  <div>
    <div style={{ float: 'right', marginTop: '-10px', marginBottom: '10px' }}>
      <IconButton onClick={() => onTeachers(channel)} variant="contained">
        <UserIcon /> <small>Teachers</small>
      </IconButton>
      <IconButton onClick={() => onCourses(channel)} variant="contained">
        <BookIcon /> <small>Courses</small>
      </IconButton>
      {false && (
        <>
          <IconButton onClick={() => onObservers(channel)} variant="contained">
            <EyeIcon /> <small>Observers</small>
          </IconButton>
          <IconButton
            onClick={() => onFacilitators(channel)}
            variant="contained">
            <TeacherIcon /> <small>Facilitators</small>
          </IconButton>
          {can(user, 'CHANNELS_MANAGE_TAAS_IMPORT') && (
            <>
              <IconButton
                onClick={() => onImportUsers(channel)}
                variant="contained">
                <UserMultipleAddIcon /> <small>Import Users</small>
              </IconButton>
              <IconButton
                onClick={() => onImportEnrolments(channel)}
                variant="contained">
                <SchoolIcon /> <small>Import Enrollments</small>
              </IconButton>
            </>
          )}
        </>
      )}
      <br />
    </div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        }
      ]}
    />
    <div style={{ clear: 'both' }} />

    <div style={{ marginBottom: '10px' }}>
      {!!(channel.buzzIds && channel.buzz2Ids) && (
        <div>
          <Select
            placeholder="Select Instance"
            value={instanceOptions.find(row => row.value === instance)}
            options={instanceOptions}
            onChange={selection => onInstanceChange(channel, selection.value)}
          />
        </div>
      )}

      {false && (
        <Button onClick={() => onHelp()} variant="outlined" color="link">
          <DownloadIcon /> Download Welcome Email Template
        </Button>
      )}
    </div>

    <Search
      className="users-search"
      value={searchTerm}
      handleChange={onSearch}
    />
    <Card className="students">
      <CardHeader className="title" title="Manage Students" />
      <TaasUserSearch
        filter={searchTerm}
        documents={students}
        type="students"
        onEdit={onStudentEdit}
        onDelete={onStudentDelete}
        onStudentEnrolments={onStudentEnrolments}
        onStudentFacilitators={onStudentFacilitators}
        onStudentObservers={onStudentObservers}
      />
    </Card>
  </div>
)

TaasStudentList.propTypes = {
  instance: PropTypes.string,
  students: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onStudentEdit: PropTypes.func.isRequired,
  onStudentDelete: PropTypes.func.isRequired,
  onStudentEnrolments: PropTypes.func.isRequired,
  onStudentFacilitators: PropTypes.func.isRequired,
  onStudentObservers: PropTypes.func.isRequired,
  onFacilitators: PropTypes.func.isRequired,
  onObservers: PropTypes.func.isRequired,
  onTeachers: PropTypes.func.isRequired,
  onCourses: PropTypes.func.isRequired,
  onImportUsers: PropTypes.func.isRequired,
  onImportEnrolments: PropTypes.func.isRequired,
  onHelp: PropTypes.func.isRequired,
  onInstanceChange: PropTypes.func.isRequired
}

export default TaasStudentList
