import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ChannelReportView from './ChannelReportView'
import Screen from '../../components/Screen'
import { getChannel } from '../../selectors'
import { channelsFetchAwait } from '../../actions/channels'
import GateExists from '../../components/GateExists'
import { useReport } from '../../hooks/useReport'
import { useOrganization } from '../../hooks/useOrganization'

const ChannelReportViewContainer = props => {
  const {
    fetchChannels,
    channel,
    organizationId,
    channelId,
    reportId,
    isLoading,
    error
  } = props

  const { report, isFetchingReport, reportError } = useReport(
    channelId,
    reportId
  )
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )

  useEffect(() => {
    fetchChannels()
  }, [])

  return (
    <Screen
      name="channel report"
      isLoading={isLoading || isFetchingReport || isFetchingOrg}
      error={error || reportError || orgError}>
      <GateExists exists={channel && organization && report}>
        <ChannelReportView
          channel={channel}
          report={report}
          organization={organization}
          {...props}
        />
      </GateExists>
    </Screen>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.users.isFetching || state.channels.isFetching,
    organizationId: ownProps.match.params.organization,
    channelId: ownProps.match.params.channel,
    reportId: ownProps.match.params.id,
    channel: getChannel(ownProps.match.params.channel)(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelReportViewContainer)
