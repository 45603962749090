import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasEnrolmentListItem from '../../components/TaasEnrolmentListItem'

const TaasStudentEnrolmentList = ({
  channel,
  organization,
  student,
  enrolments,
  onEnrolmentEdit,
  onEnrolmentDisable
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Students',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
        },
        {
          title: `${student.firstname} ${student.lastname}`,
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students/${student.id}`
        }
      ]}
    />
    <Card className="students">
      <CardHeader className="title" title="Enrollments" />
      <ul>
        {enrolments.map(enrolment => (
          <TaasEnrolmentListItem
            key={enrolment.id}
            enrolment={enrolment}
            onEdit={enrolment => onEnrolmentEdit(enrolment)}
            onDelete={enrolment =>
              onEnrolmentDisable(channel, student, enrolment)
            }
          />
        ))}
      </ul>
    </Card>
  </div>
)

TaasStudentEnrolmentList.propTypes = {
  student: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  enrolments: PropTypes.array.isRequired,
  onEnrolmentEdit: PropTypes.func.isRequired,
  onEnrolmentEnable: PropTypes.func.isRequired,
  onEnrolmentDisable: PropTypes.func.isRequired
}

export default TaasStudentEnrolmentList
