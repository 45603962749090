import { connect } from 'react-redux'
import Notifications from '../pages/Notifications'
import {
  dismissPostError,
  dismissSendError,
  fetchNotificationsAwait,
  sendNotificationEmailAwait
} from '../actions/notifications'
import { setAppBarState } from '../actions/ui'

const mapStateToProps = state => {
  return {
    isSuperAdmin: state.auth.userData.permission === 'superadmin',
    notifications: state.notifications.items,
    totalPages: state.notifications.totalPages,
    isFetching: state.notifications.isFetching,
    error: state.notifications.error
  }
}

const mapDispatchToProps = dispatch => ({
  setAppBarState: appBarState => {
    dispatch(setAppBarState(appBarState))
  },
  dismissPostError: () => {
    dispatch(dismissPostError())
  },
  dismissSendError: () => {
    dispatch(dismissSendError())
  },
  sendNotificationEmail: id => {
    dispatch(sendNotificationEmailAwait(id))
  },
  fetchNotifications: (offset, limit) => {
    dispatch(fetchNotificationsAwait(offset, limit))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
