import React from 'react'
import Chip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'

const summarize = query => {
  if (!query) return 'none'
  if (isEqual(query, {})) return chip('Full Course Access')
  if (Object.keys(query).join(',') === 'tag') {
    return query.tag.map(chip)
  }
  return '?'
}

const chip = name => <Chip className="tag__chip" key={name} label={name} />

const SearchQuerySummary = ({ query }) => (
  <span title={JSON.stringify(query, null, 4)}>{summarize(query)}</span>
)

SearchQuerySummary.propTypes = {
  query: PropTypes.shape({})
}

export default SearchQuerySummary
