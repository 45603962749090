import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import PartnerCourseReportingImportForm from './PartnerCourseReportingImportForm'

const PartnerCourseReportingImport = ({ errors, onSubmit }) => {
  return (
    <div>
      <Breadcrumbs
        links={[
          { title: 'Tools', href: '/tools' },
          {
            title: 'Partner Course Usage Reporting',
            href: '/tools/partner-course-usage-reporting'
          }
        ]}
      />
      <Card>
        <CardHeader className="title" title="Import Partner Reporting" />
        <CardContent className="description">
          <div>Expected CSV fields:</div>
          <div style={{ marginBottom: '20px' }}>
            <code>partner,lob,date,course,enrolments,lms</code>
          </div>
          <PartnerCourseReportingImportForm onSubmit={onSubmit} />

          {errors.length > 0 && (
            <div style={{ color: 'red' }}>
              <h2>Errors</h2>
              <ul>
                {errors.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

PartnerCourseReportingImport.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onAddLine: PropTypes.func.isRequired,
  onRemoveLine: PropTypes.func.isRequired,
  errors: PropTypes.array,
  addedLines: PropTypes.array.isRequired,
  partner: PropTypes.object.isRequired,
  lob: PropTypes.object.isRequired
}

export default PartnerCourseReportingImport
