import React from 'react'
import PropTypes from 'prop-types'
import OrganizationChannelForm from '../organization-channels-create/OrganizationChannelForm'
import OrganizationChannelClientForm from './OrganizationChannelClientForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const OrganizationChannelsEdit = ({
  isSuperAdmin,
  channel,
  reports,
  organization,
  channelVerifyQuery,
  onSubmit
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        }
      ]}
    />
    <Card>
      <CardHeader className="title" title={`Edit Channel: ${channel.name}`} />
      <CardContent className="description">
        {isSuperAdmin ? (
          <OrganizationChannelForm
            channel={channel}
            onSubmit={onSubmit}
            reports={reports}
            channelVerifyQuery={channelVerifyQuery}
            enableSimLicenses={true}
          />
        ) : (
          <OrganizationChannelClientForm
            channel={channel}
            onSubmit={onSubmit}
          />
        )}
      </CardContent>
    </Card>
  </div>
)

OrganizationChannelsEdit.propTypes = {
  isSuperAdmin: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onExchange: PropTypes.func.isRequired,
  channelVerifyQuery: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired
}

export default OrganizationChannelsEdit
