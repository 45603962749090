import React from 'react'
import { connect } from 'react-redux'
import TaasTeacherEnrolmentEdit from './TaasTeacherEnrolmentEdit'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import {
  fetchTeacher,
  updateTeacherEnrolment,
  fetchTeacherEnrolment
} from '../../actions/taas'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'
import PlusIcon from 'mdi-react/PlusIcon'

class TaasTeacherEnrolmentEditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, teacher: null, enrolment: null, error: null }
    this.props.setAppBarState({
      visible: true,
      title: 'Teacher Enrollments',
      rightIcon: <PlusIcon />,
      handleRight: () => {
        const { organization, channel, teacher } = props.match.params
        this.props.history.push(
          `/organizations/${organization}/channels/${channel}/taas/teachers/${teacher}/enrolments/create`
        )
      }
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, teacher: null })
      const [teacher, enrolment] = await Promise.all([
        this.props.fetchTeacher(),
        this.props.fetchEnrolment()
      ])
      this.setState({ loading: false, teacher, enrolment })
    } catch (e) {
      console.error(e.message)
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, teacher, enrolment } = this.state

    return (
      <Screen
        name="edit teacher enrolment"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel &&
            this.props.organization &&
            this.state.teacher &&
            this.state.enrolment
          }>
          <TaasTeacherEnrolmentEdit
            {...this.props}
            enrolment={enrolment}
            teacher={teacher}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchTeacher: () =>
    fetchTeacher(ownProps.match.params.channel, ownProps.match.params.teacher),
  fetchEnrolment: () =>
    fetchTeacherEnrolment(
      ownProps.match.params.channel,
      ownProps.match.params.teacher,
      ownProps.match.params.id
    ),
  onSubmit: data =>
    dispatch(
      updateTeacherEnrolment(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        ownProps.match.params.teacher,
        ownProps.match.params.id,
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasTeacherEnrolmentEditContainer)
