import React from 'react'
import PropTypes from 'prop-types'

class SimpleSearch extends React.Component {
  getResults() {
    if (!this.props.filter) {
      return this.props.documents
    }

    return this.props.documents.filter(row => {
      if (!this.props.filter) return true
      const index = this.props.fields.map(field => row[field]).join('\n')
      return index.toLowerCase().includes(this.props.filter.toLowerCase())
    })
  }

  render() {
    return this.props.children(this.getResults())
  }
}

SimpleSearch.defaultProps = {
  filter: ''
}

SimpleSearch.propTypes = {
  filter: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  fields: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.func
}

export default SimpleSearch
