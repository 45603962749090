import joi from 'joi'
import createForm from '../../components/form/createForm'
import FileCsvInput from '../../components/form/elements/FileCsvInput'

const csvSchema = joi.array().items(
  joi
    .object({
      code: joi.string().required(),
      alias: joi.string().required()
    })
    .min(1)
)

export default ({ onSubmit, ...props }) => {
  return createForm({
    fields: [
      {
        id: 'file',
        label: 'CSV File',
        inputComponent: FileCsvInput,
        validation: csvSchema,
        required: true
      }
    ],
    layoutOptions: {
      submitText: 'Submit Mappings'
    },
    onSubmit: async data => {
      return onSubmit(data.file)
    },
    ...props
  })
}
