import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import '../css/login.css'
import logo from '../assets/edl-logo.png'

class PasswordReset extends Component {
  static propTypes = {
    setAppBarState: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string
    }),
    sendPasswordReset: PropTypes.func,
    resetErrorMessage: PropTypes.string
  }

  state = {
    newPassword: '',
    confirmPassword: '',
    match: true,
    loginRedirect: false,
    isReset: false,
    errorMessage: 'Passwords must match',
    passwordMeetsRequirements: false,
    newPasswordError: null
  }

  constructor(props) {
    super(props)

    props.setAppBarState({
      visible: false
    })
  }

  handleInputChange = (name, value) => {
    this.setState({ [name]: value })
  }

  handleSubmit = async () => {
    if (!this.state.match || !this.state.passwordMeetsRequirements) return

    const [token, username] = this.props.location.search.split('&')
    const data = {
      token: token.substring(7),
      username: username.substring(9),
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword
    }
    const result = await this.props.sendPasswordReset(data)
    if (result.type === 'RESET_PASSWORD_FAILURE') return

    this.setState({
      isReset: true
    })
  }

  render() {
    const params = this.props.location.search.split('&')
    if (params.length < 2 || this.state.loginRedirect) {
      return <Redirect to={'/login'} />
    }

    const actions = [
      {
        label: 'Return to login',
        onClick: () => {
          this.setState({ loginRedirect: true })
        },
        style: { background: '#009BDF', color: 'white' }
      }
    ]

    return (
      <div className="passwordReset background">
        <div className="auth">
          <Dialog open={this.state.isReset}>
            <DialogTitle>Password updated successfully!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please login with your new credentials.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {actions.map((action, i) => (
                <Button key={i} {...action}>
                  {action.label}
                </Button>
              ))}
            </DialogActions>
          </Dialog>

          <Card className="title">
            <CardHeader title="Reset Password" />
          </Card>
          <Card
            className="reset"
            onKeyPress={e => {
              if (e.key === 'Enter') {
                this.handleSubmit()
              }
            }}>
            {this.props.resetErrorMessage && (
              <div className="error__text">{this.props.resetErrorMessage}</div>
            )}
            <TextField
              type="password"
              label="New Password"
              value={this.state.newPassword}
              onChange={event => {
                this.handleInputChange('newPassword', event.target.value)

                if (event.target.value.length < 8) {
                  this.handleInputChange('passwordMeetsRequirements', false)
                  this.handleInputChange(
                    'newPasswordError',
                    'Password must be at least 8 characters'
                  )
                  return
                }

                if (event.target.value.length > 64) {
                  this.handleInputChange('passwordMeetsRequirements', false)
                  this.handleInputChange(
                    'newPasswordError',
                    'Password cannot be longer than 64 characters'
                  )
                  return
                }

                if (
                  !/[^\u0030-\u0039\u0041-\u005A\u0061-\u007A]/.test(
                    event.target.value
                  )
                ) {
                  this.handleInputChange('passwordMeetsRequirements', false)
                  this.handleInputChange(
                    'newPasswordError',
                    'Password must contain at least one special character'
                  )
                  return
                }

                this.handleInputChange('passwordMeetsRequirements', true)
                this.handleInputChange('newPasswordError', null)
              }}
              error={!this.state.passwordMeetsRequirements}
              helperText={
                !this.state.passwordMeetsRequirements &&
                this.state.newPasswordError
              }
              fullWidth
              margin="normal"
            />
            <TextField
              type="password"
              label="Confirm Password"
              value={this.state.confirmPassword}
              onChange={event => {
                this.handleInputChange('confirmPassword', event.target.value)
                if (event.target.value !== this.state.newPassword) {
                  this.handleInputChange('match', false)
                  return
                }

                this.handleInputChange('match', true)
              }}
              error={!this.state.match}
              helperText={!this.state.match && this.state.errorMessage}
              fullWidth
              margin="normal"
            />
            <div className="footer">
              <Button
                className="back"
                onClick={() => {
                  this.setState({ loginRedirect: true })
                }}>
                Back
              </Button>
              <Button
                className="submit"
                onClick={this.handleSubmit}
                variant="raised"
                color="primary">
                Update Password
              </Button>
            </div>
          </Card>
          <div className="logo">
            <img src={logo} alt="edynamic logo" />
          </div>
        </div>
        <style>{`
          .passwordReset .auth {
            margin: 24px;
            float: right;
          }
          .passwordReset .reset {
            width: 500px;
            padding: 50px;
          }
          .passwordReset .title {
            color: white;
            background: #c66c17;
            height: 150px;
            padding: 0;
          }
          .passwordReset .title span {
            color: white;
          }
          .passwordReset .footer {
            display: flex;
            justify-content: space-between;
          }
          .passwordReset .back {
            text-align: left;
          }
          .passwordReset .submit {
            margin: 0 left;
          }
          .passwordReset .error__text {
            color: red;
            text-align: center;
            white-space: pre-wrap;
          }
          .passwordReset .logo {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: -1;
            text-align: right;
          }
          .passwordReset .logo img {
            margin-top: 50px;
            height: 200px;
          }
          @media only screen and (max-width: 550px), (max-height: 700px) {
            .passwordReset .auth {
              margin: 12px;
            }
            .passwordReset .reset {
              width: 350px;
              padding: 20px;
            }
            .passwordReset .title {
              height: 100px;
            }
            .passwordReset .logo {
              width: 375px;
            }
          }
        `}</style>
      </div>
    )
  }
}

export default PasswordReset
