import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'

export default ({ ...props }) =>
  createForm({
    fields: [
      {
        id: 'courseid',
        label: 'Course ID',
        inputComponent: TextInput,
        required: true,
        validation: joi.string()
      },
      {
        id: 'instance',
        label: 'Instance',
        inputComponent: SelectInput,
        inputOptions: {
          options: [
            { value: 'buzz', label: 'Buzz' },
            { value: 'buzz2', label: 'Buzz 2' }
          ]
        },
        required: true,
        validation: joi.string().allow('')
      }
    ],
    layoutOptions: {
      submitText: 'Merge'
    },
    ...props
  })
