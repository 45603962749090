import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FixedFooterDialog from '../components/FixedFooterDialog'
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog'
import Autocomplete from './Autocomplete'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import SearchQueryContainer from '../containers/ConnectSearchQueryContainer'
import styles from '../styles'

// TODO: Handle cases where the form data is valid but the server doesn't
// accept the notificaton for whatever reason

class CreateNotificationDialog extends Component {
  static propTypes = {
    notification: PropTypes.object,
    title: PropTypes.string,
    active: PropTypes.bool,
    toggleActive: PropTypes.func,
    history: PropTypes.object.isRequired,
    notificationActionInProgress: PropTypes.bool,
    isDeleting: PropTypes.bool,
    courseItems: PropTypes.array,
    postNotification: PropTypes.func,
    editNotification: PropTypes.func,
    fetchNotifications: PropTypes.func,
    deleteNotification: PropTypes.func
  }

  static defaultProps = {
    active: false
  }

  constructor(props) {
    super(props)

    const notification = Object.assign(
      {},
      {
        id: null,
        courses: [],
        title: '',
        message: '',
        notes: '',
        actionable: false,
        recipientsQuery: {},
        pinned: false,
        alwaysShowAll: false,
        emailRecipientsQuery: {}
      },
      props.notification
    )

    this.state = {
      courses: notification.courses,
      title: notification.title,
      message: notification.message,
      notes: notification.notes,
      isEditing: !!notification.id,
      recipientsQuery: notification.recipientsQuery,
      pinned: !!notification.pinned,
      actionable: !!notification.actionable,
      alwaysShowAll: !!notification.alwaysShowAll,
      emailRecipientsQuery: notification.emailRecipientsQuery,
      notificationDeleteRequested: false,

      inputErrors: {
        titleError: {
          isError: false,
          message: 'Please enter a title for the notification.'
        },
        messageError: {
          isError: false,
          message: 'Please enter body content for the notification.'
        },
        notesError: {
          isError: false,
          message: ''
        }
      }
    }

    this.initialState = { ...this.state }
  }

  handleAutocomplete = (value, field) => {
    this.setState(
      {
        [field]: value
      },
      () => {}
    )
  }

  handleTextChange = (value, field) => {
    this.setFieldError(field, value)
    this.setState({ [field]: value })
  }

  setFieldError = (fieldName, value) => {
    this.setState({
      inputErrors: {
        ...this.state.inputErrors,
        [fieldName + 'Error']: {
          isError:
            value !== undefined
              ? value.length === 0
              : this.state[fieldName].length === 0,
          message: this.state.inputErrors[fieldName + 'Error'].message
        }
      }
    })
  }

  setFieldErrorValues = async () => {
    await this.setFieldError('title')
    await this.setFieldError('message')
  }

  validFields = () => {
    return (
      !this.state.inputErrors.titleError.isError &&
      !this.state.inputErrors.messageError.isError
    )
  }

  handleSaveButtonClick = async () => {
    await this.setFieldErrorValues()
    if (!this.validFields()) return

    const notification = {
      courses: this.state.courses.map(course => course.value),
      title: this.state.title,
      message: this.state.message,
      notes: this.state.notes,
      pinned: this.state.pinned,
      alwaysShowAll: this.state.alwaysShowAll,
      recipientsQuery: this.state.recipientsQuery,
      emailRecipientsQuery: this.state.emailRecipientsQuery,
      actionable: this.state.actionable
    }

    if (!Object.keys(notification.recipientsQuery).length) {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(
          'This notification will be visible to everyone. Are you absolutely sure?'
        )
      ) {
        return
      }
    }

    if (this.props.title === 'Create Notification') {
      notification.date = new Date()
      await this.props.postNotification({
        ...notification,
        message: notification.message + this.getActionableCopy()
      })
    } else if (this.props.title === 'Edit Notification') {
      notification.id = this.props.notification.id
      await this.props.editNotification(notification)
    }

    this.props.fetchNotifications()
    this.props.toggleActive()
    this.setState(this.initialState)
  }

  handleCancelButtonClick = () => {
    this.props.toggleActive()
    this.setState(this.initialState)
  }

  handleNotificationDeletion = async () => {
    const response = await this.props.deleteNotification(
      this.props.notification.id
    )
    if (response.type === 'DELETE_NOTIFICATION_FAILURE') {
      this.props.toggleActive()
      this.setState(this.onCloseResetInitialState)
    }

    await this.props.fetchNotifications()
    this.props.toggleActive()
    this.setState(this.initialState)
    this.props.history.push('/notifications')
  }

  cancelNotificationDelete = () => {
    this.setState({ notificationDeleteRequested: false })
  }

  handleDeleteButtonClick = () => {
    this.setState({ notificationDeleteRequested: true })
  }

  handleQueryChange = query => {
    this.setState({
      recipientsQuery: query
    })
  }

  handleEmailQueryChange = query => {
    this.setState({
      emailRecipientsQuery: query
    })
  }

  handlePinnedChange = () => {
    this.setState({
      pinned: !this.state.pinned
    })
  }

  handleAlwaysShowAllChange = () => {
    this.setState({
      alwaysShowAll: !this.state.alwaysShowAll
    })
  }

  handleActionableChange = () => {
    this.setState({
      actionable: !this.state.actionable
    })
  }

  getActionableCopy() {
    if (this.state.actionable) {
      return `

---

**Please Note:** This update will require either a new package or manual change within your course. If you are using the Buzz LMS with courses directly connected to our master course, this change will automatically update, so no action is needed.`
    } else {
      return `

  ---

  **Please Note:** This update should occur automatically for all LTI connected courses. If your course does not receive the update, please reach out to support@edynamiclearning.com.`
    }
  }

  render() {
    const actions = [
      {
        label: 'Cancel',
        onClick: this.handleCancelButtonClick,
        style: !this.props.notificationActionInProgress
          ? styles.buttons
          : styles.buttons_disabled,
        disabled: this.props.notificationActionInProgress
      },
      {
        label: 'Save',
        onClick: this.handleSaveButtonClick,
        disabled:
          !this.validFields() || this.props.notificationActionInProgress,
        variant: 'raised',
        style:
          this.validFields() && !this.props.notificationActionInProgress
            ? styles.buttons
            : styles.buttons_disabled
      }
    ]

    if (this.state.isEditing) {
      actions.unshift({
        label: 'Delete Notification',
        onClick: this.handleDeleteButtonClick,
        style: styles.buttons_red
      })
    }

    return (
      <div>
        <ConfirmDeleteDialog
          title="Are you sure you want to delete this notification?"
          active={this.state.notificationDeleteRequested}
          disabled={this.props.isDeleting}
          onCancel={this.cancelNotificationDelete}
          onConfirm={this.handleNotificationDeletion}
        />
        <FixedFooterDialog
          actions={actions}
          active={this.props.active}
          onEscKeyDown={this.props.toggleActive}
          onOverlayClick={this.props.toggleActive}
          title={this.props.title}
          className="createNotificationsDialog">
          <div>
            <div className="recipients-query">
              <FormLabel>Notification Recipients</FormLabel>
              <SearchQueryContainer
                query={this.state.recipientsQuery}
                onChange={this.handleQueryChange}
                previewTypes={['users']}
              />
            </div>
            <div className="recipients-query email">
              <FormLabel>Email Recipients</FormLabel>
              <p>
                This filter is <em>in addition to</em> the above query.
              </p>
              <SearchQueryContainer
                query={this.state.emailRecipientsQuery}
                onChange={this.handleEmailQueryChange}
                previewTypes={['users']}
                previewTransform={query => ({
                  $and: [this.state.recipientsQuery, query]
                })}
              />
            </div>
            <TextField
              label="Title"
              inputProps={{ 'data-testid': 'createNotificationTitleInput' }}
              required={true}
              error={this.state.inputErrors.titleError.isError}
              name="title"
              value={this.state.title}
              onChange={e => this.handleTextChange(e.target.value, 'title')}
              onBlur={() => this.setFieldError('title')}
              fullWidth
              margin="normal"
              helperText={
                this.state.inputErrors.titleError.isError &&
                this.state.inputErrors.titleError.message
              }
            />
            <TextField
              rows={10}
              inputProps={{ 'data-testid': 'createNotificationBodyInput' }}
              multiline
              label="Body"
              required={true}
              error={this.state.inputErrors.messageError.isError}
              value={this.state.message}
              onChange={e => this.handleTextChange(e.target.value, 'message')}
              onBlur={() => this.setFieldError('message')}
              fullWidth
              margin="normal"
              helperText={
                this.state.inputErrors.messageError.isError &&
                this.state.inputErrors.messageError.message
              }
            />
            <div className="pinned">
              <FormLabel>Pinned</FormLabel>
              <Checkbox
                onChange={this.handlePinnedChange}
                checked={this.state.pinned}
              />
            </div>
            <div className="pinned">
              <FormLabel>Always Show All (prevent truncate)</FormLabel>
              <Checkbox
                onChange={this.handleAlwaysShowAllChange}
                checked={this.state.alwaysShowAll}
              />
            </div>
            <TextField
              rows={10}
              multiline
              label="Notes"
              value={this.state.notes}
              onChange={e => this.handleTextChange(e.target.value, 'notes')}
              fullWidth
              margin="normal"
            />
            <Autocomplete
              onChange={value =>
                this.handleAutocomplete(
                  value,
                  'courses',
                  this.props.courseItems
                )
              }
              label="Attach Courses"
              options={this.props.courseItems}
              value={this.state.courses}
              isMulti
            />
            <div>
              <FormLabel>Actionable</FormLabel>
              <Checkbox
                onChange={this.handleActionableChange}
                checked={this.state.actionable}
              />
            </div>
          </div>
        </FixedFooterDialog>
        <style>{`
          @media only screen and (max-width: 665px) {
            .fixedFooterDialog {
              width: 96vw;
            }
          }
          .recipients-query {
            margin-top: 20px;
          }
        `}</style>
      </div>
    )
  }
}

export default CreateNotificationDialog
