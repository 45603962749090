import axios from 'axios'
import { push } from 'connected-react-router'
import { getToken } from '../auth'
import { awaitUserData } from './auth'

export function createUserAwait(newUser) {
  return function(dispatch) {
    if (!getToken()) return dispatch(addUserFail())

    dispatch(addingUser())
    return postUser(newUser).then(
      result => {
        dispatch(addUserSuccess())
        return result
      },
      err => {
        dispatch(addUserFail(err))
      }
    )
  }
}

export function createUser(details) {
  return async function(dispatch) {
    await postUser(details)
    dispatch(push(`/organizations/${details.organization}/users`))
  }
}

async function postUser(newUser) {
  const result = await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/users',
    data: newUser,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result
}

function addingUser() {
  const payload = 'Adding user'
  return { type: 'ADD_USER', payload }
}

function addUserSuccess() {
  const payload = 'Added user successfully'
  return { type: 'ADD_USER_SUCCESS', payload }
}

function addUserFail(err) {
  const payload =
    err.response.status === 400
      ? err.response.data.message
      : 'Failed to add user'
  return { type: 'ADD_USER_FAILURE', payload }
}

export function dismissAddError() {
  const payload = null
  return { type: 'ADD_USER_DISMISS', payload }
}

export function fetchUsersAwait() {
  return function(dispatch) {
    if (!getToken()) return dispatch(fetchUsersFailure())

    dispatch(fetchingUsers())
    return fetchUsers().then(
      result => dispatch(fetchUsersSuccess(result)),
      err => dispatch(fetchUsersFailure(err))
    )
  }
}

async function fetchUsers() {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/users/all',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export async function fetchOrgUsers(orgId) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/users/all',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  // TODO replace with endpoint that pre-filters
  return result.data.filter(user => user.organization === orgId)
}

export async function fetchOrgUser(orgId, userId) {
  const users = await fetchOrgUsers(orgId)
  // TODO replace with endpoint that pre-filters
  return users.find(row => row.id === userId) || null
}

function fetchingUsers() {
  const payload = 'Loading users'
  return { type: 'FETCH_USERS', payload }
}

function fetchUsersSuccess(users) {
  const payload = users
  return { type: 'FETCH_USERS_SUCCESS', payload }
}

function fetchUsersFailure() {
  const payload = 'Failed to load users'
  return { type: 'FETCH_USERS_FAILURE', payload }
}

export function editUser(user) {
  return async function(dispatch) {
    await putUser(user)
    dispatch(push(`/organizations/${user.organization}/users`))
  }
}

async function putUser(newUser) {
  const result = await axios({
    method: 'PUT',
    url: process.env.REACT_APP_REMOTE + `/users/${newUser.id}`,
    data: newUser,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result
}

export function deleteUserAwait(user) {
  return async dispatch => {
    if (!confirm('Are you sure you want to delete this user?')) {
      return
    }

    await deleteUser(user.id)
    dispatch(push(`/organizations/${user.organization}/users`))
  }
}

async function deleteUser(id) {
  const response = await axios({
    method: 'DELETE',
    url: process.env.REACT_APP_REMOTE + '/users/' + id,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return response
}

export function suggestUsersAwait(query) {
  return suggestUsers(query)
}

export async function suggestUsers(query) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/users/suggest',
    params: { query },
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export function updateSettings(settings) {
  return async function(dispatch) {
    await axios({
      method: 'PUT',
      data: settings,
      url: process.env.REACT_APP_REMOTE + `/users/settings`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    alert('Done!')
    return dispatch(awaitUserData())
  }
}
