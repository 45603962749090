import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasStudentEnrolmentForm from '../taas-student-enrolment-create/TaasStudentEnrolmentForm'

const TaasStudentEnrolmentEdit = ({
  channel,
  organization,
  student,
  courses,
  enrolment,
  onSubmit
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Students',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
        },
        {
          title: `${student.firstname} ${student.lastname}`,
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students/${student.id}`
        },
        {
          title: 'Enrollments',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students/${student.id}/enrolments`
        }
      ]}
    />
    <Card className="students">
      <CardHeader className="title" title={`${enrolment.course.title}`} />
      <CardContent className="description">
        <TaasStudentEnrolmentForm
          courses={courses}
          enrolment={enrolment}
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
  </div>
)

TaasStudentEnrolmentEdit.propTypes = {
  student: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  enrolment: PropTypes.object.isRequired,
  courses: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default TaasStudentEnrolmentEdit
