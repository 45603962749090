import { withStyles } from '@material-ui/core'
import React from 'react'

const styles = {
  error: {
    color: 'red'
  }
}

export const ErrorSpan = withStyles(styles)(({ classes, message }) => (
  <span className={classes.error}>{message}</span>
))
