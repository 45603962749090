import React from 'react'
import { connect } from 'react-redux'
import Tools from './Tools'
import Screen from '../../components/Screen'
import { setAppBarState } from '../../actions/ui'

class ToolsContainer extends React.Component {
  componentDidMount() {
    this.props.setAppBarState({
      visible: true,
      title: 'Tools',
      rightIcon: null,
      handleRight: () => {}
    })
  }

  render() {
    const { isLoading, error: propError, user } = this.props
    return (
      <Screen name="tools" isLoading={isLoading} error={propError}>
        <Tools user={user} />
      </Screen>
    )
  }
}

const mapStateToProps = state => ({
  isLoading: !state.auth.userData.id,
  user: state.auth.userData
})

const mapDispatchToProps = dispatch => {
  return {
    setAppBarState: appBarState => {
      dispatch(setAppBarState(appBarState))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolsContainer)
