const initial = {
  isFetching: false,
  isCreating: false,
  isEditing: false,
  items: [],
  error: {
    fetch: null,
    create: null,
    update: null
  },
  changing: [],
  isUpdatingBuzzLti: false,
  buzzLtiRegistrations: {
    buzz: false,
    buzz2: false
  }
}

function channels(state = initial, action) {
  switch (action.type) {
    case 'CHANNELS_FETCH_START':
      return {
        ...state,
        items: [],
        changing: [],
        isFetching: true,
        error: {
          ...state.error,
          fetch: null
        }
      }

    case 'CHANNELS_FETCH_SUCCESS':
      return {
        ...state,
        items: action.payload,
        isFetching: false
      }

    case 'CHANNELS_FETCH_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: {
          ...state.error,
          fetch: action.payload
        }
      }

    case 'CHANNEL_USERS_ADD_START':
      return {
        ...state,
        changing: state.changing.concat([action.payload.user])
      }

    case 'CHANNEL_USERS_ADD_SUCCESS':
      return {
        ...state,
        changing: state.changing.filter(id => id !== action.payload.user),
        items: state.items.map(channel => {
          if (channel.id === action.payload.channel) {
            return {
              ...channel,
              users: channel.users.concat([action.payload.user])
            }
          }
          return channel
        })
      }

    case 'CHANNEL_USERS_ADD_FAILURE':
      return {
        ...state,
        changing: state.changing.filter(id => id !== action.payload.user)
      }

    case 'CHANNEL_USERS_REMOVE_START':
      return {
        ...state,
        changing: state.changing.concat([action.payload.user])
      }

    case 'CHANNEL_USERS_REMOVE_SUCCESS':
      return {
        ...state,
        changing: state.changing.filter(id => id !== action.payload.user),
        items: state.items.map(channel => {
          if (channel.id === action.payload.channel) {
            return {
              ...channel,
              users: channel.users.filter(id => id !== action.payload.user)
            }
          }
          return channel
        })
      }

    case 'CHANNEL_USERS_REMOVE_FAILURE':
      return {
        ...state,
        changing: state.changing.filter(id => id !== action.payload.user)
      }

    case 'CHANNELS_UPDATE_BUZZ_LTI_REQUEST':
      return {
        ...state,
        isUpdatingBuzzLti: true
      }

    case 'CHANNELS_UPDATE_BUZZ_LTI_SUCCESS':
      return {
        ...state,
        isUpdatingBuzzLti: false,
        buzzLtiRegistrations: {
          ...state.buzzLtiRegistrations,
          [action.buzzInstance]: action.isRegistered
        }
      }

    case 'CHANNELS_UPDATE_BUZZ_LTI_FAILURE':
      return {
        ...state,
        isUpdatingBuzzLti: false,
        buzzLtiRegistrations: {
          ...state.buzzLtiRegistrations,
          [action.buzzInstance]: false
        }
      }

    case 'CHANNELS_CHECK_BUZZ_LTI_REQUEST':
      return {
        ...state,
        isUpdatingBuzzLti: true
      }

    case 'CHANNELS_CHECK_BUZZ_LTI_SUCCESS':
      return {
        ...state,
        isUpdatingBuzzLti: false,
        buzzLtiRegistrations: {
          ...state.buzzLtiRegistrations,
          [action.buzzInstance]: action.isRegistered
        }
      }

    case 'CHANNELS_CHECK_BUZZ_LTI_FAILURE':
      return {
        ...state,
        isUpdatingBuzzLti: false,
        buzzLtiRegistrations: {
          ...state.buzzLtiRegistrations,
          [action.buzzInstance]: false
        }
      }

    default:
      return state
  }
}

export default channels
