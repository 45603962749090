import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Card from '@material-ui/core/Card'
import Breadcrumbs from '../../components/Breadcrumbs'
import OrganizationTile from '../../components/OrganizationTile'
import Screen from '../../components/Screen'
import GateExists from '../../components/GateExists'
import { isObserver, isSuperAdmin } from '../../selectors/auth'
import { setAppBarState } from '../../actions/ui'
import { useOrganization } from '../../hooks/useOrganization'

const OrganizationView = ({
  isSuperAdmin,
  isObserver,
  organizationId,
  onClickEdit,
  onClickUsers,
  onClickChannels,
  setAppBarState,
  isFetching,
  error
}) => {
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )

  useEffect(() => {
    setAppBarState({
      visible: true,
      title: 'Organizations',
      canAdd: false
    })
  }, [])

  return (
    <Screen
      name="organization view"
      isLoading={isFetching || isFetchingOrg}
      error={error || orgError}>
      <GateExists exists={organization}>
        <div>
          <Breadcrumbs
            links={[{ title: 'Organizations', href: '/organizations' }]}
          />
          <Card className="organizations">
            <OrganizationTile
              organization={organization}
              user={null}
              isSuperAdmin={isSuperAdmin}
              isObserver={isObserver}
              handleEditOrgButton={organization => onClickEdit(organization)}
              handleUsersButton={() => onClickUsers(organization)}
              handleChannelsButton={() => onClickChannels(organization)}
            />
          </Card>
        </div>
      </GateExists>
    </Screen>
  )
}

OrganizationView.propTypes = {
  isSuperAdmin: PropTypes.bool.isRequired,
  isObserver: PropTypes.bool.isRequired,
  organizationId: PropTypes.string.isRequired,
  onClickEdit: PropTypes.func,
  onClickUsers: PropTypes.func,
  onClickChannels: PropTypes.func,
  setAppBarState: PropTypes.func,
  isFetching: PropTypes.bool,
  error: PropTypes.object
}

const mapStateToProps = (state, ownProps) => ({
  isFetching: state.organizations.isFetching,
  isSuperAdmin: isSuperAdmin(state),
  isObserver: isObserver(state),
  organizationId: ownProps.match.params.id
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => {
    dispatch(setAppBarState(appBarState))
  },
  onClickEdit: organization => {
    ownProps.history.push(`/organizations/${organization.id}/edit`)
  },
  onClickUsers: organization => {
    ownProps.history.push(`/organizations/${organization.id}/users`)
  },
  onClickChannels: organization => {
    ownProps.history.push(`/organizations/${organization.id}/channels`)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationView)
