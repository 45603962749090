import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

class SearchQueryPreview extends React.Component {
  handlePreview = () => {
    localStorage.setItem('preview', JSON.stringify(this.props.query))
    return true
  }

  getPreviews() {
    const { previewTypes } = this.props
    return previewTypes.map(type => {
      const url = `/search-preview/${type}`
      return (
        <span key={type}>
          <Link onClick={this.handlePreview} target="_blank" to={url}>
            Preview {type[0].toUpperCase()}
            {type.substr(1)}
          </Link>
        </span>
      )
    })
  }

  render() {
    const { className } = this.props
    return <div className={className}>{this.getPreviews()}</div>
  }
}

SearchQueryPreview.propTypes = {
  query: PropTypes.shape({}).isRequired,
  previewTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string.isRequired
}

const StyledSearchQueryPreview = styled(SearchQueryPreview)`
  span {
    margin-right: 10px;
    display: inline-block;
    color: #ccc;
  }
  a {
    color: #aaa;
    &:visited {
      color: #aaa;
    }
    &:hover {
      color: #000;
    }
  }
`
export default StyledSearchQueryPreview
