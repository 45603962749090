import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import OrganizationChannelsEdit from './OrganizationChannelsEdit'
import Screen from '../../components/Screen'
import { channelsFetchAwait, channelsUpdateAwait } from '../../actions/channels'
import {
  channelVerifyQuery,
  channelGetConfigurableReports
} from '../../requests/channels'
import { getChannel } from '../../selectors'
import { isSuperAdmin } from '../../selectors/auth'
import GateExists from '../../components/GateExists'
import { useConfigurableReports } from '../../hooks/useConfigurableReports'
import { useOrganization } from '../../hooks/useOrganization'

const OrganizationChannelsEditContainer = props => {
  const {
    fetchChannels,
    onSubmit,
    isLoading,
    error,
    channel,
    organizationId
  } = props
  const [submitError, setSubmitError] = useState(null)
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )
  const {
    configReports,
    isFetchingConfigReports,
    configReportsError
  } = useConfigurableReports()

  const handleSubmit = async data => {
    try {
      await onSubmit(data)
    } catch (err) {
      setSubmitError(err)
    }
  }

  useEffect(() => {
    fetchChannels()
  }, [])

  return (
    <Screen
      name="organization edit channel"
      isLoading={isLoading || isFetchingConfigReports || isFetchingOrg}
      error={error || configReportsError || orgError}>
      <GateExists
        exists={channel && organization}
        renderContent={() => (
          <OrganizationChannelsEdit
            channel={channel}
            organization={organization}
            onSubmit={handleSubmit}
            reports={configReports}
            error={submitError}
            {...props}
          />
        )}
      />
    </Screen>
  )
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: !state.auth.userData.id || state.channels.isFetching,
  isSuperAdmin: isSuperAdmin(state),
  organizationId: ownProps.match.params.organization,
  channel: getChannel(ownProps.match.params.id)(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  },
  onSubmit: data =>
    dispatch(
      channelsUpdateAwait(
        ownProps.match.params.organization,
        ownProps.match.params.id,
        data
      )
    ),
  fetchConfigurableReports: () => channelGetConfigurableReports(),
  channelVerifyQuery: channelVerifyQuery
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationChannelsEditContainer)
