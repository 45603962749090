import { withRouter } from 'react-router-dom'
import {
  editNotificationAwait,
  fetchNotificationsAwait,
  postNotificationAwait,
  deleteNotificationAwait
} from '../actions/notifications'
import { connect } from 'react-redux'
import CreateNotificationDialog from '../components/CreateNotificationDialog'

function mapStateToProps(state, ownProps) {
  const courseItems = state.courses.items
    .filter(course => course.published === true)
    .map(course => ({
      value: course.id,
      label: course.title
    }))

  return {
    courseItems,
    notificationActionInProgress:
      state.notifications.isPosting || state.notifications.isEditing,
    isDeleting: state.notifications.isDeleting,
    notification: ownProps.notification && {
      ...ownProps.notification,
      // Map course objects to autocomplete objects, similar to above
      courses: ownProps.notification.courses.map(course => ({
        label: course.title,
        value: course.id
      }))
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    postNotification: notification => {
      return dispatch(postNotificationAwait(notification))
    },
    editNotification: notification => {
      return dispatch(editNotificationAwait(notification))
    },
    fetchNotifications: () => {
      return dispatch(fetchNotificationsAwait())
    },
    deleteNotification: notificationId => {
      return dispatch(deleteNotificationAwait(notificationId))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateNotificationDialog)
)
