import React from 'react'
import PropTypes from 'prop-types'
import TaasImportEnrolmentsForm from './TaasImportEnrolmentsForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const TaasImportEnrolments = ({ organization, channel, onSubmit }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Dashboard',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
        }
      ]}
    />
    <Card>
      <CardHeader className="title" title="Import Enrollments" />
      <CardContent className="description">
        <div>Expected CSV fields:</div>
        <div style={{ marginBottom: '20px' }}>
          <code>username,courseid,startdate,enddate,reference</code>
          <p>
            Tip: you can get the course IDs from the create enrollment page.
          </p>
        </div>
        <TaasImportEnrolmentsForm onSubmit={onSubmit} />
      </CardContent>
    </Card>
  </div>
)

TaasImportEnrolments.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired
}

export default TaasImportEnrolments
