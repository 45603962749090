import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledList = styled.ul`
  margin: 0 0 20px 0;
  padding: 0;
`

const StyledListItem = styled.li`
  display: inline-block
  margin-right: 0.5em
  a {
    color: inherit;
  }
`

const Breadcrumbs = ({ links, max }) => {
  const useLinks = links.slice(-max)

  return (
    <div>
      <StyledList>
        {useLinks.length < links.length && (
          <StyledListItem>.../</StyledListItem>
        )}
        {useLinks.map(({ title, href }, i) => {
          const last = i + 1 === useLinks.length
          return (
            <React.Fragment key={href}>
              <StyledListItem key={title}>
                <Link to={href}>{title}</Link>
              </StyledListItem>
              {!last && <StyledListItem>/</StyledListItem>}
            </React.Fragment>
          )
        })}
      </StyledList>
    </div>
  )
}

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    })
  ),
  max: PropTypes.number
}

Breadcrumbs.defaultProps = {
  max: 5
}

export default Breadcrumbs
