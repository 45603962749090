const initial = {
  isFetching: false,
  iaAdding: false,
  isDeleting: false,
  isGettingS3GetUrl: false,
  error: {
    fetch: null,
    upload: null,
    delete: null
  },
  items: []
}

function notificationFiles(state = initial, action) {
  switch (action.type) {
    case 'NOTIFICATION_FILES_FETCH': {
      const isFetching = true
      const items = []
      const error = { ...state.error, fetch: null }
      return { ...state, isFetching, items, error }
    }
    case 'NOTIFICATION_FILES_FETCH_SUCCESS': {
      const isFetching = false
      const items = action.payload
      return { ...state, isFetching, items }
    }
    case 'NOTIFICATION_FILES_FETCH_FAILURE': {
      const isFetching = false
      const error = { ...state.error, fetch: action.payload }
      return { ...state, isFetching, error }
    }
    case 'NOTIFICATION_FILES_ADD': {
      const isAdding = true
      const stateChange = { isAdding }
      return Object.assign({}, state, stateChange)
    }
    case 'NOTIFICATION_FILES_ADD_SUCCESS': {
      const isAdding = false
      const error = Object.assign({}, state.error, { add: null })
      const stateChange = { isAdding, error }
      return Object.assign({}, state, stateChange)
    }
    case 'NOTIFICATION_FILES_ADD_FAILURE': {
      const isAdding = false
      const error = Object.assign({}, state.error, { add: action.payload })
      const stateChange = { isAdding, error }
      return Object.assign({}, state, stateChange)
    }
    case 'NOTIFICATION_FILES_S3GETURL_REQUEST': {
      const isGettingS3GetUrl = true
      const stateChange = { isGettingS3GetUrl }
      return Object.assign({}, state, stateChange)
    }
    case 'NOTIFICATION_FILES_S3GETURL_SUCCESS': {
      const isGettingS3GetUrl = false
      const error = Object.assign({}, state.error, { s3GetUrl: null })
      const stateChange = { isGettingS3GetUrl, error }
      return Object.assign({}, state, stateChange)
    }
    case 'NOTIFICATION_FILES_S3GETURL_FAILURE': {
      const isGettingS3GetUrl = false
      const error = Object.assign({}, state.error, { s3GetUrl: action.payload })
      const stateChange = { isGettingS3GetUrl, error }
      return Object.assign({}, state, stateChange)
    }
    case 'NOTIFICATION_FILES_DELETE_SUCCESS': {
      const items = state.items.filter(row => row.id !== action.payload)
      return {
        ...state,
        items
      }
    }
    case 'NOTIFICATION_FILES_DISMISS_FAILURE': {
      return {
        ...state,
        error: {
          ...state.error,
          add: null,
          upload: null
        }
      }
    }
    default: {
      return state
    }
  }
}

export default notificationFiles
