import axios from 'axios'
import { getToken } from '../auth'

export function getS3GetUrlAwait(id) {
  return function(dispatch) {
    if (!getToken()) return dispatch(getS3GetUrlFailure())

    dispatch(getS3GetUrlRequest())
    return getSignedS3GetUrl(id).then(
      result => {
        dispatch(getS3GetUrlSuccess(id, result))
        return result
      },
      err => dispatch(getS3GetUrlFailure(err))
    )
  }
}

async function getSignedS3GetUrl(id) {
  const sig = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/files/${id}/signedS3GetUrl`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return sig.data.url
}

function getS3GetUrlRequest() {
  const payload = 'Getting signed S3 GET url'
  return { type: 'S3GETURL_REQUEST', payload }
}

function getS3GetUrlSuccess(id) {
  const payload = id
  return { type: 'S3GETURL_SUCCESS', payload }
}

function getS3GetUrlFailure() {
  const payload = 'There was an error getting the signed S3 GET url'
  return { type: 'S3GETURL_FAILURE', payload }
}

export function fetchFilesAwait(courseId) {
  return function(dispatch) {
    if (!getToken()) return dispatch(fetchFilesFailure())

    dispatch(fetchingFiles())
    return fetchFiles(courseId).then(
      result => dispatch(fetchFilesSuccess(result)),
      err => dispatch(fetchFilesFailure(err))
    )
  }
}

export async function fetchFiles(courseId) {
  const results = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/files/byCourse/${courseId}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return results.data
}

function fetchingFiles() {
  const payload = 'Loading files'
  return { type: 'FETCH_FILES', payload }
}

export function fetchFilesSuccess(files) {
  const payload = files
  return { type: 'FETCH_FILES_SUCCESS', payload }
}

function fetchFilesFailure() {
  const payload = 'There was an error loading files'
  return { type: 'FETCH_FILES_FAILURE', payload }
}

export function createFileAwait(params, file) {
  return function(dispatch) {
    if (!getToken()) return dispatch(addFileFailure())

    dispatch(addingFile())
    return addFile(params, file).then(
      result => {
        dispatch(addFileSuccess(result))
        return result
      },
      err => dispatch(addFileFailure(err))
    )
  }
}

async function getSignedS3PutUrl(params) {
  const sig = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/files/signedS3PutUrl',
    params,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return sig
}

async function addFile(params, file) {
  const sig = await getSignedS3PutUrl(params)
  const { url, filename, contentType, contentDisposition } = sig.data

  const headers = {
    'Content-Type': contentType,
    'Content-Disposition': contentDisposition
  }

  if (window.location.hostname.indexOf('.test') !== -1) {
    // TODO get uploads working locally without this
    delete headers['Content-Disposition']
  }

  await axios.put(url, file, { headers })

  delete params.ext
  const newFile = Object.assign({}, params, { filename, date: new Date() })

  const res = await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/files',
    data: newFile,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return res
}

function addingFile() {
  const payload = 'Adding files'
  return { type: 'ADD_FILE', payload }
}

function addFileSuccess() {
  const payload = 'Added files successfully'
  return { type: 'ADD_FILE_SUCCESS', payload }
}

function addFileFailure() {
  const payload = 'Failed to add files'
  return { type: 'ADD_FILE_FAILURE', payload }
}

export function deleteFileAwait(fileId) {
  return async dispatch => {
    dispatch(deleteFileRequest())

    try {
      const result = await deleteFile(fileId)
      dispatch(deleteFileSuccess())
      return result
    } catch (e) {
      return dispatch(deleteFileFailure(e))
    }
  }
}

async function deleteFile(fileId) {
  const response = await axios({
    method: 'DELETE',
    url: process.env.REACT_APP_REMOTE + '/files/' + fileId,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return response
}

function deleteFileRequest() {
  const payload = 'Deleting file'
  return { type: 'DELETE_FILE', payload }
}

function deleteFileSuccess() {
  const payload = 'Deleted file successfully'
  return { type: 'DELETE_FILE_SUCCESS', payload }
}

function deleteFileFailure() {
  const payload = 'There was an error deleting the file'
  return { type: 'DELETE_FILE_FAILURE', payload }
}

export function dismissDeleteError() {
  const payload = null
  return { type: 'DELETE_FILE_DISMISS', payload }
}
