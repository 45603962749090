import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'

export default ({ user = { name: {} }, roles, onSubmit, ...props }) =>
  createForm({
    fields: [
      {
        id: 'firstname',
        label: 'First Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string().required(),
        defaultValue: user.name.first
      },
      {
        id: 'lastname',
        label: 'Last Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string().required(),
        defaultValue: user.name.last
      },
      {
        id: 'email',
        label: 'Email Address',
        inputComponent: TextInput,
        required: true,
        validation: joi
          .string()
          .required()
          .email({ tlds: { allow: false } }),
        defaultValue: user.email
      },
      {
        id: 'permission',
        label: 'Permission',
        inputComponent: SelectInput,
        inputOptions: {
          options: roles.map(role => ({
            value: role.id,
            label: role.label
          }))
        },
        required: true,
        validation: joi.string().required(),
        defaultValue: user.permission || 'observer'
      }
    ],
    layoutOptions: {
      submitText: user.id ? 'Update Admin' : 'Create Admin'
    },
    onSubmit: async data => {
      return onSubmit(data)
    },
    ...props
  })
