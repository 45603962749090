import React from 'react'
import { connect } from 'react-redux'
import TaasCourseList from './TaasCourseList'
import Screen from '../../../components/Screen'
import { isSuperAdmin } from '../../../selectors/auth'
import { fetchCourses } from '../../../actions/taas'
import { channelsFetchAwait } from '../../../actions/channels'
import { setAppBarState } from '../../../actions/ui'
import { getOrganization, getChannel } from '../../../selectors'
import { taasCourseLink } from '../../../links'
import GateExists from '../../../components/GateExists'

class TaasCourseListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, courses: [], error: null }
    this.props.setAppBarState({
      visible: true,
      title: 'Courses',
      rightIcon: null,
      canAdd: false,
      handleRight: () => {}
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.channel && !prevProps.channel) {
      this.props.setAppBarState({
        visible: true,
        title: `${this.props.channel.name} Courses`,
        rightIcon: null,
        canAdd: false
      })
    }
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, courses: [] })
      const courses = await this.props.fetchCourses()
      this.setState({ loading: false, courses })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, courses } = this.state

    return (
      <Screen name="courses" isLoading={isLoading || loading} error={propError}>
        <GateExists exists={this.props.channel && this.props.organization}>
          <TaasCourseList
            {...this.props}
            courses={courses}
            error={stateError}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  onCourseEnrolments: course =>
    ownProps.history.push(taasCourseLink(course, `/enrolments`)),
  fetchCourses: () => fetchCourses(ownProps.match.params.channel)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasCourseListContainer)
