import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

class Selection extends React.Component {
  handleChange = ({ target: { value } }) => {
    const { selected, actions } = this.props
    const action = actions.find(({ id }) => id === value)
    if (action) {
      action.onSelect(selected)
    }
  }

  render() {
    const { selected, onSelectAll, onClear, actions } = this.props

    return (
      <div style={{ float: 'right', marginBottom: '20px' }}>
        <InputLabel htmlFor="with-selected">
          With Selected ({selected.length}){' '}
        </InputLabel>
        <Select
          disabled={!selected.length}
          value=""
          displayEmpty
          onChange={this.handleChange}
          inputProps={{ id: 'with-selected' }}>
          <MenuItem value="" disabled>
            pick action
          </MenuItem>
          {actions.map(({ id, label }) => (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <Button onClick={onSelectAll}>Select All</Button>
        <Button onClick={onClear}>Select None</Button>
      </div>
    )
  }
}

Selection.defaultProps = {
  selected: [],
  actions: []
}

Selection.propTypes = {
  selected: PropTypes.array,
  onClear: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onSelect: PropTypes.func.isRequired
    })
  )
}

export default Selection
