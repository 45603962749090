import { get, set, unset, cloneDeep } from 'lodash'

/*
 * These are mostly thin wrappers around lodash that are immutable
 */

export const deepGet = (obj, key) => {
  if (key) return get(obj, key)
  return obj
}

export const deepSet = (obj, key, val) => {
  const newValue = (() => {
    if (typeof val === 'function') {
      return val(deepGet(obj, key))
    }
    return val
  })()

  if (key) {
    const out = cloneDeep(obj)
    set(out, key, newValue)
    return out
  }

  return {
    ...obj,
    ...newValue
  }
}

export const deepUnset = (obj, key) => {
  const out = cloneDeep(obj)

  const arrayKeyRe = /\[(\d+)\]$/

  // unset destroys arrays so we deal with this separately here
  if (arrayKeyRe.test(key)) {
    const arrayKey = key.replace(arrayKeyRe, '')
    const updated = deepGet(out, arrayKey).splice(
      parseInt(key.match(arrayKeyRe)[1], 10),
      1
    )
    deepSet(out, arrayKey, updated)
    return out
  }

  unset(out, key)
  return out
}

export const up = key => {
  const parts = key
    .split(/(\.)|(\[|\])/)
    .filter(n => n !== undefined && n !== '')
  if (parts[parts.length - 1] === ']') {
    return parts.slice(0, -3).join('')
  }

  return parts.slice(0, -2).join('')
}
