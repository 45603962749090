import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../components/Breadcrumbs'
import TaasEnrolmentListItem from '../../../components/TaasEnrolmentListItem'
import { generateBreadcrumbs } from '../links'
import { taasCourseLink } from '../../../links'

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const TaasCourseView = ({
  organization,
  channel,
  course,
  teacherEnrolments,
  studentEnrolments,
  onTeacherEnrolmentAdd,
  onTeacherEnrolmentEdit,
  onTeacherEnrolmentDisable,
  onStudentEnrolmentAdd,
  onStudentEnrolmentEdit,
  onStudentEnrolmentDisable
}) => {
  return (
    <div>
      <Breadcrumbs
        links={generateBreadcrumbs(organization, channel, [
          {
            title: 'Courses',
            href: '/courses'
          }
        ])}
      />

      <Card>
        <CardHeader className="title" title={course.title} />
        <CardContent className="description">
          <div>
            <strong>ID</strong>: {course.id}
          </div>
          <div>
            <strong>Title</strong>: {course.title}
          </div>
        </CardContent>
      </Card>

      <Card style={{ marginTop: '20px' }}>
        <CardHeader
          className="title"
          title={
            <StyledLink to={taasCourseLink(course, '/teacher-enrolments')}>
              Teacher Enrolments ({teacherEnrolments.length})
            </StyledLink>
          }
        />
        <CardContent className="description">
          <div style={{ float: 'right', marginTop: '-70px' }}>
            <Button
              variant="contained"
              onClick={() => onTeacherEnrolmentAdd(course)}>
              Add
            </Button>
          </div>
          <ul>
            {teacherEnrolments.map(enrolment => (
              <TaasEnrolmentListItem
                key={enrolment.id}
                enrolment={enrolment}
                onEdit={enrolment => onTeacherEnrolmentEdit(enrolment)}
                onDelete={enrolment =>
                  onTeacherEnrolmentDisable(channel, course, enrolment)
                }
              />
            ))}
          </ul>
        </CardContent>
      </Card>

      <Card style={{ marginTop: '20px' }}>
        <CardHeader
          className="title"
          title={
            <StyledLink to={taasCourseLink(course, '/student-enrolments')}>
              Student Enrolments ({studentEnrolments.length})
            </StyledLink>
          }
        />
        <CardContent className="description">
          <div style={{ float: 'right', marginTop: '-70px' }}>
            <Button
              variant="contained"
              onClick={() => onStudentEnrolmentAdd(course)}>
              Add
            </Button>
          </div>
          <ul>
            {studentEnrolments.map(enrolment => (
              <TaasEnrolmentListItem
                key={enrolment.id}
                enrolment={enrolment}
                onEdit={enrolment => onStudentEnrolmentEdit(enrolment)}
                onDelete={enrolment =>
                  onStudentEnrolmentDisable(channel, course, enrolment)
                }
              />
            ))}
          </ul>
        </CardContent>
      </Card>
    </div>
  )
}

TaasCourseView.propTypes = {
  organization: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  studentEnrolments: PropTypes.arrayOf(PropTypes.object).isRequired,
  teacherEnrolments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTeacherEnrolmentAdd: PropTypes.func.isRequired,
  onTeacherEnrolmentEdit: PropTypes.func.isRequired,
  onTeacherEnrolmentDisable: PropTypes.func.isRequired,
  onStudentEnrolmentAdd: PropTypes.func.isRequired,
  onStudentEnrolmentEdit: PropTypes.func.isRequired,
  onStudentEnrolmentDisable: PropTypes.func.isRequired
}

export default TaasCourseView
