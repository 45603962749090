import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../components/Breadcrumbs'

const ChannelReportView = ({ organization, channel, report }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        {
          title: organization.name,
          href: `/organizations/${organization.id}`
        },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: `${channel.name} Reports`,
          href: `/organizations/${organization.id}/channels/${channel.id}/reports`
        }
      ]}
    />
    <Card className="report">
      <CardHeader className="title" title={`Report: ${report.title}`} />
      <CardContent>
        <p>{report.description}</p>
        <div style={{ height: '600px' }}>
          <iframe
            title="Metabase Embed"
            src={report.embed}
            frameBorder="0"
            width="100%"
            height="100%"
            allowTransparency
          />
        </div>
      </CardContent>
    </Card>
  </div>
)

ChannelReportView.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  channel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  report: PropTypes.object.isRequired
}

export default ChannelReportView
