import joi from 'joi'
import createForm from '../../components/form/createForm'
import ToggleInput from '../../components/form/elements/ToggleInput'
import SelectInput from '../../components/form/elements/SelectInput'

export default ({ user, courses, onSubmit, ...props }) => {
  return createForm({
    fields: [
      {
        id: 'receiveEmailNotifications',
        label: 'Receive Email Notifications?',
        inputComponent: ToggleInput,
        required: true,
        validation: joi.bool(),
        defaultValue: user.receiveEmailNotifications
      },
      {
        id: 'optOutCourses',
        label: 'Opt out of Specific Course Updates',
        inputComponent: SelectInput,
        inputOptions: {
          label: 'Opt out of Specific Course Updates',
          options: (courses || []).map(course => ({
            value: course.id,
            label: course.title
          })),
          isSearchable: true,
          isMulti: true
        },
        validation: joi.array().items(joi.string()),
        defaultValue: user.optOutCourses
      }
    ],
    layoutOptions: {
      submitText: 'Update Settings'
    },
    onSubmit: async data => {
      return onSubmit(data)
    },
    ...props
  })
}
