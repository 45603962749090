import React from 'react'
import SearchQueryInput from './SearchQueryInput'
import { fetchTagsByIdsAwait, suggestTags } from '../actions/tags'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const SearchQueryInputTag = props => (
  <SearchQueryInput
    type="tag"
    getSuggestions={suggestTags}
    renderSuggestion={suggestion => <div>{suggestion.name}</div>}
    getSuggestionValue={suggestion => {
      props.fetchTagsByIds([suggestion.name])
      return suggestion.name
    }}
    {...props}
  />
)

SearchQueryInputTag.propTypes = {
  fetchTagsByIds: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  fetchTagsByIds: fetchTagsByIdsAwait
}

export default connect(null, mapDispatchToProps)(SearchQueryInputTag)
