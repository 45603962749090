import React from 'react'
import PropTypes from 'prop-types'
import TaasStudentForm from '../taas-student-create/TaasStudentForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const TaasStudentEdit = ({ organization, channel, student, onSubmit }) => {
  const title = `Edit Student: ${student.firstname} ${student.lastname}`
  return (
    <div>
      <Breadcrumbs
        links={[
          { title: 'Organizations', href: '/organizations' },
          {
            title: organization.name,
            href: `/organizations/${organization.id}`
          },
          {
            title: 'Channels',
            href: `/organizations/${organization.id}/channels`
          },
          {
            title: channel.name,
            href: `/organizations/${organization.id}/channels/${channel.id}`
          },
          {
            title: 'Students',
            href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
          },
          {
            title: `${student.firstname} ${student.lastname}`,
            href: `/organizations/${organization.id}/channels/${channel.id}/taas/students/${student.id}`
          }
        ]}
      />
      <Card>
        <CardHeader className="title" title={title} />
        <CardContent className="description">
          <TaasStudentForm student={student} onSubmit={onSubmit} />
        </CardContent>
      </Card>
    </div>
  )
}

TaasStudentEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired
}

export default TaasStudentEdit
