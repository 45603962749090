import React from 'react'
import Card from '@material-ui/core/Card'
import { CircularProgress } from '@material-ui/core'

const LoadingCard = () => (
  <Card
    className="login loading"
    style={{
      height: 286,
      textAlign: 'center'
    }}>
    <div className="loading">
      <CircularProgress />
    </div>
  </Card>
)

export default LoadingCard
