import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ForgotPasswordCard from '../components/login/ForgotPasswordCard'
import LoadingCard from '../components/login/LoadingCard'
import LoginCard from '../components/login/LoginCard'
import PasswordResetSentDialog from '../components/login/PasswordResetSentDialog'
import SelectOrganizationDialog from '../components/login/SelectOrganizationDialog'
import { validEmail } from '../helpers'
import logo from '../assets/edl-logo.png'
import { VerifyMultiFactorAuthentication } from './VerifyMultiFactorAuthentication'
import '../css/login.css'
import TermsAndConditionsDialog from '../components/login/TermsAndConditionsDialog'

const LoginWrapper = styled.div`
  .loginPages .auth {
    margin: 24px;
    float: right;
  }
  .loginPages .title {
    color: white;
    background: #c66c17;
    height: 150px;
  }
  .loginPages .title span {
    color: white;
  }
  .loginPages .login {
    width: 500px;
    padding: 50px;
  }
  .loginPages .login .header {
    display: flex;
    justify-content: space-between;
  }
  .loginPages .loginErrorMessage {
    color: red;
    text-align: center;
  }
  .loginPages .loading {
    vertical-align: middle;
    text-align: center;
    height: 500px;
  }
  .loginPages .footer {
    display: flex;
    justify-content: space-between;
  }
  .loginPages .back {
    text-align: left;
  }
  .loginPages .submit {
    margin: 0;
  }
  .loginPages .logo {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: -1;
    text-align: right;
  }
  .loginPages .logo img {
    margin-top: 50px;
    height: 200px;
  }
  @media only screen and (max-width: 550px), (max-height: 700px) {
    .loginPages .auth {
      margin: 12px;
    }
    .loginPages .login {
      width: 375px;
      padding: 20px;
    }
    .loginPages .title {
      height: 100px;
    }
    .loginPages .logo {
      width: 375px;
    }
  }
`

const Login = ({
  location,
  organizations,
  loginErrorMessage,
  login,
  verifyCode,
  verificationErrorMessage,
  forgotPassword,
  googleLogin,
  rejectLogin,
  acceptTerms,
  organizationSelection,
  isForgotPasswordCardShown,
  isLoggingIn,
  isAcceptingTerms,
  isSelectingOrg,
  setAppBarState,
  setForgotPasswordCardShown
}) => {
  const [redirectToMfaScreen, setRedirectToMfaScreen] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordResetSent, setPasswordResetSent] = useState(false)
  const [inputErrors, setInputErrors] = useState({
    usernameError: {
      isError: false,
      message: 'Please enter a valid email (eg. test@mail.com)'
    }
  })

  const [redirectToReferrer, setRedirectToReferrer] = useState(
    !!localStorage.token
  )

  const showForgotPassword =
    isForgotPasswordCardShown && !redirectToMfaScreen && !isLoggingIn
  const showLogin =
    !isForgotPasswordCardShown && !redirectToMfaScreen && !isLoggingIn
  const showMfa =
    redirectToMfaScreen && !isForgotPasswordCardShown && !isLoggingIn

  useEffect(() => {
    setAppBarState({
      visible: false
    })
  }, [])

  const setFieldError = (name, value) => {
    const error = Object.assign({}, inputErrors[name + 'Error'])
    error.isError = value

    setInputErrors({
      ...inputErrors,
      [name + 'Error']: error
    })
  }

  const handleLogin = async () => {
    if (!validEmail(username.trim())) {
      return setFieldError('username', true)
    }

    const { type } = await login(username.trim(), password)
    if (type === 'LOGIN_SUCCESS') setRedirectToReferrer(true)
    if (type === 'LOGIN_REQUIRES_MFA') setRedirectToMfaScreen(true)
  }

  const handleMfaVerification = async (code, username) => {
    const { type } = await verifyCode(code.trim(), username)

    if (type === 'LOGIN_SUCCESS') setRedirectToReferrer(true)
  }

  const handleGoogleLogin = async params => {
    const { type } = await googleLogin(params)
    if (type === 'LOGIN_SUCCESS') setRedirectToReferrer(true)
  }

  const handleAcceptTerms = async () => {
    const { type } = await acceptTerms()
    if (type === 'LOGIN_SUCCESS') setRedirectToReferrer(true)
  }

  const handleDeclineTerms = async () => {
    if (
      confirm(
        'Are you sure you want to cancel without accepting the terms? You will not be able to log in.'
      )
    ) {
      await rejectLogin(
        'Failed to login, user declined to accept the terms and conditions.'
      )
      setPassword('')
      return true
    }
    return false
  }

  const handleForgotPassword = async () => {
    if (!validEmail(username.trim())) {
      return setFieldError('username', true)
    }

    await forgotPassword(username.trim())
    setPasswordResetSent(true)
    setForgotPasswordCardShown(false)
  }
  const handleInputChange = (name, value) => {
    switch (name) {
      case 'username':
        setUsername(value)
        break
      case 'password':
        setPassword(value)
        break
    }

    if (value.length === 0) setFieldError(name, true)
    else setFieldError(name, false)
  }

  const handleAccountOrganizationSelection = async id => {
    await organizationSelection(id)
    setRedirectToReferrer(true)
  }

  const { from } = location.state || { from: { pathname: '/' } }

  if (redirectToReferrer) {
    return <Redirect to={from} />
  } else
    return (
      <LoginWrapper>
        <div className="loginPages background">
          <div className="auth">
            <PasswordResetSentDialog
              open={passwordResetSent}
              handleClose={() => setPasswordResetSent(false)}
            />
            <SelectOrganizationDialog
              open={isSelectingOrg}
              handleSelection={handleAccountOrganizationSelection}
              orgs={organizations}
            />
            <TermsAndConditionsDialog
              open={isAcceptingTerms}
              handleCancel={handleDeclineTerms}
              handleContinue={handleAcceptTerms}
            />
            <Card className="title">
              <CardHeader className="title__text" title="Welcome back" />
            </Card>

            {showForgotPassword && (
              <ForgotPasswordCard
                username={username}
                inputErrors={inputErrors}
                setFieldError={setFieldError}
                setForgotPasswordCardShown={setForgotPasswordCardShown}
                handleInputChange={handleInputChange}
                handleForgotPassword={handleForgotPassword}
              />
            )}

            {isLoggingIn && <LoadingCard />}

            {showLogin && (
              <LoginCard
                login={handleLogin}
                username={username}
                password={password}
                loginErrorMessage={loginErrorMessage}
                inputErrors={inputErrors}
                setFieldError={setFieldError}
                handleInputChange={handleInputChange}
                setForgotPasswordCardShown={setForgotPasswordCardShown}
                handleGoogleLogin={handleGoogleLogin}
                onGoogleLoginFailure={error =>
                  rejectLogin('Failed to login with Google', error)
                }
              />
            )}

            {showMfa && (
              <VerifyMultiFactorAuthentication
                verificationErrorMessage={verificationErrorMessage}
                verifyCode={handleMfaVerification}
                username={username.trim()}
              />
            )}
            <div className="logo">
              <img src={logo} alt="edynamic logo" />
            </div>
          </div>
        </div>
      </LoginWrapper>
    )
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  organizations: PropTypes.array.isRequired,
  setAppBarState: PropTypes.func,
  setForgotPasswordCardShown: PropTypes.func,
  login: PropTypes.func,
  acceptTerms: PropTypes.func,
  forgotPassword: PropTypes.func,
  googleLogin: PropTypes.func,
  organizationSelection: PropTypes.func,
  rejectLogin: PropTypes.func.isRequired,
  loginErrorMessage: PropTypes.string,
  verificationErrorMessage: PropTypes.string,
  isForgotPasswordCardShown: PropTypes.bool,
  isLoggingIn: PropTypes.bool,
  isAcceptingTerms: PropTypes.bool,
  isSelectingOrg: PropTypes.bool,
  verifyCode: PropTypes.func.isRequired
}

export default Login
