import React from 'react'
import PropTypes from 'prop-types'
import UserForm from '../UserForm'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../../components/Breadcrumbs'

const OrganizationUsersCreate = ({
  organization,
  channels,
  canEditChannels,
  onSubmit
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Users',
          href: `/organizations/${organization.id}/users`
        }
      ]}
    />
    <Card>
      <CardHeader className="title" title="Create New User" />
      <CardContent className="description">
        <p>
          Note: do NOT use this to create students! This is for organization
          administrators.
        </p>
        <UserForm
          channels={channels}
          canEditChannels={canEditChannels}
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
  </div>
)

OrganizationUsersCreate.propTypes = {
  canEditChannels: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  channels: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired
}

export default OrganizationUsersCreate
