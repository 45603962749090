import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasTeacherEnrolmentBulkAddForm from './TaasTeacherEnrolmentBulkAddForm'

const CourseIdIndicator = styled.div`
  float: right;
  padding: 15px;
  font-size: 24px;
  color: #ccc;
`

const TaasTeacherEnrolmentCreate = ({
  channel,
  organization,
  teacher,
  courses,
  selectedCourseId,
  onChange,
  onSubmit
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Teachers',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/teachers`
        },
        {
          title: `${teacher.firstname} ${teacher.lastname}`,
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/teachers/${teacher.id}`
        },
        {
          title: 'Enrollments',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/teachers/${teacher.id}/enrolments`
        }
      ]}
    />
    <Card className="teachers">
      {selectedCourseId && (
        <CourseIdIndicator>ID {selectedCourseId}</CourseIdIndicator>
      )}
      <CardHeader className="title" title="Enroll Teacher" />
      <CardContent className="description">
        <TaasTeacherEnrolmentBulkAddForm
          courses={courses}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
  </div>
)

TaasTeacherEnrolmentCreate.propTypes = {
  teacher: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  courses: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedCourseId: PropTypes.string
}

export default TaasTeacherEnrolmentCreate
