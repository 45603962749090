import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
import FormattedDate from '../../components/FormattedDate'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PencilIcon from 'mdi-react/PencilIcon'

const TaasTeacherEnrolmentList = ({
  channel,
  organization,
  teacher,
  enrolments,
  onEnrolmentEdit,
  onEnrolmentDisable
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Dashboard',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
        },
        {
          title: 'Teachers',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/teachers`
        },
        {
          title: `${teacher.firstname} ${teacher.lastname}`,
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/teachers/${teacher.id}`
        }
      ]}
    />
    <Card className="teachers">
      <CardHeader
        className="title"
        title={`Enrolments for ${teacher.firstname} ${teacher.lastname}`}
      />
      <ul>
        {enrolments.map(enrolment => (
          <li key={enrolments.id}>
            <strong>{enrolment.course.title}</strong>
            <div>
              <small>
                from <FormattedDate date={enrolment.startdate} /> to{' '}
                <FormattedDate date={enrolment.enddate} />
              </small>
            </div>

            <IconButton onClick={() => onEnrolmentEdit(enrolment)}>
              <PencilIcon />
            </IconButton>

            <IconButton
              onClick={() => onEnrolmentDisable(channel, teacher, enrolment)}>
              <DeleteIcon />
            </IconButton>
          </li>
        ))}
      </ul>
    </Card>
  </div>
)

TaasTeacherEnrolmentList.propTypes = {
  teacher: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  enrolments: PropTypes.array.isRequired,
  onEnrolmentEdit: PropTypes.func.isRequired,
  onEnrolmentEnable: PropTypes.func.isRequired,
  onEnrolmentDisable: PropTypes.func.isRequired
}

export default TaasTeacherEnrolmentList
