import React from 'react'
import PropTypes from 'prop-types'
import DropZone from 'react-dropzone'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from 'mdi-react/CloseIcon'

const isIE = /*@cc_on!@*/ false || !!document.documentMode

const BlurredBackgroundBanner = props => {
  const cursor = props.edit ? 'pointer' : 'default'

  return (
    <div className="blurred-banner-container">
      <DropZone
        className="image-dropzone"
        acceptClassName="image-dropzone__accept-drop"
        accept="image/jpeg, image/png"
        disabled={!props.edit}
        multiple={false}
        onDrop={(accepted, rejected) =>
          props.onDrop(accepted, rejected, 'bannerImg')
        }>
        {props.edit && props.image && (
          <IconButton
            className="delete"
            onClick={e => props.onChange('', 'bannerImg', e)}>
            <CloseIcon />
          </IconButton>
        )}
        <div className="blurred-banner">
          {isIE ? (
            <svg className="blur-ie">
              <defs>
                <filter id="blur" x="0" y="0" width="100%" height="100%">
                  <feGaussianBlur stdDeviation="10" />
                </filter>
              </defs>
              <image
                width="110%"
                height="110%"
                x="-10"
                y="-10"
                xlinkHref={props.image}
                filter="url(#blur)"
                preserveAspectRatio="xMidYMid slice"
              />
            </svg>
          ) : (
            <div className="blurred-banner-img" />
          )}
        </div>
      </DropZone>

      {props.children}

      <style>{`
        .image-dropzone {
          position: absolute !important;
          width: calc(100% + 80px);
          height: 340px;
          margin: -40px -40px 0 -40px;
        }

        .image-dropzone:hover {
          cursor: ${cursor};
        }

        .image-dropzone__accept-drop {
          border: 1px solid #56ff47;
        }

        .blurred-banner {
          width: 100%;
          height: 340px;
          overflow: hidden;
          /* Position behind children components */
          position: absolute;
          z-index: -1;
        }

        .blurred-banner-img {
          /* This removes the hazy border and makes edges crisp */
          width: calc(100% + 20px);
          height: calc(100% + 20px);
          margin: -10px;

          background-image: url(${props.image});
          background-color: ${props.color || 'transparent'};
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;

          filter: blur(10px);
          -webkit-filter: blur(10px);
          -moz-filter: blur(10px);
          -o-filter: blur(10px);
          -ms-filter: blur(10px);
        }

        .delete:hover {
          cursor: ${cursor};
        }

        /* We want image-dropzone's size to be relative to this container */
        .blurred-banner-container {
          position: relative;
        }

        /* This moves the children to a higher z-index than the DropZone */
        .blurred-banner-container .main > * {
          position: relative;
          z-index: 1;
        }
      `}</style>
    </div>
  )
}

BlurredBackgroundBanner.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  image: PropTypes.string,
  edit: PropTypes.bool,
  onDrop: PropTypes.func,
  onChange: PropTypes.func
}

export default BlurredBackgroundBanner
