import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import StyledLink from '../../components/Link'

const link = survey => `/surveys/${survey.id}`

const SurveyUserList = ({ surveys }) => (
  <div>
    <Card className="tools">
      <CardHeader className="title" title="Surveys" />
      <CardContent>
        {surveys.length ? (
          <ul>
            {surveys.map(survey => (
              <li key={survey.id}>
                <StyledLink to={link(survey)}>{survey.name}</StyledLink>
              </li>
            ))}
          </ul>
        ) : (
          <p>There are no available surveys.</p>
        )}
      </CardContent>
    </Card>
  </div>
)

SurveyUserList.propTypes = {
  surveys: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
}

export default SurveyUserList
