import { connect } from 'react-redux'
import SearchQueryContainer from '../components/SearchQueryContainer'
import { saveTagAwait } from '../actions/tags'
import courseCode from '../utilities/courseCode'

import { createSelector } from 'reselect'

export const buildLookup = (arr, idKey, valueKey) => {
  const getId = typeof idKey === 'function' ? idKey : item => item[idKey]
  const getValue =
    typeof valueKey === 'function' ? valueKey : item => item[valueKey]

  return arr.reduce(
    (out, item) => ({
      ...out,
      [getId(item)]: getValue(item)
    }),
    {}
  )
}

const selectCourses = state => state.courses.items
const selectUsers = state => state.users.items
const selectOrganizationsById = state => state.organizations.items.byId

const selectTags = state => state.tags.items

const selectCourseLookup = createSelector([selectCourses], courses => {
  return buildLookup(
    courses,
    'id',
    course => `${courseCode(course)}: ${course.title}`
  )
})

const selectOrganizationLookup = createSelector(
  [selectOrganizationsById],
  organizationsMap => {
    if (Object.keys(organizationsMap).length > 0) {
      return buildLookup(Object.values(organizationsMap), 'id', 'name')
    }

    return {}
  }
)

const selectUserLookup = createSelector([selectUsers], users => {
  return buildLookup(
    users,
    'id',
    user => `${user.name.first} ${user.name.last}`
  )
})

const selectTagsLookup = createSelector([selectTags], tags => {
  return buildLookup(tags, 'name', 'name')
})

const mapStateToProps = state => {
  return {
    refs: {
      course: selectCourseLookup(state),
      organization: selectOrganizationLookup(state),
      tag: selectTagsLookup(state),
      user: selectUserLookup(state)
    }
  }
}

const mapDispatchToProps = dispatch => ({
  onSaveTag: (name, query) => dispatch(saveTagAwait(name, query, true))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchQueryContainer)
