import React from 'react'
import { connect } from 'react-redux'
import TaasCourseTeacherEnrolmentList from './TaasCourseTeacherEnrolmentList'
import PlusIcon from 'mdi-react/PlusIcon'
import Screen from '../../../components/Screen'
import {
  fetchCourse,
  fetchCourseEnrolments,
  unenrolCourseUser
} from '../../../actions/taas'
import { channelsFetchAwait } from '../../../actions/channels'
import { setAppBarState } from '../../../actions/ui'
import { getOrganization, getChannel } from '../../../selectors'
import GateExists from '../../../components/GateExists'
import { taasCourseEnrolment } from '../../../links'

class TaasTeacherEnrolmentListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, enrolments: [], course: null, error: null }
    this.props.setAppBarState({
      visible: true,
      title: 'Teacher Enrollments',
      rightIcon: <PlusIcon />,
      canAdd: true,
      handleRight: () => {
        const { organization, channel, course } = props.match.params
        this.props.history.push(
          `/organizations/${organization}/channels/${channel}/taas/courses/${course}/teacher-enrolments/create`
        )
      }
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, course: null, enrolments: [] })
      const [course, enrolments] = await Promise.all([
        this.props.fetchCourse(),
        this.props.fetchTeacherEnrolments()
      ])
      this.setState({ loading: false, course, enrolments })
    } catch (e) {
      console.error(e.message)
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, course, enrolments } = this.state

    return (
      <Screen
        name="teacher enrollments"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel &&
            this.props.organization &&
            this.state.course &&
            this.state.enrolments
          }>
          <TaasCourseTeacherEnrolmentList
            {...this.props}
            course={course}
            enrolments={enrolments}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchCourse: () =>
    fetchCourse(ownProps.match.params.channel, ownProps.match.params.course),
  fetchTeacherEnrolments: () =>
    fetchCourseEnrolments(
      ownProps.match.params.channel,
      ownProps.match.params.course,
      'teacher'
    ),
  onEnrolmentEdit: enrolment =>
    ownProps.history.push(taasCourseEnrolment(enrolment)),
  onEnrolmentDisable: (channel, teacher, enrolment) =>
    dispatch(unenrolCourseUser(channel, teacher, enrolment, 'teacher'))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasTeacherEnrolmentListContainer)
