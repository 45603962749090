import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import truncate from '../utilities/truncate'
import NoteTextIcon from 'mdi-react/NoteTextIcon'
import AlertBoxIcon from 'mdi-react/AlertBoxIcon'
import AttachmentIcon from 'mdi-react/AttachmentIcon'
import BullhornIcon from 'mdi-react/BullhornIcon'
import styled from 'styled-components'
import EmailIcon from 'mdi-react/EmailIcon'
import EmailCheckIcon from 'mdi-react/EmailCheckIcon'
import CourseTile from './CourseTile'
import IconsWithDate from './IconsWithDate'
import marked from 'marked'
marked.setOptions({
  sanitize: true
})

const NotificationTile = styled.div`
  min-width: 298px;
  padding: 24px;
  background: ${props => (props.pinned ? '#ffe' : 'none')};
  &:hover {
    cursor: ${props => props.cursor};
  }

  .actions,
  .description {
    padding: 0 !important;
  }

  .actions {
    margin-left: -12px;
    margin-top: 24px;
  }

  .title {
    padding: 0;
    margin: 0;
    margin-bottom: 12px;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  .courses {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    margin-top: 12px;

    span {
      color: grey;
    }
  }

  .readMore {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #000;
    }
  }

  svg.actionable {
    fill: #c66c17 !important;
  }

  .notification-sent {
    opacity: 0.5;
  }
`

const IconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`

class NotificationListItem extends Component {
  getMessage() {
    const {
      messageLimit,
      messageUpperLimit,
      notification: { message, alwaysShowAll }
    } = this.props
    const htmlMessage = marked(message)

    if (alwaysShowAll) {
      return htmlMessage
    }

    return truncate(htmlMessage, messageLimit, messageUpperLimit)
  }

  render() {
    const { notification, courseLimit, isSuperAdmin } = this.props

    const message = this.getMessage()

    const displayNoteIcon = notification.notes
      ? notification.notes.length > 0 && isSuperAdmin
      : false

    const displayBroadcastIcon = notification.toAll && isSuperAdmin

    return (
      <NotificationTile
        data-testid="notificationListItem"
        cursor={this.props.cursor}
        key={notification.id}
        pinned={notification.pinned}
        actionable={notification.actionable}
        onClick={e => this.props.onClick(e, notification.id)}>
        <CardHeader
          className="title"
          title={notification.title}
          subheader={
            <IconsWithDate date={notification.date}>
              {displayBroadcastIcon && <BullhornIcon />}
              {displayNoteIcon && <NoteTextIcon />}
              {!!notification.files && <AttachmentIcon />}
              {notification.actionable && (
                <AlertBoxIcon className="actionable" />
              )}
            </IconsWithDate>
          }
        />
        <CardContent className="description">
          <Typography component="div">
            <div
              onClick={e => {
                e.preventDefault()
                if (e.target.href) window.open(e.target.href)
              }}
              dangerouslySetInnerHTML={{
                __html: message
              }}
            />
          </Typography>
        </CardContent>
        {notification.courses.length > 0 && courseLimit !== 0 && (
          <div className="courses">
            {notification.courses.slice(0, courseLimit).map(course => (
              <CourseTile key={course.id} course={course} />
            ))}
            {notification.courses.length > courseLimit && courseLimit !== 0 && (
              <span>+{notification.courses.length - courseLimit}</span>
            )}
          </div>
        )}
        {isSuperAdmin && (
          <CardActions className="actions">
            <Button
              onClick={e =>
                this.props.onSendClick(
                  e,
                  notification.id,
                  notification.emailSent
                )
              }
              size="small"
              className={notification.emailSent ? 'notification-sent' : ''}
              data-testid="send-button">
              <IconContainer>
                {notification.emailSent ? (
                  <EmailCheckIcon size={22} />
                ) : (
                  <EmailIcon size={22} />
                )}
              </IconContainer>
              {notification.emailSent ? 'Sent' : 'Send'}
            </Button>
          </CardActions>
        )}
      </NotificationTile>
    )
  }
}

NotificationListItem.propTypes = {
  cursor: PropTypes.string,
  onClick: PropTypes.func,
  onSendClick: PropTypes.func,
  notification: PropTypes.object,
  courseLimit: PropTypes.number,
  messageLimit: PropTypes.number,
  messageUpperLimit: PropTypes.number,
  isSuperAdmin: PropTypes.bool
}

export default NotificationListItem
