import { connect } from 'react-redux'
import Tags from '../pages/Tags'
import { setAppBarState } from '../actions/ui'
import {
  fetchTagsAwait,
  saveTagAwait,
  deleteTagAwait,
  editTag,
  editTagCancel,
  editTagChange,
  createNewTag
} from '../actions/tags'

const mapStateToProps = state => ({
  tags: state.tags.items,
  totalPages: state.tags.totalPages,
  isFetching: state.tags.isFetching,
  isSaving: state.tags.isSaving,
  isDeleting: state.tags.isDeleting,
  editing: state.tags.editing,
  draft: state.tags.draftQuery
})

const mapDispatchToProps = dispatch => ({
  onCreateTag: () => dispatch(createNewTag()),
  onSaveTag: (name, query) => dispatch(saveTagAwait(name, query)),
  onDeleteTag: name => dispatch(deleteTagAwait(name)),
  onEditTag: name => dispatch(editTag(name)),
  onEditTagCancel: name => dispatch(editTagCancel(name)),
  onChangeTag: (name, query) => dispatch(editTagChange(name, query)),
  fetchTags: (page, limit, search) =>
    dispatch(fetchTagsAwait(page, limit, search)),
  setAppBarState: appBarState => {
    dispatch(setAppBarState(appBarState))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Tags)
