import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'
import SearchQueryInput from '../../components/form/elements/SearchQueryInput'
import LtiKeyInput from '../../components/form/elements/LtiKeyInput'
import ToggleInput from '../../components/form/elements/ToggleInput'
import SimulationLicensesInput from '../../components/simulations/SimulationLicensesInput'

export default ({
  channel = {},
  reports,
  onSubmit,
  channelVerifyQuery,
  enableSimLicenses = false,
  ...props
}) => {
  return createForm({
    fields: [
      {
        id: 'name',
        label: 'Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: channel.name
      },
      {
        id: 'nameClient',
        label: 'Alias',
        inputComponent: TextInput,
        required: true,
        validation: joi.string().allow(''),
        defaultValue: channel.nameClient
      },
      {
        id: 'frostIds',
        label: 'Frost IDs',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: channel.frostIds
      },
      {
        id: 'whichBuzz',
        label: 'Buzz Instances',
        inputComponent: SelectInput,
        inputOptions: {
          label: 'Buzz Instances',
          options: [
            { value: 'buzz', label: 'Buzz 1' },
            { value: 'buzz2', label: 'Buzz 2' },
            { value: 'buzz,buzz2', label: 'Buzz 1 and Buzz 2' }
          ]
        },
        required: false,
        validation: joi.string().allow(''),
        defaultValue: [
          channel.buzzIds ? 'buzz' : '',
          channel.buzz2Ids ? 'buzz2' : ''
        ]
          .filter(n => n)
          .join(',')
      },
      {
        id: 'buzzIds',
        label: 'Buzz IDs',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: channel.buzzIds,
        isVisible: data => data.whichBuzz.split(',').includes('buzz')
      },
      {
        id: 'buzz2Ids',
        label: 'Buzz 2 IDs',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: channel.buzz2Ids,
        isVisible: data => data.whichBuzz.split(',').includes('buzz2')
      },
      {
        id: 'salesforceContractIds',
        label: 'Salesforce Contract IDs',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: channel.salesforceContractIds
      },
      {
        id: 'salesforceSiteId',
        label: 'Salesforce Site ID',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: channel.salesforceSiteId
      },
      {
        id: 'coursesQuery',
        label: 'Courses Query',
        inputComponent: SearchQueryInput,
        inputOptions: {
          previewText: 'Preview Courses',
          previewType: 'courses',
          allowedTypes: ['course', 'tag']
        },
        required: false,
        validation: joi.object(),
        defaultValue: channel.coursesQuery || {}
      },
      {
        id: 'notes',
        label: 'Private Notes',
        inputComponent: TextInput,
        inputOptions: {
          multiline: true
        },
        required: false,
        validation: joi.string().allow(''),
        defaultValue: channel.notes
      },
      {
        id: 'ltiKey',
        label: 'LTI Key',
        inputComponent: LtiKeyInput,
        inputOptions: {
          max: 1
        },
        required: false,
        validation: joi.array(),
        defaultValue: channel.ltiKey ? [channel.ltiKey] : []
      },
      {
        id: 'translations',
        label: 'Translations',
        inputComponent: ToggleInput,
        required: false,
        validation: joi.bool(),
        defaultValue: channel.translations !== false
      },
      {
        id: 'syncFrost',
        label: 'Automatically Sync LTI Access',
        inputComponent: ToggleInput,
        required: false,
        validation: joi.bool(),
        defaultValue: !!channel.syncFrost
      },
      {
        id: 'syncBuzz',
        label: 'Automatically Sync LMS Subscriptions',
        inputComponent: ToggleInput,
        required: false,
        validation: joi.bool(),
        defaultValue: !!channel.syncBuzz,
        isVisible: data => data.whichBuzz.split(',').includes('buzz')
      },
      {
        id: 'syncBuzz2',
        label: 'Automatically Sync LMS Subscriptions (buzz 2)',
        inputComponent: ToggleInput,
        required: false,
        validation: joi.bool(),
        defaultValue: !!channel.syncBuzz2,
        isVisible: data => data.whichBuzz.split(',').includes('buzz2')
      },
      {
        id: 'syncGlassroom',
        label: 'Automatically Sync Google Classroom',
        inputComponent: ToggleInput,
        required: false,
        validation: joi.bool(),
        defaultValue: !!channel.syncGlassroom,
        isVisible: data => data.googleClassroomDomains
      },
      {
        id: 'inherit',
        label: 'Buzz Inherit Permissions',
        inputComponent: ToggleInput,
        required: false,
        validation: joi.bool(),
        defaultValue: !!channel.inherit
      },
      {
        id: 'taas',
        label: 'Student Information Dashboard (RETIRED)',
        inputComponent: ToggleInput,
        required: false,
        validation: joi.bool(),
        defaultValue: !!channel.taas
      },
      {
        id: 'disabled',
        label: 'Disable access to courses',
        inputComponent: ToggleInput,
        required: false,
        validation: joi.bool(),
        defaultValue: !!channel.disabled
      },
      {
        id: 'customSetup',
        label: 'Has a custom setup',
        inputComponent: ToggleInput,
        required: false,
        validation: joi.bool(),
        defaultValue: !!channel.customSetup
      },
      {
        id: 'reports',
        label: 'BI Reports Enabled',
        inputComponent: SelectInput,
        inputOptions: {
          options: reports.map(row => ({
            value: row.id,
            label: row.title
          })),
          isMulti: true
        },
        required: false,
        validation: joi.array(),
        // TODO get this working
        defaultValue: channel.reports || []
      },
      {
        id: 'licenses',
        inputComponent: SimulationLicensesInput,
        inputOptions: {
          enableSimLicenses
        },
        required: false,
        validation: joi.array().items(
          joi.object({
            id: joi.string(),
            productCode: joi.string(),
            onlineLicenseKey: joi.string().required(),
            salesforceAccountId: joi
              .string()
              .allow(null)
              .allow('')
              .required(),
            terms: joi
              .array()
              .min(1)
              .items(
                joi.object({
                  start: joi
                    .date()
                    .raw()
                    .required(),
                  end: joi
                    .date()
                    .raw()
                    .required(),
                  specialExtensionEnd: joi
                    .date()
                    .raw()
                    .allow(null),
                  multiplayerEnd: joi
                    .date()
                    .raw()
                    .allow(null)
                })
              )
              .required(),
            enrollmentLimit: joi.number().min(-1),
            maxConcurrentUsers: joi
              .number()
              .min(1)
              .required(),
            enforceMaxConcurrentUsers: joi.boolean(),
            sponsorshipCode: joi
              .string()
              .allow(null)
              .allow(''),
            piiCollection: joi.boolean()
          })
        ),
        defaultValue: channel.licenses || []
      },
      {
        id: 'googleClassroomDomains',
        label: 'Google Classroom Domains',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: (channel.googleClassroomDomains || []).join(',')
      }
    ],
    layoutOptions: {
      submitText: 'Save'
    },
    onSubmit: async data => {
      delete data.whichBuzz

      if (!Object.keys(data.coursesQuery).length) {
        if (
          // eslint-disable-next-line no-restricted-globals
          !confirm(
            'This organization will have access to all courses. Are you absolutely sure?'
          )
        ) {
          return
        }
      } else {
        if (!(await channelVerifyQuery(data.coursesQuery))) {
          if (
            // eslint-disable-next-line no-restricted-globals
            !confirm(
              'This search query yielded no results. Are you sure you want to continue?'
            )
          ) {
            return
          }
        }
      }

      data.ltiKey = data.ltiKey[0] || null
      data.googleClassroomDomains = data.googleClassroomDomains
        .split(/[, ]+/)
        .filter(Boolean)
      if (data.googleClassroomDomains.length && !data.syncGlassroom) {
        data.syncGlassroom = true
      }

      return onSubmit(data)
    },
    ...props
  })
}
