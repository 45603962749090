import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import omitEmpty from 'omit-empty'
import { isEqual } from 'lodash'
import { deepGet, deepSet, deepUnset } from '../utilities/deep'
import { addDotChild, addArrayChild, getChild } from '../utilities/queryPath'
import SearchQuery from './SearchQuery'
import SearchQueryEditor from './SearchQueryEditor'
import SearchQueryAddCondition from './SearchQueryAddCondition'
import SearchQueryPreview from './SearchQueryPreview'
import Button from '@material-ui/core/Button'
import TagPlusIcon from 'mdi-react/TagPlusIcon'
import CodeBracesIcon from 'mdi-react/CodeBracesIcon'

class SearchQueryContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query: props.query,
      addingPath: null,
      editingPath: null,
      adding: false,
      debug: false
    }
  }

  /**
   * Adds a new condition to state
   * Adds a new key if available, or moves into $and if not
   *
   * @param {Object} cond
   * @param {String} path
   */
  handleConditionAdd = (key, value) => {
    if (Array.isArray(value) && !value.length) {
      return
    }

    let query = this.state.query
    const existing = deepGet(query, key)
    if (existing) {
      query = deepUnset(query, key)
      query = deepSet(query, '$and', old => {
        const adding = { [key]: value }
        if (old) return [...old, { [key]: existing }, adding]
        return [{ [key]: existing }, adding]
      })
    } else {
      query = deepSet(query, key, value)
    }

    this.setState({ query })
    this.props.onChange(query)
  }

  /**
   * Modify an existing condition
   *
   * @param {String} path
   * @param {*} value
   */
  handleConditionChange = (path, value) => {
    const query = deepSet(this.state.query, path, value)
    this.setState({ query })
    this.props.onChange(query)
  }

  /**
   * Handles the removal of a condition
   *
   * @param {String} path
   */
  handleConditionRemove = path => {
    const query = omitEmpty(deepUnset(this.state.query, path))
    this.setState({ query })
    this.props.onChange(query)
  }

  /**
   * Handles the removal of a condition and replaces it with a new one
   */
  handleConditionReplace = (path, newKey, newValue) => {
    const removed = deepUnset(this.state.query, path)
    const reAdded = deepSet(removed, newKey, newValue)
    this.setState({ query: reAdded })
    this.props.onChange(reAdded)
  }

  setJson = query => {
    this.setState({ query })
  }

  handleConditionSave = (key, value) => {
    const { addingPath, editingPath } = this.state
    if (addingPath !== null) {
      const childKey = getChild(addingPath)
      const childValue = deepGet(this.state.query, childKey)
      // append next array key
      if (Array.isArray(childValue)) {
        this.handleConditionAdd(addArrayChild(addingPath, childValue.length), {
          [key]: value
        })
      } else {
        // set child
        this.handleConditionAdd(addDotChild(addingPath, key), value)
      }
    } else if (editingPath !== null) {
      this.handleConditionChange(editingPath, value)
    }
    this.setState({ editingPath: null, addingPath: null })
  }

  handleEditPath = path => {
    this.setState({ editingPath: path, addingPath: null })
  }

  handleAddPath = path => {
    this.setState({ addingPath: path, editingPath: null })
  }

  handleCancelEdit = () => {
    this.setState({ addingPath: null, editingPath: null })
  }

  handleSaveTag = () => {
    const { onSaveTag } = this.props
    const name = prompt('Save as Tag')
    if (name) {
      onSaveTag(name, this.state.query)
    }
  }

  handleToggleDebug = () => {
    this.setState(state => ({
      debug: !state.debug
    }))
  }

  render() {
    const { query, addingPath, editingPath, debug } = this.state
    const {
      query: baseQuery,
      refs,
      readOnly,
      saveable,
      allowedTypes,
      previewTypes,
      previewTransform,
      className
    } = this.props

    const canSaveQuery = !!(saveable && Object.keys(this.state.query).length)
    const isDirty = !isEqual(baseQuery, query)

    return (
      <div className={classnames(className, { dirty: isDirty })}>
        <div className="search-query-header">
          {!readOnly && canSaveQuery && (
            <Button title="Save Query as New Tag" onClick={this.handleSaveTag}>
              <TagPlusIcon />
            </Button>
          )}
          <Button title="Toggle Debug" onClick={this.handleToggleDebug}>
            <CodeBracesIcon />
          </Button>
        </div>

        {addingPath !== null && (
          <SearchQueryAddCondition
            refs={refs}
            onSubmit={this.handleConditionSave}
            onCancel={this.handleCancelEdit}
            allowedTypes={allowedTypes}
          />
        )}
        {editingPath !== null && (
          <SearchQueryAddCondition
            refs={refs}
            onSubmit={this.handleConditionSave}
            onCancel={this.handleCancelEdit}
            type={getChild(editingPath)}
            value={deepGet(this.state.query, editingPath)}
            allowedTypes={allowedTypes}
            editMode
          />
        )}
        <SearchQuery
          query={query}
          path=""
          addingPath={addingPath}
          editingPath={editingPath}
          onConditionAdd={this.handleConditionAdd}
          onConditionChange={this.handleConditionChange}
          onConditionReplace={this.handleConditionReplace}
          onConditionRemove={this.handleConditionRemove}
          onEdit={this.handleEditPath}
          onEditNew={this.handleAddPath}
          refs={refs}
          readOnly={readOnly}
        />

        {debug && <SearchQueryEditor onChange={this.setJson} query={query} />}

        <SearchQueryPreview
          query={previewTransform(query)}
          previewTypes={previewTypes}
        />
      </div>
    )
  }
}

SearchQueryContainer.propTypes = {
  query: PropTypes.shape({}),
  refs: PropTypes.shape({}),
  onChange: PropTypes.func,
  onSaveTag: PropTypes.func,
  readOnly: PropTypes.bool,
  saveable: PropTypes.bool,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  previewTypes: PropTypes.arrayOf(PropTypes.string),
  previewTransform: PropTypes.func,
  className: PropTypes.string
}

SearchQueryContainer.defaultProps = {
  query: {},
  refs: {},
  previewTypes: [],
  previewTransform: q => q,
  onChange: () => {},
  readOnly: false,
  saveable: true,
  allowedTypes: ['course', 'user', 'tag', 'organization']
}

const StyledSearchQueryContainer = styled(SearchQueryContainer)`
  &.dirty {
    border: 1px solid #a00;
    &:before {
      content: 'Unsaved Changes';
      color: #a00;
      float: right;
      margin: 20px;
      font-size: 20px;
    }
  }
  .search-query-header button {
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
  }
`

export default StyledSearchQueryContainer
