import moment from 'moment-timezone'
import { createSelector } from 'reselect'
import _, { keyBy, orderBy } from 'lodash'
import courseCode from '../utilities/courseCode'

const getCourseFolders = state => state.courseFolders.items

export const CourseTypes = Object.freeze({
  SIMULATION: 'SIMULATION',
  COURSE: 'COURSE'
})

const getCourses = state =>
  state.courses.items.map(course => {
    const updatedCourse = {
      ...course
    }

    if (course.courseType === CourseTypes.COURSE) {
      updatedCourse.eid = courseCode(updatedCourse)
    }

    return {
      ...updatedCourse
    }
  })

export const getOrganization = id => state => state.organizations.items.byId[id]

export const getOrganizationUsers = id => state =>
  state.users.items.byId.filter(user => user.organization === id)

export const getChannels = state =>
  state.channels.items.map(row => ({
    ...row,
    name:
      state.auth.userData.permission === 'superadmin'
        ? row.name
        : row.nameClient || row.name
  }))

export const getChannel = id =>
  createSelector([getChannels], channels => channels.find(row => row.id === id))

export const getChannelUsers = id =>
  createSelector(
    [getChannel(id), state => state.users.items],
    (channel, users) => {
      if (!channel) return null
      return users
        .filter(user => user.organization === channel.organization)
        .filter(user => (channel.users || []).includes(user.id))
    }
  )

const getCoursesById = createSelector([getCourses], courses =>
  keyBy(courses, 'id')
)

/**
 * Returns the courses grouped by course folders
 *
 * @return {Object[]}
 */
const getCoursesByFolders = createSelector(
  [getCourseFolders, getCoursesById],
  (courseFolders, coursesById) => {
    return courseFolders.map(courseFolder => ({
      ...courseFolder,
      courses: courseFolder.courses.map(id => coursesById[id]).filter(n => n)
    }))
  }
)

export const getAllFolders = createSelector([getCoursesByFolders], grouped =>
  [
    ...orderBy(grouped, row => {
      if (row.readOnly) {
        return '000' + row.name
      }
      return '111' + row.name
    })
  ].filter(row => row.courses.length)
)

export const getFileDownloadDates = createSelector(
  [state => state.downloads.items],
  items =>
    _.chain(items)
      .orderBy('date', ['desc'])
      .groupBy('file')
      .mapValues(row => moment(row[0].date).toDate())
      .value()
)

export const getRawFiles = state => state.files.items

export const getFiles = createSelector(
  [getFileDownloadDates, getRawFiles],
  (downloadDates, files) =>
    files.map(row => ({
      ...row,
      downloaded: downloadDates[row.id] || null
    }))
)

const getRecentDownloads = count => state =>
  orderBy(state.downloads.orgItems, 'date', ['desc']).slice(0, count)

export const getDownloadHistory = createSelector(
  [getRecentDownloads(100)],
  downloads => downloads
)
