import React from 'react'
import { connect } from 'react-redux'
import TaasCourseView from './TaasCourseView'
import Screen from '../../../components/Screen'
import { isSuperAdmin } from '../../../selectors/auth'
import { channelsFetchAwait } from '../../../actions/channels'
import {
  fetchCourse,
  fetchCourseEnrolments,
  unenrolCourseUser
} from '../../../actions/taas'
import { setAppBarState } from '../../../actions/ui'
import { getOrganization, getChannel } from '../../../selectors'
import GateExists from '../../../components/GateExists'
import { taasCourseLink, taasCourseEnrolment } from '../../../links'

class TaasCourseViewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      course: null,
      loading: false,
      error: null,
      studentEnrolments: [],
      teacherEnrolments: []
    }
    this.props.setAppBarState({
      visible: true,
      title: 'View Course',
      rightIcon: null
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true })
      const [course, teacherEnrolments, studentEnrolments] = await Promise.all([
        this.props.fetchCourse(),
        this.props.fetchCourseTeacherEnrolments(),
        this.props.fetchCourseStudentEnrolments()
      ])

      this.setState({
        loading: false,
        course,
        studentEnrolments,
        teacherEnrolments
      })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError } = this.state

    return (
      <Screen
        name="course"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel && this.props.organization && this.state.course
          }>
          <TaasCourseView
            {...this.props}
            course={this.state.course}
            teacherEnrolments={this.state.teacherEnrolments}
            studentEnrolments={this.state.studentEnrolments}
            observers={this.state.observers}
            facilitators={this.state.facilitators}
            error={stateError}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchCourse: () =>
    fetchCourse(ownProps.match.params.channel, ownProps.match.params.course),
  fetchCourseStudentEnrolments: () =>
    fetchCourseEnrolments(
      ownProps.match.params.channel,
      ownProps.match.params.course,
      'student'
    ),
  fetchCourseTeacherEnrolments: () =>
    fetchCourseEnrolments(
      ownProps.match.params.channel,
      ownProps.match.params.course,
      'teacher'
    ),
  onTeacherEnrolments: course =>
    ownProps.history.push(taasCourseLink(course, '/teacher-enrolments')),
  onTeacherEnrolmentAdd: course =>
    ownProps.history.push(taasCourseLink(course, '/teacher-enrolments/create')),
  onTeacherEnrolmentEdit: enrolment =>
    ownProps.history.push(taasCourseEnrolment(enrolment)),
  onTeacherEnrolmentEnable: () => {},
  onTeacherEnrolmentDisable: (channel, course, enrolment) =>
    dispatch(unenrolCourseUser(channel, course, enrolment, 'teacher')),

  onStudentEnrolments: course =>
    ownProps.history.push(taasCourseLink(course, '/student-enrolments')),
  onStudentEnrolmentAdd: course =>
    ownProps.history.push(taasCourseLink(course, '/student-enrolments/create')),
  onStudentEnrolmentEdit: enrolment =>
    ownProps.history.push(taasCourseEnrolment(enrolment)),
  onStudentEnrolmentEnable: () => {},
  onStudentEnrolmentDisable: (channel, course, enrolment) =>
    dispatch(unenrolCourseUser(channel, course, enrolment, 'student'))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasCourseViewContainer)
