import { sortBy } from 'lodash'
const initial = {
  isFetching: false,
  isSaving: false,
  isDeleting: false,
  error: {
    fetch: null,
    save: null,
    delete: null
  },
  items: [],
  totalPages: 0,
  draftQuery: null
}

function tags(state = initial, action) {
  switch (action.type) {
    case 'FETCH_TAGS_START': {
      const changes = { isFetching: true, items: [] }
      return Object.assign({}, state, changes)
    }
    case 'FETCH_TAGS_SUCCESS': {
      const changes = {
        isFetching: false,
        items: action.tags,
        totalPages: action.totalPages
      }
      return Object.assign({}, state, changes)
    }
    case 'FETCH_TAGS_FAILURE': {
      const error = Object.assign({}, state.error, { fetch: action.error })
      const changes = { isFetching: false, error }
      return Object.assign({}, state, changes)
    }
    case 'SAVE_TAG_START': {
      const changes = { isSaving: true }
      return Object.assign({}, state, changes)
    }
    case 'SAVE_TAG_SUCCESS': {
      const error = Object.assign({}, state.error, { save: null })
      const tags = sortBy(
        state.items
          .filter(tag => tag.name !== action.name)
          .concat([{ name: action.name, query: action.query }]),
        tag => tag.name.toLowerCase()
      )
      const changes = {
        isSaving: false,
        error,
        items: tags,
        draftQuery: null,
        editing: null
      }
      return Object.assign({}, state, changes)
    }
    case 'SAVE_TAG_FAILURE': {
      const error = Object.assign({}, state.error, { save: action.error })
      const changes = { isSaving: false, error }
      return Object.assign({}, state, changes)
    }
    case 'DELETE_TAG_START': {
      const changes = { isDeleting: true }
      return Object.assign({}, state, changes)
    }
    case 'DELETE_TAG_SUCCESS': {
      const error = Object.assign({}, state.error, { delete: null })
      const items = state.items.filter(item => item.name !== action.name)
      const changes = { isDeleting: false, error, items }
      return Object.assign({}, state, changes)
    }
    case 'DELETE_TAG_FAILURE': {
      const error = Object.assign({}, state.error, { delete: action.error })
      const changes = { isDeleting: false, error }
      return Object.assign({}, state, changes)
    }
    case 'EDIT_TAG': {
      const changes = {
        editing: action.name,
        draftQuery: state.items.find(item => item.name === action.name).query
      }
      return Object.assign({}, state, changes)
    }
    case 'EDIT_TAG_CHANGE': {
      const changes = { draftQuery: action.query }
      return Object.assign({}, state, changes)
    }
    case 'EDIT_TAG_CANCEL': {
      const changes = { editing: null, draftQuery: null }
      return Object.assign({}, state, changes)
    }
    default:
      return state
  }
}

export default tags
