import React from 'react'
import { connect } from 'react-redux'
import TaasStudentCreate from './TaasStudentCreate'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import { channelsFetchAwait } from '../../actions/channels'
import { createStudent } from '../../actions/taas'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'

class TaasStudentCreateContainer extends React.Component {
  componentDidMount() {
    this.props.fetchChannels()
  }

  render() {
    return (
      <Screen
        name="students"
        isLoading={this.props.isLoading}
        error={this.props.error}>
        <GateExists exists={this.props.channel && this.props.organization}>
          <TaasStudentCreate {...this.props} />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  onSubmit: data =>
    dispatch(
      createStudent(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasStudentCreateContainer)
