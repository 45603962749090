import React from 'react'
import PropTypes from 'prop-types'
import SearchQueryContainer from '../../../containers/ConnectSearchQueryContainer'
import FormLabel from '@material-ui/core/FormLabel'

class SearchQueryInput extends React.Component {
  handleChange = query => {
    this.props.onChange(this.props.id, query)
  }

  render() {
    const { value, label } = this.props
    return (
      <div style={{ margin: '20px 0' }}>
        <FormLabel>{label}</FormLabel>
        <SearchQueryContainer query={value} onChange={this.handleChange} />
      </div>
    )
  }
}

SearchQueryInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.shape({}),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

SearchQueryInput.defaultProps = {
  label: 'Courses Search Query'
}

export default SearchQueryInput
