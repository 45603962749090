import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ChannelBuzzAdminCreate from './ChannelBuzzAdminCreate'
import Screen from '../../components/Screen'
import { getChannel } from '../../selectors'
import {
  channelsFetchAwait,
  channelBuzzAdminCreateAwait
} from '../../actions/channels'
import { isSuperAdmin } from '../../selectors/auth'
import GateExists from '../../components/GateExists'
import { useOrganization } from '../../hooks/useOrganization'

const ChannelBuzzAdminCreateContainer = props => {
  const { fetchChannels, error, isLoading, channel, organizationId } = props
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )

  useEffect(() => {
    fetchChannels()
  }, [])

  return (
    <Screen
      name="domain create"
      isLoading={isLoading || isFetchingOrg}
      error={error || orgError}>
      <GateExists exists={channel && organization}>
        <ChannelBuzzAdminCreate
          organization={organization}
          channel={channel}
          {...props}
        />
      </GateExists>
    </Screen>
  )
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.channels.isFetching,
  isSuperAdmin: isSuperAdmin(state),
  organizationId: ownProps.match.params.organization,
  channel: getChannel(ownProps.match.params.channel)(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  },
  onSubmit: data =>
    dispatch(
      channelBuzzAdminCreateAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelBuzzAdminCreateContainer)
