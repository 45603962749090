import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import SurveyForm from '../survey-admin-create/SurveyForm'

const SurveyAdminEdit = ({ survey, onSubmit, onDelete }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Tools', href: '/tools' },
        { title: 'Surveys', href: '/tools/surveys' }
      ]}
    />
    <Card>
      <CardHeader className="title" title={`Edit Survey: ${survey.name}`} />
      <CardContent className="description">
        <SurveyForm survey={survey} onSubmit={onSubmit} />
        <br />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onDelete(survey)}>
          Delete Survey
        </Button>
      </CardContent>
    </Card>
  </div>
)

SurveyAdminEdit.propTypes = {
  survey: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default SurveyAdminEdit
