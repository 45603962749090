import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import GoogleLogin from 'react-google-login'

const LoginCard = ({
  username,
  password,
  login,
  loginErrorMessage,
  inputErrors,
  handleInputChange,
  setForgotPasswordCardShown,
  setFieldError,
  handleGoogleLogin,
  onGoogleLoginFailure
}) => (
  <Card
    className="login"
    onKeyPress={e => {
      if (e.key === 'Enter') {
        login()
      }
    }}>
    {loginErrorMessage && (
      <div className="loginErrorMessage">{loginErrorMessage}</div>
    )}
    <TextField
      type="text"
      label="Email"
      inputProps={{ 'data-testid': 'username' }}
      value={username}
      onChange={event => handleInputChange('username', event.target.value)}
      error={inputErrors.usernameError.isError}
      helperText={
        inputErrors.usernameError.isError && inputErrors.usernameError.message
      }
      fullWidth
      margin="normal"
    />
    <TextField
      inputProps={{ 'data-testid': 'password' }}
      type="password"
      label="Password"
      value={password}
      onChange={event => handleInputChange('password', event.target.value)}
      fullWidth
      margin="normal"
    />
    <div className="footer">
      <Button
        data-testid="resetPasswordLink"
        className="passwordReset"
        onClick={() => {
          setForgotPasswordCardShown(true)
          if (username.length === 0) setFieldError('username', false)
        }}>
        Forgot your password?
      </Button>
      <Button
        data-testid="login-button"
        className="submit"
        onClick={login}
        variant="contained"
        color="primary">
        Login
      </Button>
    </div>
    {process.env.REACT_APP_GOOGLE_CLIENT_ID && (
      <div style={{ marginTop: '25px' }}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          responseType="code"
          redirectUri="postmessage"
          onSuccess={handleGoogleLogin}
          onFailure={onGoogleLoginFailure}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    )}
  </Card>
)

LoginCard.propTypes = {
  login: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  loginErrorMessage: PropTypes.string,
  inputErrors: PropTypes.object.isRequired,
  setFieldError: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  setForgotPasswordCardShown: PropTypes.func.isRequired,
  handleGoogleLogin: PropTypes.func.isRequired,
  onGoogleLoginFailure: PropTypes.func.isRequired
}

export default LoginCard
