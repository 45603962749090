import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

const IconsWithDate = props => (
  <span className="IconsWithDate">
    <span className="icons">{props.children}</span>
    {props.date && moment(props.date).format('MMMM D, YYYY')}
    <style>{`
      .IconsWithDate {
        color: #757575;
        display: flex;
        line-height: 24px;
      }
      .IconsWithDate .icons {
        display: flex;
        flex-wrap: wrap;
      }
      .IconsWithDate .icons svg {
        width: 22px;
        height: 22px;
        margin-right: 10px;
        fill: #757575;
      }
    `}</style>
  </span>
)

IconsWithDate.propTypes = {
  children: PropTypes.node,
  date: PropTypes.string
}

export default IconsWithDate
