import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { taasCourseLink } from '../../../links'

const StyledLink = styled(Link)`
  color: inherit;
`

const CourseInfo = styled.div`
  padding: 24px;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  clear: both;
`

const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0;

  .details {
    flex-grow: 1;
    width: 100%;
  }

  .name {
    font-weight: bold;
    margin-right: 10px;
  }
`

const TaasCourseListItem = ({ course }) => (
  <div>
    <Container>
      <div className="details">
        <div className="course">
          <div className="name">
            <span>
              <StyledLink to={taasCourseLink(course, '')}>
                {course.title}
              </StyledLink>
            </span>
          </div>
        </div>
      </div>
    </Container>
    <CourseInfo>
      <div className="courseInfo" />
    </CourseInfo>
  </div>
)

TaasCourseListItem.propTypes = {
  course: PropTypes.object.isRequired
}

export default TaasCourseListItem
