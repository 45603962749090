import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

class PasswordInput extends Component {
  static defaultProps = {
    value: '',
    placeholder: '',
    fieldSuffix: '',
    valid: true
  }

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target: { id, value } }) {
    const { onChange } = this.props
    onChange(id, value)
  }

  render() {
    const {
      id,
      value,
      label,
      placeholder,
      fieldSuffix,
      valid,
      onBlur
    } = this.props
    const validClass = valid ? '' : 'invalid'
    return (
      <div className="text-input">
        <TextField
          id={id}
          value={value}
          label={label}
          placeholder={placeholder}
          className={validClass}
          onChange={this.handleChange}
          onBlur={onBlur}
          type="password"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
        {fieldSuffix !== '' ? (
          <span className="field-suffix">{fieldSuffix}</span>
        ) : null}
      </div>
    )
  }
}

PasswordInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldSuffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  label: PropTypes.string,
  valid: PropTypes.bool
}

PasswordInput.displayName = 'PasswordInput'

export default PasswordInput
