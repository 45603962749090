const initial = {
  isFetching: false,
  isVerifying: false,
  isAdding: false,
  error: {
    fetch: null,
    verificationErrorMessage: null
  },
  data: null
}

function mfaCodes(state = initial, action) {
  switch (action.type) {
    case 'POST_MFA_CODE_REQUEST': {
      const isFetching = true
      const stateChange = { isFetching }
      return Object.assign({}, state, stateChange)
    }
    case 'POST_MFA_CODE_SUCCESS': {
      const isFetching = false
      const error = Object.assign({}, state.error, { fetch: null })
      const data = action.payload
      const stateChange = { isFetching, error, data }
      return Object.assign({}, state, stateChange)
    }
    case 'POST_MFA_CODE_FAILURE': {
      const isFetching = false
      const error = Object.assign({}, state.error, { fetch: action.payload })
      const data = null
      const stateChange = { isFetching, error, data }
      return Object.assign({}, state, stateChange)
    }
    case 'POST_MFA_VERIFY_CODE_REQUEST': {
      const stateChange = { isVerifying: true }
      return Object.assign({}, state, stateChange)
    }
    case 'POST_MFA_VERIFY_CODE_SUCCESS': {
      const error = Object.assign({}, state.error, {
        verificationErrorMessage: null
      })
      const data = action.payload
      const stateChange = {
        isVerifying: false,
        error,
        data: { ...state.data, ...data }
      }
      return Object.assign({}, state, stateChange)
    }
    case 'POST_MFA_VERIFY_CODE_FAILURE': {
      const stateChange = {
        isVerifying: false,
        error: { ...state.error, verificationErrorMessage: action.payload }
      }
      return Object.assign({}, state, stateChange)
    }
    default: {
      return state
    }
  }
}

export default mfaCodes
