import React from 'react'
import { connect } from 'react-redux'
import TaasFacilitatorEdit from './TaasFacilitatorEdit'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import { channelsFetchAwait } from '../../actions/channels'
import { updateFacilitator, fetchFacilitator } from '../../actions/taas'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'

class TaasFacilitatorEditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      facilitator: null,
      loading: false,
      error: null
    }
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true })
      const facilitator = await this.props.fetchFacilitator()
      this.setState({ loading: false, facilitator })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError } = this.state

    return (
      <Screen
        name="facilitators"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel &&
            this.props.organization &&
            this.state.facilitator
          }>
          <TaasFacilitatorEdit
            {...this.props}
            facilitator={this.state.facilitator}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(channelsFetchAwait(ownProps.match.params.organization))
  },
  fetchFacilitator: () =>
    fetchFacilitator(
      ownProps.match.params.channel,
      ownProps.match.params.facilitator
    ),
  onSubmit: data =>
    dispatch(
      updateFacilitator(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        ownProps.match.params.facilitator,
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasFacilitatorEditContainer)
