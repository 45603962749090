import React from 'react'
import PropTypes from 'prop-types'
import UserForm from '../UserForm'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../../components/Breadcrumbs'

const OrganizationUsersEdit = ({
  organization,
  user,
  channels,
  canEditChannels,
  onDelete,
  onSubmit
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Users',
          href: `/organizations/${organization.id}/users`
        }
      ]}
    />
    <Card>
      <CardHeader
        className="title"
        title={`Edit User: ${user.name.first} ${user.name.last}`}
      />
      <CardContent className="description">
        <UserForm
          canEditChannels={canEditChannels}
          user={user}
          channels={channels}
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
    <div style={{ margin: '20px 0' }} />
    <Card>
      <CardActions className="actions" style={{ margin: '10px' }}>
        <Button
          onClick={() => onDelete(user)}
          variant="contained"
          color="secondary">
          Delete User
        </Button>
      </CardActions>
    </Card>
  </div>
)

OrganizationUsersEdit.propTypes = {
  canEditChannels: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  channels: PropTypes.array.isRequired,
  organization: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default OrganizationUsersEdit
