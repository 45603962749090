import React from 'react'
import PropTypes from 'prop-types'
import TaasObserverForm from '../taas-observer-create/TaasObserverForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const TaasObserverEdit = ({ organization, channel, observer, onSubmit }) => {
  const title = `Edit Observer: ${observer.firstname} ${observer.lastname}`
  return (
    <div>
      <Breadcrumbs
        links={[
          { title: 'Organizations', href: '/organizations' },
          {
            title: organization.name,
            href: `/organizations/${organization.id}`
          },
          {
            title: 'Channels',
            href: `/organizations/${organization.id}/channels`
          },
          {
            title: channel.name,
            href: `/organizations/${organization.id}/channels/${channel.id}`
          },
          {
            title: 'Dashboard',
            href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
          },
          {
            title: 'Observers',
            href: `/organizations/${organization.id}/channels/${channel.id}/taas/observers`
          }
        ]}
      />
      <Card>
        <CardHeader className="title" title={title} />
        <CardContent className="description">
          <TaasObserverForm observer={observer} onSubmit={onSubmit} />
        </CardContent>
      </Card>
    </div>
  )
}

TaasObserverEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  observer: PropTypes.object.isRequired
}

export default TaasObserverEdit
