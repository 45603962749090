import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'

export default ({ channel, ...props }) =>
  createForm({
    fields: [
      {
        id: 'nameClient',
        label: 'Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string().allow(''),
        defaultValue: channel.nameClient || channel.name
      }
    ],
    layoutOptions: {
      submitText: 'Save'
    },
    ...props
  })
