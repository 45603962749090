import React from 'react'
import SearchQueryInput from './SearchQueryInput'
import { suggestOrganizations } from '../requests/organizations'
import { fetchOrganizationsByIdsAwait } from '../actions/organizations'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const SearchQueryInputOrganization = props => (
  <SearchQueryInput
    type="organization"
    getSuggestions={suggestOrganizations}
    renderSuggestion={suggestion => <div>{suggestion.name}</div>}
    getSuggestionValue={suggestion => {
      props.fetchOrganizationsByIds([suggestion.id])
      return suggestion.id
    }}
    {...props}
  />
)

SearchQueryInputOrganization.propTypes = {
  fetchOrganizationsByIds: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  fetchOrganizationsByIds: fetchOrganizationsByIdsAwait
}

export default connect(null, mapDispatchToProps)(SearchQueryInputOrganization)
