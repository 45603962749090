import React from 'react'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Breadcrumbs from '../../../components/Breadcrumbs'
import TaasCourseListItem from './TaasCourseListItem'
import { generateBreadcrumbs } from '../links'

const TaasCourseList = ({ channel, organization, courses, onView }) => (
  <div>
    <Breadcrumbs links={generateBreadcrumbs(organization, channel)} />
    <div style={{ clear: 'both' }} />
    <Card className="courses">
      <CardHeader className="title" title="Courses" />
      {courses.map((course, i) => (
        <>
          <TaasCourseListItem key={course.id} onView={onView} course={course} />
          {i < courses.length - 1 && <Divider />}
        </>
      ))}
    </Card>
  </div>
)

TaasCourseList.propTypes = {
  courses: PropTypes.array.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  onView: PropTypes.func.isRequired
}

export default TaasCourseList
