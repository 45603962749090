import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SearchQueryCondition from './SearchQueryCondition'
import { addDotChild } from '../utilities/queryPath'
import Button from '@material-ui/core/Button'

const StyledSearchQueryCondition = styled(SearchQueryCondition)`
  border-left: 1px solid #eee;
  padding: 5px;
  margin-bottom: 5px;
`

const StyledQueryObject = styled.div`
  border-left: 1px solid #eee;
  padding: 5px;
  margin-bottom: 5px;

  .scope {
    font-size: 12px;
    padding: 0.5em;
  }

  .scope .controls {
    text-align: left;
    float: none;
    display: inline;
  }
  .scope .controls button {
    font-size: 10px;
  }

  .controls {
    text-align: right;
  }

  .query-actions .controls {
    text-align: left;
    float: right;
  }

  ul {
    list-style-type: disc;
  }
  li {
    margin: 0;
    padding: 0;
  }

  .key {
    font-size: 12px;
  }
`

class SearchQuery extends React.Component {
  render() {
    const {
      refs,
      query,
      path,
      onEdit,
      onEditNew,
      onConditionChange,
      onConditionReplace,
      onConditionRemove,
      addingPath,
      editingPath,
      readOnly
    } = this.props
    return (
      <div>
        <StyledQueryObject>
          <div className="scope">
            All of the following
            {!readOnly && !editingPath && addingPath !== path && (
              <div className="controls">
                <Button size="small" onClick={() => onEditNew(path)}>
                  add condition
                </Button>
              </div>
            )}
          </div>
          <ul>
            {Object.keys(query).map(key => {
              const childPath = addDotChild(path, key)
              return (
                <li key={key}>
                  <StyledSearchQueryCondition
                    className="searchQueryCondition"
                    path={childPath}
                    keyName={key}
                    query={query[key]}
                    onConditionRemove={onConditionRemove}
                    onConditionChange={onConditionChange}
                    onConditionReplace={onConditionReplace}
                    onEdit={onEdit}
                    onEditNew={onEditNew}
                    refs={refs}
                    addingPath={addingPath}
                    editingPath={editingPath}
                    readOnly={readOnly}
                  />
                </li>
              )
            })}
          </ul>
        </StyledQueryObject>
      </div>
    )
  }
}

SearchQuery.propTypes = {
  query: PropTypes.shape({}),
  path: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onEditNew: PropTypes.func.isRequired,
  addingPath: PropTypes.string,
  editingPath: PropTypes.string,
  onConditionChange: PropTypes.func.isRequired,
  onConditionReplace: PropTypes.func.isRequired,
  onConditionRemove: PropTypes.func.isRequired,
  refs: PropTypes.shape({}),
  readOnly: PropTypes.bool
}

SearchQuery.defaultProps = {
  query: {},
  refs: {},
  addingPath: null,
  editingPath: null,
  readOnly: false
}

export default SearchQuery
