export const channelLink = (channel, action) => {
  return `/organizations/${channel.organization}/channels/${channel.id}${action}`
}

export const taasStudent = (student, action) => {
  return `/organizations/${student.organization}/channels/${student.channel}/taas/students/${student.id}${action}`
}

export const taasEnrolment = enrolment => {
  return `/organizations/${enrolment.organization}/channels/${enrolment.channel}/taas/students/${enrolment.student}/enrolments/${enrolment.id}`
}

export const taasStudentLink = taasStudent

export const taasObserverLink = (observer, action) => {
  return `/organizations/${observer.organization}/channels/${observer.channel}/taas/observers/${observer.id}${action}`
}

export const taasFacilitatorLink = (facilitator, action) => {
  return `/organizations/${facilitator.organization}/channels/${facilitator.channel}/taas/facilitators/${facilitator.id}${action}`
}

export const taasTeacherLink = (teacher, action) => {
  return `/organizations/${teacher.organization}/channels/${teacher.channel}/taas/teachers/${teacher.id}${action}`
}

export const taasCourseLink = (course, action) => {
  return `/organizations/${course.organization}/channels/${course.channel}/taas/courses/${course.id}${action}`
}

export const taasCourseEnrolment = (enrolment, action = '') => {
  return `/organizations/${enrolment.organization}/channels/${enrolment.channel}/taas/courses/${enrolment.courseid}/${enrolment.user.reference}-enrolments/${enrolment.id}${action}`
}

export const taasTeacherEnrolment = enrolment => {
  return `/organizations/${enrolment.organization}/channels/${enrolment.channel}/taas/teachers/${enrolment.teacher}/enrolments/${enrolment.id}`
}
