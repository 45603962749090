import { getToken } from '../auth'
import axios from 'axios'
import { promptTerms, completeLogin, promptOrg } from './auth'

async function generateMfaCode(email) {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_REMOTE}/v2/users/mfa/codes`,
    withCredentials: true,
    data: {
      email
    },
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return res.data
}
export const awaitMfaCode = email => dispatch => {
  if (!getToken()) return dispatch(generateMfaCodeFailure)

  dispatch(generateMfaCodeRequest())
  return generateMfaCode(email).then(
    result => dispatch(generateMfaCodeSuccess(result)),
    err => dispatch(generateMfaCodeFailure(err))
  )
}

const generateMfaCodeRequest = () => ({ type: 'POST_MFA_CODE_REQUEST' })

const generateMfaCodeSuccess = mfaCode => ({
  type: 'POST_MFA_CODE_SUCCESS',
  payload: mfaCode
})

const generateMfaCodeFailure = error => ({
  type: 'POST_MFA_CODE_FAILURE',
  payload: error,
  error
})

async function verifyMfaCode(code, email) {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_REMOTE}/v2/users/mfa/verify`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data: {
      code,
      email
    }
  })
  return res.data
}
export const awaitVerifyMfaCode = (code, email) => dispatch => {
  if (!getToken()) return dispatch(verifyMfaCodeFailure)

  dispatch(verifyMfaCodeRequest())
  return verifyMfaCode(code, email).then(
    result => {
      if (result.verified) {
        dispatch(verifyMfaCodeSuccess(result))
      } else {
        dispatch(verifyMfaCodeFailure("Couldn't verify MFA code setup"))
      }
    },
    err => dispatch(verifyMfaCodeFailure(err))
  )
}

const verifyMfaCodeRequest = () => ({ type: 'POST_MFA_VERIFY_CODE_REQUEST' })

const verifyMfaCodeSuccess = verified => ({
  type: 'POST_MFA_VERIFY_CODE_SUCCESS',
  payload: verified
})

const verifyMfaCodeFailure = error => ({
  type: 'POST_MFA_VERIFY_CODE_FAILURE',
  payload: error,
  error
})

async function submitMfaCode(code, email, token) {
  const res = await axios({
    method: 'POST',
    url: `${process.env.REACT_APP_REMOTE}/v2/auth/mfa/token`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + token },
    data: {
      code,
      email
    }
  })
  return res.data
}

const submitMfaCodeRequest = () => ({ type: 'POST_MFA_CODE_AUTH_REQUEST' })

const submitMfaCodeFailure = error => ({
  type: 'POST_MFA_CODE_AUTH_FAILURE',
  payload: error,
  error
})

export const awaitSubmitMfaCode = (code, email) => (dispatch, getState) => {
  const {
    auth: { token }
  } = getState()

  dispatch(submitMfaCodeRequest())
  return submitMfaCode(code, email, token).then(
    result => {
      if (!result.hasAcceptedTerms) {
        return dispatch(promptTerms(result))
      }

      if (result.selectionToken) {
        return dispatch(promptOrg(result))
      }

      return completeLogin(dispatch, result.token)
    },
    () => {
      dispatch(submitMfaCodeFailure('Failed to verify code. Please try again.'))
    }
  )
}
