import { connect } from 'react-redux'
import NotificationFileDialog from '../components/NotificationFileDialog'
import { createFileAwait } from '../actions/notificationFiles'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addFile: (params, file) => {
      const uploadParams = {
        notification: ownProps.notification.id,
        ...params
      }

      dispatch(createFileAwait(uploadParams, file))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationFileDialog)
