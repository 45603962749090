import React from 'react'
import PropTypes from 'prop-types'
import TaasFacilitatorForm from './TaasFacilitatorForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const TaasFacilitatorCreate = ({ organization, channel, onSubmit }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Dashboard',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
        },
        {
          title: 'Facilitators',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/facilitators`
        }
      ]}
    />
    <Card>
      <CardHeader className="title" title="Add Facilitator" />
      <CardContent className="description">
        <TaasFacilitatorForm onSubmit={onSubmit} />
      </CardContent>
    </Card>
  </div>
)

TaasFacilitatorCreate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired
}

export default TaasFacilitatorCreate
