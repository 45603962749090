import joi from 'joi'
import moment from 'moment-timezone'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'

export default ({ enrolment = {}, courses, onSubmit, ...props }) =>
  createForm({
    fields: [
      {
        id: 'courseid',
        label: 'Course',
        inputComponent: SelectInput,
        inputOptions: {
          label: 'Course',
          options: (courses || []).map(course => ({
            value: course.id,
            label: course.title
          })),
          isSearchable: true
        },
        required: true,
        validation: joi.string(),
        defaultValue: enrolment.courseid
      },
      {
        id: 'startdate',
        label: 'Start Date',
        inputComponent: TextInput,
        inputOptions: {
          type: 'date',
          placeholder: ''
        },
        required: true,
        validation: joi.string().allow(''),
        defaultValue: enrolment.startdate
          ? moment(enrolment.startdate).format('YYYY-MM-DD')
          : ''
      },
      {
        id: 'enddate',
        label: 'End Date',
        inputComponent: TextInput,
        inputOptions: {
          type: 'date',
          placeholder: ''
        },
        required: true,
        validation: joi.string().allow(''),
        defaultValue: enrolment.enddate
          ? moment(enrolment.enddate).format('YYYY-MM-DD')
          : ''
      },
      {
        id: 'reference',
        label: 'Reference / External ID',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: enrolment.reference
      }
    ].filter(enrolment.courseid ? row => row.id !== 'courseid' : row => row),
    onSubmit: async data => {
      if (data.startdate) {
        data.startdate = moment(data.startdate)
          .tz('Etc/UTC')
          .toISOString()
      }
      if (data.enddate) {
        data.enddate = moment(data.enddate)
          .tz('Etc/UTC')
          .toISOString()
      }
      return onSubmit(data)
    },
    layoutOptions: {
      submitText: 'Save'
    },
    ...props
  })
