const initial = {
  isFetching: false,
  isPosting: false,
  isEditing: false,
  isDeleting: false,
  isSending: false,
  error: {
    fetch: null,
    add: null,
    edit: null,
    delete: null,
    send: null
  },
  items: [],
  totalPages: 0
}

function notifications(state = initial, action) {
  switch (action.type) {
    case 'FETCH_NOTIFICATIONS': {
      return { ...state, isFetching: true }
    }
    case 'FETCH_NOTIFICATIONS_SUCCESS': {
      const error = Object.assign({}, state.error, { fetch: null })
      return {
        ...state,
        isFetching: false,
        error,
        items: action.payload.items,
        totalPages: action.payload.totalPages
      }
    }
    case 'FETCH_NOTIFICATIONS_FAILURE': {
      const error = Object.assign({}, state.error, { fetch: action.payload })
      return { ...state, isFetching: false, error, items: [] }
    }
    case 'POST_NOTIFICATION': {
      return { ...state, isPosting: true }
    }
    case 'POST_NOTIFICATION_SUCCESS': {
      const item = action.payload
      const error = Object.assign({}, state.error, { add: null })
      const lastCreatedItem = { data: item }
      return { ...state, isPosting: false, error, lastCreatedItem }
    }
    case 'POST_NOTIFICATION_FAILURE': {
      const error = Object.assign({}, state.error, { add: action.payload })
      return { ...state, isPosting: false, error }
    }
    case 'POST_NOTIFICATION_DISMISS': {
      const isPosting = false
      const error = Object.assign({}, state.error, { add: null })
      return { ...state, isPosting, error }
    }
    case 'EDIT_NOTIFICATION': {
      return { ...state, isEditing: true }
    }
    case 'EDIT_NOTIFICATION_SUCCESS': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: null })
      return { ...state, isEditing, error }
    }
    case 'EDIT_NOTIFICATION_FAILURE': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: action.payload })
      return { ...state, isEditing, error }
    }
    case 'EDIT_NOTIFICATION_DISMISS': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: null })
      return { ...state, isEditing, error }
    }
    case 'DELETE_NOTIFICATION': {
      return { ...state, isDeleting: true }
    }
    case 'DELETE_NOTIFICATION_SUCCESS': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: null })
      return { ...state, isDeleting, error }
    }
    case 'DELETE_NOTIFICATION_FAILURE': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: action.payload })
      return { ...state, isDeleting, error }
    }
    case 'DELETE_NOTIFICATION_DISMISS': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: null })
      return { ...state, isDeleting, error }
    }
    case 'SEND_NOTIFICATION_EMAIL': {
      return { ...state, isSending: true }
    }
    case 'SEND_NOTIFICATION_SUCCESS': {
      const { notification } = action.payload
      const isSending = false
      const error = { ...state.error, send: null }
      const items = state.items.map(i => {
        if (i.id !== notification.id) return i
        return { ...i, emailSent: notification.emailSent }
      })

      return { ...state, isSending, error, items }
    }
    case 'SEND_NOTIFICATION_FAILURE': {
      const isSending = false
      const error = { ...state.error, send: action.payload }
      return { ...state, isSending, error }
    }
    case 'SEND_NOTIFICATION_DISMISS': {
      const isSending = false
      const error = Object.assign({}, state.error, { send: null })
      return { ...state, isSending, error }
    }
    default: {
      return state
    }
  }
}

export default notifications
