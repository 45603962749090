import React from 'react'
import { connect } from 'react-redux'
import TaasStudentView from './TaasStudentView'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import { channelsFetchAwait } from '../../actions/channels'
import {
  fetchStudent,
  deleteStudent,
  fetchStudentEnrolments,
  fetchFacilitators,
  fetchStudentFacilitators,
  fetchStudentObservers,
  unenrolStudent,
  removeStudentObserver,
  removeStudentFacilitator
} from '../../actions/taas'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'
import { taasStudentLink, taasEnrolment } from '../../links'

class TaasStudentViewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      student: null,
      loading: false,
      submitting: false,
      error: null
    }
    this.props.setAppBarState({
      visible: true,
      title: 'View Student',
      rightIcon: null
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true })
      const [student, enrolments, facilitators, observers] = await Promise.all([
        this.props.fetchStudent(),
        this.props.fetchStudentEnrolments(),
        this.props.fetchStudentFacilitators(),
        this.props.fetchStudentObservers()
      ])

      this.setState({
        loading: false,
        student,
        enrolments,
        facilitators: facilitators.map(row => ({ ...row, authorized: true })),
        observers: observers.map(row => ({ ...row, authorized: true }))
      })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError } = this.state

    return (
      <Screen
        name="student"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel && this.props.organization && this.state.student
          }>
          <TaasStudentView
            {...this.props}
            student={this.state.student}
            enrolments={this.state.enrolments}
            observers={this.state.observers}
            facilitators={this.state.facilitators}
            error={stateError}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchStudent: () =>
    fetchStudent(ownProps.match.params.channel, ownProps.match.params.student),
  fetchStudentEnrolments: () =>
    fetchStudentEnrolments(
      ownProps.match.params.channel,
      ownProps.match.params.student
    ),
  onStudentEdit: student =>
    ownProps.history.push(taasStudentLink(student, '/edit')),
  onStudentEnrolments: student =>
    ownProps.history.push(taasStudentLink(student, '/enrolments')),
  onStudentDelete: student => dispatch(deleteStudent(student)),
  onEnrolmentAdd: student =>
    ownProps.history.push(taasStudentLink(student, '/enrolments/create')),
  onEnrolmentEdit: enrolment => ownProps.history.push(taasEnrolment(enrolment)),
  onEnrolmentEnable: () => {},
  onEnrolmentDisable: (channel, student, enrolment) =>
    dispatch(unenrolStudent(channel, student, enrolment)),
  fetchStudentFacilitators: () =>
    fetchStudentFacilitators(
      ownProps.match.params.channel,
      ownProps.match.params.student
    ),
  fetchFacilitators: () => fetchFacilitators(ownProps.match.params.channel),
  fetchStudentObservers: () =>
    fetchStudentObservers(
      ownProps.match.params.channel,
      ownProps.match.params.student
    ),
  onObserverRemove: (student, observer) =>
    dispatch(removeStudentObserver(student, observer)),
  onFacilitatorRemove: (student, facilitator) =>
    dispatch(removeStudentFacilitator(student, facilitator)),
  onStudentFacilitators: student =>
    ownProps.history.push(taasStudentLink(student, '/facilitators')),
  onStudentObservers: student =>
    ownProps.history.push(taasStudentLink(student, '/observers'))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasStudentViewContainer)
