import React, { Component } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import * as LMSInspector from '../lmsInspector'
import FixedFooterDialog from '../components/FixedFooterDialog'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import ZipBoxIcon from 'mdi-react/ZipBoxIcon'
import FilePdfBoxIcon from 'mdi-react/FilePdfBoxIcon'
import styles from '../styles'
import FormLabel from '@material-ui/core/FormLabel'
import SearchQueryContainer from '../containers/ConnectSearchQueryContainer'

const iconMap = {
  package: <ZipBoxIcon size={48} />,
  guide: <FilePdfBoxIcon size={48} />
}

const fileTypeMap = {
  'application/pdf': 'guide'
}

const translations = {
  moodle: 'Moodle',
  canvas: 'Canvas',
  buzz: 'Buzz',
  blackboard: 'Blackboard',
  d2l: 'Desire2Learn'
}

const translateInferredName = name => {
  if (typeof translations[name] !== 'undefined') {
    return translations[name]
  }
  return name
}

class FileDialog extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    toggleActive: PropTypes.func.isRequired,
    input: PropTypes.object.isRequired,
    courseId: PropTypes.string,
    addFile: PropTypes.func.isRequired,
    fetchFiles: PropTypes.func.isRequired,
    isAdding: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(PropTypes.shape({})),
    dateReleasing: PropTypes.string
  }

  static defaultProps = {
    tags: []
  }

  state = {
    file: null,
    type: '',
    previous: '0.0.0',
    version: '',
    description: '',
    name: '',
    viewersQuery: {},
    dateReleasing: this.props.dateReleasing,
    inferred: {},
    inputErrors: {
      fileError: {
        isError: false,
        message: 'Please choose a file to upload. '
      },
      typeError: {
        isError: false,
        message: 'A file type must be specified.'
      },
      versionError: {
        isError: false,
        message: 'A new version number must be specified.'
      },
      nameError: {
        isError: false,
        message: 'A name must be specified.'
      }
    }
  }

  initialState = { ...this.state }

  onCloseResetInitialState = Object.assign({}, this.initialState, {
    isDialogClosing: true
  })

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ ...newProps.input })
    if (newProps.input.file) {
      this.inferFileProperties(newProps.input.file)
    }
    if (newProps.input.type && newProps.input.latestVersions) {
      this.setState({
        previous: newProps.input.latestVersions[newProps.input.type] || '0.0.0'
      })
    }
  }

  cancel = () => {
    this.props.toggleActive()
  }

  validFields = () => {
    return (
      !this.state.inputErrors.versionError.isError &&
      !this.state.inputErrors.typeError.isError &&
      !this.state.inputErrors.fileError.isError &&
      !this.state.inputErrors.nameError.isError
    )
  }

  setFieldErrorValues = async () => {
    if (!this.state.file) await this.setFieldError('file', true)
    if (this.state.type.length === 0) await this.setFieldError('type', true)
    if (this.state.version.length === 0)
      await this.setFieldError('version', true)
    if (
      this.state.type === 'package' &&
      Object.keys(this.state.viewersQuery).length === 0
    ) {
      await this.setFieldError('lms', true)
    }
  }

  onSubmit = async () => {
    await this.setFieldErrorValues()
    if (!this.validFields()) return

    if (
      this.state.type === 'package' &&
      !Object.keys(this.state.viewersQuery).length
    ) {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(
          'This package query is empty and will be shown to all users. Are you sure?'
        )
      ) {
        return
      }
    }

    const params = {
      courseId: this.props.courseId,
      name: this.state.name,
      viewersQuery: this.state.viewersQuery,
      version: this.state.version,
      type: this.state.type,
      ext: this.state.file.name.split('.').slice(-1)[0],
      description: this.state.description,
      dateReleasing: this.state.dateReleasing
    }

    await this.props.addFile(params, this.state.file)
    this.props.toggleActive({ file: null, type: '', previous: '0.0.0' })

    await this.props.fetchFiles()
    this.setState(this.initialState)
  }

  setFieldError = (fieldName, value) => {
    let error = Object.assign({}, this.state.inputErrors[fieldName + 'Error'])
    error.isError = value

    this.setState({
      inputErrors: {
        ...this.state.inputErrors,
        [fieldName + 'Error']: error
      }
    })
  }

  handleChange = (field, value) => {
    let previous = this.state.previous
    if (value === 'package') {
      previous = this.state.latestVersions.packages[this.state.name] || '0.0.0'
    }
    if (field === 'name') {
      previous = this.state.latestVersions.packages[value] || '0.0.0'
    }
    if (value === 'guide') {
      previous = this.state.latestVersions[value] || '0.0.0'
    }

    this.setState({
      [field]: value,
      previous
    })

    if (value.length === 0) this.setFieldError(field, true)
    else this.setFieldError(field, false)
  }

  handleQueryChange = query => {
    this.setState({ viewersQuery: query })
    this.setFieldError('viewersQuery', false)
  }

  inferFileProperties = async file => {
    const fileAsArrayBuffer = await LMSInspector.convertFileToArrayBuffer(file)
    const compression = LMSInspector.determineCompression(fileAsArrayBuffer)
    let lms
    let lmsName
    let compressionType
    if (compression) {
      compressionType = 'package'
      const lmsResult = await LMSInspector.inspect(file)
      lms = translateInferredName(lmsResult.type)
      lmsName = lms
      if (lmsResult.version) lms = lms + lmsResult.version.substring(0, 3)
    }

    const tags = this.props.tags
    const expectedTagName = `lms_${lmsName}`
    const lmsTagExists = tags.find(
      tag => tag.name === expectedTagName || tag.name === expectedTagName
    )

    if (!lmsTagExists && lmsName) {
      // eslint-disable-next-line no-restricted-globals
      alert(
        `Warning: tag ${expectedTagName} does not exist. Not adding to query.`
      )
    }

    const type = compressionType || fileTypeMap[file.type] || this.state.type
    const name = lms || this.state.name
    const viewersQuery =
      !Object.keys(this.state.viewersQuery).length && lmsTagExists
        ? { tag: ['lms_' + (lmsName || this.state.name)] }
        : this.state.viewersQuery

    this.setState({
      file,
      type,
      name,
      viewersQuery,
      inferred: lms,
      previous:
        type === 'package'
          ? this.state.latestVersions.packages[name] || '0.0.0'
          : this.state.latestVersions[type] || '0.0.0'
    })

    return type
  }

  onDrop = async accepted => {
    if (accepted.length === 0) return

    const file = accepted[0]
    const type = await this.inferFileProperties(file)

    if (type.length > 0) await this.setFieldError('type', false)
    if (file) await this.setFieldError('file', false)
  }

  render() {
    return (
      <div>
        <FixedFooterDialog
          active={this.props.active}
          actions={[
            {
              label: 'Cancel',
              style: !this.props.isAdding
                ? styles.buttons
                : styles.buttons_disabled,
              onClick: this.cancel,
              disabled: this.props.isAdding
            },
            {
              label: 'Save',
              variant: 'raised',
              style: !this.props.isAdding
                ? styles.buttons
                : styles.buttons_disabled,
              onClick: this.onSubmit,
              disabled: this.props.isAdding
            }
          ]}
          title="Upload file"
          className="fileDialog">
          <div className="radio__group__error">
            {this.state.inputErrors.fileError.isError &&
              this.state.inputErrors.fileError.message}
            {this.state.inputErrors.typeError.isError &&
              this.state.inputErrors.typeError.message}
          </div>
          <Dropzone
            multiple={false}
            className="dropzone"
            acceptClassName="dropzoneAccept"
            onDrop={accepted => this.onDrop(accepted)}>
            <div className="file__content">
              {iconMap[this.state.type]}
              {this.state.file && (
                <div className="file__content__info">
                  <p>{this.state.file.name}</p>
                  <p>{this.state.file.size} bytes</p>
                </div>
              )}
              {this.props.isAdding && (
                <CircularProgress className="loading" size={40} />
              )}
            </div>
          </Dropzone>
          <RadioGroup
            className="radio__group"
            value={this.state.type}
            onChange={event => this.handleChange('type', event.target.value)}>
            <FormControlLabel
              label="Package"
              value="package"
              control={<Radio />}
            />
            <FormControlLabel label="Guide" value="guide" control={<Radio />} />
          </RadioGroup>
          {this.state.type === 'package' && (
            <React.Fragment>
              <TextField
                type="text"
                label="Package Name"
                value={this.state.name}
                onChange={event =>
                  this.handleChange('name', event.target.value)
                }
                error={this.state.inputErrors.nameError.isError}
                helperText={
                  this.state.inputErrors.nameError.isError &&
                  this.state.inputErrors.nameError.message
                }
                margin="normal"
              />
              <div className="courses-search-query">
                <FormLabel>Courses Search Query</FormLabel>
                <SearchQueryContainer
                  key={this.state.name}
                  query={this.state.viewersQuery}
                  onChange={this.handleQueryChange}
                  previewText="Preview Users"
                  previewType="users"
                />
              </div>
            </React.Fragment>
          )}
          <div className="versions">
            <TextField
              type="text"
              label="Version"
              value={this.state.version}
              onChange={event =>
                this.handleChange('version', event.target.value)
              }
              error={this.state.inputErrors.versionError.isError}
              helperText={
                this.state.inputErrors.versionError.isError &&
                this.state.inputErrors.versionError.message
              }
              margin="normal"
            />
            <TextField
              type="text"
              label="Previous"
              value={this.state.previous}
              disabled={true}
              margin="normal"
            />
          </div>
          <TextField
            type="text"
            multiline
            rows={8}
            label="Description"
            value={this.state.description}
            onChange={event =>
              this.handleChange('description', event.target.value)
            }
            fullWidth
            margin="normal"
          />

          <TextField
            label="Override Package Release"
            type="date"
            defaultValue={moment(this.props.dateReleasing).format('YYYY-MM-DD')}
            onChange={() => {}}
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
            key={this.props.dateReleasing}
          />
        </FixedFooterDialog>
        <style>{`
          .fileDialog .dropzone {
            border: 1px dashed grey;
            margin-bottom: 16px;
            cursor: pointer;
            min-height: 54px;
            font-size: 14px;
            color: #757575;
          }
          .fileDialog .dropzoneAccept {
            border: 1px solid #56ff47;
          }
          .fileDialog .file__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 50px;
            justify-content: ${
              this.props.isAdding ? 'space-between' : 'flex-start'
            };
          }
          .fileDialog .file__content__info {
            margin-left: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;
          }
          .fileDialog .file__content__info p {
            margin: 0;
          }
          .fileDialog .file__content__info h4 {
            margin: 0;
          }
          .fileDialog .radio__group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-evenly;
          }
          .fileDialog .radio__group__error {
            font-size: 12px;
            color: red;
            text-align: center;
            margin-bottom: 10px;
          }
          .fileDialog .versions {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        `}</style>
      </div>
    )
  }
}

export default FileDialog
