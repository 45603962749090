import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import ToggleInput from '../../components/form/elements/ToggleInput'

export default ({ student = {}, onSubmit, ...props }) =>
  createForm({
    fields: [
      {
        id: 'firstname',
        label: 'First Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: student.firstname
      },
      {
        id: 'lastname',
        label: 'Last Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: student.lastname
      },
      student.id
        ? null
        : {
            id: 'username',
            label: 'Username',
            inputComponent: TextInput,
            required: true,
            validation: joi.string(),
            defaultValue: student.username
          },
      {
        id: 'email',
        label: 'Email Address',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: student.email
      },
      student.id
        ? {
            id: 'resetPassword',
            label: `Reset Password via Email`,
            inputComponent: ToggleInput,
            defaultValue: false,
            validation: joi.bool().default(false)
          }
        : null
    ].filter(row => row),
    layoutOptions: {
      submitText: 'Save',
      formOptions: {
        autocomplete: 'off'
      }
    },
    onSubmit: async data => {
      return onSubmit({
        ...data,
        resetPassword: student.id ? data.resetPassword : true
      })
    },
    ...props
  })
