import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Trigger from './Trigger'
import Collapsible from 'react-collapsible'

class Collapse extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.isOpen
    }
    this.toggleOpen = this.toggleOpen.bind(this)
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { title, body } = this.props

    return (
      <div className="Collapse">
        <Collapsible
          easing="ease-in-out"
          transitionTime={250}
          open={this.props.isOpen}
          onOpen={this.toggleOpen}
          onClose={this.toggleOpen}
          trigger={
            <Trigger
              title={title}
              isOpened={this.state.isOpen}
              hasBody={body.length > 0}
              date={this.props.date}
            />
          }>
          <div
            className="description"
            onClick={e => {
              e.preventDefault()
              if (e.target.href) window.open(e.target.href)
            }}
            dangerouslySetInnerHTML={{
              __html: body
            }}
          />
        </Collapsible>
        <style>{`
          .Collapse {
            width: 100%;
          }
          .Collapse .description {
            margin-top: -12px;
          }
        `}</style>
      </div>
    )
  }
}

Collapse.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  date: PropTypes.string,
  isOpen: PropTypes.bool
}

Collapse.defaultProps = {
  isOpen: false,
  title: '',
  body: ''
}

export default Collapse
