import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LabelIcon from 'mdi-react/LabelIcon'
import ContentSaveIcon from 'mdi-react/ContentSaveIcon'
import CancelIcon from 'mdi-react/CancelIcon'
import EditIcon from 'mdi-react/EditIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import SearchQueryContainer from '../containers/ConnectSearchQueryContainer'
import { Pagination } from '../components/pagination/Pagination'
import Search from '../components/Search'
import Spinner from '../components/Spinner'
import NoTagsIcon from 'mdi-react/TagOffOutlineIcon'
import ErrorMessage from '../components/ErrorMessage'
import { debounce } from 'lodash'

const TagTile = ({
  tag,
  editing,
  onCancelTag,
  onSaveTag,
  onChangeTag,
  onEditTag,
  onDeleteTag,
  className
}) => (
  <Paper>
    <div className={className}>
      <div style={{ float: 'right' }}>
        {editing ? (
          <React.Fragment>
            <Button onClick={() => onSaveTag()}>
              <ContentSaveIcon />
            </Button>
            <Button onClick={() => onCancelTag()}>
              <CancelIcon />
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button onClick={() => onEditTag()}>
              <EditIcon />
            </Button>
            <Button onClick={() => onDeleteTag()}>
              <DeleteIcon />
            </Button>
          </React.Fragment>
        )}
      </div>
      <h3>{tag.name}</h3>
      <SearchQueryContainer
        readOnly={!editing}
        query={tag.query}
        onChange={onChangeTag}
        saveable={false}
        key={tag.name + (editing ? 'edit' : 'read')}
        previewTypes={['users', 'courses']}
      />
    </div>
  </Paper>
)

// TODO move this somewhere else and clean it up
TagTile.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string.isRequired,
    query: PropTypes.shape({}).isRequired
  }).isRequired,
  editing: PropTypes.bool.isRequired,
  onCancelTag: PropTypes.func.isRequired,
  onSaveTag: PropTypes.func.isRequired,
  onChangeTag: PropTypes.func.isRequired,
  onEditTag: PropTypes.func.isRequired,
  onDeleteTag: PropTypes.func.isRequired,
  className: PropTypes.string
}

const StyledTagTile = styled(TagTile)`
  padding: 15px;
  margin-bottom: 30px;
  h3 {
    margin: 0.5em 0;
  }
`

const Tags = ({
  setAppBarState,
  onCreateTag,
  editing,
  draft,
  tags,
  totalPages,
  isFetching,
  onEditTag,
  onEditTagCancel,
  onSaveTag,
  onChangeTag,
  onDeleteTag,
  fetchTags
}) => {
  const [searchTerm, setSearchTerm] = useState(null)
  const [page, setPage] = useState(0)

  const limit = 25

  useEffect(() => {
    setAppBarState({
      visible: true,
      title: 'Tags',
      rightIcon: <LabelIcon />,
      handleRight: onCreateTag
    })
  }, [])

  useEffect(() => {
    fetchTags(0, limit, searchTerm)
    setPage(0)
  }, [searchTerm])

  const handleSearch = debounce(
    searchTerm => {
      setSearchTerm(searchTerm)
    },
    500,
    { maxWait: 1000 }
  )

  return (
    <div>
      <Search
        autoFocus
        className="course-search"
        value={searchTerm}
        handleChange={handleSearch}
      />
      {isFetching ? (
        <Spinner loading />
      ) : (
        <div>
          {tags.length > 0 ? (
            <>
              {tags.map(tag => (
                <StyledTagTile
                  key={tag.name}
                  tag={tag}
                  editing={editing === tag.name}
                  onChangeTag={query => onChangeTag(tag.name, query)}
                  onEditTag={() => onEditTag(tag.name)}
                  onSaveTag={() => onSaveTag(tag.name, draft)}
                  onCancelTag={() => onEditTagCancel(tag.name)}
                  onDeleteTag={() => onDeleteTag(tag.name)}
                />
              ))}
              <Pagination
                totalPages={totalPages}
                limit={limit}
                fetch={fetchTags}
                search={searchTerm}
                page={page}
                setPage={setPage}
              />
            </>
          ) : (
            <ErrorMessage
              icon={<NoTagsIcon size={48} />}
              message="No Tags Found"
            />
          )}
        </div>
      )}
    </div>
  )
}

Tags.propTypes = {
  setAppBarState: PropTypes.func,
  isFetching: PropTypes.bool,
  tag: PropTypes.shape({
    name: PropTypes.string.isRequired,
    query: PropTypes.shape({}).isRequired
  }),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      query: PropTypes.shape({}).isRequired
    })
  ),
  totalPages: PropTypes.number,
  fetchTags: PropTypes.func.isRequired,
  onCreateTag: PropTypes.func.isRequired,
  onSaveTag: PropTypes.func.isRequired,
  onDeleteTag: PropTypes.func.isRequired,
  onEditTag: PropTypes.func.isRequired,
  onChangeTag: PropTypes.func.isRequired,
  onEditTagCancel: PropTypes.func.isRequired,
  editing: PropTypes.string,
  draft: PropTypes.shape({})
}

Tags.defaultProps = {
  isFetching: false,
  editing: null,
  draft: null
}

export default Tags
