import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import CloseCircleIcon from 'mdi-react/CloseCircleIcon'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import classNames from 'classnames'

//  TODO: Add prop-types and/or disable for specific components
/* eslint-disable react/prop-types */

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: 'flex',
    padding: 0
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    )
  },
  chipDisabled: {
    color: theme.palette.grey[500]
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 300,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
})

const NoOptionsMessage = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}>
    {props.children}
  </Typography>
)

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
)

const Control = props => (
  <TextField
    fullWidth
    margin="normal"
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps
      }
    }}
    {...props.selectProps.textFieldProps}
  />
)

const Option = props => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400
    }}
    {...props.innerProps}>
    {props.children}
  </MenuItem>
)

const Placeholder = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}>
    {props.children}
  </Typography>
)

const SingleValue = props => (
  <Typography
    className={props.selectProps.classes.singleValue}
    {...props.innerProps}>
    {props.children}
  </Typography>
)

const ValueContainer = props => (
  <div className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>
)

ValueContainer.propTypes = {
  selectProps: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string
  ])
}

const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={classNames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
      [props.selectProps.classes.chipDisabled]: props.isDisabled
    })}
    onDelete={!props.isDisabled && props.removeProps.onClick}
    deleteIcon={<CloseCircleIcon {...props.removeProps} />}
  />
)

const Menu = props => (
  <Paper
    square
    className={props.selectProps.classes.paper}
    {...props.innerProps}>
    {props.children}
  </Paper>
)

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
}

class Autocomplete extends Component {
  state = {
    focused: false
  }

  handleFocus = (...args) => {
    this.setState({ focused: true })
    if (this.props.onFocus) this.props.onFocus(...args)
  }
  handleBlur = (...args) => {
    this.setState({ focused: false })
    if (this.props.onBlur) this.props.onBlur(...args)
  }

  render() {
    const { classes, theme, label, value, error, helperText } = this.props

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      })
    }

    return (
      <Select
        components={components}
        classes={classes}
        styles={selectStyles}
        textFieldProps={{
          label: label,
          InputLabelProps: {
            shrink:
              this.state.focused ||
              (value && !!(value.label || value.length > 0))
          },
          error,
          helperText
        }}
        placeholder=""
        {...this.props}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    )
  }
}

export default withStyles(styles, { withTheme: true })(Autocomplete)
