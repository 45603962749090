import moment from 'moment-timezone'
import React from 'react'
import PropTypes from 'prop-types'

const FormattedDate = ({ date, format, className }) => (
  <span className={className}>{moment(date).format(format)}</span>
)

FormattedDate.defaultProps = {
  format: 'DD-MMM-YYYY',
  className: ''
}

FormattedDate.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.string,
  className: PropTypes.string
}

export default FormattedDate
