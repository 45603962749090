import _ from 'lodash'
import axios from 'axios'
import { getToken } from './auth'
import marked from 'marked'
marked.setOptions({
  sanitize: true
})

export function validEmail(email) {
  /* Regular expression to validate emails
   * Only alpha-numeric characters (upper and lower), periods, underscores, pluses and
   * hyphens allowed before the @. After the @, the same is allowed excluding
   * underscores and pluses. Then a final period followed by at least 2 alpha-numeric
   * characters
   */
  const re = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return re.test(email)
}

export function findListObjectById(id, obj) {
  return obj.id === id
}

export async function sendPasswordReset(username, reason) {
  const response = await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/auth/reset/request',
    data: { username, reason },
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return response
}

export function attachUserToOrganizations(organizations, users) {
  return organizations.map(org => {
    const orgUsers = users.map(user => {
      if (user.organization === org.id) return user
      return null
    })
    return Object.assign({}, org, { users: orgUsers.filter(Boolean) })
  })
}

export function courseFilesByType(courseId, files) {
  return _.chain(files)
    .filter(['courseId', courseId])
    .orderBy('version', 'desc')
    .groupBy('type')
    .value()
}

export function latestVersionByName(files) {
  return _.chain(files)
    .groupBy('name')
    .mapValues(item => item[0].version)
    .value()
}

export function latestVersionByTitle(files) {
  return _.chain(files)
    .mapValues(item => {
      item.title = item.title.toLowerCase()
      return item
    })
    .groupBy('title')
    .mapValues(item => item[0].version)
    .value()
}

export function filterUsersByOrg(users, id) {
  const orgUsers = users.map(user => {
    return user.organization === id ? user : null
  })
  return orgUsers.filter(Boolean)
}

export function parseDescription(description) {
  // Parse out a title and body from the markdown formatted description
  let descTitle = '',
    body = '',
    title = ''
  if (description) {
    const elements = description.split('\n')
    descTitle = marked.inlineLexer(elements.shift(), [])
    body = marked(elements.join('\n'))
  }

  descTitle.length === 0
    ? body.length === 0
      ? // Generic title if no description
        (title = '<i>No description</i>')
      : // Generic title if description body but no title
        (title = '<i>See description</i>')
    : (title = descTitle)

  return { title, body }
}

export function isVersionDeprecated(version, type, isSuperAdmin) {
  return (
    parseInt(version.charAt(0), 10) < 3 && type !== 'guide' && !isSuperAdmin
  )
}

/**
 * Checks if a file is deprecated (v < 3)
 *
 * @param {Object} file
 * @return {Boolean}
 */
export function isDeprecated(file) {
  if (file.type === 'guide') {
    return true
  }

  const DEPRECATE_BEFORE = 3
  const majorVersion = parseInt(file.version.split('.')[0], 10)

  return majorVersion < DEPRECATE_BEFORE
}

/**
 * Generates the grouping key for packages
 *
 * @param {Object} file
 * @return {String}
 */
export function getGroupingKey(file) {
  const q = file.viewersQuery

  if (_.isObject(q)) {
    const keys = Object.keys(q)
    if (keys.length === 1 && keys[0] === 'tag' && Array.isArray(q.tag)) {
      if (q.tag.length === 1 && q.tag[0].indexOf('lms_') === 0) {
        return stripVersion(q.tag[0].substr(4))
      }
    }
  }

  return stripVersion(file.name)
}

/**
 * Strips the version from the end of a string
 *
 * @param {String} str
 * @return {String}
 */
function stripVersion(str) {
  if (str) {
    return str.replace(/(\d+)(\.(\d+))?$/g, '')
  }
  return str
}
