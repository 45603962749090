import React from 'react'
import { connect } from 'react-redux'
import UploadIcon from 'mdi-react/UploadIcon'
import ClockIcon from 'mdi-react/ClockIcon'
import Tabs from '../components/Tabs'
import FileList from '../components/FileList'
import NotificationList from '../components/NotificationList'
import { courseFilesByType, latestVersionByName } from '../helpers'
import { getFiles } from '../selectors'

const mapStateToProps = (state, ownProps) => {
  const {
    courseId,
    notifications,
    edit,
    deleteFile,
    download,
    ...rest
  } = ownProps
  const filesByType = courseFilesByType(courseId, getFiles(state))
  const isSuperAdmin = state.auth.userData.permission === 'superadmin'

  //  Make sure first file is marked as latest
  let latestPackageVersion = {}
  let latestGuideVersion = '0.0.0'
  if (filesByType.package) {
    filesByType.package[0].isLatest = true
    latestPackageVersion = latestVersionByName(filesByType.package)
  }
  if (filesByType.guide) {
    filesByType.guide[0].isLatest = true
    latestGuideVersion = filesByType.guide[0].version
  }

  const allLatestVersions = {
    packages: latestPackageVersion,
    guide: latestGuideVersion
  }

  const defaults = edit
    ? {
        defaultIcon: <UploadIcon />,
        defaultMessage: courseId
          ? 'Upload some files'
          : 'Please create course before adding files'
      }
    : {
        defaultIcon: <ClockIcon />,
        defaultMessage: 'There are no files uploaded yet'
      }

  let notificationTabBody
  if (notifications)
    notificationTabBody = notifications.length > 0 ? notifications : null

  const showCoursePackages = true

  const tabs = [
    {
      title: 'Notifications',
      active: true,
      body: notificationTabBody && (
        <NotificationList
          notifications={notifications}
          onClick={(e, id) => ownProps.handleNotificationClick(e, id)}
          messageLimit={undefined}
          courseLimit={0}
          isSuperAdmin={isSuperAdmin}
        />
      ),
      defaultIcon: <ClockIcon />,
      defaultMessage: 'There are no notifications for this course'
    },
    {
      title: 'Course Packages',
      active: showCoursePackages,
      body: filesByType.package && (
        <FileList
          showLmsChips
          files={filesByType.package}
          edit={edit}
          deleteFile={id => deleteFile(id)}
          download={id => download(id)}
          isSuperAdmin={isSuperAdmin}
        />
      ),
      ...defaults
    },
    {
      title: 'Guides',
      // disabled for now
      active: false,
      body: filesByType.guide && (
        <FileList
          files={filesByType.guide}
          edit={edit}
          deleteFile={id => deleteFile(id)}
          download={id => download(id)}
          isSuperAdmin={isSuperAdmin}
        />
      ),
      ...defaults
    }
  ].filter(tab => tab.active)

  return {
    tabs,
    allLatestVersions,
    showUpload: !!ownProps.courseId,
    ...rest
  }
}

export default connect(mapStateToProps)(Tabs)
