import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { ReadonlyTextFieldWithCopy } from '../components/ReadonlyTextFieldWithCopy/ReadonlyTextFieldWithCopy'
import { MultifactorVerificationForm } from '../components/MultifactorVerificationForm'
import { ErrorSpan } from '../components/ErrorSpan'

export const MultiFactorAuthentication = ({
  qrCodeSrcUrl,
  code,
  onSubmit,
  user,
  verified,
  verificationErrorMessage,
  history
}) => {
  useEffect(() => {
    if (verified) {
      history.goBack()
    }
  }, [verified])

  return (
    <Card>
      <CardHeader
        className="title"
        title="Enable Multi-factor Authentication"
      />
      <CardContent className="description">
        <h3>Setup authenticator app</h3>
        <p>
          Authenticator apps and browser extensions like 1Password, Authy,
          Microsoft Authenticator, etc. generate one-time passwords that are
          used as a second factor to verify your identity when prompted during
          sign-in.
        </p>
        <h3>Scan the QR code</h3>
        <p>Use an authenticator app or browser extension to scan.</p>
        <img src={qrCodeSrcUrl} alt="A QR code to scan" />
        <p>
          Unable to scan? You can use the setup key below to manually configure
          your authenticator app.
        </p>
        <ReadonlyTextFieldWithCopy value={code} />
        {!verified && (
          <Fragment>
            <h3>Verify your setup</h3>
            <p>
              Once you have your authenticator setup please provide the code
              generated in the authenticator to verify your setup.
            </p>
            {verificationErrorMessage && (
              <ErrorSpan message={verificationErrorMessage} />
            )}
            <MultifactorVerificationForm
              onSubmit={data => {
                onSubmit(data.code, user.email)
              }}
            />
          </Fragment>
        )}
      </CardContent>
    </Card>
  )
}

MultiFactorAuthentication.propTypes = {
  qrCodeSrcUrl: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({ email: PropTypes.string.isRequired }).isRequired,
  verificationErrorMessage: PropTypes.string,
  verified: PropTypes.bool.isRequired,
  history: PropTypes.any.isRequired
}
