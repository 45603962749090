import joi from 'joi'
import { orderBy } from 'lodash'
import createForm from '../../components/form/createForm'
import SelectInput from '../../components/form/elements/SelectInput'
import {
  generateDateOptions,
  generateEndDate
} from '../../utilities/partnerReporting'

export default ({ report = {}, partner, lob, onSubmit, ...props }) => {
  const dateOptions = generateDateOptions(partner, lob)

  return createForm({
    fields: [
      {
        id: 'datePeriodStart',
        label: 'Date Period',
        inputComponent: SelectInput,
        inputOptions: {
          options: convertToOptions(dateOptions)
        },
        required: true,
        validation: joi.date(),
        defaultValue: report.datePeriodStart
      }
    ],
    layoutOptions: {
      submitText: 'Submit Report'
    },
    onSubmit: async data => {
      data.datePeriodEnd = generateEndDate(data.datePeriodStart, dateOptions)
      return onSubmit(data)
    },
    ...props
  })
}

const convertToOptions = (
  rows,
  now = new Date(),
  cutoff = new Date('2018-01-01')
) => {
  return orderBy(
    rows.filter(row => row.option.value < now && row.option.value >= cutoff),
    'option.value',
    ['desc']
  ).map(row => row.option)
}
