import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'

class ErrorDialog extends Component {
  render() {
    return (
      <div>
        <Dialog open={this.props.active}>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.error}. Please try again!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {this.props.actions &&
              this.props.actions.map((action, i) => (
                <Button key={i} {...action}>
                  {action.label}
                </Button>
              ))}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ErrorDialog.propTypes = {
  active: PropTypes.bool,
  actions: PropTypes.array,
  error: PropTypes.string
}

ErrorDialog.defaultProps = {
  active: false
}
export default ErrorDialog
