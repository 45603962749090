import React from 'react'
import { connect } from 'react-redux'
import TaasObserverList from './TaasObserverList'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import { fetchObservers, deleteObserver } from '../../actions/taas'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'
import { channelLink, taasObserverLink } from '../../links'
import PlusIcon from 'mdi-react/PlusIcon'

class TaasObserverListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, observers: [], error: null, searchTerm: '' }
    this.props.setAppBarState({
      visible: true,
      title: 'Observers',
      rightIcon: <PlusIcon />,
      handleRight: () => {}
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.channel && !prevProps.channel) {
      this.props.setAppBarState({
        visible: true,
        title: `${this.props.channel.name} Observers`,
        rightIcon: <PlusIcon />,
        canAdd: true,
        handleRight: () =>
          this.props.history.push(
            channelLink(this.props.channel, '/taas/observers/create')
          )
      })
    }
  }

  handleSearch = searchTerm => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.setState({ searchTerm }), 300)
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, observers: [] })
      const observers = await this.props.fetchObservers()
      this.setState({ loading: false, observers })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, observers, searchTerm } = this.state

    return (
      <Screen
        name="observers"
        isLoading={isLoading || loading}
        error={propError}>
        <GateExists exists={this.props.channel && this.props.organization}>
          <TaasObserverList
            {...this.props}
            observers={observers}
            searchTerm={searchTerm}
            onSearch={this.handleSearch}
            error={stateError}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchObservers: () => fetchObservers(ownProps.match.params.channel),
  onObserverEdit: observer =>
    ownProps.history.push(taasObserverLink(observer, '/edit')),
  onObserverDelete: observer => dispatch(deleteObserver(observer))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasObserverListContainer)
