import React, { useEffect, useState } from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import OrganizationsEdit from './OrganizationsEdit'
import Screen from '../../components/Screen'
import {
  editOrganizationAwait,
  deleteOrganizationAwait
} from '../../actions/organizations'
import GateExists from '../../components/GateExists'
import { setAppBarState } from '../../actions/ui'
import { useOrganization } from '../../hooks/useOrganization'

const OrganizationEditContainer = ({
  onSubmit,
  isLoading,
  error,
  organizationId,
  setAppBarState,
  ...props
}) => {
  const [submitError, setSubmitError] = useState(null)
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )

  useEffect(() => {
    setAppBarState({
      visible: true,
      title: 'Organizations',
      canAdd: false
    })
  }, [])

  const handleSubmit = async data => {
    try {
      await onSubmit(data)
    } catch (err) {
      setSubmitError(err)
    }
  }

  return (
    <Screen
      name="organization edit"
      isLoading={isLoading || isFetchingOrg}
      error={error || orgError}>
      <GateExists exists={organization}>
        <OrganizationsEdit
          onSubmit={handleSubmit}
          error={submitError}
          organization={organization}
          {...props}
        />
      </GateExists>
    </Screen>
  )
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.organizations.isFetching,
  organizationId: ownProps.match.params.id
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => {
    dispatch(setAppBarState(appBarState))
  },
  onDelete: () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Are you sure you want to delete this organization?')) {
      return
    }

    dispatch(deleteOrganizationAwait(ownProps.match.params.id)).then(() => {
      dispatch(push(`/organizations`))
    })
  },
  onSubmit: data => {
    dispatch(
      editOrganizationAwait({
        ...data,
        id: ownProps.match.params.id
      })
    )
    dispatch(push(`/organizations`))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationEditContainer)
