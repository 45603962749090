import React from 'react'
import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import ToggleInput from '../../components/form/elements/ToggleInput'
import SelectInput from '../../components/form/elements/SelectInput'
import ServerError from '../../components/ServerError'
import { getParameterByName } from '../../utilities/qs'

export default props => {
  const options = [
    { value: 'buzz', label: 'Buzz' },
    { value: 'buzz2', label: 'Buzz 2' }
  ].filter(row => props.channel[row.value + 'Ids'])

  if (!options.length) {
    const err = new Error('No buzz instances configured')
    return <ServerError error={err} />
  }

  return createForm({
    fields: [
      {
        id: 'instance',
        label: 'Instance',
        inputComponent: SelectInput,
        inputOptions: {
          options
        },
        required: true,
        validation: joi.string().allow(''),
        defaultValue:
          getParameterByName('instance') ||
          localStorage.getItem(`buzz_instance_${props.channel.id}`) ||
          (options.length ? options[0].value : '')
      },
      {
        id: 'reference',
        label: 'External ID',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow('')
      },
      {
        id: 'append',
        label: 'Append to Title',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow('')
      },
      {
        id: 'onlyMissing',
        label: 'Only Copy Missing Courses',
        inputComponent: ToggleInput,
        required: false,
        defaultValue: false,
        validation: joi.bool()
      }
    ],
    layoutOptions: {
      submitText: 'Copy Courses'
    },
    ...props
  })
}
