import React from 'react'
import styled from 'styled-components'

const ComingSoon = styled.div`
  position: absolute;
  top: 45px;
  padding: 3px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  background: green;
  z-index: 10;
  font-size: 24px;
  color: white;
`

const ComingSoonBanner = () => <ComingSoon>Coming Soon</ComingSoon>

export default ComingSoonBanner
