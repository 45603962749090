import axios from 'axios'
import { omit } from 'lodash'
import { getToken } from '../auth'

export async function fetchChannels(organizationId) {
  const result = await axios({
    method: 'GET',
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${organizationId}/channels`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export async function channelsCreate(id, data) {
  handleWarnings(
    await axios({
      method: 'POST',
      url: process.env.REACT_APP_REMOTE + `/channels/orgs/${id}/channels`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() },
      data
    })
  )
}

export async function channelsUpdate(orgId, channelId, data) {
  handleWarnings(
    await axios({
      method: 'PUT',
      url:
        process.env.REACT_APP_REMOTE +
        `/channels/orgs/${orgId}/channels/${channelId}`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() },
      data
    })
  )
}

export async function channelUsersAdd(channel, user) {
  await axios({
    method: 'PUT',
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${channel.organization}/channels/${channel.id}/users/${user.id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

export async function channelUsersRemove(channel, user) {
  await axios({
    method: 'DELETE',
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${channel.organization}/channels/${channel.id}/users/${user.id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

export const channelSync = async (channel, remote) => {
  return axios({
    method: 'POST',
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${channel.organization}/channels/${channel.id}/sync`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data: {
      remote
    }
  })
}

export const channelExchange = async (organizationId, channelId) => {
  return await axios({
    method: 'POST',
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${organizationId}/channels/${channelId}/exchange`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

export const channelCopy = async (organizationId, channelId, data) => {
  return await axios({
    method: 'POST',
    data,
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${organizationId}/channels/${channelId}/buzz-copy`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

export const channelDomainCreate = async (organizationId, channelId, data) => {
  return await axios({
    method: 'POST',
    data,
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${organizationId}/channels/${channelId}/create-domain`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

export const channelBuzzAdminCreate = async (
  organizationId,
  channelId,
  data
) => {
  return await axios({
    method: 'POST',
    data: omit(data, 'instanceId'),
    url:
      process.env.REACT_APP_REMOTE +
      `/channels/orgs/${organizationId}/channels/${channelId}/create-buzz-account`,
    withCredentials: true,
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'X-BuzzInstance': data.instanceId
    }
  })
}

export async function channelVerifyQuery(query) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/courses/search',
    params: { query },
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return result.data.length > 0
}

export async function channelGetConfigurableReports() {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/bi/reports',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return result.data.reports
}

export async function channelGetReports(channelId) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/bi/reports/${channelId}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return result.data.reports
}

export async function channelGetReport(channelId, id) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/bi/reports/${channelId}/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return result.data.report
}

export async function channelsGetAll() {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/channels',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })

  return result.data
}

export function bulkCopyCourses(data) {
  return async function() {
    const res = await axios({
      method: 'POST',
      data,
      url: process.env.REACT_APP_REMOTE + '/channels/bulk-copy-courses',
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })

    if (res.data.results.some(row => row.status === 'ERROR')) {
      alert('Some channels could not be copied')
      return res.data.results
    } else {
      alert('Done')
      return []
    }
  }
}

export async function channelCreatePlatformConfiguration(
  organizationId,
  channelId,
  data
) {
  await axios({
    method: 'PUT',
    data,
    url: `${process.env.REACT_APP_REMOTE}/channels/orgs/${organizationId}/channels/${channelId}/platform`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

export async function fetchPublicLti13Config() {
  const res = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_REMOTE}/channels/lti-1.3-configuration`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return res.data
}

export async function fetchLti1p3ApplicationConfigs() {
  const res = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_REMOTE}/channels/lti-1.3-app-configurations`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return res.data
}

function handleWarnings(res) {
  if (res && res.data && res.data.warningMessage) {
    alert(`A warning occured while saving:\n${res.data.warningMessage}`)
  }
  return res
}

export const checkBuzzLtiRegistration = async (
  organizationId,
  channelId,
  buzzInstance
) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_REMOTE}/channels/orgs/${organizationId}/channels/${channelId}/${buzzInstance}/lti-registration`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    return data
  } catch (error) {
    throw new Error(JSON.stringify(error.response))
  }
}

export const updateBuzzLtiRegistration = async (
  organizationId,
  channelId,
  buzzInstance
) => {
  try {
    const { data } = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_REMOTE}/channels/orgs/${organizationId}/channels/${channelId}/${buzzInstance}/lti-registration`,
      withCredentials: true,
      headers: { Authorization: 'Bearer ' + getToken() }
    })
    return data
  } catch (error) {
    throw new Error(JSON.stringify(error.response, null, 2))
  }
}
