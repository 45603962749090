import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

const SelectOrganizationDialog = ({ open, orgs, handleSelection }) => (
  <Dialog open={open}>
    <DialogTitle>Select Organization</DialogTitle>
    <DialogContent>
      <DialogContentText>
        You have multiple organizations linked to your account. Which one would
        you like to log into
      </DialogContentText>
      <ul>
        {orgs.map(org => (
          <li key={org.id}>
            {org.name}
            <Button
              data-testid={org.name}
              onClick={() => handleSelection(org.id)}>
              Log In
            </Button>
          </li>
        ))}
      </ul>
    </DialogContent>
  </Dialog>
)

SelectOrganizationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  orgs: PropTypes.any.isRequired,
  handleSelection: PropTypes.func.isRequired
}

export default SelectOrganizationDialog
