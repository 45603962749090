import React from 'react'
import PropTypes from 'prop-types'
import { ReactTypeformEmbed } from 'react-typeform-embed'

const buildUrl = (url, params) => {
  return `${url}?${Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')}`
}

const TypeformSurvey = ({ url, params, onComplete }) => (
  <div style={{ position: 'relative', minHeight: '700px' }}>
    <ReactTypeformEmbed
      onSubmit={() => onComplete()}
      url={buildUrl(url, params)}
    />
  </div>
)

TypeformSurvey.defaultProps = {
  params: {}
}

TypeformSurvey.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.object,
  onComplete: PropTypes.func.isRequired
}

export default TypeformSurvey
