import notifications from './notifications'
import notificationFiles from './notificationFiles'
import organizations from './organizations'
import courses from './courses'
import files from './files'
import ui from './ui'
import users from './users'
import auth from './auth'
import notificationsByCourseId from './notificationsByCourseId'
import searchPreview from './searchPreview'
import tags from './tags'
import downloads from './downloads'
import courseFolders from './courseFolders'
import channels from './channels'
import taas from './taas'
import mfaCodes from './mfaCodes'

import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

const appReducer = combineReducers({
  ui,
  auth,
  notifications,
  notificationFiles,
  organizations,
  courses,
  courseFolders,
  files,
  users,
  notificationsByCourseId,
  routing: routerReducer,
  searchPreview,
  tags,
  downloads,
  channels,
  taas,
  mfaCodes
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') state = undefined

  return appReducer(state, action)
}

export default rootReducer
