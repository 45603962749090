import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasLinkedUserSearch from '../../components/TaasLinkedUserSearch'
import Search from '../../components/Search'

const TaasStudentObserverList = ({
  student,
  channel,
  organization,
  observers,
  onAdd,
  onRemove,
  searchTerm,
  onSearch
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Students',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students`
        },
        {
          title: `${student.firstname} ${student.lastname}`,
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/students/${student.id}`
        }
      ]}
    />
    <Search
      className="users-search"
      value={searchTerm}
      handleChange={onSearch}
    />
    <Card className="students">
      <CardHeader className="title" title="Observers" />
      <TaasLinkedUserSearch
        student={student}
        onAdd={onAdd}
        onRemove={onRemove}
        documents={observers}
        filter={searchTerm}
      />
    </Card>
  </div>
)

TaasStudentObserverList.defaultProps = {}

TaasStudentObserverList.propTypes = {
  student: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  observers: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string
}

export default TaasStudentObserverList
