import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import PlusIcon from 'mdi-react/PlusIcon'
import SurveyAdminList from './SurveyAdminList'
import Screen from '../../components/Screen'
import { surveysFetchAll } from '../../actions/surveys'
import { setAppBarState } from '../../actions/ui'

class SurveyAdminListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isLoading: true, surveys: null, error: null }
    this.props.setAppBarState({
      visible: true,
      title: 'Surveys',
      rightIcon: <PlusIcon />,
      handleRight: () => this.props.history.push('/tools/surveys/create')
    })
  }

  async componentDidMount() {
    try {
      this.setState({
        surveys: await this.props.fetchSurveys(),
        isLoading: false
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        error: e
      })
    }
  }

  render() {
    const { error: propError } = this.props
    const { error: stateError, isLoading, surveys } = this.state

    return (
      <Screen
        name="surveys"
        isLoading={isLoading}
        error={propError || stateError}
        renderContent={() => (
          <SurveyAdminList
            surveys={surveys}
            {...this.props}
            error={stateError}
          />
        )}
      />
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchSurveys: () => surveysFetchAll(),
  onSurveyView: survey => dispatch(push(`/tools/surveys/${survey.id}`))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyAdminListContainer)
