import { get } from 'lodash'
import { connect } from 'react-redux'
import CourseSelectionActions from '../components/CourseSelectionActions'
import {
  selectionAdd,
  selectionRemove,
  selectionClear,
  coursesReleaseDatePrompt,
  coursesReleaseDateCancel,
  coursesReleaseDateSubmitAwait
} from '../actions/courses'
import { downloadSelected } from '../actions/downloads'
import {
  courseFoldersAdd,
  courseFoldersRemove,
  courseFoldersSelectCancel,
  courseFoldersApply,
  courseFoldersCreateAwait,
  courseFoldersDeleteAwait
} from '../actions/courseFolders'

const mapStateToProps = (state, ownProps) => {
  let courses = ownProps.courses || state.courses.items
  let selection = state.courses.selection
  let intent = null
  const intentType = get(state, 'courseFolders.intent.type')
  if (intentType) {
    intent = intentType
      .split('_')
      .slice(-1)
      .join('')
      .toLowerCase()
  }

  return {
    isSuperAdmin: state.auth.userData.permission === 'superadmin',
    courses,
    courseFolders: state.courseFolders.items.filter(row => !row.readOnly),
    isSelectingCourseFolder: state.courseFolders.isSelecting,
    selection,
    isFetching: state.courses.isFetching,
    fetchError: state.courses.error.fetch,
    isSelectingReleaseDate: state.courses.releaseDates.active,
    isSavingReleaseDate: state.courses.releaseDates.saving,
    releaseDateSaveError: state.courses.releaseDates.error,
    intent
  }
}

const mapDispatchToProps = dispatch => ({
  onSelect: ids => {
    dispatch(selectionAdd(ids))
  },
  onDeselect: ids => {
    dispatch(selectionRemove(ids))
  },
  onClear: () => {
    dispatch(selectionClear())
  },
  onDownload: ids => {
    dispatch(downloadSelected(ids))
  },
  onCourseFoldersAdd: ids => {
    dispatch(courseFoldersAdd(ids))
  },
  onCourseFoldersRemove: ids => {
    dispatch(courseFoldersRemove(ids))
  },
  onCourseFoldersSelectCancel: () => {
    dispatch(courseFoldersSelectCancel())
  },
  onCourseFoldersSubmit: id => {
    dispatch(courseFoldersApply(id))
  },
  onCourseFoldersCreate: data => {
    dispatch(courseFoldersCreateAwait(data))
  },
  onCourseFoldersDelete: id => {
    dispatch(courseFoldersDeleteAwait(id))
  },
  onReleaseDatePrompt: ids => {
    dispatch(coursesReleaseDatePrompt(ids))
  },
  onReleaseDateCancel: () => {
    dispatch(coursesReleaseDateCancel())
  },
  onReleaseDateSubmit: data => {
    dispatch(coursesReleaseDateSubmitAwait(data))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseSelectionActions)
