import React from 'react'
import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import PasswordInput from '../../components/form/elements/PasswordInput'
import SelectInput from '../../components/form/elements/SelectInput'
import ToggleInput from '../../components/form/elements/ToggleInput'
import ServerError from '../../components/ServerError'
import { getParameterByName } from '../../utilities/qs'

export default ({ channel, ...props }) => {
  const options = [
    { value: 'buzz', label: 'Buzz' },
    { value: 'buzz2', label: 'Buzz 2' }
  ].filter(row => channel[row.value + 'Ids'])

  if (!options.length) {
    const err = new Error('No buzz instances configured')
    return <ServerError error={err} />
  }

  return createForm({
    fields: [
      {
        id: 'instanceId',
        label: 'Instance',
        inputComponent: SelectInput,
        inputOptions: {
          options
        },
        required: true,
        defaultValue: getParameterByName('instance'),
        validation: joi.string().allow('')
      },
      {
        id: 'role',
        label: 'Role',
        inputComponent: SelectInput,
        inputOptions: {
          options: [
            { value: 'admin', label: 'Admin' },
            { value: 'teacher', label: 'Teacher' }
          ]
        },
        defaultValue: 'admin',
        validation: joi.string()
      },
      {
        id: 'firstname',
        label: 'First Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string()
      },
      {
        id: 'lastname',
        label: 'Last Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string()
      },
      {
        id: 'username',
        label: 'Username',
        inputComponent: TextInput,
        required: true,
        validation: joi.string()
      },
      {
        id: 'email',
        label: 'Email Address',
        inputComponent: TextInput,
        required: true,
        validation: joi.string()
      },
      {
        id: 'password',
        label: 'Set Initial Password',
        inputComponent: PasswordInput,
        required: false,
        validation: joi.string().when('resetPassword', {
          is: true,
          then: joi.string().allow(''),
          otherwise: joi
            .string()
            .min(8)
            .allow('')
        }),
        defaultValue: '',
        isVisible: data => !data.resetPassword
      },
      {
        id: 'resetPassword',
        label: `OR Set Password via Email`,
        inputComponent: ToggleInput,
        defaultValue: false,
        validation: joi.bool().default(false)
      }
    ],
    layoutOptions: {
      submitText: 'Create Admin'
    },
    ...props
  })
}
