import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import FormattedDate from '../../components/FormattedDate'

const PaddedDiv = styled.div`
  margin-bottom: 10px;
`

const SecondaryText = styled.span`
  opacity: 0.5;
  color: #252525;
  font-size: 10pt;
`

const DownloadHistory = ({ downloads, onClick }) => (
  <PaddedDiv>
    <Card>
      <CardHeader className="subtitle" title="Recent Downloads" />
      <CardContent>
        <List dense>
          {downloads.map(download => (
            <ListItem
              key={download.id}
              button
              onClick={() => onClick(download)}>
              <ListItemText
                primary={download.fileName}
                secondary={download.courseName}
              />
              <ListItemSecondaryAction>
                <SecondaryText>
                  {download.userName} on <FormattedDate date={download.date} />
                </SecondaryText>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  </PaddedDiv>
)

DownloadHistory.propTypes = {
  onClick: PropTypes.func,
  downloads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      user: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      course: PropTypes.string.isRequired,
      courseName: PropTypes.string.isRequired,
      date: PropTypes.date
    })
  )
}

export default DownloadHistory
