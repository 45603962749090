import React from 'react'
import { connect } from 'react-redux'
import TaasTeacherEnrolmentCreate from './TaasTeacherEnrolmentCreate'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import {
  fetchTeacher,
  fetchCourses,
  createTeacherEnrolment
} from '../../actions/taas'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'
import PlusIcon from 'mdi-react/PlusIcon'

class TaasTeacherEnrolmentCreateContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      teacher: null,
      courses: null,
      error: null,
      selectedCourseId: null
    }
    this.props.setAppBarState({
      visible: true,
      title: 'Teacher Enrollments',
      rightIcon: <PlusIcon />,
      handleRight: () => {
        const { organization, channel, teacher } = props.match.params
        this.props.history.push(
          `/organizations/${organization}/channels/${channel}/taas/teachers/${teacher}/enrolments/create`
        )
      }
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, teacher: null, courses: null })
      const [teacher, courses] = await Promise.all([
        this.props.fetchTeacher(),
        this.props.fetchCourses()
      ])
      this.setState({ loading: false, teacher, courses })
    } catch (e) {
      console.error(e.message)
      this.setState({ loading: false, error: e.message })
    }
  }

  handleFormChange = data => {
    this.setState({ selectedCourseId: data.courseid })
  }

  render() {
    const { isLoading, error: propError } = this.props
    const {
      loading,
      error: stateError,
      teacher,
      courses,
      selectedCourseId
    } = this.state

    return (
      <Screen
        name="teacher enrollments"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel && this.props.organization && this.state.teacher
          }>
          <TaasTeacherEnrolmentCreate
            {...this.props}
            selectedCourseId={selectedCourseId}
            onChange={this.handleFormChange}
            teacher={teacher}
            courses={courses}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchTeacher: () =>
    fetchTeacher(ownProps.match.params.channel, ownProps.match.params.teacher),
  fetchCourses: () => fetchCourses(ownProps.match.params.channel),
  onSubmit: data =>
    dispatch(
      createTeacherEnrolment(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        ownProps.match.params.teacher,
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasTeacherEnrolmentCreateContainer)
