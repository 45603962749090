import React from 'react'
import PropTypes from 'prop-types'
import { handleRenderError } from '../errorHandler'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { error, hasError: !!error }
  }

  componentDidCatch(error, errorInfo) {
    handleRenderError(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return this.props.renderContent(this.state.error)
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  renderContent: PropTypes.func.isRequired
}

export default ErrorBoundary
