import axios from 'axios'
import { getToken } from '../auth'
import { selectionClear } from './courses'

export function fetchCourseFoldersAwait() {
  return function(dispatch) {
    if (!getToken()) return dispatch(courseFoldersFetchFailure())

    dispatch(courseFoldersFetch())
    return fetchCourseFolders()
      .then(result => dispatch(courseFoldersFetchSuccess(result)))
      .catch(() => dispatch(courseFoldersFetchFailure()))
  }
}

async function fetchCourseFolders() {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/course-folders',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

function courseFoldersFetch() {
  return { type: 'COURSE_FOLDERS_FETCH' }
}

function courseFoldersFetchSuccess(folders) {
  return { type: 'COURSE_FOLDERS_FETCH_SUCCESS', payload: folders }
}

function courseFoldersFetchFailure() {
  return {
    type: 'COURSE_FOLDERS_FETCH_FAILURE',
    payload: 'Failed to fetch course folders'
  }
}

export function courseFoldersAdd(ids) {
  return function(dispatch) {
    dispatch(
      courseFoldersSelectStart({
        type: 'COURSE_FOLDERS_ASSIGN',
        payload: {
          courses: ids
        }
      })
    )
  }
}

export function courseFoldersRemove(ids) {
  return function(dispatch) {
    dispatch(
      courseFoldersSelectStart({
        type: 'COURSE_FOLDERS_UNASSIGN',
        payload: {
          courses: ids
        }
      })
    )
  }
}

export function courseFoldersSelectStart(intent) {
  return {
    type: 'COURSE_FOLDERS_SELECT_START',
    payload: intent
  }
}

export function courseFoldersSelectCancel() {
  return { type: 'COURSE_FOLDERS_SELECT_CANCEL' }
}

export function courseFoldersApply(courseFolderId) {
  return function(dispatch, getState) {
    const {
      courseFolders: {
        items,
        intent: {
          type,
          payload: { courses }
        }
      }
    } = getState()
    const existing = items.find(item => item.id === courseFolderId).courses

    let setCourses = []
    if (type === 'COURSE_FOLDERS_ASSIGN') {
      setCourses = [...new Set(existing.concat(courses))]
    } else {
      setCourses = existing.filter(item => !courses.includes(item))
    }

    dispatch(courseFoldersAssignStart())

    return courseFoldersAssign(courseFolderId, setCourses)
      .then(() => {
        dispatch(courseFoldersAssignFinish(courseFolderId, setCourses))
        dispatch(courseFoldersSelectCancel())
        dispatch(selectionClear())
      })
      .catch(e => {
        dispatch(courseFoldersAssignFailure())
        console.error(e)
      })
  }
}

async function courseFoldersAssign(courseFolderId, courseIds) {
  await axios({
    method: 'put',
    url:
      process.env.REACT_APP_REMOTE +
      `/course-folders/${courseFolderId}/courses`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data: { courses: courseIds }
  })
}

function courseFoldersAssignStart() {
  return { type: 'COURSE_FOLDERS_ASSIGN_START' }
}

function courseFoldersAssignFinish(courseFolderId, courses) {
  const payload = { courseFolderId, courses }
  return { type: 'COURSE_FOLDERS_ASSIGN_FINISH', payload }
}

function courseFoldersAssignFailure() {
  return { type: 'COURSE_FOLDERS_ASSIGN_FAILURE' }
}

export function courseFoldersCreateAwait(data) {
  return function(dispatch) {
    dispatch(courseFolderCreateStart())
    return courseFolderCreate(data)
      .then(result => {
        dispatch(courseFolderCreateFinish(result))
      })
      .catch(error => {
        dispatch(courseFolderCreateFailure(error))
      })
  }
}

function courseFolderCreateStart(payload) {
  return { type: 'COURSE_FOLDERS_CREATE_START', payload }
}

function courseFolderCreateFinish(payload) {
  return { type: 'COURSE_FOLDERS_CREATE_FINISH', payload }
}

function courseFolderCreateFailure(error) {
  return { type: 'COURSE_FOLDERS_CREATE_FALIURE', payload: error }
}

async function courseFolderCreate(data) {
  const result = await axios({
    method: 'post',
    url: process.env.REACT_APP_REMOTE + '/course-folders',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data
  })
  return result.data
}

export function courseFoldersDeleteAwait(id) {
  return function(dispatch) {
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to delete this folder?')) {
      return
    }

    dispatch(courseFoldersDeleteStart(id))
    return courseFolderDelete(id)
      .then(() => {
        dispatch(courseFoldersDeleteFinish(id))
      })
      .catch(error => {
        dispatch(courseFoldersDeleteFailure(error))
      })
  }
}

async function courseFolderDelete(id) {
  await axios({
    method: 'delete',
    url: process.env.REACT_APP_REMOTE + `/course-folders/${id}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

function courseFoldersDeleteStart(id) {
  const payload = id
  return { type: 'COURSE_FOLDERS_DELETE_START', payload }
}

function courseFoldersDeleteFinish(id) {
  const payload = id
  return { type: 'COURSE_FOLDERS_DELETE_FINISH', payload }
}

function courseFoldersDeleteFailure(error) {
  const payload = error.message
  return { type: 'COURSE_FOLDERS_DELETE_FALIURE', payload }
}
