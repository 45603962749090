import React from 'react'
import { connect } from 'react-redux'
import TaasTeacherList from './TaasTeacherList'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import { fetchTeachers } from '../../actions/taas'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import { taasTeacherLink } from '../../links'
import GateExists from '../../components/GateExists'

class TaasTeacherListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, teachers: [], error: null, searchTerm: '' }
    this.props.setAppBarState({
      visible: true,
      title: 'Teachers',
      rightIcon: null,
      canAdd: false,
      handleRight: () => {}
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.channel && !prevProps.channel) {
      this.props.setAppBarState({
        visible: true,
        title: `${this.props.channel.name} Teachers`,
        rightIcon: null,
        canAdd: false
      })
    }
  }

  handleSearch = searchTerm => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.setState({ searchTerm }), 300)
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, teachers: [] })
      const teachers = await this.props.fetchTeachers()
      this.setState({ loading: false, teachers })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError, teachers, searchTerm } = this.state

    return (
      <Screen
        name="teachers"
        isLoading={isLoading || loading}
        error={propError}>
        <GateExists exists={this.props.channel && this.props.organization}>
          <TaasTeacherList
            {...this.props}
            teachers={teachers}
            searchTerm={searchTerm}
            onSearch={this.handleSearch}
            error={stateError}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  onTeacherEnrolments: teacher =>
    ownProps.history.push(taasTeacherLink(teacher, `/enrolments`)),
  fetchTeachers: () => fetchTeachers(ownProps.match.params.channel)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasTeacherListContainer)
