import React from 'react'
import SearchQueryContainer from '../containers/ConnectSearchQueryContainer'

const query = {
  user: ['4ee87ca9-e2ee-47d7-aac2-7c6c535fbfc7'],
  $or: [
    { course: ['a82c564b-dc1d-4c35-b435-8c54f90b91cf'] },
    { user: ['4ee87ca9-e2ee-47d7-aac2-7c6c535fbfc7'] }
  ]
}

const Test = () => <SearchQueryContainer query={query} onChange={console.log} />

export default Test
