import React from 'react'
import PropTypes from 'prop-types'
import ClockIcon from 'mdi-react/ClockIcon'
import ErrorMessage from '../components/ErrorMessage'
import { isVersionDeprecated } from '../helpers'
import FeaturedFileListItem from '../components/FeaturedFileListItem'

const FeaturedFileList = props => {
  return (
    <ul className="featuredFileList">
      {props.files.map((file, i) => {
        if (file.version) {
          //if files have one deprecated and one not, don't show error message for deprecated file
          if (
            isVersionDeprecated(file.version, file.type, props.isSuperAdmin) &&
            props.files.length === 2
          ) {
            return null
          }
          //if one deprecated file present, show error message
          if (
            isVersionDeprecated(file.version, file.type, props.isSuperAdmin) &&
            props.files.length === 1
          ) {
            return (
              <ErrorMessage
                key={i}
                icon={<ClockIcon />}
                message="There are no files available"
              />
            )
          }
        }

        return (
          <FeaturedFileListItem
            key={i}
            {...file}
            download={id => props.download(id)}
            showLmsChips={props.showLmsChips}
          />
        )
      })}
      <style>{`
      ul.featuredFileList {
        list-style-type: none;
        padding: 0;
      }

      ul.featuredFileList > li {
        margin-left: 2rem;
        margin-right: 2rem;
      }

      ul.featuredFileList > li::after {
        content: "";
        margin: 14px -2rem;
        display: block;
        width: calc(100% + 4rem);
        border-bottom: 1px solid #e1e1e1;
      }

      /* <style> is the last-child */
      ul.featuredFileList li:nth-last-child(2)::after {
        border-bottom: 0 !important;
      }
    `}</style>
    </ul>
  )
}

FeaturedFileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape(FeaturedFileListItem.propTypes))
    .isRequired,
  download: PropTypes.func,
  showLmsChips: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default FeaturedFileList
