import joi from 'joi'
import moment from 'moment-timezone'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'

export default ({ enrolment = {}, courses, onSubmit, ...props }) =>
  createForm({
    fields: [
      {
        id: 'courseids',
        label: 'Courses',
        inputComponent: SelectInput,
        inputOptions: {
          label: 'Courses',
          options: (courses || []).map(course => ({
            value: course.id,
            label: course.title
          })),
          isMulti: true,
          isSearchable: true,
          addAllButton: true
        },
        required: true,
        validation: joi.array(),
        defaultValue: []
      },
      {
        id: 'reference',
        label: 'Reference / External ID',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: enrolment.reference
      }
    ],
    layoutOptions: {
      submitText: 'Save'
    },
    onSubmit: async data => {
      data.startdate = moment()
        .subtract(1, 'day')
        .tz('Etc/UTC')
        .toISOString()
      data.enddate = moment()
        .add(5, 'year')
        .tz('Etc/UTC')
        .toISOString()
      return onSubmit(data)
    },
    ...props
  })
