import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import csv from 'csv-parse/lib/sync'

class FileCsvInput extends Component {
  static defaultProps = {
    value: '',
    type: 'text',
    placeholder: '',
    fieldSuffix: ''
  }

  constructor(props) {
    super(props)
    this.state = { file: null, error: null }
  }

  handleDrop = async files => {
    if (!files.length) {
      return
    }

    const [file] = files
    if (!file.name.match(/\.csv$/i)) {
      this.setState({ error: 'Please upload a CSV file' })
      return
    }

    this.props.onBlur(this.props.id)
    const data = await this.getData(files[0])
    this.props.onChange(this.props.id, data)
    this.setState({ file, error: null })
  }

  getData = async file => {
    const content = await this.getFileContents(file)
    const parsed = await this.parseCsv(content)
    return parsed
  }

  getFileContents = file => {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsBinaryString(file)
    })
  }

  parseCsv = async rawCsv => {
    const parser = csv(rawCsv, {
      columns: true,
      bom: true,
      skip_empty_lines: true
    })
    const parsed = []
    for (const record of parser) {
      parsed.push(record)
    }

    return parsed
  }

  handleRemove = () => {
    this.setState({ file: null, error: null })
    this.props.onChange(this.props.id, null)
  }

  render() {
    const { id, label } = this.props
    const { file, error } = this.state
    return (
      <div className="text-input">
        {error && <div>{error}</div>}
        {file ? (
          <div>
            <strong>{file.name}</strong>
            <button onClick={this.handleRemove}>x</button>
          </div>
        ) : (
          <div style={{ margin: '20px 0' }}>
            <FormLabel>{label}</FormLabel>
            <Dropzone
              id={id}
              acceptClassName="dropzoneAccept"
              // accept="text/csv"
              multiple={false}
              onDrop={this.handleDrop}
            />
          </div>
        )}
      </div>
    )
  }
}

FileCsvInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fieldSuffix: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  multiline: PropTypes.bool,
  label: PropTypes.string
}

FileCsvInput.displayName = 'FileCsvInput'

export default FileCsvInput
