import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Card from '@material-ui/core/Card'
import NotificationListItem from '../components/NotificationListItem'
import NotificationFileList from '../components/NotificationFileList'
import ErrorDialog from '../components/ErrorDialog'
import CreateNotificationDialog from '../containers/ConnectCreateNotificationDialog'
import NotificationFileDialog from '../containers/ConnectNotificationFileDialog'
import NoteTile from '../components/NoteTile'
import PencilIcon from 'mdi-react/PencilIcon'

class Notification extends Component {
  static propTypes = {
    notification: PropTypes.object,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    isSuperAdmin: PropTypes.bool,
    dismissErrors: PropTypes.func,
    setAppBarState: PropTypes.func,
    isCreateNotificationDialogOpen: PropTypes.bool,
    isAttachFileDialogOpen: PropTypes.bool,
    sendNotificationEmail: PropTypes.func,
    fetchFiles: PropTypes.func,
    files: PropTypes.array,
    isFetchingFiles: PropTypes.bool,
    onFileDownload: PropTypes.func,
    onFileDelete: PropTypes.func
  }

  state = {
    notification: {
      id: '',
      message: '',
      courses: [],
      notes: ''
    },
    isCreateNotificationDialogOpen: false,
    isAttachFileDialogOpen: false
  }

  constructor(props) {
    super(props)

    props.setAppBarState({
      visible: true,
      title: 'Notification',
      rightIcon: <PencilIcon />,
      handleRight: this.toggleNotificationsDialog
    })
  }

  componentDidMount() {
    this.props.fetchFiles()
  }

  handleSendClick = (e, id, emailSent) => {
    e.preventDefault()
    e.stopPropagation()

    if (
      emailSent &&
      !confirm(
        'This email has already been sent. Are you sure you want to re-send it?'
      )
    ) {
      return
    }

    this.props.sendNotificationEmail(id)
  }

  static getDerivedStateFromProps(props) {
    if (props.notification) return { notification: props.notification }

    return null
  }

  toggleNotificationsDialog = () => {
    this.setState({
      isCreateNotificationDialogOpen: !this.state.isCreateNotificationDialogOpen
    })
  }

  toggleAttachFileDialog = () => {
    this.setState({
      isAttachFileDialogOpen: !this.state.isAttachFileDialogOpen
    })
  }

  render() {
    if (!this.state.notification.id.length === 0 && !this.props.isFetching) {
      return <Redirect to={'/404'} />
    }

    const errors = Object.keys(this.props.error)

    return (
      <div className="main">
        {this.props.isFetching && (
          <div className="loading">
            <CircularProgress size={50} />
          </div>
        )}
        {!this.props.isFetching && (
          <div>
            {this.props.isSuperAdmin && (
              <NoteTile notes={this.state.notification.notes} />
            )}
            <Card>
              <NotificationListItem
                cursor="default"
                onClick={() => {}}
                onSendClick={e =>
                  this.handleSendClick(
                    e,
                    this.props.notification.id,
                    this.props.notification.emailSent
                  )
                }
                notification={this.state.notification}
                courseLimit={undefined}
                messageLimit={undefined}
                isSuperAdmin={this.props.isSuperAdmin}
                showFiles
                files={this.props.files}
                isFetchingFiles={this.props.isFetchingFiles}
                onShowAttachDialog={this.toggleAttachFileDialog}
                onFileDownload={this.props.onFileDownload}
              />
            </Card>

            <NotificationFileList
              showFiles
              files={this.props.files}
              isFetching={this.props.isFetchingFiles}
              isSuperAdmin={this.props.isSuperAdmin}
              onShowAttachDialog={this.toggleAttachFileDialog}
              onDownload={this.props.onFileDownload}
              onDelete={this.props.onFileDelete}
            />
          </div>
        )}

        {errors.map(
          type =>
            type !== 'fetch' && (
              <ErrorDialog
                key={type}
                active={this.props.error[type] ? true : false}
                actions={[
                  {
                    label: 'Ok',
                    onClick: () => this.props.dismissErrors(type)
                  }
                ]}
                error={this.props.error[type]}
              />
            )
        )}

        {this.state.isCreateNotificationDialogOpen && (
          <CreateNotificationDialog
            key={`${this.state.notification.id}`}
            title="Edit Notification"
            active={this.state.isCreateNotificationDialogOpen}
            toggleActive={this.toggleNotificationsDialog}
            notification={this.state.notification}
          />
        )}
        {this.state.isAttachFileDialogOpen && (
          <NotificationFileDialog
            key={`${this.state.notification.id}`}
            active={this.state.isAttachFileDialogOpen}
            toggleActive={this.toggleAttachFileDialog}
            notification={this.state.notification}
            input={{}}
            isAdding={false}
            fetchFiles={this.props.fetchFiles}
          />
        )}
      </div>
    )
  }
}

export default Notification
