export function jsonToCsv(rows, columns) {
  const headings = columns.join(',')
  const newline = `\r\n`
  const body = rows
    .map(
      row =>
        columns
          .map(column => row[column])
          .map(String) // convert every value to String
          .map(v => v.replaceAll('"', '""')) // escape double colons
          .map(v => `"${v}"`) // quote it
          .join(',') // comma-separated
    )
    .join(newline) // rows starting on new lines

  return `${headings}${newline}${body}`
}
