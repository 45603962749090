import { fetchLti1p3ApplicationConfigs } from '../requests/channels'
import { useRequest } from './useRequest'

export const useLtiConfig = () => {
  const { result, isFetching, error } = useRequest(
    fetchLti1p3ApplicationConfigs
  )

  return { ltiConfig: result, isFetchingLti: isFetching, ltiError: error }
}
