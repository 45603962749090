import React from 'react'
import joi from 'joi'
import slugify from 'slugify'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'
import ToggleInput from '../../components/form/elements/ToggleInput'
import ServerError from '../../components/ServerError'

export default ({ channel, ...props }) => {
  const options = [
    { value: 'buzz', label: 'Buzz' }
    // buzz2 temporarily disabled
    // { value: 'buzz2', label: 'Buzz 2' }
  ].filter(row => !channel[row.value + 'Ids'])

  if (!options.length) {
    const err = new Error('All buzz instances configured')
    return <ServerError error={err} />
  }

  return createForm({
    fields: [
      {
        id: 'instanceId',
        label: 'Instance',
        inputComponent: SelectInput,
        inputOptions: {
          options
        },
        required: true,
        validation: joi.string()
      },
      {
        id: 'taas',
        label: 'TAAS Domain?',
        inputComponent: ToggleInput,
        inputOptions: {
          options
        },
        required: true,
        defaultValue: channel.taas,
        validation: joi.boolean()
      },
      {
        id: 'parentId',
        label: 'Override Parent ID',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow('')
      },
      {
        id: 'name',
        label: 'Name',
        inputComponent: TextInput,
        required: true,
        defaultValue: channel.name,
        validation: joi.string()
      },
      {
        id: 'userspace',
        label: 'Userspace / Subdomain',
        inputComponent: TextInput,
        required: true,
        defaultValue: slugify(channel.name, { lower: true }),
        validation: joi.string()
      },
      {
        id: 'reference',
        label: 'Reference',
        inputComponent: TextInput,
        validation: joi.string().allow('')
      }
    ],
    layoutOptions: {
      submitText: 'Create Domain'
    },
    ...props
  })
}
