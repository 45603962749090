const initialBulkDownloader = {
  stage: 'idle',
  coursesQueued: [],
  coursesProcessing: [],
  coursesCompleted: [],
  downloadsQueued: [],
  downloadsProcessing: [],
  downloadsCompleted: []
}

const initial = {
  items: [],
  isLoading: false,
  fetchError: null,
  orgItems: [],
  orgIsLoading: false,
  orgFetchError: null,
  ...initialBulkDownloader
}

export default function downloads(state = initial, action) {
  switch (action.type) {
    case 'DOWNLOADS_FETCH_START':
      return {
        ...state,
        isLoading: true,
        fetchError: null
      }

    case 'DOWNLOADS_FETCH_FINISH':
      return {
        ...state,
        items: action.payload,
        isLoading: false
      }

    case 'DOWNLOADS_FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        fetchError: action.payload
      }

    case 'DOWNLOAD_HISTORY_FETCH_START':
      return {
        ...state,
        orgIsLoading: true,
        orgFetchError: null
      }

    case 'DOWNLOAD_HISTORY_FETCH_FINISH':
      return {
        ...state,
        orgItems: action.payload,
        orgIsLoading: false
      }

    case 'DOWNLOAD_HISTORY_FETCH_FAILURE':
      return {
        ...state,
        orgIsLoading: false,
        orgFetchError: action.payload
      }

    case 'S3GETURL_SUCCESS':
      return {
        ...state,
        items: state.items.concat([
          {
            id: Math.random(),
            file: action.payload,
            date: new Date().toISOString()
          }
        ])
      }

    case 'BULK_DOWNLOAD_SCAN_START':
      return {
        ...initial,
        stage: 'gathering',
        coursesQueued: action.payload
      }

    case 'BULK_DOWNLOAD_SCAN_COURSE_START':
      return {
        ...state,
        coursesQueued: state.coursesQueued.filter(id => id !== action.payload),
        coursesProcessing: state.coursesProcessing.concat([action.payload])
      }

    case 'BULK_DOWNLOAD_SCAN_COURSE_FINISH':
      return {
        ...state,
        coursesProcessing: state.coursesProcessing.filter(
          id => id !== action.payload
        ),
        coursesCompleted: state.coursesCompleted.concat([action.payload])
      }

    case 'BULK_DOWNLOAD_SCAN_FINISH':
      return {
        ...state,
        stage: 'downloading',
        coursesCompleted: [],
        downloadsQueued: action.payload
      }

    case 'BULK_DOWNLOAD_DOWNLOAD_START':
      return {
        ...state,
        stage: 'downloading'
      }

    case 'BULK_DOWNLOAD_DOWNLOAD_FILE_START':
      return {
        ...state,
        downloadsQueued: state.downloadsQueued.filter(
          id => id !== action.payload
        ),
        downloadsProcessing: state.downloadsProcessing.concat([action.payload])
      }

    case 'BULK_DOWNLOAD_DOWNLOAD_FILE_FINISH':
      return {
        ...state,
        items: state.items.concat([
          {
            id: Math.random(),
            file: action.payload,
            date: new Date().toISOString()
          }
        ]),
        downloadsProcessing: state.downloadsProcessing.filter(
          id => id !== action.payload
        ),
        downloadsCompleted: state.downloadsCompleted.concat([action.payload])
      }

    case 'BULK_DOWNLOAD_DOWNLOAD_FINISH':
      return {
        ...state,
        stage: 'complete'
      }

    case 'BULK_DOWNLOAD_RESET':
      return {
        ...state,
        ...initialBulkDownloader
      }

    default:
      return state
  }
}
