import React from 'react'
import { connect } from 'react-redux'
import TaasObserverEdit from './TaasObserverEdit'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import { channelsFetchAwait } from '../../actions/channels'
import { updateObserver, fetchObserver } from '../../actions/taas'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'
// import { taasObserver } from '../../links'

class TaasObserverEditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      observer: null,
      loading: false,
      submitting: false,
      error: null
    }
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true })
      const observer = await this.props.fetchObserver()
      this.setState({ loading: false, observer })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  render() {
    const { isLoading, error: propError } = this.props
    const { loading, error: stateError } = this.state

    return (
      <Screen
        name="observers"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel && this.props.organization && this.state.observer
          }>
          <TaasObserverEdit
            {...this.props}
            observer={this.state.observer}
            error={stateError}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchObserver: () =>
    fetchObserver(
      ownProps.match.params.channel,
      ownProps.match.params.observer
    ),
  onSubmit: data =>
    dispatch(
      updateObserver(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        ownProps.match.params.observer,
        data
      )
    )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasObserverEditContainer)
