import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ClockIcon from 'mdi-react/ClockIcon'
import ErrorMessage from '../components/ErrorMessage'
import FeaturedFileList from '../components/FeaturedFileList'
import { courseFilesByType } from '../helpers'
import { getFiles } from '../selectors'

const edlUrl = 'http://edynamiclearning.com'

const mapStateToProps = (state, ownProps) => {
  const { courseId, ...rest } = ownProps
  const files = []
  const filesByType = courseFilesByType(courseId, getFiles(state))

  const isSuperAdmin = state.auth.userData.permission === 'superadmin'

  // Only add file if type exists
  Object.keys(filesByType).forEach(type => {
    if (type === 'guide') {
      return
    }

    if (type === 'package' || type === 'guide') {
      const file = filesByType[type] && filesByType[type][0]
      if (file) {
        if (type === 'package') {
          file.title = 'Course Package'
        } else if (type === 'guide') {
          file.title = 'Teacher Guide'
        }

        files.push(file)
      }
    }
  })

  return {
    files,
    ...rest,
    isSuperAdmin
  }
}

const FeaturedFileListOrErrorMessage = props =>
  !props.organizationSubscribesToCourse ? (
    <ErrorMessage
      message={`You are not currently subscribed to this course.
To subscribe, please contact the eDynamic Team at [www.edynamiclearning.com](${props.courseUrl ||
        edlUrl}) and request access.`}
    />
  ) : props.files.length ? (
    <FeaturedFileList {...props} />
  ) : (
    <ErrorMessage
      icon={<ClockIcon />}
      message="There are no files uploaded yet"
    />
  )

FeaturedFileListOrErrorMessage.propTypes = {
  files: PropTypes.array,
  organizationSubscribesToCourse: PropTypes.bool,
  courseUrl: PropTypes.string,
  isSuperAdmin: PropTypes.bool
}

export default connect(mapStateToProps)(FeaturedFileListOrErrorMessage)
