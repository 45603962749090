const initial = {
  isFetching: false,
  isCreating: false,
  isEditing: false,
  isAssigning: false,
  items: [],
  isSelecting: false,
  intent: null,
  error: {
    fetch: null,
    create: null,
    assign: null,
    delete: null
  }
}

function courseFolders(state = initial, action) {
  switch (action.type) {
    case 'COURSE_FOLDERS_FETCH':
      return {
        ...state,
        isFetching: true,
        items: [],
        error: {
          ...state.error,
          fetch: null
        }
      }

    case 'COURSE_FOLDERS_FETCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        items: action.payload
      }

    case 'COURSE_FOLDERS_FETCH_FALIURE':
      return {
        ...state,
        isFetching: false,
        error: {
          ...state.error,
          fetch: action.payload
        }
      }

    case 'COURSE_FOLDERS_SELECT_START':
      return {
        ...state,
        isSelecting: true,
        intent: action.payload
      }

    case 'COURSE_FOLDERS_SELECT_CANCEL':
      return {
        ...state,
        isSelecting: false,
        intent: null
      }

    case 'COURSE_FOLDERS_ASSIGN_FINISH':
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.payload.courseFolderId) {
            return {
              ...item,
              courses: action.payload.courses
            }
          }
          return item
        }),
        isSelecting: false,
        intent: null
      }

    case 'COURSE_FOLDERS_CREATE_START':
      return {
        ...state,
        isCreating: true,
        error: {
          ...state.error,
          create: null
        }
      }

    case 'COURSE_FOLDERS_CREATE_FINISH':
      return {
        ...state,
        items: state.items.concat([action.payload]),
        isCreating: false
      }

    case 'COURSE_FOLDERS_CREATE_FAILURE':
      return {
        ...state,
        isCreating: false,
        error: {
          ...state.error,
          create: action.payload
        }
      }

    case 'COURSE_FOLDERS_DELETE_START':
      return {
        ...state,
        error: {
          ...state.error,
          delete: null
        },
        items: state.items.map(row => {
          if (row.id === action.payload) {
            return {
              ...row,
              deleting: true
            }
          }
          return row
        })
      }

    case 'COURSE_FOLDERS_DELETE_FINISH': {
      return {
        ...state,
        items: state.items.filter(row => row.id !== action.payload)
      }
    }

    case 'COURSE_FOLDERS_DELETE_FAILURE':
      return {
        ...state,
        error: {
          ...state.error,
          delete: action.payload
        },
        items: state.items.map(row => {
          if (row.id === action.payload) {
            return {
              ...row,
              deleting: false
            }
          }
          return row
        })
      }

    default:
      return state
  }
}

export default courseFolders
