import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import PencilIcon from 'mdi-react/PencilIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import FormattedDate from './FormattedDate'
import Link from './Link'

const buildCourseLink = enrolment =>
  `/organizations/${enrolment.organization}/channels/${
    enrolment.channel
  }/taas/courses/${enrolment.course.id || enrolment.course}`
const buildUserLink = enrolment =>
  `/organizations/${enrolment.organization}/channels/${enrolment.channel}/taas/${enrolment.user.reference}s/${enrolment.user.id}`

const TaasEnrolmentListItem = ({ enrolment, onEdit, onDelete }) => (
  <li>
    {enrolment.course && enrolment.course.id ? (
      <Link to={buildCourseLink(enrolment)}>
        <strong>{enrolment.course.title}</strong>
      </Link>
    ) : (
      <Link to={buildUserLink(enrolment)}>
        <strong>
          {enrolment.user.firstname} {enrolment.user.lastname}
        </strong>
      </Link>
    )}
    <div>
      <small>
        from <FormattedDate date={enrolment.startdate} /> to{' '}
        <FormattedDate date={enrolment.enddate} />
      </small>
    </div>

    <IconButton onClick={() => onEdit(enrolment)}>
      <PencilIcon />
    </IconButton>

    <IconButton onClick={() => onDelete(enrolment)}>
      <DeleteIcon />
    </IconButton>
  </li>
)

TaasEnrolmentListItem.propTypes = {
  enrolment: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default TaasEnrolmentListItem
