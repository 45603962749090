import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 24px;

  .details {
    flex-grow: 1;
    width: 100%;
  }

  .channel {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .name {
    font-weight: bold;
    margin-right: 10px;
    line-height: 28px;
  }

  .chip {
    flex-shrink: 0;
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .edit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

// eslint-disable-next-line
const ChannelListItem = ({ children }) => <Container>{children}</Container>

export default ChannelListItem
