import React from 'react'
import { connect } from 'react-redux'
import AdminCreate from './AdminCreate'
import Screen from '../../components/Screen'
import { createAdmin, fetchRoles } from '../../actions/admins'
import { setAppBarState } from '../../actions/ui'

class AdminCreateContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      roles: []
    }
    props.setAppBarState({
      visible: true,
      title: 'Admins'
    })
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true, roles: [], loadingError: null })
      const roles = await this.props.fetchRoles()
      this.setState({ loading: false, roles })
    } catch (e) {
      this.setState({ loading: false, loadingError: e.message })
    }
  }

  render() {
    return (
      <Screen name="admin create" error={this.props.error}>
        <AdminCreate roles={this.state.roles} {...this.props} />
      </Screen>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  onSubmit: data => dispatch(createAdmin(data)),
  fetchRoles: () => fetchRoles()
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCreateContainer)
