import moment from 'moment-timezone'
import { orderBy } from 'lodash'

export const generateEndDate = (dateStart, dateOptions) => {
  const matching = dateOptions.find(option => {
    if (option.start.toString() === dateStart.toString()) {
      return true
    }
    return false
  })

  if (matching) {
    return matching.end
  }
}

export const generateDateOptions = (partner, lob) => {
  const reportingSchedule = lob.reportingSchedule

  if (reportingSchedule.frequency === 'monthly') {
    return generateDateOptionMonths()
  }

  if (reportingSchedule.frequency === 'quarterly') {
    return generateQuarterDateOptions(reportingSchedule.firstQuarter)
  }

  if (reportingSchedule.frequency === 'periods') {
    return generatePeriodDateOptions(reportingSchedule.periods)
  }

  throw new Error(`unknown reporting frequency: ${reportingSchedule.frequency}`)
}

const generateDateOptionMonths = (yearsBack = 4) => {
  const out = []
  for (let i = 0; i < 12 * yearsBack; i++) {
    const date = moment()
      .startOf('month')
      .subtract(i, 'months')

    out.push({
      start: date.toDate(),
      end: moment(date)
        .add(1, 'month')
        .toDate(),
      option: {
        value: date.toDate(),
        label: moment(date).format('MMM YYYY')
      }
    })
  }

  return out
}

const generateQuarterDateOptions = (
  firstQuarter,
  yearsBack = 4,
  now = new Date()
) => {
  const rows = []

  for (let i = 0; i <= yearsBack; i++) {
    const firstQuarterOfYear = moment(now)
      .subtract(i, 'years')
      .month(firstQuarter)
      .startOf('month')
      .toDate()
    for (let q = 0; q < 4; q++) {
      const start = moment(firstQuarterOfYear)
        .add(q, 'quarters')
        .toDate()
      const end = moment(firstQuarterOfYear)
        .add(q + 1, 'quarters')
        .add(-1, 'day')
        .toDate()

      const option = {
        value: start,
        label: `Q${q + 1} ${moment(start).format('MMM D')} - ${moment(
          end
        ).format('MMM D')}
    ${moment(start).format('YYYY')}`
      }

      rows.push({ start, end, option })
    }
  }

  return rows
}

const generatePeriodDateOptions = (
  periods,
  yearsBack = 4,
  now = new Date()
) => {
  const rows = []

  for (let i = 0; i <= yearsBack; i++) {
    const startOfYear = moment(now)
      .subtract(i, 'years')
      .startOf('year')
      .toDate()

    periods.forEach(period => {
      const start = forwardToMonth(moment(startOfYear), period.start)
        .startOf('month')
        .toDate()
      const end = forwardToMonth(start, period.end)
        .endOf('month')
        .toDate()

      const option = {
        value: start,
        label: `${moment(start).format('MMM D YYYY')} - ${moment(end).format(
          'MMM D YYYY'
        )}`
      }

      rows.push({ start, end, option })
    })
  }

  return orderBy(rows, 'start', ['desc'])
}

const forwardToMonth = (mt, monthName) => {
  const months = moment.months()
  while (months[moment(mt).month()] !== monthName) {
    mt = moment(mt).add(1, 'month')
  }
  return mt
}
