import axios from 'axios'
import { getToken } from '../auth'
import { fetchOrganizationsByIdsAwait } from './organizations'
import { uniq } from 'lodash'

const fetchTagsStart = tags => ({
  type: 'FETCH_TAGS_START',
  tags
})

const fetchTagsSuccess = tags => ({
  type: 'FETCH_TAGS_SUCCESS',
  tags: tags.data,
  totalPages: tags.metadata.pagination.totalPages
})

const fetchTagsFailure = error => ({
  type: 'FETCH_TAGS_FAILURE',
  error
})

const fetchTags = async (page, limit, search) => {
  const searchQuery = search ? `search=${search}&` : ''
  const result = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_REMOTE}/v2/tags?${searchQuery}page=${page}&limit=${limit}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export const fetchTagsAwait = (page, limit, search) => dispatch => {
  if (!getToken()) return dispatch(fetchTagsFailure(new Error('no token')))
  dispatch(fetchTagsStart())
  return fetchTags(page || 1, limit || 25, search).then(
    result => {
      dispatch(fetchTagsSuccess(result))

      const orgIds = result.data
        .filter(
          tag =>
            Array.isArray(tag.query.organization) &&
            tag.query.organization.length > 0
        )
        .flatMap(tag => tag.query.organization)

      if (Array.isArray(orgIds) && orgIds.length > 0) {
        dispatch(fetchOrganizationsByIdsAwait(orgIds))
      }

      const tagNames = result.data
        .filter(tag => Array.isArray(tag.query.tag) && tag.query.tag.length > 0)
        .flatMap(tag => tag.query.tag)

      if (Array.isArray(tagNames) && tagNames.length > 0) {
        const tagNamesWithDuplicatesRemoved = uniq(
          result.data.map(tag => tag.name).concat(tagNames)
        )
        dispatch(fetchTagsByIdsAwait(tagNamesWithDuplicatesRemoved))
      }
    },
    error => dispatch(fetchTagsFailure(error))
  )
}

const fetchTagsByIds = async ids => {
  const result = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_REMOTE}/v2/tags?ids=${ids}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

const fetchingOrganizationsByIds = () => {
  const payload = 'Loading tags'
  return { type: 'FETCH_TAGS_BY_IDS', payload }
}

const fetchTagsByIdsSuccess = tags => ({
  type: 'FETCH_TAGS_BY_IDS_SUCCESS',
  tags: tags.data
})

const fetchTagsByIdsFailure = error => ({
  type: 'FETCH_TAGS_BY_IDS_FAILURE',
  error
})

export const fetchTagsByIdsAwait = ids => {
  return function(dispatch) {
    if (!getToken()) return dispatch(fetchTagsByIdsFailure())

    dispatch(fetchingOrganizationsByIds())
    return fetchTagsByIds(ids).then(
      result => dispatch(fetchTagsByIdsSuccess(result)),
      err => dispatch(fetchTagsByIdsFailure(err))
    )
  }
}

const saveTagStart = (name, query) => ({
  type: 'SAVE_TAG_START',
  name,
  query
})

const saveTagSuccess = (name, query) => ({
  type: 'SAVE_TAG_SUCCESS',
  name,
  query
})

const saveTagFailure = error => ({
  type: 'SAVE_TAG_FAILURE',
  error
})

const saveTag = async (name, query) => {
  const { data } = await axios({
    method: 'PUT',
    data: query,
    url: process.env.REACT_APP_REMOTE + `/tags/${name}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return data
}

export const saveTagAwait = (name, query, creating = false) => (
  dispatch,
  getState
) => {
  if (!getToken()) return dispatch(saveTagFailure(new Error('no token')))

  if (creating && getState().tags.items.find(tag => tag.name === name)) {
    alert('Warning: already exists. Cancelling')
    return
  }

  dispatch(saveTagStart(name, query))

  if (!creating && !Object.keys(query).length) {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('This tag query is empty and has no effect. Are you sure?')) {
      return
    }
  }

  return saveTag(name, query).then(
    () => dispatch(saveTagSuccess(name, query)),
    error => dispatch(saveTagFailure(error))
  )
}

const deleteTagStart = name => ({
  type: 'DELETE_TAG_START',
  name
})

const deleteTagSuccess = name => ({
  type: 'DELETE_TAG_SUCCESS',
  name
})

const deleteTagFailure = (name, error) => ({
  type: 'DELETE_TAG_FAILURE',
  name,
  error
})

const deleteTag = async name => {
  await axios({
    method: 'DELETE',
    url: process.env.REACT_APP_REMOTE + `/tags/${name}`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
}

export const deleteTagAwait = name => dispatch => {
  // eslint-disable-next-line no-restricted-globals
  if (!confirm(`Are you sure you want to delete tag "${name}"`)) {
    return
  }

  if (!getToken())
    return dispatch(deleteTagFailure(name, new Error('no token')))
  dispatch(deleteTagStart(name))
  return deleteTag(name).then(
    () => dispatch(deleteTagSuccess(name)),
    error => dispatch(deleteTagFailure(name, error))
  )
}

export function suggestTagsAwait(query) {
  return suggestTags(query)
}

export async function suggestTags(query) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/tags/suggest',
    params: { query },
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export const editTag = name => ({
  type: 'EDIT_TAG',
  name
})

export const editTagChange = (name, query) => ({
  type: 'EDIT_TAG_CHANGE',
  name,
  query
})

export const editTagCancel = name => ({
  type: 'EDIT_TAG_CANCEL',
  name
})

export const createNewTag = () => dispatch => {
  const name = prompt('New Tag Name')
  if (!name) return
  dispatch(saveTagAwait(name, {}, true))
}
