import React from 'react'
import { connect } from 'react-redux'
import PartnerCourseReportingLob from './PartnerCourseReportingLob'
import Screen from '../../components/Screen'
import {
  partnerReportingPartnerFetch,
  partnerCourseMappingsSubmit
} from '../../actions/tools'
import { setAppBarState } from '../../actions/ui'
import { jsonToCsv } from '../../utilities/csv'

class PartnerReportingLobContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      partner: null,
      error: null
    }
    this.props.setAppBarState({
      visible: true,
      title: 'Partner Usage Reporting',
      rightIcon: null,
      handleRight: () => {}
    })
  }

  async componentDidMount() {
    try {
      const partner = await this.props.fetchPartner()
      this.setState({ partner, isLoading: false })
    } catch (e) {
      this.setState({ isLoading: false, error: e })
    }
  }

  handleDownloadMappings = () => {
    // generate csv string
    const csv = jsonToCsv(this.state.partner.courseMappings, ['code', 'alias'])

    // trigger download of csv
    const filename = `${this.state.partner.id}-mappings.csv`
    const a = document.createElement('a')
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    a.setAttribute('href', url)
    a.setAttribute('download', filename)
    a.click()
  }

  handleUploadMappings = async data => {
    await this.props.courseMappingsUpdate(this.state.partner, data)
  }

  render() {
    return (
      <Screen
        name="partner course usage reporting"
        isLoading={this.state.isLoading}
        error={this.props.error || this.state.error}
        renderContent={() => (
          <PartnerCourseReportingLob
            {...this.props}
            partner={this.state.partner}
            onDownloadMappings={this.handleDownloadMappings}
            onUploadMappings={this.handleUploadMappings}
          />
        )}
      />
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPartner: () =>
    partnerReportingPartnerFetch(ownProps.match.params.partner),
  courseMappingsUpdate: (partner, mappings) =>
    dispatch(partnerCourseMappingsSubmit(partner, mappings)),
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState))
})

export default connect(null, mapDispatchToProps)(PartnerReportingLobContainer)
