import React from 'react'
import { connect } from 'react-redux'
import ToolsBulkCopyCourses from './ToolsBulkCopyCourses'
import Screen from '../../components/Screen'
import { channelsGetAll, bulkCopyCourses } from '../../requests/channels'
import { setAppBarState } from '../../actions/ui'

class ToolsBulkCopyCoursesContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      channels: [],
      error: null,
      selected: [],
      errors: [],
      searchTerm: '',
      instanceId: 'buzz'
    }
    this.props.setAppBarState({
      visible: true,
      title: 'Bulk Copy Courses',
      rightIcon: null,
      handleRight: () => {}
    })
  }

  async componentDidMount() {
    try {
      const channels = await this.props.fetchChannels()
      const channelMap = channels.reduce(
        (out, channel) => ({
          ...out,
          [channel.id]: `${channel.name} (${channel.organization.name})`
        }),
        {}
      )

      this.setState({
        channels,
        channelMap,
        isLoading: false
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        error: e
      })
    }
  }

  handleSubmit = async data => {
    const items = await this.props.onSubmit({
      ...data,
      channels: this.state.selected
    })

    const itemsToUnselect = items
      .filter(row => row.status === 'OK')
      .map(row => row.id)
    this.setState({
      selected: this.state.selected.filter(id => !itemsToUnselect.includes(id)),
      errors: items.filter(row => row.status === 'ERROR')
    })
  }

  handleSelect = channel => {
    if (!channel.buzzIds && !channel.buzz2Ids) {
      return
    }

    if (this.state.selected.includes(channel.id)) {
      this.setState({
        selected: this.state.selected.filter(id => id !== channel.id)
      })
    } else {
      this.setState({ selected: this.state.selected.concat([channel.id]) })
    }
  }

  handleSearchChange = searchTerm => {
    this.setState({ searchTerm })
  }

  handleFormChange = ({ instanceId }) => {
    if (this.state.instanceId !== instanceId) {
      alert('Clearing selected courses')
      this.setState({ instanceId, selected: [] })
    } else {
      this.setState({ instanceId })
    }
  }

  filterChannels(channels) {
    if (!this.state.instanceId) {
      return channels
    }

    const field = `${this.state.instanceId}Ids`
    return channels.filter(row => row[field])
  }

  render() {
    const { error: propError } = this.props
    const { error: stateError, isLoading, channels, selected } = this.state

    return (
      <Screen
        name="bulk copy courses"
        isLoading={isLoading}
        error={propError || stateError}
        renderContent={() => (
          <ToolsBulkCopyCourses
            {...this.props}
            channels={this.filterChannels(channels)}
            channelMap={this.state.channelMap}
            selected={selected}
            searchTerm={this.state.searchTerm}
            errors={this.state.errors}
            onSelect={this.handleSelect}
            onSearchChange={this.handleSearchChange}
            onFormChange={this.handleFormChange}
            onSubmit={this.handleSubmit}
          />
        )}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchChannels: () => channelsGetAll(),
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  onSubmit: data => dispatch(bulkCopyCourses(data))
})

export default connect(null, mapDispatchToProps)(ToolsBulkCopyCoursesContainer)
