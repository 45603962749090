import { connect } from 'react-redux'
import Organizations from './Organizations'
import { setAppBarState } from '../../actions/ui'
import { fetchOrganizationsAwait } from '../../actions/organizations'
import { isSuperAdmin, isObserver } from '../../selectors/auth'
import { createSelector } from 'reselect'

const selectOrganizationsById = state => state.organizations.items.byId
const selectOrganizationPageItemIds = state =>
  state.organizations.items.currentPageItemIds
const selectOrganizationCurrentPageItems = createSelector(
  [selectOrganizationsById, selectOrganizationPageItemIds],
  (entityMap, pageIds) => {
    return pageIds.map(id => entityMap[id])
  }
)

const mapStateToProps = state => {
  return {
    organizations: selectOrganizationCurrentPageItems(state),
    totalPages: state.organizations.totalPages,
    isFetching: state.organizations.isFetching,
    isSuperAdmin: isSuperAdmin(state),
    isObserver: isObserver(state),
    me: state.auth.userData
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => {
    dispatch(setAppBarState(appBarState))
  },
  fetchOrganizations: (page, limit, search, filter) => {
    dispatch(fetchOrganizationsAwait(page, limit, search, filter))
  },
  onClickAdd: () => {
    ownProps.history.push('/organizations/create')
  },
  onClickEdit: organization => {
    ownProps.history.push(`/organizations/${organization.id}/edit`)
  },
  onClickUsers: organization => {
    ownProps.history.push(`/organizations/${organization.id}/users`)
  },
  onClickChannels: organization => {
    ownProps.history.push(`/organizations/${organization.id}/channels`)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Organizations)
