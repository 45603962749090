import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Link,
  Tooltip
} from '@material-ui/core'
import EyeCheckIcon from 'mdi-react/EyeCheckOutlineIcon'
import EyeOffIcon from 'mdi-react/EyeOffOutlineIcon'
import styled from 'styled-components'
import green from '@material-ui/core/colors/green'

const darkGreen = green[700]

const ViewedStatusChip = styled(Chip)`
  && {
    color: ${({ viewed }) => (viewed ? darkGreen : 'darkgray')};
    border-color: ${({ viewed }) => (viewed ? darkGreen : 'darkgray')};
  }
`

const ViewedStatus = ({ viewed }) => (
  <ViewedStatusChip
    viewed={viewed}
    label={viewed ? 'Viewed' : 'Not Viewed Yet'}
    icon={
      viewed ? (
        <EyeCheckIcon color={darkGreen} />
      ) : (
        <EyeOffIcon color={'darkgray'} />
      )
    }
    variant="outlined"
  />
)

ViewedStatus.propTypes = {
  viewed: PropTypes.bool
}

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
`

const OutlinedLink = styled(Link)`
  && {
    width: 100%;
    text-align: center;
    padding: 0.5em;
    border: 1px solid #3f51b5;
    border-radius: 16px;

    &:hover {
      text-decoration: none;
      background-color: #3f51b5;
      color: white;
    }
  }
`

const Form = styled(FormGroup)`
  margin-top: 1.5em;
  width: 100%;
`

const Content = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AcceptanceLabel = styled(FormControlLabel)`
  && {
    width: fit-content;
    margin: 0 0 1.25em 0.5em;
  }
`

const ViewFirstTooltip = ({ disabled, children }) => (
  <Tooltip
    title="View document above first"
    placement="right"
    disableFocusListener={disabled}
    disableTouchListener={disabled}
    disableHoverListener={disabled}
    enterDelay={200}>
    {children}
  </Tooltip>
)

ViewFirstTooltip.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}

const TermsAndConditionsDialog = ({ open, handleCancel, handleContinue }) => {
  const [termsOfUse, setTermsOfUse] = useState({
    hasClicked: false,
    hasAccepted: false
  })
  const [privacyPolicy, setPrivacyPolicy] = useState({
    hasClicked: false,
    hasAccepted: false
  })

  const resetDialog = () => {
    setTermsOfUse({
      hasClicked: false,
      hasAccepted: false
    })

    setPrivacyPolicy({
      hasClicked: false,
      hasAccepted: false
    })
  }

  const allTasksComplete =
    termsOfUse.hasAccepted &&
    termsOfUse.hasClicked &&
    privacyPolicy.hasAccepted &&
    privacyPolicy.hasClicked

  return (
    <Dialog open={open}>
      <DialogTitle>Welcome to the eDynamic Learning Client Portal!</DialogTitle>
      <Content>
        <DialogContentText>
          Before you continue, you must read and accept our Terms of Use and
          Privacy Policy.
        </DialogContentText>
        <br />
        <DialogContentText>
          View the documents at the links below and check the acceptance box for
          each when you&apos;re ready. You <strong>must</strong> accept these
          agreements before continuing to use eDynamic Learning&apos;s Client
          Portal.
        </DialogContentText>
        <Form>
          <LinkWrapper>
            <OutlinedLink
              href="https://edynamiclearning.com/eula/"
              target="_blank"
              rel="noopener"
              onClick={() =>
                setTermsOfUse(prev => ({
                  ...prev,
                  hasClicked: true
                }))
              }>
              Terms of Use/EULA
            </OutlinedLink>
            <ViewedStatus viewed={termsOfUse.hasClicked} />
          </LinkWrapper>
          <ViewFirstTooltip disabled={termsOfUse.hasClicked}>
            <AcceptanceLabel
              control={
                <Checkbox
                  disabled={!termsOfUse.hasClicked}
                  checked={termsOfUse.hasAccepted}
                  onChange={event => {
                    event.persist()
                    setTermsOfUse(prev => ({
                      ...prev,
                      hasAccepted: event.target.checked
                    }))
                  }}
                  value="acceptTerms"
                  color="primary"
                />
              }
              labelPlacement="end"
              label="Accept Terms of Use"
            />
          </ViewFirstTooltip>

          <LinkWrapper>
            <OutlinedLink
              href="https://edynamiclearning.com/privacy-policy/"
              target="_blank"
              rel="referrer"
              onClick={() =>
                setPrivacyPolicy(prev => ({
                  ...prev,
                  hasClicked: true
                }))
              }>
              Privacy Policy
            </OutlinedLink>
            <ViewedStatus viewed={privacyPolicy.hasClicked} />
          </LinkWrapper>
          <ViewFirstTooltip disabled={privacyPolicy.hasClicked}>
            <AcceptanceLabel
              control={
                <Checkbox
                  disabled={!privacyPolicy.hasClicked}
                  checked={privacyPolicy.hasAccepted}
                  onChange={event => {
                    event.persist()
                    setPrivacyPolicy(prev => ({
                      ...prev,
                      hasAccepted: event.target.checked
                    }))
                  }}
                  value="acceptPrivacyPolicy"
                  color="primary"
                />
              }
              labelPlacement="end"
              label="Accept Privacy Policy"
            />
          </ViewFirstTooltip>
        </Form>
      </Content>
      <DialogActions>
        <Button
          onClick={async () => {
            if (await handleCancel()) resetDialog()
          }}
          color="secondary">
          Cancel
        </Button>
        <Button
          disabled={!allTasksComplete}
          onClick={async () => {
            await handleContinue()
            resetDialog()
          }}
          color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TermsAndConditionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired
}

export default TermsAndConditionsDialog
