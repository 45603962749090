import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../../components/Breadcrumbs'
import { generateBreadcrumbs } from '../links'
import TaasCourseEnrolmentForm from './TaasCourseStudentEnrolmentForm'

const TaasCourseEnrolmentCreate = ({
  channel,
  organization,
  course,
  users,
  onSubmit
}) => (
  <div>
    <Breadcrumbs
      links={generateBreadcrumbs(organization, channel, [
        {
          title: 'Courses',
          href: '/courses'
        },
        {
          title: course.title,
          href: `/courses/${course.id}`
        },
        {
          title: 'Student Enrollments',
          href: `/courses/${course.id}/student-enrolments`
        }
      ])}
    />
    <Card className="users">
      <CardHeader className="title" title="Enroll Student" />
      <CardContent className="description">
        <TaasCourseEnrolmentForm
          users={users}
          type="student"
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
  </div>
)

TaasCourseEnrolmentCreate.propTypes = {
  course: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedCourseId: PropTypes.string
}

export default TaasCourseEnrolmentCreate
