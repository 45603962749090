import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { contrastRatio } from 'chromatism'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ErrorMessage from '../components/ErrorMessage'

class CardWithHeader extends Component {
  render() {
    const background = this.props.color || '#c6c6c6;'
    const color = contrastRatio(background).hex
    return (
      <div className="card-with-header">
        <Card className="body">
          <CardHeader
            title={this.props.title}
            className="header"
            titleTypographyProps={{ className: 'title' }}
          />
          {this.props.children ? (
            this.props.children
          ) : (
            <ErrorMessage
              icon={this.props.defaultIcon}
              message={this.props.defaultMessage}
            />
          )}
        </Card>
        <style>{`
          .card-with-header {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
          .card-with-header .header {
            background: ${background};
          }
          .card-with-header .header .title {
            color: ${color};
          }
          .card-with-header .body {
            flex-grow: 1;
          }
        `}</style>
      </div>
    )
  }
}

CardWithHeader.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  color: PropTypes.string,
  defaultIcon: PropTypes.element,
  defaultMessage: PropTypes.string
}

export default CardWithHeader
