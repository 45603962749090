import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Link from '../../components/Link'
import can from '../../utilities/can'

const Tools = ({ user }) => (
  <div>
    <Card className="tools">
      <CardHeader className="title" title="Tools" />
      <CardContent>
        <ul>
          {can(user, 'MERGE_COURSE') && (
            <li>
              <Link to="/tools/merge-course">Merge Course</Link>
            </li>
          )}
          {can(user, 'SURVEYS_MANAGE') && (
            <li>
              <Link to="/tools/surveys">Manage Surveys</Link>
            </li>
          )}
          {can(user, 'CHANNELS_MANAGE_ALL') && (
            <li>
              <Link to="/tools/bulk-copy-courses">Bulk Copy Courses</Link>
            </li>
          )}
          {can(user, 'PARTNER_USAGE_REPORT') && (
            <li>
              <Link to="/tools/partner-course-usage-reporting">
                Partner Usage - Course Reporting
              </Link>
            </li>
          )}
          {can(user, 'USERS_EDIT_ALL') && (
            <li>
              <Link to="/tools/genius-remove-final-grade">
                Genius - Remove Final Grade
              </Link>
            </li>
          )}
        </ul>
      </CardContent>
    </Card>
  </div>
)

Tools.propTypes = {
  user: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
}

export default Tools
