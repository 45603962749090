import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import React from 'react'
import Chip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'

const EnableButton = () => (
  <Button
    variant={'contained'}
    size={'small'}
    color={'primary'}
    component={Link}
    primary
    to={'/multi-factor-authentication'}>
    Enable
  </Button>
)
const ConfiguredLabel = () => (
  <Chip label={'Configured'} variant={'outlined'} color={'primary'} />
)

export const MultiFactorAuthSection = ({ enabled }) => (
  <h3>
    Multi-factor Authentication {enabled && <ConfiguredLabel />}
    {!enabled && <EnableButton />}
  </h3>
)

MultiFactorAuthSection.propTypes = {
  enabled: PropTypes.bool.isRequired
}
