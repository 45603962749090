import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'

export const VerifyMultiFactorAuthentication = ({
  verifyCode,
  verificationErrorMessage,
  username
}) => {
  const [verificationCode, setVerificationCode] = useState('')
  return (
    <Card
      className={'login'}
      onKeyUp={e => {
        if (e.key === 'Enter') {
          verifyCode(verificationCode, username)
        }
      }}>
      <h3>Please Enter Your MFA Verification Code</h3>
      {verificationErrorMessage && (
        <div className="loginErrorMessage">{verificationErrorMessage}</div>
      )}
      <TextField
        onChange={e => setVerificationCode(e.target.value)}
        type="text"
        label="Verification Code"
        fullWidth
        margin="normal"
      />
      <div className="footer">
        <Button
          className="submit"
          onClick={() => {
            verifyCode(verificationCode, username)
          }}
          variant="raised"
          color="primary">
          Verify
        </Button>
      </div>
    </Card>
  )
}

VerifyMultiFactorAuthentication.propTypes = {
  verifyCode: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  verificationErrorMessage: PropTypes.string
}
