import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationList from '../components/NotificationList'
import ErrorMessage from '../components/ErrorMessage'
import ErrorDialog from '../components/ErrorDialog'
import CreateNotificationDialog from '../containers/ConnectCreateNotificationDialog'
import BellPlusIcon from 'mdi-react/BellPlusIcon'
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon'
import InboxIcon from 'mdi-react/InboxIcon'
import { Pagination } from '../components/pagination/Pagination'

class Notifications extends Component {
  static propTypes = {
    history: PropTypes.object,
    isSuperAdmin: PropTypes.bool,
    notifications: PropTypes.array,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    setAppBarState: PropTypes.func,
    dismissPostError: PropTypes.func,
    dismissSendError: PropTypes.func,
    sendNotificationEmail: PropTypes.func,
    fetchNotifications: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired
  }

  state = {
    isCreateNotificationDialogOpen: false
  }

  constructor(props) {
    super(props)

    props.setAppBarState({
      visible: true,
      title: 'Notifications',
      rightIcon: <BellPlusIcon />,
      handleRight: this.toggleNotificationsDialog
    })

    this.limit = 25
  }

  componentDidMount() {
    this.props.fetchNotifications(1, this.limit)
  }

  toggleNotificationsDialog = () => {
    this.setState({
      isCreateNotificationDialogOpen: !this.state.isCreateNotificationDialogOpen
    })
  }

  handleClick = (e, id) => {
    e.preventDefault()
    this.props.history.push(`/notification/${id}`)
  }

  handleSendClick = (e, id, emailSent) => {
    e.preventDefault()
    e.stopPropagation()

    if (
      emailSent &&
      !confirm(
        'This email has already been sent. Are you sure you want to re-send it?'
      )
    ) {
      return
    }

    this.props.sendNotificationEmail(id)
  }

  render() {
    const {
      isFetching,
      error,
      notifications,
      dismissPostError,
      dismissSendError,
      isSuperAdmin,
      fetchNotifications,
      totalPages
    } = this.props

    return (
      <div className="main home">
        {isFetching && (
          <div className="loading">
            <CircularProgress size={50} />
          </div>
        )}
        {error.fetch && !isFetching && (
          <ErrorMessage
            icon={<AlertCircleOutlineIcon />}
            message={error.fetch}
          />
        )}
        {notifications &&
          notifications.length === 0 &&
          !isFetching &&
          !error.fetch && (
            <ErrorMessage
              icon={<InboxIcon />}
              message={'No notifications to display'}
            />
          )}

        {error.add && (
          <ErrorDialog
            active={error.add ? true : false}
            actions={[{ label: 'Ok', onClick: dismissPostError }]}
            error={error.add}
          />
        )}

        {error.send && (
          <ErrorDialog
            active={error.send ? true : false}
            actions={[{ label: 'Ok', onClick: dismissSendError }]}
            error={error.send}
          />
        )}

        <NotificationList
          notifications={notifications}
          onClick={(e, id) => this.handleClick(e, id)}
          onSendClick={(e, id, emailSent) =>
            this.handleSendClick(e, id, emailSent)
          }
          courseLimit={3}
          messageLimit={50}
          messageUpperLimit={10000}
          isSuperAdmin={isSuperAdmin}
        />

        <Pagination
          fetch={fetchNotifications}
          limit={this.limit}
          totalPages={totalPages}
        />

        <CreateNotificationDialog
          title="Create Notification"
          active={this.state.isCreateNotificationDialogOpen}
          toggleActive={this.toggleNotificationsDialog}
        />
      </div>
    )
  }
}

export default Notifications
