import AppLayout from '../components/AppLayout'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setNavDrawerPinnedState } from '../actions/ui'
import { awaitUserData } from '../actions/auth'
import { fetchOrganizationsAwait } from '../actions/organizations'
import { fetchNotificationsAwait } from '../actions/notifications'
import { fetchUsersAwait } from '../actions/users'
import { fetchCoursesAwait } from '../actions/courses'
import { fetchCourseFoldersAwait } from '../actions/courseFolders'
import { fetchFilesAwait } from '../actions/files'
import { fetchTagsAwait } from '../actions/tags'
import { downloadsFetchAwait } from '../actions/downloads'

function mapStateToProps(state) {
  return {
    user: state.auth.userData,
    organization: state.auth.userData.organization
      ? state.auth.userData.organization.name + ' - eDL Account'
      : 'eDynamic Learning Account',
    showAppBar: state.ui.appBarState.visible,
    canAdd:
      state.ui.appBarState.canAdd ||
      state.auth.userData.permission === 'superadmin',
    title: state.ui.appBarState.title,
    rightIcon: state.ui.appBarState.rightIcon,
    handleRight: state.ui.appBarState.handleRight,
    isNavDrawerPinned:
      state.ui.isNavDrawerPinned &&
      state.auth.isLoggedIn &&
      state.router.location.pathname !== '/password-reset',
    isUserLoggedIn: state.auth.isLoggedIn,
    isLoggedInUserSuperAdmin: state.auth.userData.permission === 'superadmin',
    location: state.router.location.pathname,
    downloadsState: state.downloads
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setNavDrawerPinnedState: isNavDrawerPinned => {
      return dispatch(setNavDrawerPinnedState(isNavDrawerPinned))
    },
    awaitUserData: () => dispatch(awaitUserData()),
    fetchNotifications: (page, limit) => {
      dispatch(fetchNotificationsAwait(page, limit))
    },
    fetchCourseFolders: () => dispatch(fetchCourseFoldersAwait()),
    fetchCourses: () => {
      dispatch(fetchCoursesAwait())
    },
    fetchMyDownloads: () => {
      dispatch(downloadsFetchAwait())
    },
    fetchOrganizations: (page, limit, search) => {
      dispatch(fetchOrganizationsAwait(page, limit, search))
    },
    fetchUsers: () => {
      dispatch(fetchUsersAwait())
    },
    fetchFiles: () => {
      dispatch(fetchFilesAwait())
    },
    fetchTags: (page, limit, search) => {
      dispatch(fetchTagsAwait(page, limit, search))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppLayout)
)
