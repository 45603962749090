import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import styled from 'styled-components'

const OrgTagChip = styled(Chip)`
  && {
    background-color: transparent;
    border: 1px solid darkgray;
    margin-right: 0.5em;
  }
`

const OrgTagsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25em;
  overflow: auto;
  scrollbar-width: thin;
`

const OrganizationTags = ({ tags }) => {
  return (
    tags?.length > 0 && (
      <OrgTagsRow>
        {tags?.map((lms, index) => (
          <OrgTagChip key={index} label={lms.name.replace('lms_', '')} />
        ))}
      </OrgTagsRow>
    )
  )
}

OrganizationTags.propTypes = {
  tags: PropTypes.array.isRequired
}

export default OrganizationTags
