import joi from 'joi'
import createForm from '../../components/form/createForm'
import TextInput from '../../components/form/elements/TextInput'
import SelectInput from '../../components/form/elements/SelectInput'
import { OrganizationTypes } from '../organizations/Organizations'

export default ({ organization = {}, onSubmit, ...props }) =>
  createForm({
    fields: [
      {
        id: 'name',
        label: 'Name',
        inputComponent: TextInput,
        required: true,
        validation: joi.string(),
        defaultValue: organization.name
      },
      {
        id: 'salesforceId',
        label: 'Salesforce ID',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: organization.salesforceId
      },
      {
        id: 'buzzId',
        label: 'Buzz IDs',
        inputComponent: TextInput,
        required: false,
        validation: joi.string().allow(''),
        defaultValue: organization.buzzId
      },
      {
        id: 'type',
        label: 'Organization Type',
        inputComponent: SelectInput,
        inputOptions: {
          options: Object.entries(OrganizationTypes).map(([key, value]) => ({
            value: value,
            label: key
          }))
        },
        required: false,
        validation: joi.string().allow(''),
        defaultValue: organization.type
      }
    ],
    layoutOptions: {
      submitText: organization.id
        ? 'Update Organization'
        : 'Create Organization'
    },
    onSubmit: async data => {
      return onSubmit(data)
    },
    ...props
  })
