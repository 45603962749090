import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Indicator = styled(CircularProgress)`
  && {
    color: #e67825;
  }
`

const Spinner = ({ loading, textOnly = false }) =>
  textOnly ? (
    <div>{loading && <div>Loading...</div>}</div>
  ) : (
    <>
      {loading && (
        <SpinnerContainer>
          <Indicator />
        </SpinnerContainer>
      )}
    </>
  )

Spinner.defaultProps = {
  loading: false,
  textOnly: false
}

Spinner.propTypes = {
  loading: PropTypes.bool,
  textOnly: PropTypes.bool
}

export default Spinner
