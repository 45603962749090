import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

const ForgotPasswordCard = ({
  username,
  inputErrors,
  setForgotPasswordCardShown,
  handleForgotPassword,
  setFieldError,
  handleInputChange
}) => (
  <Card
    className="login"
    onKeyPress={e => {
      if (e.key === 'Enter') {
        handleForgotPassword()
      }
    }}>
    <TextField
      type="text"
      inputProps={{ 'data-testid': 'resetPasswordEmail' }}
      label="Email"
      value={username}
      onChange={event => handleInputChange('username', event.target.value)}
      error={inputErrors?.usernameError?.isError}
      helperText={
        inputErrors?.usernameError?.isError &&
        inputErrors?.usernameError?.message
      }
      fullWidth
      margin="normal"
    />
    <div className="footer">
      <Button
        className="back"
        onClick={() => {
          setForgotPasswordCardShown(false)
          if (username.length === 0) setFieldError('username', false)
        }}>
        Back
      </Button>
      <Button
        data-testid="resetPasswordButton"
        className="submit"
        onClick={handleForgotPassword}
        variant="contained"
        color="primary">
        Forgot Password
      </Button>
    </div>
  </Card>
)

ForgotPasswordCard.propTypes = {
  username: PropTypes.string.isRequired,
  inputErrors: PropTypes.object.isRequired,
  setForgotPasswordCardShown: PropTypes.func.isRequired,
  handleForgotPassword: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired
}

export default ForgotPasswordCard
