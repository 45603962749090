import React from 'react'
import PropTypes from 'prop-types'
import ChannelDomainForm from './ChannelDomainForm'
import Breadcrumbs from '../../components/Breadcrumbs'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const ChannelDomainCreate = ({ channel, organization, onSubmit }) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        }
      ]}
    />
    <div style={{ clear: 'both' }} />
    <Card>
      <CardHeader className="title" title="Create Domain" />
      <CardContent className="description">
        <ChannelDomainForm channel={channel} onSubmit={onSubmit} />
      </CardContent>
    </Card>
  </div>
)

ChannelDomainCreate.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onExchange: PropTypes.func.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired
}

export default ChannelDomainCreate
