import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import SyncIcon from 'mdi-react/SyncIcon'
const StyledSyncIcon = styled(SyncIcon)`
  color: ${props => (props.active ? '#c66c17' : 'inherit')};
`
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = ({ title, autoSync, status }) => (
  <TitleWrapper>
    <span>
      {title}
      {autoSync && <StyledSyncIcon active />}
    </span>
    {status && status}
  </TitleWrapper>
)

Title.propTypes = {
  title: PropTypes.string,
  status: PropTypes.node,
  autoSync: PropTypes.bool
}

const Integration = ({
  configured,
  canManage,
  autoSync,
  title,
  onSync,
  syncing,
  canView,
  status,
  body,
  actions,
  configureBody
}) =>
  canView ? (
    configured ? (
      <div style={{ marginTop: '20px' }}>
        <Card>
          <CardHeader
            className="title"
            title={<Title title={title} autoSync={autoSync} status={status} />}
          />
          <CardContent className="description">{body}</CardContent>
          <CardActions>
            {canManage && (
              <Button disabled={syncing} onClick={() => onSync()}>
                <SyncIcon /> Force Sync
              </Button>
            )}
            {actions}
          </CardActions>
        </Card>
      </div>
    ) : canManage && !configured && configureBody ? (
      <Card style={{ marginTop: '20px' }}>
        <CardHeader className="title" title={<span>{title} Setup</span>} />
        <CardContent className="description">{configureBody}</CardContent>
      </Card>
    ) : null
  ) : null

Integration.defaultProps = {
  Actions: <></>,
  canView: true
}

Integration.propTypes = {
  configured: PropTypes.bool.isRequired,
  canManage: PropTypes.bool,
  autoSync: PropTypes.bool,
  title: PropTypes.string,
  onSync: PropTypes.func,
  syncing: PropTypes.bool,
  canView: PropTypes.bool,
  status: PropTypes.node,
  configureBody: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  body: PropTypes.oneOfType([PropTypes.bool, PropTypes.element])
}

export default Integration
