import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../components/Breadcrumbs'
import Link from '../../components/Link'

const buildLink = partner =>
  `/tools/partner-course-usage-reporting/${partner.id}`

const PartnerCourseReporting = ({ partners }) => {
  return (
    <div>
      <Breadcrumbs links={[{ title: 'Tools', href: '/tools' }]} />
      <Card>
        <CardHeader className="title" title="Partner Course Usage Reporting" />
        <CardContent className="description">
          <h3>Step 1: Select a partner</h3>
          <ul>
            {partners.map(partner => (
              <li key={partner.id}>
                <Link to={buildLink(partner)}>{partner.name}</Link>
              </li>
            ))}
          </ul>

          <h3>or, Import a CSV file</h3>
          <Link to="/tools/partner-course-usage-reporting/import">
            Import CSV
          </Link>
        </CardContent>
      </Card>
    </div>
  )
}

PartnerCourseReporting.propTypes = {
  partners: PropTypes.array.isRequired
}

export default PartnerCourseReporting
