import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Screen from '../../components/Screen'
import GateExists from '../../components/GateExists'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import ChannelListItem from '../../components/ChannelListItem'
import Breadcrumbs from '../../components/Breadcrumbs'
import PlusIcon from 'mdi-react/PlusIcon'
import { useOrganization } from '../../hooks/useOrganization'

const OrganizationChannels = ({
  history,
  error,
  setAppBarState,
  fetchChannels,
  isLoading,
  organizationId,
  isSuperAdmin,
  user,
  channels,
  onChannelEdit,
  onChannelTaas,
  onChannelUsers,
  onChannelReports,
  onChannelSyncFrost,
  onChannelSyncBuzz,
  onChannelSyncBuzz2
}) => {
  const { organization, isFetchingOrg, orgError } = useOrganization(
    organizationId
  )

  useEffect(() => {
    setAppBarState({
      visible: true,
      title: 'Channels',
      rightIcon: <PlusIcon />,
      handleRight: () =>
        history.push(`/organizations/${organizationId}/channels/create`)
    })
  }, [])

  useEffect(() => {
    fetchChannels()
  }, [])

  return (
    <Screen
      name="organization channels"
      isLoading={isLoading || isFetchingOrg}
      error={error || orgError}>
      <GateExists exists={organization}>
        <div className="main">
          <Breadcrumbs
            links={[
              { title: 'Organizations', href: '/organizations' },
              {
                title: organization?.name || '',
                href: `/organizations/${organization?.id}`
              }
            ]}
          />
          <Card className="organizations">
            <CardHeader className="title" title="Channels" />
            {channels.length ? (
              channels.map((channel, i) => (
                <React.Fragment key={channel.id}>
                  <ChannelListItem
                    user={user}
                    isSuperAdmin={isSuperAdmin}
                    organization={organization}
                    channel={channel}
                    onChannelEdit={channel => onChannelEdit(channel)}
                    onChannelTaas={channel => onChannelTaas(channel)}
                    onChannelUsers={channel => onChannelUsers(channel)}
                    onChannelSyncFrost={channel => onChannelSyncFrost(channel)}
                    onChannelSyncBuzz={channel => onChannelSyncBuzz(channel)}
                    onChannelSyncBuzz2={channel => onChannelSyncBuzz2(channel)}
                    onChannelReports={channel => onChannelReports(channel)}
                  />
                  {i < channels.length - 1 && <Divider />}
                </React.Fragment>
              ))
            ) : (
              <CardContent>No channels found.</CardContent>
            )}
          </Card>
        </div>
      </GateExists>
    </Screen>
  )
}

OrganizationChannels.propTypes = {
  isSuperAdmin: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  isLoading: PropTypes.bool.isRequired,
  setAppBarState: PropTypes.func.isRequired,
  fetchChannels: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
  channels: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  onChannelEdit: PropTypes.func.isRequired,
  onChannelTaas: PropTypes.func.isRequired,
  onChannelReports: PropTypes.func.isRequired,
  onChannelSyncFrost: PropTypes.func.isRequired,
  onChannelSyncBuzz: PropTypes.func.isRequired,
  onChannelSyncBuzz2: PropTypes.func.isRequired,
  onChannelUsers: PropTypes.func.isRequired,
  error: PropTypes.object
}

export default OrganizationChannels
