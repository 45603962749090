import React from 'react'
import { connect } from 'react-redux'
import TaasStudentFacilitatorList from './TaasStudentFacilitatorList'
import Screen from '../../components/Screen'
import { isSuperAdmin } from '../../selectors/auth'
import {
  fetchStudent,
  fetchFacilitators,
  fetchStudentFacilitators,
  addStudentFacilitator,
  removeStudentFacilitator
} from '../../actions/taas'
import { channelsFetchAwait } from '../../actions/channels'
import { setAppBarState } from '../../actions/ui'
import { getOrganization, getChannel } from '../../selectors'
import GateExists from '../../components/GateExists'

class TaasStudentFacilitatorListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, students: [], error: null, searchTerm: '' }
    this.props.setAppBarState({
      visible: true,
      title: 'Student Facilitators',
      rightIcon: null
    })
  }

  async componentDidMount() {
    this.props.fetchChannels()
    try {
      this.setState({ loading: true, student: null, facilitators: [] })
      const [
        student,
        studentFacilitators,
        allFacilitators
      ] = await Promise.all([
        this.props.fetchStudent(),
        this.props.fetchStudentFacilitators(),
        this.props.fetchFacilitators()
      ])

      const facilitators = allFacilitators.map(row => ({
        ...row,
        authorized: studentFacilitators.some(
          studentFacilitator => studentFacilitator.id === row.id
        )
      }))

      this.setState({ loading: false, student, facilitators })
    } catch (e) {
      console.error(e.message)
      this.setState({ loading: false, error: e.message })
    }
  }

  handleSearch = searchTerm => {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => this.setState({ searchTerm }), 300)
  }

  render() {
    const { isLoading, error: propError } = this.props
    const {
      loading,
      error: stateError,
      student,
      facilitators,
      searchTerm
    } = this.state

    return (
      <Screen
        name="student facilitators"
        isLoading={isLoading || loading}
        error={propError || stateError}>
        <GateExists
          exists={
            this.props.channel &&
            this.props.organization &&
            this.state.student &&
            this.state.facilitators
          }>
          <TaasStudentFacilitatorList
            {...this.props}
            student={student}
            facilitators={facilitators}
            searchTerm={searchTerm}
            onSearch={this.handleSearch}
          />
        </GateExists>
      </Screen>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isSuperAdmin: isSuperAdmin(state),
  channel: getChannel(ownProps.match.params.channel)(state),
  organization: getOrganization(ownProps.match.params.organization)(state),
  isLoading: state.organizations.isFetching || state.channels.isFetching
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchChannels: () => {
    dispatch(
      channelsFetchAwait(
        ownProps.match.params.organization,
        ownProps.match.params.channel,
        true
      )
    )
  },
  fetchStudent: () =>
    fetchStudent(ownProps.match.params.channel, ownProps.match.params.student),
  fetchStudentFacilitators: () =>
    fetchStudentFacilitators(
      ownProps.match.params.channel,
      ownProps.match.params.student
    ),
  fetchFacilitators: () => fetchFacilitators(ownProps.match.params.channel),
  onAdd: (student, facilitator) =>
    dispatch(addStudentFacilitator(student, facilitator)),
  onRemove: (student, facilitator) =>
    dispatch(removeStudentFacilitator(student, facilitator))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaasStudentFacilitatorListContainer)
