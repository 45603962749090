import React from 'react'
import { connect } from 'react-redux'
import AdminList from './AdminList'
import Screen from '../../components/Screen'
import { setAppBarState } from '../../actions/ui'
import { fetchAdmins, deleteAdmin } from '../../actions/admins'
import { canAssume } from '../../selectors/auth'
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'

class AdminListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isLoading: true, admins: null, error: null }
    props.setAppBarState({
      visible: true,
      title: 'Admins',
      rightIcon: <PlusCircleOutlineIcon />,
      handleRight: () => props.onCreate()
    })
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true, admins: null, error: null })
      const admins = await this.props.fetchAdmins()
      this.setState({ isLoading: false, admins })
    } catch (e) {
      this.setState({ isLoading: false, error: e })
    }
  }

  render() {
    const { isLoading, error, admins } = this.state

    return (
      <Screen name="admins" isLoading={isLoading} error={error}>
        <AdminList admins={admins} {...this.props} />
      </Screen>
    )
  }
}

const mapStateToProps = state => ({
  me: {
    id: 'made up',
    ...state.auth.userData
  },
  canAssume: canAssume(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  setAppBarState: appBarState => dispatch(setAppBarState(appBarState)),
  fetchAdmins: () => fetchAdmins(),
  onCreate: () => ownProps.history.push(`/admins/create`),
  onEdit: user => ownProps.history.push(`/admins/${user.id}/edit`),
  onDelete: user => dispatch(deleteAdmin(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminListContainer)
