const initial = {
  isFetching: false,
  isAdding: false,
  isEditing: false,
  isDeleting: false,
  error: {
    fetch: null,
    add: null,
    edit: null,
    delete: null
  },
  items: []
}

function user(state = initial, action) {
  switch (action.type) {
    case 'ADD_USER': {
      const isAdding = true
      const stateChange = { isAdding }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_USER_SUCCESS': {
      const isAdding = false
      const error = Object.assign({}, state.error, { add: null })
      const stateChange = { isAdding, error }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_USER_FAILURE': {
      const isAdding = false
      const error = Object.assign({}, state.error, { add: action.payload })
      const stateChange = { isAdding, error }
      return Object.assign({}, state, stateChange)
    }
    case 'ADD_USER_DISMISS': {
      const isAdding = false
      const error = Object.assign({}, state.error, { add: action.payload })
      const stateChange = { isAdding, error }
      return Object.assign({}, state, stateChange)
    }
    case 'FETCH_USERS': {
      const isFetching = true
      const stateChange = { isFetching }
      return Object.assign({}, state, stateChange)
    }
    case 'FETCH_USERS_SUCCESS': {
      const isFetching = false
      const error = Object.assign({}, state.error, { fetch: null })
      const items = action.payload
      const stateChange = { isFetching, error, items }
      return Object.assign({}, state, stateChange)
    }
    case 'FETCH_USERS_FAILURE': {
      const isFetching = false
      const error = Object.assign({}, state.error, { fetch: action.payload })
      const items = []
      const stateChange = { isFetching, error, items }
      return Object.assign({}, state, stateChange)
    }
    case 'EDIT_USER': {
      const isEditing = true
      const stateChange = { isEditing }
      return Object.assign({}, state, stateChange)
    }
    case 'EDIT_USER_SUCCESS': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: null })
      const stateChange = { isEditing, error }
      return Object.assign({}, state, stateChange)
    }
    case 'EDIT_USER_FAILURE': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: action.payload })
      const stateChange = { isEditing, error }
      return Object.assign({}, state, stateChange)
    }
    case 'EDIT_USER_DISMISS': {
      const isEditing = false
      const error = Object.assign({}, state.error, { edit: action.payload })
      const stateChange = { isEditing, error }
      return Object.assign({}, state, stateChange)
    }
    case 'DELETE_USER': {
      return { ...state, isDeleting: true }
    }
    case 'DELETE_USER_SUCCESS': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: null })
      return { ...state, isDeleting, error }
    }
    case 'DELETE_USER_FAILURE': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: action.payload })
      return { ...state, isDeleting, error }
    }
    case 'DELETE_USER_DISMISS': {
      const isDeleting = false
      const error = Object.assign({}, state.error, { delete: null })
      return { ...state, isDeleting, error }
    }
    default: {
      return state
    }
  }
}

export default user
