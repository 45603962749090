import { channelGetReport } from '../requests/channels'
import { useRequest } from './useRequest'

export const useReport = (channelId, reportId) => {
  const { result, isFetching, error } = useRequest(
    channelGetReport,
    channelId,
    reportId
  )

  return {
    report: result,
    isFetchingReport: isFetching,
    reportError: error
  }
}
