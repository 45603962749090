import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Breadcrumbs from '../../components/Breadcrumbs'
import TaasTeacherEnrolmentForm from '../taas-teacher-enrolment-create/TaasTeacherEnrolmentForm'

const TaasTeacherEnrolmentEdit = ({
  channel,
  organization,
  teacher,
  courses,
  enrolment,
  onSubmit
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        { title: organization.name, href: `/organizations/${organization.id}` },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        },
        {
          title: 'Teachers',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/teachers`
        },
        {
          title: `${teacher.firstname} ${teacher.lastname}`,
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/teachers/${teacher.id}`
        },
        {
          title: 'Enrollments',
          href: `/organizations/${organization.id}/channels/${channel.id}/taas/teachers/${teacher.id}/enrolments`
        }
      ]}
    />
    <Card className="teachers">
      <CardHeader className="title" title={`${enrolment.course.title}`} />
      <CardContent className="description">
        <TaasTeacherEnrolmentForm
          courses={courses}
          enrolment={enrolment}
          onSubmit={onSubmit}
        />
      </CardContent>
    </Card>
  </div>
)

TaasTeacherEnrolmentEdit.propTypes = {
  teacher: PropTypes.object.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  enrolment: PropTypes.object.isRequired,
  courses: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default TaasTeacherEnrolmentEdit
