import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledFileLink = styled.button`
  display: flex;
  width: ${props => props.width || null};
  align-items: center;
  border: none;
  font-size: 1rem;
  padding: 0;
  opacity: 0.5;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

const LinkLabel = styled.span`
  display: block;
  line-height: 21px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LinkIcon = styled.span`
  display: block;
  height: 31px;
  line-height: 27px;
  vertical-align: middle;
  margin-right: 10px;
  fill: #757575;
`

const FileLink = ({ onClick, icon, label, width }) => (
  <StyledFileLink onClick={onClick} width={width}>
    <LinkIcon>{icon}</LinkIcon>
    <LinkLabel>{label}</LinkLabel>
  </StyledFileLink>
)

FileLink.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.object,
  width: PropTypes.string
}

export default FileLink
