import axios from 'axios'
import { getToken } from '../auth'
import { push } from 'connected-react-router'
import { FILTER_ALL } from '../pages/Courses'

export function fetchCoursesAwait(maybeFilter) {
  return function(dispatch) {
    if (!getToken()) return dispatch(fetchCoursesFailure())

    dispatch(fetchingCourses())
    return fetchCourses(maybeFilter).then(
      result => dispatch(fetchCoursesSuccess(result)),
      err => dispatch(fetchCoursesFailure(err))
    )
  }
}

async function fetchCourses(maybeFilter) {
  let url = process.env.REACT_APP_REMOTE + '/v2/courses'
  if (maybeFilter && maybeFilter !== FILTER_ALL) {
    url = `${url}?courseType=${maybeFilter}`
  }

  const result = await axios({
    method: 'GET',
    url,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

function fetchingCourses() {
  const payload = 'Loading courses'
  return { type: 'FETCH_COURSES', payload }
}

function fetchCoursesSuccess(courses) {
  const payload = courses
  return { type: 'FETCH_COURSES_SUCCESS', payload }
}

function fetchCoursesFailure() {
  const payload = 'There was an error loading courses'
  return { type: 'FETCH_COURSES_FAILURE', payload }
}

export function addCourseFileAwait(type, file, courseId) {
  return async dispatch => {
    if (!getToken()) return dispatch(addCourseFileFailure())

    dispatch(addingCourseFile())
    try {
      const result = await addCourseFile(type, file, courseId)
      dispatch(addCourseFileSuccess(result))
      return result
    } catch (e) {
      return dispatch(addCourseFileFailure(e))
    }
  }
}

async function getSignedS3PutUrl(params, id) {
  const sig = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + `/courses/${id}/signS3PutUrl`,
    params,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return sig
}

async function addCourseFile(type, file, courseId) {
  const sig = await getSignedS3PutUrl(
    { type, filename: file.name, contentType: file.type },
    courseId
  )

  const headers = { 'Content-Type': file.type }
  await axios.put(sig.data.signedUrl, file, { headers })

  return sig.data
}

function addingCourseFile() {
  const payload = 'Adding file to course'
  return { type: 'ADD_FILE', payload }
}

function addCourseFileSuccess() {
  const payload = 'Successfully added the file to course'
  return { type: 'ADD_FILE_SUCCESS', payload }
}

function addCourseFileFailure() {
  const payload = 'Failed to update course file'
  return { type: 'ADD_FILE_FAILURE', payload }
}

export function dismissAddFileError() {
  const payload = null
  return { type: 'ADD_FILE_ERROR_DISMISS', payload }
}

export function createCourseAwait(newCourse) {
  return async dispatch => {
    dispatch(addingCourse())

    try {
      const course = await postCourse(newCourse)
      dispatch(addCourseSuccess())
      return course
    } catch (e) {
      return dispatch(addCourseFail(e))
    }
  }
}

async function postCourse(newCourse) {
  return axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/courses',
    data: newCourse,
    withCredentials: true,
    headers: { Authorization: `Bearer ${getToken()}` }
  })
}

function addingCourse() {
  const payload = 'Adding course'
  return { type: 'ADD_COURSE', payload }
}

function addCourseSuccess() {
  const payload = 'Added course successfully'
  return { type: 'ADD_COURSE_SUCCESS', payload }
}

function addCourseFail(reason) {
  if (reason.response && reason.response.status === 400) {
    return {
      type: 'ADD_COURSE_FAILURE',
      payload: reason.response.data.message
    }
  }

  const payload = 'Failed to add course'
  return { type: 'ADD_COURSE_FAILURE', payload }
}

export function dismissAddCourseError() {
  const payload = null
  return { type: 'ADD_COURSE_ERROR_DISMISS', payload }
}

export function editCourseAwait(newCourse) {
  return async dispatch => {
    dispatch(editingCourse())

    try {
      const course = await putCourse(newCourse)
      dispatch(editCourseSuccess())
      return course
    } catch (e) {
      return dispatch(editCourseFail(e))
    }
  }
}

async function putCourse(newCourse) {
  // eslint-disable-next-line no-unused-vars
  const { courseType, ...rest } = newCourse
  return axios({
    method: 'PUT',
    url: process.env.REACT_APP_REMOTE + `/courses/${newCourse.id}`,
    data: { ...rest },
    withCredentials: true,
    headers: { Authorization: `Bearer ${getToken()}` }
  })
}

function editingCourse() {
  const payload = 'Editing course'
  return { type: 'EDIT_COURSE', payload }
}

function editCourseSuccess() {
  const payload = 'Edited course successfully'
  return { type: 'EDIT_COURSE_SUCCESS', payload }
}

function editCourseFail(reason) {
  if (reason.response && reason.response.status === 400) {
    return {
      type: 'EDIT_COURSE_FAILURE',
      payload: reason.response.data.message
    }
  }

  const payload = 'Failed to edit course'
  return { type: 'EDIT_COURSE_FAILURE', payload }
}

export function dismissEditError() {
  const payload = null
  return { type: 'EDIT_COURSE_ERROR_DISMISS', payload }
}

export async function suggestCourses(query) {
  const result = await axios({
    method: 'GET',
    url: process.env.REACT_APP_REMOTE + '/courses/suggest',
    params: { query },
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() }
  })
  return result.data
}

export function selectionAdd(ids) {
  return { type: 'COURSE_SELECTIONS_ADD', payload: ids }
}

export function selectionRemove(ids) {
  return { type: 'COURSE_SELECTIONS_REMOVE', payload: ids }
}

export function selectionClear() {
  return { type: 'COURSE_SELECTIONS_CLEAR', payload: null }
}

export function coursesReleaseDatePrompt(courseIds) {
  return { type: 'COURSES_RELEASE_DATE_PROMPT', payload: courseIds }
}

export function coursesReleaseDateCancel() {
  return { type: 'COURSES_RELEASE_DATE_CANCEL' }
}

export function coursesReleaseDateSubmitAwait({ dateReleasing }) {
  return function(dispatch, getState) {
    const courses = getState().courses.releaseDates.courses
    dispatch(coursesReleaseDateStart())
    return coursesSetReleaseDate(dateReleasing, courses)
      .then(() => {
        dispatch(coursesReleaseDateFinish(dateReleasing))
      })
      .catch(error => {
        dispatch(coursesReleaseDateFailure(error))
      })
  }
}

async function coursesSetReleaseDate(date, courses) {
  await axios({
    method: 'PUT',
    url: process.env.REACT_APP_REMOTE + `/courses/release-dates`,
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data: {
      courses,
      dateReleasing: date || null
    }
  })
}

function coursesReleaseDateStart() {
  return { type: 'COURSES_RELEASE_DATE_START' }
}

function coursesReleaseDateFinish(date) {
  return { type: 'COURSES_RELEASE_DATE_FINISH', payload: date }
}

function coursesReleaseDateFailure(error) {
  return { type: 'COURSES_RELEASE_DATE_FAILURE', payload: error.message }
}

export function courseMerge(data) {
  return async function(dispatch) {
    await mergeCourse(data)
    alert(
      'The merge is still running in the background. You will be emailed when it is done.'
    )
    dispatch(push('/tools'))
  }
}

async function mergeCourse(data) {
  await axios({
    method: 'POST',
    url: process.env.REACT_APP_REMOTE + '/courses/merge',
    withCredentials: true,
    headers: { Authorization: 'Bearer ' + getToken() },
    data
  })
}
