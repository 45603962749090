import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from 'mdi-react/AddIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import CheckIcon from 'mdi-react/CheckIcon'
import CircularProgress from '@material-ui/core/CircularProgress'
import CardRow from '../../components/CardRow'
import Breadcrumbs from '../../components/Breadcrumbs'

const OrganizationChannelUsers = ({
  organization,
  channel,
  users,
  onUsersAdd,
  onUsersRemove,
  isAdmin
}) => (
  <div>
    <Breadcrumbs
      links={[
        { title: 'Organizations', href: '/organizations' },
        {
          title: organization.name,
          href: `/organizations/${organization.id}`
        },
        {
          title: 'Channels',
          href: `/organizations/${organization.id}/channels`
        },
        {
          title: channel.name,
          href: `/organizations/${organization.id}/channels/${channel.id}`
        }
      ]}
    />
    {users.length ? (
      <Card className="organizations">
        <CardHeader
          className="title"
          title={`Channel Users: ${channel.name}`}
        />
        {users.map(user => (
          <CardRow key={user.id}>
            <div>
              {user.name.first} {user.name.last}
            </div>
            <div>
              {user.changing ? (
                <CircularProgress />
              ) : user.inChannel ? (
                isAdmin ? (
                  <IconButton onClick={() => onUsersRemove(user)}>
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <IconButton disabled onClick={() => {}}>
                    <CheckIcon />
                  </IconButton>
                )
              ) : (
                <IconButton onClick={() => onUsersAdd(user)}>
                  <AddIcon />
                </IconButton>
              )}
            </div>
          </CardRow>
        ))}
      </Card>
    ) : (
      <Card>
        <CardRow>
          <p>No users found in organization.</p>
        </CardRow>
      </Card>
    )}
  </div>
)

OrganizationChannelUsers.propTypes = {
  organization: PropTypes.object,
  channel: PropTypes.object,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUsersAdd: PropTypes.func.isRequired,
  onUsersRemove: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired
}

export default OrganizationChannelUsers
