import React from 'react'
import PropTypes from 'prop-types'
import TaasResetForm from './TaasResetForm'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

const TaasReset = ({ onSubmit, token, username, complete }) => (
  <div>
    <Card>
      <CardHeader className="title" title="Reset Password" />
      <CardContent className="description">
        {complete ? (
          <p>Your password has been changed.</p>
        ) : (
          <div>
            <TaasResetForm
              token={token}
              username={username}
              onSubmit={onSubmit}
            />
          </div>
        )}
      </CardContent>
    </Card>
  </div>
)

TaasReset.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  complete: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
}

export default TaasReset
