import { Tab } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import Tabs from '@material-ui/core/Tabs'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { copyToClipboard } from '../../actions/channels'
import Breadcrumbs from '../../components/Breadcrumbs'
import ChannelPlatformForm from './ChannelPlatformConfigureForm'

// eslint-disable-next-line
const CopyableValue = ({ children }) => (
  <div>
    <code>{children}</code>
    <IconButton onClick={() => copyToClipboard(children)}>
      <ContentCopyIcon size={16} />
    </IconButton>
  </div>
)

const a11yProps = index => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const ChannelPlatformConfigure = ({
  channel,
  config,
  organization,
  onSubmit
}) => {
  const [tabIndex, setTabIndex] = useState(0)
  const handleChangeTab = (_, index) => {
    setTabIndex(index)
  }
  const toolConfigs = [
    {
      header: 'Courseware',
      tool: 'EDL',
      ...config.apps.delivery
    },
    {
      header: 'Learning Blade',
      ...config.apps.dash
    }
  ]

  return (
    <div>
      <Breadcrumbs
        links={[
          { title: 'Organizations', href: '/organizations' },
          {
            title: organization.name,
            href: `/organizations/${organization.id}`
          },
          {
            title: 'Channels',
            href: `/organizations/${organization.id}/channels`
          },
          {
            title: channel.name,
            href: `/organizations/${organization.id}/channels/${channel.id}`
          }
        ]}
      />
      <div style={{ clear: 'both' }} />

      <div>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          {toolConfigs.map((config, idx) => (
            <Tab
              key={config.header}
              label={config.header}
              {...a11yProps(idx)}
            />
          ))}
        </Tabs>
        {toolConfigs.map(
          (config, idx) =>
            tabIndex === idx && (
              <Card key={config.header}>
                <CardHeader title={config.header} />
                <CardContent className="description">
                  <p>
                    Use these values when inside the LMS adding the{' '}
                    {config.tool || config.header} tool.
                  </p>

                  <ul>
                    <li>
                      OIDC Login URL:{' '}
                      <CopyableValue>
                        {config.oidc_initiation_url}
                      </CopyableValue>
                    </li>
                    <li>
                      Target Link URL:{' '}
                      <CopyableValue>{config.target_link_uri}</CopyableValue>
                    </li>
                    <li>
                      Public JWK URL:{' '}
                      <CopyableValue>{config.public_jwk_url}</CopyableValue>
                    </li>
                    <li>
                      Redirect URLs:{' '}
                      <ul>
                        {config.redirect_uris.map((url, i) => (
                          <li key={i}>
                            <CopyableValue>{url}</CopyableValue>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </CardContent>
              </Card>
            )
        )}
      </div>

      <div style={{ marginTop: '20px', clear: 'both' }} />

      <Card>
        <CardHeader className="title" title="Configure LTI 1.3 Platform" />
        <CardContent className="description">
          <p>Get these values from the LMS after adding the tool.</p>
          <ChannelPlatformForm
            channel={channel}
            platform={channel.platform || {}}
            onSubmit={onSubmit}
          />
        </CardContent>
      </Card>
    </div>
  )
}

ChannelPlatformConfigure.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  channel: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired
}

export default ChannelPlatformConfigure
