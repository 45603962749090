import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import joi from 'joi'

import DynamicFormContainer from './DynamicFormContainer'

const createForm = ({
  children,
  fields,
  derivedFields,
  onSubmit,
  onChange,
  ...props
}) => {
  const validationRules = joi.object(
    fields
      .filter(field => field.validation)
      .reduce(
        (out, field) => ({
          ...out,
          [field.id]: field.validation.label(field.label || field.id)
        }),
        {}
      )
  )

  return (
    <DynamicFormContainer
      onSubmit={onSubmit || noop}
      validationRules={validationRules}
      fields={fields}
      derivedFields={derivedFields}
      onChange={onChange || noop}
      {...props}>
      {children}
    </DynamicFormContainer>
  )
}

createForm.propTypes = {
  children: PropTypes.node.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  derivedFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default createForm
